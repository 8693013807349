import { Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import FlexContainer from "../../shared/ui/container/flex-container.component";
import { PaperCard } from "../../shared/ui/paper-card/paper-card.component";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useListPericias } from "../../features/pericia/list-pericias";
import { PericiasList } from "../../features/pericia/list-pericias/ui/pericias-list.component";
import { ToastContainer, toast } from "react-toastify";
import { useUpdatePericia } from "../../features/pericia/update-pericia/api/useUpdatePericia";
import { CreateNewPericia, Pericia } from "../../entities/pericia";

interface Filter {
  done: boolean;
  notDone: boolean;
  billed: boolean;
  priceCalculated: boolean;
  page: number;
}

interface Search {
  term: string;
  done: boolean;
  notDone: boolean;
  billed: boolean;
  priceCalculated: boolean;
  page: number;
}

const INITIAL_SEARCH: Search = {
  term: "",
  done: false,
  notDone: false,
  billed: false,
  priceCalculated: false,
  page: 1
};

const getLocalStorageFilter = (): Search => {
  const filter = localStorage.getItem("pericia-filter");
  if (!filter) {
    return INITIAL_SEARCH;
  }
  return JSON.parse(filter);
};

const setLocalStorageFilter = (filter: Search) => {
  localStorage.setItem("pericia-filter", JSON.stringify(filter));
};

export const ListPericiasPage = () => {
  const [term, setTerm] = useState<string>(getLocalStorageFilter().term);
  const [filter, setFilter] = useState<Filter>(getLocalStorageFilter());
  const [periciaToUpdate, setPericiaToUpdate] = useState<Pericia>(CreateNewPericia());
  const [search, setSearch] = useState(getLocalStorageFilter());
  const { data: pericias, isLoading, error: errorFetchingPericias, refetch: refectPericias } = useListPericias(search);
  const { data: updatePericiaRes, error: errorUpdatingPericia, isLoading: updatePericiaLoading } = useUpdatePericia(periciaToUpdate);

  const debouncedSetSearch = _.debounce(
    () =>
      setSearch({
        ...filter,
        term
      }),
    500
  );

  const handlePericiaUpdate = (pericia: Pericia) => {
    setPericiaToUpdate({ ...pericia });
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setFilter({ ...filter, page: value });
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 1, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    debouncedSetSearch();

    return () => {
      debouncedSetSearch.cancel();
    };
  }, [term]);

  useEffect(() => {
    setSearch({
      ...filter,
      term
    });
  }, [filter]);

  useEffect(() => {
    if (!updatePericiaRes) return;
    refectPericias();
    toast.success("Perícia atualizada com sucesso!");
  }, [updatePericiaRes]);

  useEffect(() => {
    setLocalStorageFilter(search);
  }, [search]);

  if (errorFetchingPericias) toast.error(errorFetchingPericias.toast_message);
  if (errorUpdatingPericia) toast.error("Erro ao atualizar perícia...");

  return (
    <FlexContainer>
      <Typography variant="h4">Busca de Perícias Cadastradas</Typography>
      <Typography variant="subtitle1">Utilize os filtros abaixo para buscar por perícias</Typography>
      <PaperCard title="Filtro">
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={12} mb={2}>
            <TextField
              value={term || ""}
              fullWidth
              id="filter"
              label="cliente, modelo, placa, chassi..."
              name="filter"
              variant="standard"
              onChange={(e) => setTerm(e.target.value)}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <FormGroup sx={{ width: "100%" }}>
              <FormControlLabel
                control={<Checkbox onChange={handleFilterChange} name="notDone" checked={filter.notDone} />}
                label="Andamento"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4} sm={3}>
            <FormGroup sx={{ width: "100%" }}>
              <FormControlLabel
                control={<Checkbox onChange={handleFilterChange} name="done" checked={filter.done} />}
                label="Finalizadas"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4} sm={3}>
            <FormGroup sx={{ width: "100%" }}>
              <FormControlLabel
                control={<Checkbox onChange={handleFilterChange} name="priceCalculated" checked={filter.priceCalculated} />}
                label="Controladas"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4} sm={3}>
            <FormGroup sx={{ width: "100%" }}>
              <FormControlLabel
                control={<Checkbox onChange={handleFilterChange} name="billed" checked={filter.billed} />}
                label="Faturadas"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </PaperCard>
      <PericiasList
        pericias={pericias || []}
        page={filter.page}
        isLoading={isLoading}
        handlePageChange={handlePageChange}
        handlePericiaUpdate={handlePericiaUpdate}
      />
      <ToastContainer />
    </FlexContainer>
  );
};
