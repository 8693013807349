import { useParams } from "react-router-dom";
import FlexContainer from "../../shared/ui/container/flex-container.component";
import { Grid, Paper, Skeleton, TextField, TextareaAutosize, Typography } from "@mui/material";
import { currencyMask, formatCurrency, getNumberFromCurrencyMask } from "../../utils/helpers/numbers";
import { LoadingButton } from "@mui/lab";
import { useCreatePaymentRecord } from "../../features/payment-record/create-payment-record/useCreatePaymentRecord";
import { useEffect, useState } from "react";
import { ListPaymentRecordsResponse } from "../../entities/payment-record";
import { ToastContainer, toast } from "react-toastify";
import { useGetUserBalanceById } from "../../features/user/get-user-balance-by-id/api/useGetUserBalanceById";
import { useListPaymentRecordsByUserId } from "../../features/payment-record/list-payment-records-by-user-id/useListPaymentRecordsByUserId";

const INITIAL_PAYMENT_RECORD_STATE = {
  amount: 0,
  description: "",
  userId: ""
};

export const GetUserBalanceByIdPage = () => {
  const { userId } = useParams();
  const [description, setDescription] = useState("");
  const [amountMask, setAmountMask] = useState<string>(currencyMask("0"));
  const [paymentRecord, setPaymentRecord] = useState(INITIAL_PAYMENT_RECORD_STATE);

  const { data: balanceData, isLoading, isError, refetch: refacthBalance } = useGetUserBalanceById(userId || "");

  const { data: paymentCreated, isLoading: isLoadingCreatePayment, isError: isErrorCreatePayment } = useCreatePaymentRecord(paymentRecord);

  const {
    data: paymentRecords,
    isError: isErrorListPaymentRecords,
    isLoading: isLoadingListPaymentRecords,
    refetch: refetchPaymentRecords
  } = useListPaymentRecordsByUserId(userId || "");

  const handleCreateNewPayment = () => {
    if (!userId) return;
    const value = getNumberFromCurrencyMask(amountMask);

    if (value <= 0) {
      toast.warn("O valor do pagamento deve ser maior que zero");
      return;
    }

    setPaymentRecord({
      ...paymentRecord,
      userId,
      description,
      amount: value
    });
  };

  useEffect(() => {
    if (!paymentCreated) return;

    setPaymentRecord(INITIAL_PAYMENT_RECORD_STATE);
    setAmountMask(currencyMask("0"));
    setDescription("");
    refacthBalance();
    refetchPaymentRecords();
    toast.success("Pagamento emitido com sucesso");
  }, [paymentCreated]);

  if (isError) {
    toast.error("Erro ao buscar saldo do usuário");
  }

  if (isErrorCreatePayment) {
    toast.error("Erro ao emitir pagamento");
  }

  if (isErrorListPaymentRecords) {
    toast.error("Erro ao buscar histórico de pagamentos");
  }

  return (
    <FlexContainer>
      <Typography variant="h4" component="h1" mb={2}>
        Saldo {balanceData && `- ${balanceData?.userDisplayName || "Usuário"}`}
      </Typography>
      {wrapComponentInSkeleton(
        <Paper elevation={3} sx={{ padding: 5, margin: 2, width: "100%", borderRadius: "8px" }}>
          <Typography variant="h5" component="h2" mb={2}>
            Balanço
          </Typography>
          <Grid container>
            <Grid item xs={12} md={4}>
              <TextField
                size="medium"
                margin="dense"
                id="name"
                label="Histórico feito"
                variant="outlined"
                value={formatCurrency(balanceData?.totalHistory || 0)}
                name="balanceData"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                size="medium"
                margin="dense"
                id="name"
                label="Histórico recebido"
                variant="outlined"
                value={formatCurrency(balanceData?.totalReceived || 0)}
                name="balanceData"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                size="medium"
                margin="dense"
                id="name"
                label="A receber"
                variant="outlined"
                value={formatCurrency(balanceData?.balance || 0)}
                name="balanceData"
                disabled
              />
            </Grid>
          </Grid>
        </Paper>,
        isLoading
      )}
      {wrapComponentInSkeleton(
        <Paper elevation={3} sx={{ padding: 5, margin: 2, width: "100%", borderRadius: "8px" }}>
          <Typography variant="h5" component="h2" mb={2}>
            Emitir Pagamento
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                size="medium"
                margin="dense"
                id="name"
                label="Valor"
                variant="standard"
                value={amountMask}
                name="amount"
                onChange={(e) => setAmountMask(currencyMask(e.target.value))}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="medium"
                margin="dense"
                id="name"
                label="Descrição"
                variant="standard"
                value={description}
                name="description"
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <LoadingButton fullWidth variant="contained" onClick={handleCreateNewPayment} loading={isLoadingCreatePayment}>
                Emitir Pagamento
              </LoadingButton>
            </Grid>
          </Grid>
        </Paper>,
        isLoading
      )}
      {wrapComponentInSkeleton(
        <Paper elevation={3} sx={{ padding: 5, margin: 2, width: "100%", borderRadius: "8px" }}>
          <Typography variant="h5" component="h2" mb={2}>
            Histórico recente de pagamentos
          </Typography>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            placeholder="Histórico de pagamentos..."
            value={formatPaymentRecordStatements(paymentRecords)}
            style={{ width: "100%" }}
            disabled
          />
        </Paper>,
        isLoading
      )}
      <ToastContainer />
    </FlexContainer>
  );
};

function wrapComponentInSkeleton(component: JSX.Element, isLoading: boolean) {
  if (!isLoading) return component;

  return <Skeleton width="100%">{component}</Skeleton>;
}

function formatPaymentRecordStatements(paymentRecords: ListPaymentRecordsResponse | undefined | null) {
  if (!paymentRecords) return "";

  return paymentRecords.PaymentRecords.map(
    (payment) =>
      `\n- ${payment.issuedBy.displayName} pagou ${formatCurrency(payment.amount)} em ${new Date(payment.createdAt).toLocaleDateString(
        "pt-BR"
      )} - Detalhes: ${payment.description}.`
  ).join("\n\n");
}
