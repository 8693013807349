import { UseQueryResult, useQuery } from "react-query";
import { ListNotificationsRes, listNotificationsReq } from "../../../../entities/notification/api/list-notifications";

export const queryKeys = {
  ListNotifications: () => ["ListNotifications"]
};

export function useListNotifications(): UseQueryResult<ListNotificationsRes, Error> {
  return useQuery(queryKeys.ListNotifications(), () => listNotificationsReq(), {
    retry: false,
    refetchOnWindowFocus: false
  });
}
