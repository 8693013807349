import { Pagination, Stack, Typography } from "@mui/material";
import FlexContainer from "../../shared/ui/container/flex-container.component";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useListInvoices } from "../../features/invoice/list-invoices/api/useListInvoices";
import { ToastContainer, toast } from "react-toastify";
import { ListInvoicesSearchComponent } from "../../features/invoice/list-invoices/ui/list-invoices-search.component";
import InvoicesListComponent from "../../features/invoice/list-invoices/ui/invoices-list.component";

interface ListInvoicesPageProps {
  term: string;
  startDate: Date;
  endDate: Date;
  page: number;
  limit: number;
  shouldListByDate: boolean;
  enabled: boolean;
}

const YESTERDAY = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

export const ListInvoicesPage = () => {
  const [pageProps, setPageProps] = useState<ListInvoicesPageProps>({
    term: "",
    startDate: YESTERDAY,
    endDate: new Date(),
    page: 1,
    limit: 32,
    shouldListByDate: false,
    enabled: true
  });
  const [displayDateRange, setDisplayDateRange] = useState(false);
  const [termMask, setTermMask] = useState<string>("");
  const { data, error } = useListInvoices(pageProps);
  const { startDate, endDate, page, limit } = pageProps;

  const handleDatesChange = (startDate: Date, endDate: Date) => {
    setPageProps({ ...pageProps, startDate, endDate });
  };

  const toggleDisplayDateRange = () => {
    setPageProps({ ...pageProps, shouldListByDate: !displayDateRange, page: 1 });
    setDisplayDateRange(!displayDateRange);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageProps({ ...pageProps, page: value });
  };

  const debouncedSetPageProps = _.debounce(() => setPageProps({ ...pageProps, term: termMask, page: 1 }), 500);

  useEffect(() => {
    debouncedSetPageProps();

    return () => {
      debouncedSetPageProps.cancel();
    };
  }, [termMask]);

  useEffect(() => {
    if (data && data.invoices.length === 0) {
      toast.info("Nenhuma fatura encontrada", { autoClose: 1000 });
    }
  }, [data]);

  return (
    <FlexContainer>
      <Typography component="h1" variant="h4">
        Lista de faturas
      </Typography>
      <ListInvoicesSearchComponent
        props={{
          termMask,
          startDate,
          endDate,
          displayDateRange,
          handleTermMaskChange: (term: string) => setTermMask(term),
          toggleDisplayDateRange,
          handleDatesChange
        }}
      />
      {data && data.invoices && data.invoices.length > 0 && (
        <InvoicesListComponent props={{ totalWithoutIVA: data.totalPrice, invoices: data.invoices, totalFound: data.totalFound }} />
      )}
      {data && data.invoices && data.invoices.length > 0 && (
        <Stack spacing={2} mt={5}>
          <Pagination
            count={data.invoices.length >= limit ? page + 1 : page}
            size="large"
            onChange={handlePageChange}
            page={page}
            sx={{ alignSelf: "center" }}
          />
        </Stack>
      )}
      <ToastContainer />
    </FlexContainer>
  );
};
