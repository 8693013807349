import supabase from "../../../shared/database/supabase";

interface UpdateUserByIdRequest {
  updated_user: {
    id: string;
    name: string;
    phone: string;
    lastName: string;
    displayName: string;
    nationality: string;
    role: string;
  };
}

export const updateUserById = async (req: UpdateUserByIdRequest): Promise<string | Error> => {
  const { updated_user } = req;
  try {
    const { data, error } = await supabase.functions.invoke("update-user-by-id", {
      body: JSON.stringify({ updated_user })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    return data;
  } catch (err) {
    console.log("error fetching users:", err);
    throw err;
  }
};
