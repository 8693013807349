import { ListItem, ListItemText, Typography } from "@mui/material";
import { Invoice } from "../../../../entities/invoice/model/invoice";
import { PaperCard } from "../../../../shared/ui/paper-card/paper-card.component";
import { currencyMask, formatIntegerToCurrency } from "../../../../utils/helpers/numbers";
import InvoiceListItemComponent from "./invoice-list-item.component";

interface InvoicesListComponentProps {
  props: {
    totalWithoutIVA: number;
    totalFound: number;
    invoices: Invoice[];
  };
}

export const InvoicesListComponent = ({ props }: InvoicesListComponentProps) => {
  const { totalWithoutIVA, totalFound, invoices } = props;

  return (
    <PaperCard title={`Lista - mostrando ${invoices.length} fatura(s) de ${totalFound} encontrada(s)`}>
      <Typography variant="body2">Total sem IVA: {currencyMask(formatIntegerToCurrency(totalWithoutIVA))}</Typography>
      <ListItem
        divider
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
          textAlign: "center",
          marginTop: "2rem"
        }}
      >
        {["FATURA", "CLIENTE", "DATA", "VALOR", "TOTAL C/ IVA", "STATUS"].map((item, idx) => (
          <ListItemText id={`invoice-list-header-${item}`} primary={item} sx={{ flex: "1", fontWeight: "bold" }} key={item} />
        ))}
      </ListItem>
      {invoices.map((invoice) => (
        <InvoiceListItemComponent invoice={invoice} key={invoice.id} />
      ))}
    </PaperCard>
  );
};

export default InvoicesListComponent;
