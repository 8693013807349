export interface Car {
  id: string;
  brand: string;
  model: string;
  plate: string;
  chassisNumber: string;
  color: string;
  insuranceName: string;
  createdAt: Date;
  updatedAt: Date;
}

export function CreateNewCar(override?: Partial<Car>): Car {
  return {
    id: "",
    brand: "",
    model: "",
    plate: "",
    chassisNumber: "",
    color: "",
    insuranceName: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  };
}

export function isValidCar(car: Car): boolean {
  return (
    car.brand !== "" &&
    car.model !== "" &&
    car.plate !== "" &&
    car.chassisNumber !== "" &&
    car.color !== "" &&
    car.insuranceName !== ""
  );
}
