import { UseQueryResult, useQuery } from "react-query";
import { ListInvoicesResponse, listInvoicesRequest } from "../../../../entities/invoice/api/list-invoices";

export interface UseListInvoiceProps {
  term: string;
  startDate: Date;
  endDate: Date;
  page: number;
  limit: number;
  shouldListByDate: boolean;
  enabled: boolean;
}

export const queryKeys = {
  listInvoices: (props: UseListInvoiceProps) => ["listInvoices", props]
};

export function useListInvoices(props: UseListInvoiceProps): UseQueryResult<ListInvoicesResponse, Error> {
  const { term, startDate, endDate, page, limit, shouldListByDate, enabled } = props;

  let start_date = startDate.toISOString();
  let end_date = endDate.toISOString();

  if (!shouldListByDate) {
    start_date = "";
    end_date = "";
  }

  return useQuery(
    queryKeys.listInvoices(props),
    () =>
      listInvoicesRequest({
        term,
        start_date,
        end_date,
        page,
        limit
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: enabled
    }
  );
}
