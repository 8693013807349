import { Checkbox, FormControlLabel, FormGroup, Grid, Paper, Typography } from "@mui/material";
import { PaperCard } from "../../../shared/ui/paper-card/paper-card.component";

interface PericiaStatusProps {
  props: Props;
}

interface Props {
  billed: boolean;
  updatePericiaIsBilled: (billed: boolean) => void;
  isPriceCalculated: boolean;
  updatePericiaIsPriceCalculated: (isPriceCalculated: boolean) => void;
  isEditable: boolean;
  payed: boolean;
  done: boolean;
  updatePericiaIsDone: (done: boolean) => void;
}

const PericiaStatus: React.FC<PericiaStatusProps> = ({ props }) => {
  const { billed, isPriceCalculated, isEditable, payed, done, updatePericiaIsBilled, updatePericiaIsDone, updatePericiaIsPriceCalculated } =
    props;

  return (
    <PaperCard title="Status da perícia">
      <Grid container spacing={2} mt={2}>
        {[
          { label: "Faturado", value: billed, callBack: updatePericiaIsBilled, disabled: false },
          { label: "Controlado", value: isPriceCalculated, callBack: updatePericiaIsPriceCalculated, disabled: false },
          { label: "Pago", value: payed, callBack: () => {}, disabled: true },
          { label: "Finalizado", value: done, callBack: updatePericiaIsDone, disabled: false }
        ].map((status) => {
          return (
            <Grid item xs={12} sm={3} key={`${status.label}-grid-item`}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox disabled={status.disabled} checked={status.value} onChange={(e) => status.callBack(e.target.checked)} />
                  }
                  label={status.label}
                />
              </FormGroup>
            </Grid>
          );
        })}
      </Grid>
    </PaperCard>
  );
};

export default PericiaStatus;
