import { UseQueryResult, useQuery } from "react-query";
import { updateInvoiceReq, UpdateInvoiceResponse } from "../../../../entities/invoice/api/update-invoice";

interface UseUpdateInvoiceProps {
  id: string;
  enabled: boolean;
}

export const queryKeys = {
  updateInvoice: (props: UseUpdateInvoiceProps) => ["updateInvoice", props]
};

export function useUpdateInvoice(props: UseUpdateInvoiceProps): UseQueryResult<UpdateInvoiceResponse, Error> {
  return useQuery(queryKeys.updateInvoice(props), () => updateInvoiceReq(props), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: props.id !== "" && props.enabled
  });
}
