import { CAR_PARTS } from "../../../shared/constants/car-parts.constants";
import { CarPart, FindCarPartArgs } from "../model";
import CarPartDisabledComponent from "./periciaImgCarPartDisabled.component";
import PericiaImgContainer from "./periciaImgContainer.component";

interface PericiaImgProps {
  props: Props;
}

interface Props {
  findCarPart: (name: FindCarPartArgs) => CarPart;
  updateCarPart: (carPart: CarPart) => void;
}

const PericiaImgDisabled: React.FC<PericiaImgProps> = ({ props }) => {
  const {
    PARAFANGO_AD,
    PORTA_AD,
    PORTA_PD,
    PARAFANGO_PD,
    COFANO,
    TETTO,
    PIANTONE_D,
    PIANTONE_S,
    SPORTELLO_S,
    SPORTELLO_I,
    PARAFANGO_AS,
    PORTA_AS,
    PORTA_PS,
    PARAFANGO_PS
  } = CAR_PARTS;
  const { updateCarPart, findCarPart } = props;

  return (
    <PericiaImgContainer>
      <CarPartDisabledComponent top={80} left={170} carPart={findCarPart(PARAFANGO_AD.value)} updateCarPart={updateCarPart} />
      <CarPartDisabledComponent top={65} left={310} carPart={findCarPart(PORTA_AD.value)} updateCarPart={updateCarPart} />
      <CarPartDisabledComponent top={65} left={450} carPart={findCarPart(PORTA_PD.value)} updateCarPart={updateCarPart} />
      <CarPartDisabledComponent top={75} left={580} carPart={findCarPart(PARAFANGO_PD.value)} updateCarPart={updateCarPart} />
      <CarPartDisabledComponent top={270} left={150} carPart={findCarPart(COFANO.value)} updateCarPart={updateCarPart} />
      <CarPartDisabledComponent top={270} left={440} carPart={findCarPart(TETTO.value)} updateCarPart={updateCarPart} />
      <CarPartDisabledComponent top={190} left={530} carPart={findCarPart(PIANTONE_D.value)} updateCarPart={updateCarPart} />
      <CarPartDisabledComponent top={345} left={530} carPart={findCarPart(PIANTONE_S.value)} updateCarPart={updateCarPart} />
      <CarPartDisabledComponent top={270} left={635} carPart={findCarPart(SPORTELLO_S.value)} updateCarPart={updateCarPart} />
      <CarPartDisabledComponent top={270} left={713} carPart={findCarPart(SPORTELLO_I.value)} updateCarPart={updateCarPart} />
      <CarPartDisabledComponent top={460} left={170} carPart={findCarPart(PARAFANGO_AS.value)} updateCarPart={updateCarPart} />
      <CarPartDisabledComponent top={460} left={310} carPart={findCarPart(PORTA_AS.value)} updateCarPart={updateCarPart} />
      <CarPartDisabledComponent top={460} left={450} carPart={findCarPart(PORTA_PS.value)} updateCarPart={updateCarPart} />
      <CarPartDisabledComponent top={440} left={590} carPart={findCarPart(PARAFANGO_PS.value)} updateCarPart={updateCarPart} />
    </PericiaImgContainer>
  );
};

export default PericiaImgDisabled;
