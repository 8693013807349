import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { ChangeEvent } from "react";

interface FinishedInputProps {
  props: Props;
}

interface Props {
  finished: boolean;
  updateFinished: (finished: boolean) => void;
}

export const PericiaFinishedInput: React.FC<FinishedInputProps> = ({
  props,
}) => {
  const { finished, updateFinished } = props;

  const handleFinishedChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    updateFinished(checked);
  };

  return (
    <FormGroup sx={{ width: "100%", mt: 3 }}>
      <FormControlLabel
        control={
          <Checkbox onChange={handleFinishedChange} checked={finished} />
        }
        label="Liquidado"
      />
    </FormGroup>
  );
};
