import { Skeleton } from "@mui/material";

interface Props {
  quantity: number;
}

export const ListSkeleton: React.FC<Props> = ({ quantity }) => {
  quantity = quantity > 9 ? 9 : quantity;

  return (
    <>
      {Array.from(new Array(quantity)).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          height={40}
          sx={{ mt: 2 }}
        />
      ))}
    </>
  );
};
