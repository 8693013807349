import supabase from "../../../shared/database/supabase";
import { CustomError } from "../../../shared/error/error";

export interface UpdateFinishedRepairRecordReq {
  id: string;
  finished_at: string;
  payed: boolean;
  payed_at: string;
  payed_value: number;
}

export interface UpdateFinishedRepairRecordRes {
  data: string;
}

export const updateFinishedRepairRecordRequest = async (record: UpdateFinishedRepairRecordReq): Promise<UpdateFinishedRepairRecordRes> => {
  try {
    const { data, error } = await supabase.functions.invoke("update-repair-record", {
      body: JSON.stringify({
        finished_repair_record: {
          ...record
        }
      })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    return data;
  } catch (err) {
    const apiError = err as CustomError;
    console.error(`API Error: ${apiError.message}`);

    if (apiError && apiError.code && apiError.message && apiError.toast_message && apiError.http_status_code) {
      throw new CustomError(apiError);
    }

    throw new CustomError({});
  }
};
