import { PericiaPriceCalculated } from "../../../models/pericia/pericia";
import { updatePericiaPriceCalculated } from "../../../storage/supabase/pericia/pericia.storage";
import { updateRepairRecordStatus } from "../../../storage/supabase/repair-records/repair-records.storage";

export const setIsPriceCalculated = async (pericia: PericiaPriceCalculated) => {
  try {
    const { data, error } = await updatePericiaPriceCalculated(pericia);

    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};
