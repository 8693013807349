import "react-toastify/dist/ReactToastify.css";
import { Box, TextareaAutosize, Typography } from "@mui/material";
import FlexContainer from "../../../../shared/ui/container/flex-container.component";
import PericiaHeader from "../../../../entities/pericia/ui/pericia-header.component";
import { CreateNewPericia, Pericia, PericiaFinishedInput, PericiaInsuranceForm, usePericia } from "../../../../entities/pericia";
import { useEffect, useState } from "react";
import { Car, CreateNewCar } from "../../../../entities/car";
import PericiaImg from "../../../../entities/pericia/ui/pericia-img.component";
import PericiaAddtionalCost from "../../../../entities/pericia/ui/pericia-addtional-cost.component";
import PericiaTable from "../../../../entities/pericia/ui/periciaTable.component";
import PericiaCostumerPrice from "../../../../entities/pericia/ui/pericia-costumer-price.component";
import PericiaStatus from "../../../../entities/pericia/ui/pericia-status.component";
import TechnicianAmountToPayForm from "../../../../components/technician/amount-to-pay-form.component";
import TablePDFGenerator from "../../../../components/pdf/table/table-pdf-component";
import PDFGenerator from "../../../../components/pdf/pdf.component";
import { useUpdatePericia } from "../api/useUpdatePericia";
import { toast, ToastContainer } from "react-toastify";
import { getFinishedRepairRecordsByPericiaId } from "../../../../storage/supabase/repair-records/repair-records.storage";
import { createTechnicianPaymentService } from "../../../../services/payment-service/create-technician-payment.service";
import { useDeleteRepairRecord } from "../../../repair-record/list-repair-records-by-user-id/api";
import UserAutocompleteSearchBar from "../../../../entities/user/ui/users-autocomplete-search-bar.component";
import { User, makeNewUser } from "../../../../entities/user";
import { PaperCard } from "../../../../shared/ui/paper-card/paper-card.component";
import { LoadingButton } from "@mui/lab";
import { useCreateRepairRecordByUserId } from "../../../repair-record/list-repair-records-by-user-id/api/useCreateRepairRecordByUserId";
import { useGetTechnicianPaymentRecord } from "../../../technician-payment/get-technician-payment/useGetTechnicianPaymentRecord";
import { CreatePericiaNoteForm } from "../../../pericia-note/ui/create-pericia-note-form";
import BasicAccordion from "../../../../shared/ui/accordion";
import { PericiaNotes } from "../../../../entities/pericia-notes/model/pericia-notes";
import { useListPericiaNotes } from "../../../pericia-note/api/useListPericiaNotes";
import FinishedRepairRecordsList from "../../../../entities/repair-record/ui/finished-repair-records-list.component";
import { FinishedRepairRecord } from "../../../repair-record/model/finished-repair-record";
import supabase from "../../../../shared/database/supabase";
import { USER_ROLES, UserWithRole } from "../../../../contexts/user/user.context";

interface UpdatePericiaComponentProps {
  pericia: Pericia;
  onPericiaUpdated: (pericia: Pericia) => void;
}

interface RepairRecordToDelete {
  periciaID: string;
  userID: string;
}

interface RepairRecordToCreate {
  periciaId: string;
  userId: string;
  done: boolean;
}

const INITIAL_REPAIR_RECORD_TO_CREATE: RepairRecordToCreate = {
  periciaId: "",
  userId: "",
  done: true
};

function makeNewRepairRecordToDelete(): RepairRecordToDelete {
  return {
    periciaID: "",
    userID: ""
  };
}

export const UpdatePericiaComponent: React.FC<UpdatePericiaComponentProps> = (props) => {
  const user = supabase.auth.user() as UserWithRole;
  const [isLoadingPaymentRecord, setIsLoadingPaymentRecord] = useState(false);
  const { pericia: initialPericia, onPericiaUpdated } = props;
  const {
    pericia,
    updatePericia,
    updatePericiaCostumer,
    updatePericiaCostumerPrice,
    updatePericiaCar,
    updatePericiaPricePerHour,
    updatePericiaShouldUnmount,
    updatePericiaUnmountPrice,
    updatePericiaDate,
    updatePericiaAddtionalCost,
    updatePericiaAddtionalCostDescription,
    updatePericiaFinished,
    updateCarPart,
    updatePericiaInsuranceHours,
    updatePericiaIsBilled,
    updatePericiaIsDone,
    updatePericiaIsPriceCalculated,
    findCarPart
  } = usePericia(initialPericia);
  //TODO refact
  const [amountToPay, setAmountToPay] = useState(0);
  const [carBeingCreated, setCarBeingCreated] = useState<Car>(CreateNewCar());
  const [repairRecords, setRepairRecords] = useState<FinishedRepairRecord[]>([]);
  const {
    data: fetchedTechnicianPaymentRecord,
    error: errorFetchingTechnicianPaymentRecord,
    isLoading: isLoadingFetchingTechnicianPaymentRecord
  } = useGetTechnicianPaymentRecord({ id_pericia: pericia.id });

  const [periciaUpdatedCount, setPericiaUpdatedCount] = useState(0);
  const [periciaToUpdate, setPericiaToUpdate] = useState(CreateNewPericia());
  const [repairRecorToCreate, setRepairRecorToCreate] = useState<RepairRecordToCreate>(INITIAL_REPAIR_RECORD_TO_CREATE);
  const [repairRecordToDelete, setRepairRecordToDelete] = useState<RepairRecordToDelete>(makeNewRepairRecordToDelete());
  const {
    data: deleteRepairRecordData,
    error: deleteRepairRecordError,
    isLoading: deleteRepairRecordIsLoading
  } = useDeleteRepairRecord(repairRecordToDelete);
  const { data: periciaUpdated, error: errorUpdatingPericia, isLoading: isLoadingUpdatingPericia } = useUpdatePericia(periciaToUpdate);
  const {
    data: createRepairRecord,
    error: errorCreatingRepairRecord,
    isLoading: isLoadingCreatingRepairRecord
  } = useCreateRepairRecordByUserId(repairRecorToCreate);
  const [userToCreateRepairRecord, setUserToCreateRepairRecord] = useState<User>(makeNewUser());
  const {
    data: periciaNotes,
    error: errorFetchingPericiaNotes,
    isLoading: isLoadingPericiaNotes,
    refetch: refetchPericiaNotes
  } = useListPericiaNotes({ id_pericia: pericia.id });
  const {
    costumer,
    car,
    pricePerHour,
    shouldUnmount,
    unmountPrice,
    date,
    carParts,
    totalHours,
    totalPrice,
    addtionalCost,
    addtionalCostDescription,
    finished,
    billed,
    insuranceHours,
    costumerPrice,
    done,
    isEditable,
    isPriceCalculated
  } = pericia;

  const IS_ALLOWED = (...roles: string[]) => roles.includes(user.permission);

  const handlePericiaNoteCreated = () => {
    toast.success("Observação sobre a perícia criada com sucesso", { autoClose: 2000 });
    refetchPericiaNotes();
  };

  const handleCreatePayment = async (amount: number) => {
    setIsLoadingPaymentRecord(true);
    const { data, error } = await createTechnicianPaymentService.execute({
      id_pericia: pericia.id,
      amount
    });

    setIsLoadingPaymentRecord(false);
    if (error) {
      toast.error(`erro ao criar pagamento!`);
      return;
    }

    onPericiaUpdated(pericia);
    toast.success("Pagamento atualizado com sucesso! Calculando...", { autoClose: 2000 });
    setTimeout(() => {
      fetchRepairRecords(pericia.id);
    }, 2000);
  };

  const fetchRepairRecords = async (id_pericia: string) => {
    const res = await getFinishedRepairRecordsByPericiaId(id_pericia);
    if (res.error) {
      console.log(res.error);
      return;
    }

    setRepairRecords(res.data);
  };

  const onEditRepairRecord = () => {
    if (!pericia.id) return;

    fetchRepairRecords(pericia.id);
  };

  const handleDeleteRepairRecord = (userID: string) => {
    if (!userID) return;
    setRepairRecordToDelete({ ...repairRecordToDelete, periciaID: pericia.id, userID });
  };

  const handleCreateRepairRecord = () => {
    if (!pericia.id) return;

    if (!userToCreateRepairRecord.id) {
      toast.error("Selecione um técnico!");
      return;
    }

    setRepairRecorToCreate({ ...repairRecorToCreate, periciaId: pericia.id, userId: userToCreateRepairRecord.id });
    setUserToCreateRepairRecord(makeNewUser());
  };

  const handleUpdateCostumerPrice = (costumerPrice: number) => {
    updatePericiaCostumerPrice(costumerPrice);
    //update pericia
  };

  useEffect(() => {
    if (!periciaUpdated) return;
    if (!periciaToUpdate.id) return;

    toast.success("Pericia atualizada com sucesso!", { autoClose: 600 });
    onPericiaUpdated(pericia);
  }, [periciaUpdated]);

  useEffect(() => {
    if (!deleteRepairRecordData) return;

    toast.success("Registro de reparo deletado com sucesso!", { autoClose: 800 });
    fetchRepairRecords(pericia.id);
  }, [deleteRepairRecordData]);

  useEffect(() => {
    if (!createRepairRecord) return;

    toast.success("Registro de reparo criado com sucesso!", { autoClose: 800 });
    fetchRepairRecords(pericia.id);
  }, [createRepairRecord]);

  useEffect(() => {
    fetchRepairRecords(pericia.id);
    setPericiaUpdatedCount(periciaUpdatedCount + 1);
    if (periciaUpdatedCount < 1) return;

    setPericiaToUpdate(pericia);
  }, [pericia]);

  useEffect(() => {
    if (!fetchedTechnicianPaymentRecord) return;

    setAmountToPay(fetchedTechnicianPaymentRecord.amount);
  }, [fetchedTechnicianPaymentRecord]);

  if (errorUpdatingPericia) toast.error("Erro ao atualizar pericia!");
  if (errorCreatingRepairRecord) toast.error("Erro ao criar registro de reparo!");
  if (deleteRepairRecordError) toast.error("Erro ao deletar registro de reparo!");

  return (
    <FlexContainer>
      <Typography variant="h4" component="h1">
        Atualizar Perícia
      </Typography>
      <PericiaHeader
        props={{
          costumer,
          car,
          shouldUnmount,
          pricePerHour,
          unmountPrice,
          date,
          updateCostumer: updatePericiaCostumer,
          updateCar: updatePericiaCar,
          updatePricePerHour: updatePericiaPricePerHour,
          updateShouldUnmount: updatePericiaShouldUnmount,
          updateUnmountPrice: updatePericiaUnmountPrice,
          updateDate: updatePericiaDate
        }}
      />
      <Typography component="h1" variant="h5">
        Pericia
      </Typography>
      <PericiaImg
        props={{
          findCarPart,
          updateCarPart
        }}
      />
      <PericiaFinishedInput props={{ finished, updateFinished: updatePericiaFinished }} />
      <PericiaAddtionalCost
        props={{
          addtionalCost,
          addtionalCostDescription,
          updateAddtionalCost: updatePericiaAddtionalCost,
          updateAddtionalCostDescription: updatePericiaAddtionalCostDescription
        }}
      />
      {IS_ALLOWED(USER_ROLES.ADMIN, USER_ROLES.SALESMAN) && (
        <>
          <Typography component="h1" variant="h5" sx={{ mt: 5, mb: 3 }}>
            Tabela
          </Typography>
          <PericiaTable
            props={{
              carParts,
              totalHours,
              totalPrice,
              pricePerHour,
              unmountPrice,
              addtionalCost
            }}
          />
        </>
      )}
      <CreatePericiaNoteForm id_pericia={pericia.id} onPericiaNoteCreated={handlePericiaNoteCreated} />
      <BasicAccordion
        expanded={periciaNotes && periciaNotes.length > 0}
        title="Observações"
        children={
          <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            placeholder="Observações"
            style={{ width: "100%" }}
            value={formatPericiaNotes(periciaNotes || [])}
          />
        }
      />
      <PericiaInsuranceForm
        props={{
          insuranceHours,
          updateInsuranceHours: updatePericiaInsuranceHours,
          insurancePrice: insuranceHours * pricePerHour
        }}
      />
      <PericiaCostumerPrice
        props={{
          costumerPrice,
          updatePericiaCostumerPrice: handleUpdateCostumerPrice
        }}
      />
      {IS_ALLOWED(USER_ROLES.ADMIN, USER_ROLES.SALESMAN) && (
        <>
          <PaperCard title="Adicionar reparo">
            <UserAutocompleteSearchBar props={{ user: userToCreateRepairRecord, updateUser: setUserToCreateRepairRecord }} />
            <LoadingButton
              variant="contained"
              loading={isLoadingCreatingRepairRecord}
              onClick={handleCreateRepairRecord}
              sx={{ marginTop: "1rem" }}
            >
              Adicionar
            </LoadingButton>
          </PaperCard>
          <TechnicianAmountToPayForm value={amountToPay} isLoading={isLoadingPaymentRecord} handleCreatePayment={handleCreatePayment} />
          <PericiaStatus
            props={{
              billed,
              updatePericiaIsBilled,
              done,
              updatePericiaIsDone,
              isEditable,
              isPriceCalculated,
              payed: false,
              updatePericiaIsPriceCalculated
            }}
          />
        </>
      )}
      {IS_ALLOWED(USER_ROLES.ADMIN, USER_ROLES.SALESMAN) && repairRecords.length > 0 && (
        <FinishedRepairRecordsList
          props={{
            repairRecords,
            amountToPay,
            hideActionsAndValues: !IS_ALLOWED(USER_ROLES.ADMIN),
            deleteRepairRecord: handleDeleteRepairRecord,
            onEditRepairRecord
          }}
        />
      )}

      {IS_ALLOWED(USER_ROLES.ADMIN, USER_ROLES.SALESMAN, USER_ROLES.LIMITED_ADMIN) && (
        <TablePDFGenerator
          carParts={carParts}
          pricePerWorkingHour={pricePerHour}
          aditionalCost={addtionalCost}
          unmountPrice={unmountPrice}
          car={car}
          costumer={costumer.name}
          date={date}
          insuranceHours={insuranceHours}
          costumerPrice={costumerPrice}
        />
      )}
      <PDFGenerator
        disabled={isLoadingUpdatingPericia}
        withCostumerPrice={false}
        props={{
          carParts,
          costumer,
          car,
          date,
          finished,
          shouldUnmount,
          unmountPrice,
          costumerPrice,
          addtionalCost,
          totalPrice,
          insurancePrice: insuranceHours * pricePerHour,
          addtionalCostDescription,
          totalHours
        }}
      />
      {IS_ALLOWED(USER_ROLES.ADMIN, USER_ROLES.SALESMAN, USER_ROLES.LIMITED_ADMIN) && (
        <>
          <PDFGenerator
            disabled={isLoadingUpdatingPericia}
            withCostumerPrice={true}
            props={{
              carParts,
              costumer,
              car,
              date,
              finished,
              shouldUnmount,
              unmountPrice,
              costumerPrice,
              addtionalCost,
              totalPrice,
              insurancePrice: insuranceHours * pricePerHour,
              addtionalCostDescription,
              totalHours
            }}
          />
        </>
      )}
      <Box mb={5} />
      <ToastContainer />
    </FlexContainer>
  );
};

const formatPericiaNotes = (notes: PericiaNotes[]) => {
  if (!notes) return "";

  return notes.map((note) => `\n- ${note.note}  (${note.createdAt.toLocaleString("pt-BR")})`);
};
