import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import { useRef } from "react";

interface DeletePericiaButtonProps {
  props: Props;
}

interface Props {
  deleteFunction: () => void;
  message: string;
  buttonText: string;
  loading: boolean;
  disabled: boolean;
}

const DeleteButton: React.FC<DeletePericiaButtonProps> = ({ props }) => {
  const { message, buttonText, loading, disabled, deleteFunction } = props;
  const [open, setOpen] = React.useState(false);

  const deleteRef = useRef<HTMLInputElement>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickConfirm = () => {
    if (deleteRef?.current?.value.toLocaleLowerCase() !== "deletar") {
      return alert("Digite deletar para confirmar");
    }

    deleteFunction();
    setOpen(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <LoadingButton
        fullWidth
        variant="contained"
        color="error"
        sx={{ mb: 1, mt: 1 }}
        onClick={handleClickOpen}
        loading={loading}
        disabled={disabled}
      >
        {buttonText}
      </LoadingButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Deletar Pericia</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message} Digite <strong>deletar</strong> no campo abaixo para
            confirmar.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="deletar"
            type="text"
            fullWidth
            variant="standard"
            inputRef={deleteRef}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleClickConfirm} color="error">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteButton;
