import { UseQueryResult, useQuery } from "react-query";
import { GetUserBalanceByIdResponse, getUserBalanceById } from "../../../../entities/user";

export const queryKeys = {
  getUserBalanceById: (userId: string) => ["getUserBalanceById", userId]
};

export function useGetUserBalanceById(userId: string): UseQueryResult<GetUserBalanceByIdResponse, Error> {
  return useQuery(queryKeys.getUserBalanceById(userId), () => getUserBalanceById(userId), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: userId !== ""
  });
}
