import { Grid, ListItem, ListItemButton, ListItemText, TextField } from "@mui/material";
import { getPericiaCustomPrice } from "../../../../entities/pericia/model/pericia";
import { currencyMask, formatIntegerToCurrency } from "../../../../utils/helpers/numbers";
import { PaperCard } from "../../../../shared/ui/paper-card/paper-card.component";
import { PericiaInvoiceItem } from "../../../../entities/pericia";

interface PendingPericiasListProps {
  props: {
    pericias: PericiaInvoiceItem[];
    selectedFieldsToDisplay: SelectedFieldsToDisplay;
    totalPrice: number;
  };
}

interface SelectedFieldsToDisplay {
  displayPlate: boolean;
  displayDate: boolean;
  displayPrice: boolean;
  displayUserName: boolean;
  displayTotalHours: boolean;
}

export const PendingPericiasList: React.FC<PendingPericiasListProps> = ({ props }) => {
  const { pericias, selectedFieldsToDisplay, totalPrice } = props;
  const { displayPlate, displayDate, displayPrice, displayUserName, displayTotalHours } = selectedFieldsToDisplay;

  return (
    <PaperCard title="Perícias">
      {
        <ListItem
          key="report-header"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            textAlign: "center",
            flexWrap: "wrap"
          }}
        >
          {listHeader(selectedFieldsToDisplay)}
        </ListItem>
      }

      {pericias.map((pericia) => {
        const { car, totalHours } = pericia;
        const { plate, model, brand, chassisNumber } = car;
        const price = getPericiaCustomPrice(pericia);
        const status = getPericiaStatus(pericia);

        return (
          <ListItem key={pericia.id}>
            <ListItemButton
              // onClick={() => navigate(`/pericias/${pericia.id}`)}
              role={undefined}
              dense
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                textAlign: "center",
                flexWrap: "wrap"
              }}
            >
              <ListItemText primary={`${status}`} sx={{ flex: "1", minWidth: 0, maxWidth: "100%" }} />
              <ListItemText primary={`${brand} ${model}`} sx={{ flex: "1", minWidth: 0, maxWidth: "100%" }} />
              {displayPlate && (
                <ListItemText primary={`${plate || "-"}  (${chassisNumber})`} sx={{ flex: "1", minWidth: 0, maxWidth: "100%" }} />
              )}
              {displayDate && (
                <ListItemText primary={pericia.createdAt.toLocaleDateString("pt-BR")} sx={{ flex: "1", minWidth: 0, maxWidth: "100%" }} />
              )}
              {displayUserName && (
                <ListItemText
                  primary={pericia.repairRecords[0] ? pericia.repairRecords[0].user.display_name : "-"}
                  sx={{ flex: "1", minWidth: 0, maxWidth: "100%" }}
                />
              )}
              {displayPrice && (
                <ListItemText
                  primary={`${currencyMask(formatIntegerToCurrency(price))}`}
                  sx={{ flex: "1", minWidth: 0, maxWidth: "100%" }}
                />
              )}
              {displayTotalHours && <ListItemText primary={`${totalHours}`} sx={{ flex: "1", minWidth: 0, maxWidth: "100%" }} />}
            </ListItemButton>
          </ListItem>
        );
      })}
      {displayPrice && (
        <Grid item xs={12} sm={12} mt={5} container justifyContent="flex-end">
          <TextField label="Total" variant="standard" disabled value={currencyMask(formatIntegerToCurrency(totalPrice))} />
        </Grid>
      )}
    </PaperCard>
  );
};

const listHeader = (fields: SelectedFieldsToDisplay) => {
  const headers = [
    { label: "STATUS", display: true },
    { label: "MODELO", display: true }
  ];
  if (fields.displayPlate) headers.push({ label: "PLACA", display: true });
  if (fields.displayDate) headers.push({ label: "DATA", display: true });
  if (fields.displayUserName) headers.push({ label: "TÉCNICO", display: true });
  if (fields.displayPrice) headers.push({ label: "VALOR", display: true });
  if (fields.displayTotalHours) headers.push({ label: "HORAS", display: true });

  return (
    <ListItem
      divider
      key="report-header"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        textAlign: "center",
        flexWrap: "wrap"
      }}
    >
      {headers.map((header) => {
        return <ListItemText primary={`${header.label}`} sx={{ flex: "1", minWidth: 0, maxWidth: "100%" }} />;
      })}
    </ListItem>
  );
};

const getPericiaStatus = (pericia: PericiaInvoiceItem) => {
  if (pericia.billed) return "Faturado";
  if (pericia.isPriceCalculated) return "Controlado";
  if (pericia.done) return "Finalizado";
  if (!pericia.done) return "Andamento";
};
