import { UseQueryResult, useQuery } from "react-query";
import { updateUserById } from "../../../../entities/user";

interface UserUpdateUserByIdArgs {
  id: string;
  name: string;
  phone: string;
  lastName: string;
  displayName: string;
  nationality: string;
  role: string;
}

export const queryKeys = {
  updateUserById: (user: UserUpdateUserByIdArgs) => ["updateUserById", user]
};

export function useUpdateUserById(user: UserUpdateUserByIdArgs): UseQueryResult<[], Error> {
  const { id, name, phone, lastName, displayName, nationality, role } = user;

  return useQuery(
    queryKeys.updateUserById(user),
    () =>
      updateUserById({
        updated_user: user
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: id !== "" && name !== "" && phone !== "" && lastName !== "" && displayName !== "" && nationality !== "" && role !== ""
    }
  );
}
