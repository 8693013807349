import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { PericiaCostumer } from "../../../models/costumer/costumer";
import { findCostumerById } from "../../../shared/helpers/costumers";
import { useListCostumers } from "../../../features/costumer/list-costumers";

interface CostumerAutocompleteProps {
  props: Props;
}

interface Props {
  costumer: PericiaCostumer;
  updateCostumer: (costumer: PericiaCostumer) => void;
}

const CostumerAutocomplete: React.FC<CostumerAutocompleteProps> = ({ props }) => {
  const { data: costumers } = useListCostumers();
  const { updateCostumer, costumer } = props;

  if (!costumers) return <TextField label="Cliente" required fullWidth id="costumerName" autoFocus variant="standard" disabled />;

  return (
    <Autocomplete
      disablePortal
      id="costumer-autocomplete"
      options={costumers.map((costumer) => ({
        label: costumer.name,
        id: costumer.id
      }))}
      onChange={(e, value) => {
        if (!value) return;
        updateCostumer(findCostumerById(costumers, value.id));
      }}
      onInputChange={(e, value, reason) => {
        if (reason === "clear") {
          updateCostumer({ id: "", name: "", language: "ita" });
        }
      }}
      value={findCostumer(costumers, costumer)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField {...params} label="Cliente" required fullWidth id="costumerName" autoFocus variant="standard" />}
    />
  );
};

const findCostumer = (costumers: PericiaCostumer[], costumer: PericiaCostumer) => {
  if (!costumer) return null;

  const res = costumers.find((item) => item.id === costumer.id);
  if (!res) return null;

  return { label: res.name, id: res.id };
};

function sortAlphabetically(arr: PericiaCostumer[]) {
  return arr.sort((a, b) => a.name.localeCompare(b.name));
}

export default CostumerAutocomplete;
