import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  ListItem,
  ListItemButton,
  ListItemText,
  Pagination,
  Stack,
  Typography
} from "@mui/material";
import { PaperCard } from "../../../../shared/ui/paper-card/paper-card.component";
import { Pericia } from "../../../../entities/pericia";
import { useNavigate } from "react-router-dom";
import { ListSkeleton } from "../../../../shared/ui/skeleton/list-skeleton.component";

interface PericiasListProps {
  pericias: Pericia[];
  page: number;
  isLoading: boolean;
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  handlePericiaUpdate: (pericia: Pericia) => void;
}

export const PericiasList: React.FC<PericiasListProps> = ({ pericias, page, handlePageChange, handlePericiaUpdate, isLoading }) => {
  const navigate = useNavigate();
  const metrics = getMetrics(pericias);
  const metricsArray = Object.entries(metrics).sort((a, b) => b[1].value - a[1].value);

  const handleBilledUpdate = async (id: string) => {
    const pericia = pericias.filter((pericia) => pericia.id === id)[0];
    if (!pericia) return null;

    pericia.billed = !pericia.billed;
    handlePericiaUpdate(pericia);
  };

  const handlePriceCalculatedUpdate = async (id: string) => {
    const pericia = pericias.filter((pericia) => pericia.id === id)[0];
    if (!pericia) return null;

    pericia.isPriceCalculated = !pericia.isPriceCalculated;
    handlePericiaUpdate(pericia);
  };

  if (isLoading) {
    return (
      <PaperCard title={``} key="lista-de-pericias-id">
        <ListSkeleton quantity={10} />
      </PaperCard>
    );
  }

  return (
    <>
      <PaperCard title={`Página ${page} - ${pericias.length} perícias`} key="lista-de-pericias-id">
        <Box mb={3} mt={1}>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" fontWeight="bold">
                Números
              </Typography>
              {metricsArray.map(([key, metric]) => (
                <Typography variant="body2" key={key}>{`${metric.label}: ${metric.value}/${pericias.length}`}</Typography>
              ))}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" fontWeight="bold">
                Legenda
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox color="success" checked={true} size="small" />}
                  label={<Typography variant="caption">Controlado</Typography>}
                />
              </FormGroup>
              <FormGroup sx={{ marginTop: "-.8rem" }}>
                <FormControlLabel
                  control={<Checkbox checked={true} size="small" />}
                  label={<Typography variant="caption">Faturado</Typography>}
                />
              </FormGroup>
              <Typography variant="body2" sx={{ textDecoration: "line-through" }}>
                Liquidado
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {pericias.map((pericia) => {
          const { id, done, finished, isPriceCalculated, billed, car, costumer, date } = pericia;
          const status = getStatus(done, finished);
          const textDecoration = finished ? "line-through" : "none";

          return (
            <ListItem key={pericia.id}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox color="success" onChange={() => handlePriceCalculatedUpdate(id)} checked={isPriceCalculated} />}
                  label=""
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  onChange={() => {
                    handleBilledUpdate(id);
                  }}
                  control={<Checkbox checked={billed} />}
                  label=""
                />
              </FormGroup>
              <ListItemButton
                onClick={() => navigate(`/pericias/${pericia.id}`)}
                role={undefined}
                dense
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  textAlign: "center",
                  flexWrap: "wrap",
                  textDecoration
                }}
              >
                <ListItemText id={car.plate + 1} primary={`${costumer.name}`} sx={{ flex: "1", textAlign: "left" }} />
                <ListItemText id={car.plate + 2} primary={`${car.plate}`} sx={{ flex: "1" }} />
                <ListItemText id={car.plate + 3} primary={`${car.model}`} sx={{ flex: "1" }} />
                <ListItemText
                  id={car.plate + 4}
                  primary={status.text}
                  sx={{
                    color: status.color,
                    flex: "1"
                  }}
                />
                <ListItemText
                  id={car.plate + 5}
                  primary={new Date(date).toLocaleDateString("pt-BR")}
                  sx={{
                    flex: "1"
                  }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </PaperCard>
      <Stack spacing={2} mt={5}>
        <Pagination
          count={pericias.length >= 10 ? page + 1 : page}
          size="large"
          onChange={handlePageChange}
          page={page}
          sx={{ alignSelf: "center" }}
        />
      </Stack>
    </>
  );
};

const getStatus = (done: boolean, finished: boolean) => {
  if (finished) {
    return { color: "black", text: "Liquidado" };
  }

  if (done) {
    return { color: "success.main", text: "Finalizado" };
  }

  return { color: "error.main", text: "Em andamento" };
};

const getMetrics = (pericias: Pericia[]) => {
  const metrics = {
    done: {
      value: 0,
      label: "Finalizadas"
    },
    notDone: {
      value: 0,
      label: "Em andamento"
    },
    finished: {
      value: 0,
      label: "Liquidadas"
    },
    isPriceCalculated: {
      value: 0,
      label: "Controladas"
    },
    billed: {
      value: 0,
      label: "Faturadas"
    }
  };

  return pericias.reduce((acc, pericia) => {
    const { done, finished, isPriceCalculated, billed } = pericia;
    if (done) {
      acc.done.value += 1;
    } else {
      acc.notDone.value += 1;
    }
    if (finished) {
      acc.finished.value += 1;
    }
    if (isPriceCalculated) {
      acc.isPriceCalculated.value += 1;
    }
    if (billed) {
      acc.billed.value += 1;
    }
    return acc;
  }, metrics);
};
