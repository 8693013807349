import { UseQueryResult, useQuery } from "react-query";
import { inviteUserByEmail } from "../../../../entities/user";

interface UseInveUserByEmailProps {
  email: string;
  role: string;
}

export const queryKeys = {
  inviteUserByEmail: (props: UseInveUserByEmailProps) => ["InviteUserByEmail", props]
};

export function useInviteUserByEmail(props: UseInveUserByEmailProps): UseQueryResult<boolean, Error> {
  return useQuery(queryKeys.inviteUserByEmail(props), () => inviteUserByEmail(props), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: props.email !== ""
  });
}
