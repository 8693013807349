import { LoadingButton } from "@mui/lab";
import jsPDF from "jspdf";
import { useState } from "react";
import { Car } from "../../../models/car/car";
import { formatCurrency } from "../../../utils/helpers/numbers";
import { drawBorder } from "../../../utils/helpers/pdf";
import { removeWhiteSpaces } from "../../../utils/helpers/strings";
import Canvas from "./canvas.component";
import { CarPart } from "../../../entities/pericia";

const CANVAS_ID = "table-pdf";

const nuovautoIMG = require("../../../assets/nuovauto.png");

interface ListPDFProps {
  carParts: CarPart[];
  car: Car;
  aditionalCost: number;
  unmountPrice: number;
  costumer: string;
  date: Date;
  pricePerWorkingHour: number;
  insuranceHours: number;
  costumerPrice: number;
}

const TablePDFGenerator: React.FC<ListPDFProps> = ({
  carParts,
  costumer,
  pricePerWorkingHour,
  unmountPrice,
  car,
  aditionalCost,
  date,
  insuranceHours = 0,
  costumerPrice = 0
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pdfComponents, setPdfComponents] = useState<JSX.Element[]>([]);
  const { plate, chassisNumber } = car;
  const totalHours = carParts.reduce((acc, curr) => acc + curr.workingHours, 0);

  const draw = (context: any) => {
    const img = new Image();
    img.src = nuovautoIMG;
    img.onload = () => {
      context.fillStyle = "white";
      context.fillRect(0, 0, 1200, 1200);
      context.drawImage(img, 370, 32, 524, 100);

      drawCostumerField(context, costumer, date, car);
      drawBorder(context, 1200, 1200);
      drawTable(context, carParts, pricePerWorkingHour, unmountPrice, aditionalCost, totalHours, insuranceHours, costumerPrice);
    };
  };

  const handleAddPDFComponent = () => {
    const newPdfComponent = <Canvas key={pdfComponents.length} id={CANVAS_ID} draw={draw} width={1200} height={1200} />;
    setPdfComponents([newPdfComponent]);
    setIsLoading(true);
    setTimeout(() => {
      handleGeneratePDF(1);
    }, 1000);
  };

  const handleGeneratePDF = (attempt: number) => {
    const canvas = document.getElementById(CANVAS_ID) as HTMLCanvasElement;

    const doc = new jsPDF();
    doc.addImage(canvas, "JPEG", 5, 5, 200, 285, "", "NONE", 0);

    let plateValue = plate.length < 2 ? "assente" : plate;

    if (plate === "assente") {
      plateValue = chassisNumber;
    }

    const fileName = `${removeWhiteSpaces(costumer)}-${plateValue}-${date.toLocaleDateString("pt-BR")}-tabella.pdf`;

    setIsLoading(false);
    doc.save(fileName);
    setPdfComponents([]);
  };

  return (
    <>
      <LoadingButton fullWidth variant="contained" sx={{ mb: 1, mt: 5 }} onClick={handleAddPDFComponent} loading={isLoading}>
        GERAR PDF DA TABELA
      </LoadingButton>
      {pdfComponents}
    </>
  );
};

const drawCostumerField = (context: any, costumerName: string, date: Date, car: Car) => {
  context.font = "22px Arial";
  context.fillStyle = "black";

  context.fillText(`Cliente: ${costumerName}`, 100, 250);
  context.fillText(`Data: ${date.toLocaleDateString("pt-br")} `, 100, 280);
  context.fillText(`Marca: ${car.brand}`, 550, 250);
  context.fillText(`Modello: ${car.model}`, 550, 280);
  context.fillText(`Targa: ${car.plate}`, 550, 310);
  context.fillText(`Colore: ${car.color}`, 830, 250);
  context.fillText(`Assicurazione: ${car.insuranceName}`, 830, 280);
  context.fillText(`Telaio: ${car.chassisNumber}`, 830, 310);
};

const drawTable = (
  context: any,
  carParts: CarPart[],
  pricePerWorkingHour: number,
  unmountPrice: number,
  aditionalCost: number,
  totalHours: number,
  insuranceHours: number,
  costumerPrice: number
) => {
  context.font = "18px Arial";
  context.fillStyle = "black";
  let x = 100;
  let y = 420;
  let totalPrice = 0;

  context.fillText("Desc", x, y - 30);
  context.fillText("25mm", x + 175, y - 30);
  context.fillText("AW", x + 275, y - 30);
  context.fillText(">25mm", x + 350, y - 30);
  context.fillText("AW", x + 455, y - 30);
  context.fillText("Cola", x + 535, y - 30);
  context.fillText("Alum", x + 590, y - 30);
  context.fillText("Pintura", x + 655, y - 30);
  context.fillText("Total", x + 735, y - 30);
  context.fillText("CHF/Ora", x + 830, y - 30);
  context.fillText("Subtotale", x + 930, y - 30);

  carParts.forEach((carPart) => {
    const { name, smallSmash, smash, smallSmashWorkingHours, smashWorkingHours, shouldGlue, isAluminum, shouldPaint, workingHours, price } =
      carPart;

    totalPrice += price;

    context.fillText(name, x, y);
    context.fillText(smallSmash, x + 175, y);
    context.fillText(smallSmashWorkingHours, x + 275, y);
    context.fillText(smash, x + 350, y);
    context.fillText(smashWorkingHours, x + 455, y);
    context.fillText(shouldGlue ? "✓" : "", x + 535, y);
    context.fillText(isAluminum ? "✓" : "", x + 590, y);
    context.fillText(shouldPaint ? "✓" : "", x + 655, y);
    context.fillText(workingHours.toFixed(2), x + 735, y);
    context.fillText(pricePerWorkingHour.toFixed(2), x + 830, y);
    context.fillText(formatCurrency(price), x + 930, y);

    context.beginPath();
    context.moveTo(x, y + 10);
    context.lineWidth = 1;
    context.lineTo(x + 1035, y + 10);
    context.stroke();

    y += 40;
  });

  const increaseXBy = 930;
  const x1 = 600;

  let workingHours = totalHours;
  let customTotalPrice = totalPrice;

  if (insuranceHours > 0) {
    workingHours = insuranceHours;
    customTotalPrice = insuranceHours * pricePerWorkingHour;
  }

  if (costumerPrice > 0) {
    workingHours = totalHours;
    customTotalPrice = costumerPrice;
  }

  context.fillText("Subtotale:", x + 600, y);
  context.fillText(workingHours, x + 735, y);
  context.fillText(`${formatCurrency(customTotalPrice)}`, x + increaseXBy, y);
  y += 40;

  if (unmountPrice > 0) {
    context.fillText("Smontaggio:", x + x1, y);
    context.fillText(`${formatCurrency(unmountPrice)}`, x + increaseXBy, y);
    customTotalPrice += unmountPrice;
    y += 40;
  }

  if (aditionalCost > 0) {
    context.fillText("Botta Aggiuntiva:", x + x1, y);
    context.fillText(`${formatCurrency(aditionalCost)}`, x + increaseXBy, y);
    customTotalPrice += aditionalCost;
    y += 40;
  }

  context.fillText(`Totale:`, x + x1, y);
  context.fillText(`${formatCurrency(customTotalPrice)}`, x + increaseXBy, y);
};

export default TablePDFGenerator;
