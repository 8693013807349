import jsPDF from "jspdf";
import { getTranslation } from "../../../../shared/locale/locale";
import { createPagesFromInvoiceItems } from "../../../../shared/helpers/array";
import { currencyMask, formatCurrency, formatIntegerToCurrency } from "../../../../utils/helpers/numbers";
import { InvoiceItem } from "../../../../entities/invoice/api/get-invoice-by-id";
import { getPericiaCustomPrice } from "../../../../entities/pericia";

interface GenerateInvoicePdfDTO {
  costumerName: string;
  costumerAddress: string;
  costumerLanguage: string;
  createdAt: string;
  totalPrice: string;
  IVA: number;
  totalIVA: string;
  items: InvoiceItem[];
  invoiceNumber: number;
  displayTotalHours: boolean;
  invoiceText: string;
}

export const useCreateInvoicePDF = {
  execute: async (generateInvoicePdfDTO: GenerateInvoicePdfDTO) => {
    const { costumerName, costumerAddress, createdAt, invoiceNumber, costumerLanguage, IVA, invoiceText } = generateInvoicePdfDTO;
    const text = getTranslation(costumerLanguage);

    const logoImg = require("../../../../assets/nuovauto.png");

    const pdf = new jsPDF("p", "mm", "a4");
    pdf.addImage(logoImg, "PNG", 110, 6, 90, 24);

    pdf.setFontSize(12);
    pdf.setFont("helvetica", "bold");

    const costumerAddress1 = costumerAddress.split(",").slice(0, 2).join(" ");
    const costumerAddress2 = costumerAddress.split(",").slice(2).join(" ");

    pdf.text(`NUOVAUTO di Roger Keil`, 16, 16);

    pdf.setFont("helvetica", "normal");
    pdf.text(`Via G. Canevascini 15`, 16, 22);
    pdf.text(`6600 Locarno`, 16, 28);

    const italyImg = require("../../../../assets/italy.png");
    pdf.addImage(italyImg, "PNG", 52, 34, 4, 4);
    pdf.text(`+41 76 746 44 12 `, 16, 37);

    const germanyImg = require("../../../../assets/germany.png");
    pdf.addImage(germanyImg, "PNG", 52, 40, 4, 4);
    pdf.text(`+41 79 900 54 38`, 16, 43);

    pdf.setTextColor(0, 0, 255);
    pdf.textWithLink(`www.nuovauto.ch`, 16, 52, {});
    pdf.textWithLink(`${text.invoice_pdf.email}`, 16, 58, {});

    pdf.setTextColor(0, 0, 0);
    pdf.setFont("helvetica", "bold");
    pdf.text(`${costumerName}`, 110, 47);
    pdf.text(`${costumerAddress1}`, 110, 53);
    pdf.text(`${costumerAddress2}`, 110, 59);

    pdf.setFontSize(12);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${text.invoice_pdf.header.invoice_number} 0${invoiceNumber}`, 16, 80);
    pdf.text(`${text.invoice_pdf.header.invoice_date}: ${createdAt}`, 16, 86);

    pdf.setTextColor(128);
    pdf.setFontSize(11);

    pdf.text(`${text.invoice_pdf.introduction_message}`, 16, 102);

    pdf.setFontSize(12);
    pdf.setTextColor(0, 0, 0);

    const itemsPerPage = createPagesFromInvoiceItems(generateInvoicePdfDTO.items);

    pdf.text(`${text.invoice_pdf.table.description}`, 16, 138);

    if (generateInvoicePdfDTO.displayTotalHours) {
      pdf.text(`${text.invoice_pdf.table.plate}`, 65, 138);
      pdf.text(`${text.invoice_pdf.table.chassis}`, 100, 138);
      pdf.text(`${text.invoice_pdf.table.hours}`, 135, 138);
      pdf.text(`${text.invoice_pdf.table.repair}`, 160, 138);
    } else {
      pdf.text(`${text.invoice_pdf.table.plate}`, 70, 138);
      pdf.text(`${text.invoice_pdf.table.chassis}`, 115, 138);
      pdf.text(`${text.invoice_pdf.table.repair}`, 160, 138);
    }

    pdf.setFont("helvetica", "normal");
    let totalHoursCount = 0;
    let finalPrice = 0;

    itemsPerPage.forEach((items, page) => {
      let finalY = 0;
      items.forEach((item, index) => {
        const yOffset = page === 0 ? 140 : 40;
        const row = index + 1;
        const y = row * 6 + yOffset;

        const { pericia, car } = item;
        const { brand, model, plate, chassisNumber } = car;
        const chassis = chassisNumber.length > 2 ? chassisNumber : "-";
        const totalHours = (pericia.insuranceHours > 0 ? pericia.insuranceHours : pericia.totalHours) / 10;
        totalHoursCount += totalHours;

        pdf.text(`${brand} ${model}`, 16, y);

        if (generateInvoicePdfDTO.displayTotalHours) {
          pdf.text(plate, 65, y);
          pdf.text(chassis, 100, y);
          pdf.text(`${totalHours.toFixed(2)}`, 135, y);
          pdf.text(formatCurrency(getPericiaCustomPrice(pericia)), 160, y);
          finalPrice += getPericiaCustomPrice(pericia);
        } else {
          pdf.text(plate, 70, y);
          pdf.text(chassis, 115, y);
          pdf.text(formatCurrency(getPericiaCustomPrice(pericia)), 160, y);
          finalPrice += getPericiaCustomPrice(pericia);
        }

        finalY = y;
      });

      if (page !== itemsPerPage.length - 1) {
        pdf.setFontSize(8);
        pdf.setTextColor(128);
        pdf.setFont("helvetica", "normal");

        pdf.text(`PostFinance`, 16, 282);
        pdf.text(`CH7309000000155473924`, 16, 286);
        pdf.text(`IVA: CHE-162.607.499`, 16, 290);

        pdf.setFontSize(12);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("helvetica");
        pdf.addPage();
      } else {
        if (generateInvoicePdfDTO.displayTotalHours) {
          pdf.text(`${totalHoursCount.toFixed(2)}`, 135, finalY + 6);
        }

        const IVAValue = (finalPrice * IVA) / 100;

        pdf.text(`${invoiceText.slice(0, 89)}`, 16, finalY + 11);

        if (invoiceText.length > 89) {
          pdf.text(`${invoiceText.slice(89)}`, 15, finalY + 17);
        }

        const subtotalX = costumerLanguage === "de" ? 105 : 132;
        pdf.text(`${text.invoice_pdf.costs.subtotal}:`, subtotalX, finalY + 26);

        pdf.text(`${currencyMask(formatIntegerToCurrency(finalPrice))}`, 160, finalY + 26);

        const ivaX = costumerLanguage === "de" ? 128 : 132;
        pdf.text(`IVA ${IVA}%:`, ivaX, finalY + 32);
        pdf.text(`${currencyMask(formatIntegerToCurrency(IVAValue))}`, 160, finalY + 32); //IVA VALUE
        pdf.setFont("helvetica", "bold");

        const totalX = costumerLanguage === "de" ? 100 : 137;
        pdf.text(`${text.invoice_pdf.costs.total}:`, totalX, finalY + 38);
        pdf.text(`${currencyMask(formatIntegerToCurrency(finalPrice + IVAValue))}`, 160, finalY + 38);

        pdf.setFontSize(11);
        pdf.text(`${text.invoice_pdf.salute_message}`, 16, finalY + 46);

        pdf.setFontSize(8);
        pdf.setTextColor(128);
        pdf.setFont("helvetica", "normal");

        pdf.text(`PostFinance`, 16, 282);
        pdf.text(`CH7309000000155473924`, 16, 286);
        pdf.text(`IVA: CHE-162.607.499`, 16, 290);

        pdf.setFontSize(12);
        pdf.setTextColor(0, 0, 0);
      }
    });

    pdf.save(`${costumerName}_fattura_0${invoiceNumber}.pdf`);
  }
};
