import * as React from "react";
import { Link, Outlet } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import ConstructionIcon from "@mui/icons-material/Construction";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import GroupIcon from "@mui/icons-material/Group";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PaidIcon from "@mui/icons-material/Paid";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";

import { useContext, useEffect, useState } from "react";
import { USER_ROLES, UserContext } from "../../contexts/user/user.context";
import { signOut } from "../../services/auth/auth.service";
import { Drawer } from "@mui/material";
import NotificationBadgeIcon from "../../features/notification/list-notifications/ui/notification-badge-icon";

type Anchor = "top" | "left" | "bottom" | "right";

interface PageLinks {
  name: string;
  path: string;
  icon: React.ReactNode;
  roles: string[];
}

const PAGE_LINKS: PageLinks[] = [
  {
    name: "Home",
    path: "/",
    icon: <HomeIcon />,
    roles: [USER_ROLES.ADMIN, USER_ROLES.SALESMAN, USER_ROLES.LIMITED_ADMIN, USER_ROLES.USER_PRIVILEGED, USER_ROLES.PERICIA_CREATOR]
  },
  {
    name: "Perícias",
    path: "/pericias/list",
    icon: <ConstructionIcon />,
    roles: [USER_ROLES.ADMIN, USER_ROLES.SALESMAN, USER_ROLES.LIMITED_ADMIN, USER_ROLES.USER_PRIVILEGED, USER_ROLES.PERICIA_CREATOR]
  },
  {
    name: "Add Cliente",
    path: "/costumers/add",
    icon: <GroupAddIcon />,
    roles: [USER_ROLES.ADMIN, USER_ROLES.SALESMAN, USER_ROLES.LIMITED_ADMIN]
  },
  {
    name: "Clientes",
    path: "/costumers/list",
    icon: <GroupIcon />,
    roles: [USER_ROLES.ADMIN, USER_ROLES.SALESMAN, USER_ROLES.LIMITED_ADMIN]
  },
  {
    name: "Relatórios",
    path: "/costumers/list/pericias/pending",
    icon: <AssessmentOutlinedIcon />,
    roles: [USER_ROLES.ADMIN, USER_ROLES.SALESMAN, USER_ROLES.LIMITED_ADMIN]
  },
  {
    name: "Métricas",
    path: "/pericias/metrics",
    icon: <SignalCellularAltIcon />,
    roles: [USER_ROLES.ADMIN]
  },
  {
    name: "Add Usuário",
    path: "/users/add",
    icon: <PersonAddAltIcon />,
    roles: [USER_ROLES.ADMIN]
  },
  {
    name: "Usuários",
    path: "/users/list",
    icon: <PeopleOutlineIcon />,
    roles: [USER_ROLES.ADMIN]
  },
  {
    name: "Gerar Fatura",
    path: "/invoices/create",
    icon: <RequestQuoteIcon />,
    roles: [USER_ROLES.ADMIN, USER_ROLES.LIMITED_ADMIN, USER_ROLES.SALESMAN]
  },
  {
    name: "Listar Faturas",
    path: "/invoices/list",
    icon: <PaidIcon />,
    roles: [USER_ROLES.ADMIN, USER_ROLES.SALESMAN, USER_ROLES.LIMITED_ADMIN]
  },
  {
    name: "Buscar Carros",
    path: "/mechanics/pericias/list",
    icon: <ConstructionIcon />,
    roles: [USER_ROLES.ADMIN, USER_ROLES.USER, USER_ROLES.USER_PRIVILEGED]
  },
  {
    name: "Carros Finalizados",
    path: "/mechanics/pericias/repair-records",
    icon: <CarRepairIcon />,
    roles: [USER_ROLES.ADMIN, USER_ROLES.USER, USER_ROLES.USER_PRIVILEGED]
  },
  {
    name: "Saldo",
    path: "/users/me/balance",
    icon: <AttachMoneyIcon />,
    roles: [USER_ROLES.ADMIN, USER_ROLES.SALESMAN, USER_ROLES.USER, USER_ROLES.USER_PRIVILEGED]
  }
];

const INITIAL_LINKS = PAGE_LINKS.filter((page) => {
  return page.roles.includes(USER_ROLES.USER);
});

const Navigation = () => {
  const { user } = useContext(UserContext);
  const [navLinks, setNavLinks] = useState<PageLinks[]>(INITIAL_LINKS);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });
  const [exitTime, setExitTime] = useState(500);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (navLinks.length === 0) {
      const links = PAGE_LINKS.filter((page) => {
        return page.roles.includes(user?.permission || USER_ROLES.USER);
      });
      setNavLinks(links);
    }
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {navLinks.map((page, index) => (
          <ListItem key={page.name} disablePadding>
            <ListItemButton href={page.path} onClick={() => setExitTime(0)}>
              <ListItemIcon>{page.icon}</ListItemIcon>
              <ListItemText primary={page.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  useEffect(() => {
    if (!user) {
      setNavLinks(INITIAL_LINKS);
      return;
    }

    if (user.permission === "undefined") {
      setNavLinks(INITIAL_LINKS);
      return;
    }

    const links = PAGE_LINKS.filter((page) => {
      return page.roles.includes(user.permission || USER_ROLES.USER);
    });
    setNavLinks(links);
  }, [user]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <React.Fragment key={"left"}>
              {user && (
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={toggleDrawer("left", true)}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Drawer
                anchor={"left"}
                open={state["left"]}
                onClose={toggleDrawer("left", false)}
                transitionDuration={{ exit: exitTime, enter: 500 }}
              >
                {list("left")}
              </Drawer>
            </React.Fragment>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                Nuovauto
              </Link>
            </Typography>
            {user && user.permission === USER_ROLES.ADMIN && <NotificationBadgeIcon />}
            {user && (
              <Button color="inherit" onClick={signOut}>
                Log out
              </Button>
            )}
          </Toolbar>
        </AppBar>
      </Box>

      <Outlet />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "15vh"
        }}
      >
        <CssBaseline />
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: "auto",
            backgroundColor: (theme) => (theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[800])
          }}
        >
          <Container maxWidth="sm">
            <Typography variant="body1" textAlign="center">
              Nuovauto © 2024
            </Typography>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Navigation;
