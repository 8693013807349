import { useCallback } from "react";
import { Pericia, getPericiaCustomPriceCurrencyFormat, getPericiasCustomPrice } from "../../../../entities/pericia";
import jsPDF from "jspdf";
import { removeWhiteSpaces } from "../../../../shared/helpers/string";
import { splitArrayIntoPages } from "../../../../shared/helpers/array";
import { currencyMask, formatIntegerToCurrency } from "../../../../utils/helpers/numbers";

interface CostumerPericiasListPDFProps {
  pericias: Pericia[];
  costumerName: string;
  start: Date;
  end: Date;
}

interface CreatePDFProps {
  pericias: Pericia[];
  totalPrice: string;
  costumerName: string;
  start: string;
  end: string;
}

function create(props: CreatePDFProps) {
  const { pericias, totalPrice, costumerName, start, end } = props;
  const logoImg = require("../../../../assets/nuovauto.png");

  const pdf = new jsPDF("p", "mm", "a4");
  pdf.addImage(logoImg, "PNG", 16, 12, 90, 24);

  pdf.setFontSize(12);
  pdf.setFont("helvetica", "bold");

  pdf.text(`Cliente: ${costumerName}`, 16, 60);
  pdf.text(`Período: ${start} - ${end}`, 16, 66);

  const pages = splitArrayIntoPages(pericias, 28, 34);

  pages.forEach((page, pageIndex) => {
    let x = 16;
    let y = pageIndex === 0 ? 96 : 30;

    page.forEach((pericia) => {
      const { car, date } = pericia;
      const { plate, model } = car;

      pdf.setFont("helvetica", "normal");

      pdf.text(plate, x, y);
      pdf.text(model, x + 12 * 4, y);
      pdf.text(new Date(date).toLocaleDateString("pt-br"), x + 12 * 8, y);
      pdf.text(getPericiaCustomPriceCurrencyFormat(pericia), x + 12 * 12, y);

      y += 6;
    });

    if (pageIndex === pages.length - 1) {
      pdf.text(`Totale: ${totalPrice}`, x + 12 * 11, y + 6);
    } else {
      pdf.addPage();
    }
  });

  const fileName = `${removeWhiteSpaces(costumerName)}-${start}-${end}.pdf`;

  pdf.save(fileName);
}

export function useCostumerPericiasListPDFCreator() {
  const createPDF = useCallback((props: CostumerPericiasListPDFProps) => {
    const { pericias, costumerName, end, start } = props;
    const totalPrice = currencyMask(formatIntegerToCurrency(getPericiasCustomPrice(pericias)));

    create({
      pericias,
      totalPrice,
      costumerName,
      start: start.toLocaleDateString("pt-br"),
      end: end.toLocaleDateString("pt-br")
    });
  }, []);

  return { createPDF };
}
