import { Route, Routes } from "react-router-dom";
import MechanistPericiaList from "./pericia-list/pericia-list-mechanic.component";
// import MechanicPericiaUpdate from "./pericia-update/pericia-update-mechanic.component";
import RepairRecordsListComponent from "./repair-records/repair-records.component";
import { ListRepairRecordsByUserIdPage } from "../../../pages/repair-records/list-repair-records-by-user-id.page";
import { MechanicUpdatePericiaPage } from "../../../pages/pericia/mechanic-update-pericia.page";
import RepairRecordsListByDateComponent from "./repair-records/repair-records-by-date.component";

const MechanicPericiasRoute = () => {
  return (
    <Routes>
      <Route path="/list" element={<MechanistPericiaList />} />
      {/* <Route path=":periciaID" element={<MechanicPericiaUpdate />} /> */}
      <Route path=":id" element={<MechanicUpdatePericiaPage />} />
      <Route path="/repair-records" element={<RepairRecordsListComponent />} />
      <Route path="/repair-records/date" element={<RepairRecordsListByDateComponent />} />
      <Route path="/repair-records2" element={<ListRepairRecordsByUserIdPage />} />
    </Routes>
  );
};

export default MechanicPericiasRoute;
