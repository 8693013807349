import { Typography } from "@mui/material";
import FlexContainer from "../../shared/ui/container/flex-container.component";
import { useEffect, useState } from "react";
import { Costumer, CreateNewCostumer } from "../../entities/costumer";
import { ToastContainer, toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { useListRepairRecordsByUserId } from "../../features/repair-record/list-repair-records-by-user-id/api/useListRepairRecordsByUserId";
import { RepairRecord } from "../../entities/repair-record";
import RepairRecordsList from "../../features/repair-record/list-repair-records-by-user-id/ui/repair-records-list.component";
import { RepairRecordsSearchComponent } from "../../features/repair-record/list-repair-records-by-user-id/ui/repair-records-list-search-form.component";
import supabase from "../../shared/database/supabase";

export const ListRepairRecordsByUserIdPage = () => {
  const [userId, setUserId] = useState<string>("");
  const [costumer, setCostumer] = useState<Costumer>(CreateNewCostumer());
  const [date, setDate] = useState(new Date());
  const [useFilter, setUseFilter] = useState(false);
  const { data, isLoading, error } = useListRepairRecordsByUserId(userId);

  const handleToggleUseFilter = () => {
    setUseFilter(!useFilter);
  };

  const handleDateUpdate = (date: Date) => {
    setDate(date);
  };

  const handleCostumerUpdate = (costumer: Costumer) => {
    setCostumer(costumer);
  };

  //TODO refact this
  const handleListRepairRecords = () => {
    if (supabase.auth.user()) {
      const userId = supabase.auth.user()?.id as string;
      setUserId(userId);
    }
  };

  if (error) {
    toast.error("Erro ao buscar reparos");
  }

  useEffect(() => {
    if (data?.length === 0) {
      toast.info("Nenhum reparo encontrado");
    }
  }, [data]);

  return (
    <>
      <FlexContainer>
        <Typography variant="h4" mb={5}>
          Lista de reparos realizados
        </Typography>

        <RepairRecordsSearchComponent
          props={{
            costumer,
            date,
            useFilter,
            isLoading,
            handleListRepairRecords,
            updateDate: handleDateUpdate,
            updateCostumer: handleCostumerUpdate,
            handleToggleUseFilter
          }}
        />
        {data && data?.length >= 1 && (
          <>
            <RepairRecordsList
              props={{
                repairRecords: useFilter ? filterRepairRecordsByCostumerName(data, costumer.name) : data
              }}
            />

            <LoadingButton
              fullWidth
              variant="contained"
              sx={{ mb: 1, mt: 5 }}
              loading={isLoading}
              // onClick={generatePDF}
            >
              GERAR PDF
            </LoadingButton>
          </>
        )}
        <ToastContainer />
      </FlexContainer>
    </>
  );
};

function filterRepairRecordsByCostumerName(repairRecords: RepairRecord[], costumerName: string) {
  return repairRecords.filter((repairRecord) => repairRecord.pericia.costumer.name === costumerName);
}
