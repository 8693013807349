import { getInvoiceNextCustomCount } from "../../../entities/invoice/api/get-invoice-next-custom-count";
import { InvoiceToInsert } from "../../../models/invoice/invoice.repository";
import { PericiaByCostumerID } from "../../../models/pericia/pericia";
import supabase from "../../../shared/database/supabase";

export const createInvoice = async (invoice: InvoiceToInsert) => {
  try {
    const invoiceNumber = await getInvoiceNextCustomCount();
    const { data, error } = await supabase.from("invoices").insert({ ...invoice, invoice_custom_count: invoiceNumber });
    if (error) {
      return { data: null, error };
    }

    return { data: data[0].id, error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deleteInvoice = async (invoiceId: string) => {
  try {
    const { error } = await supabase.from("invoices").delete().eq("id", invoiceId);
    if (error) {
      return { error };
    }

    return { error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

interface PericiaInvoiceDTO {
  invoice_id: string;
  costumer_id: string;
  pericias: PericiaByCostumerID[];
}

interface PericiaInvoiceToInsert {
  invoice_id: string;
  pericia_id: string;
  car_id: string;
  costumer_id: string;
}

export const createPericiaInvoices = async (p: PericiaInvoiceDTO) => {
  const periciaInvoicesToInsert: PericiaInvoiceToInsert[] = p.pericias.map((pericia) => ({
    invoice_id: p.invoice_id,
    costumer_id: p.costumer_id,
    pericia_id: pericia.id,
    car_id: pericia.car.id
  }));

  try {
    const { data, error } = await supabase.from("pericia_invoices").insert(periciaInvoicesToInsert);
    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deletePericiaInvoices = async (invoiceId: string) => {
  try {
    const { error } = await supabase.from("pericia_invoices").delete().eq("invoice_id", invoiceId);
    if (error) {
      return { error };
    }

    return { error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateInvoiceCustomCount = async (invoiceId: string, count: number) => {
  try {
    const { error } = await supabase.from("invoices").update({ invoice_custom_count: count }).eq("id", invoiceId);
    if (error) {
      return { error };
    }

    return { error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};
