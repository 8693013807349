import _debounce from "lodash/debounce";
import { Grid, TextField, Typography } from "@mui/material";
import FlexContainer from "../../shared/ui/container/flex-container.component";
import { useInvoice } from "../../entities/invoice/model/useInvoice";
import { useEffect, useState } from "react";
import { useListPericiasNotBilled } from "../../features/pericia/list-pericias-not-billed/api/useListPericiasNotBilled";
import { PaperCard } from "../../shared/ui/paper-card/paper-card.component";
import DateRange from "../../components/date/date-range/date-range.component";
import { LoadingButton } from "@mui/lab";
import { ToastContainer, toast } from "react-toastify";
import { Costumer, CreateNewCostumer } from "../../entities/costumer";
import CostumerAutocompleteSearchBar from "../../entities/costumer/ui/costumer-autocomplete-search-bar.component";
import InvoiceItemsList from "../../features/invoice/ui/invoice-items-list.component";
import { Pericia } from "../../entities/pericia";
import { filterPericiaIds } from "../../entities/pericia/model/helpers";
import { extractPercentage, toCHF } from "../../utils/helpers/numbers";
import { UseCreateInvoiceRequest, useCreateInvoice } from "../../features/invoice/create-invoice/api/useCreateInvoice";
import { createInvoiceReq } from "../../entities/invoice/api/create-invoice";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../shared/helpers/string";

interface SearchPericiasRequest {
  costumerId: string;
  start: Date;
  end: Date;
}

const INITIAL_INVOICE: UseCreateInvoiceRequest = {
  costumerId: "",
  startDate: new Date(),
  endDate: new Date(),
  totalPericias: 0,
  totalPrice: 0,
  agentComission: 0,
  pericias: []
};

interface CreateInvoicePageProps {
  costumer: Costumer;
  startDate: Date;
  endDate: Date;
  selectedPericias: Pericia[];
}

const INITIAL_PAGE_PROPS: CreateInvoicePageProps = {
  costumer: CreateNewCostumer(),
  startDate: new Date(),
  endDate: new Date(),
  selectedPericias: []
};

export const CreateInvoicePage = () => {
  const [costumer, setCostumer] = useState<Costumer>(INITIAL_PAGE_PROPS.costumer);
  const [startDate, setStartDate] = useState<Date>(INITIAL_PAGE_PROPS.startDate);
  const [endDate, setEndDate] = useState<Date>(INITIAL_PAGE_PROPS.endDate);
  const [agentComission, setAgentComission] = useState<string>("");
  const [request, setRequest] = useState<SearchPericiasRequest>({
    costumerId: "",
    start: INITIAL_PAGE_PROPS.startDate,
    end: INITIAL_PAGE_PROPS.endDate
  });
  const [filter, setFilter] = useState<string>("");
  const [selectedPericias, setSelectedPericias] = useState<Pericia[]>([]);
  const [filteredPericias, setFilteredPericias] = useState<Pericia[]>([]);
  const [selectedPericiasTotalPrice, setSelectedPericiasTotalPrice] = useState<number>(0);
  const {
    data: pericias,
    error: errorFetchingPericias,
    isLoading: isLoadingFetchingPericias,
    refetch: refetchpericias
  } = useListPericiasNotBilled(request);
  const [invoice, setInvoice] = useState<UseCreateInvoiceRequest>(INITIAL_INVOICE);
  const {
    data: createInvoiceRes,
    error: errorCreatingInvoice,
    isLoading: isLoadingCreateInvoice
  } = useCreateInvoice(createInvoiceReq, invoice);
  const { getInvoiceTotalPrice } = useInvoice();

  const navigate = useNavigate();

  const handleSetSelectedPericias = (pericias: Pericia[]) => {
    setSelectedPericias(pericias);
  };

  const handleSetFilteredPericias = (pericias: Pericia[]) => {
    setFilteredPericias(pericias);
  };

  const handlePericiaUpdated = () => {
    refetchpericias();
  };

  const handleSearchPericias = () => {
    if (!costumer.id) {
      toast.warn("Selecione um cliente");
      return;
    }

    setRequest({ costumerId: costumer.id, start: startDate, end: endDate });
  };

  const handleCreateInvoice = () => {
    setInvoice({
      costumerId: costumer.id,
      totalPericias: selectedPericias.length,
      totalPrice: selectedPericiasTotalPrice,
      pericias: selectedPericias,
      endDate,
      startDate,
      agentComission: parseFloat(agentComission)
    });
  };

  const handleChangeDate = (startDate: Date, endDate: Date) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleToggleSelectPericia = (pericia: Pericia) => {
    if (selectedPericias.includes(pericia)) {
      handleSetSelectedPericias(selectedPericias.filter((item) => item.id !== pericia.id));
      return;
    }

    handleSetSelectedPericias([...selectedPericias, pericia]);
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const handleSelectAllPericias = () => {
    if (selectedPericias.length === filteredPericias.length) {
      handleSetSelectedPericias([]);
      return;
    }

    handleSetSelectedPericias(filteredPericias);
  };

  useEffect(() => {
    if (!pericias) return;

    if (pericias.length === 0) toast.info("Nenhuma perícia encontrada");
  }, [pericias]);

  useEffect(() => {
    if (!pericias) return;

    handleSetFilteredPericias(pericias);
  }, [pericias]);

  useEffect(() => {
    if (!pericias) return;

    if (selectedPericias.length === 0) return;

    setSelectedPericias(pericias.filter((item) => selectedPericias.map((pericia) => pericia.id).includes(item.id)));
  }, [pericias]);

  useEffect(() => {
    setSelectedPericiasTotalPrice(getInvoiceTotalPrice(selectedPericias));
  }, [selectedPericias]);

  useEffect(() => {
    if (!filter && pericias) {
      handleSetFilteredPericias(pericias);
      return;
    }

    const debounced = _debounce(() => {
      handleSetFilteredPericias(filterPericiaIds(pericias as Pericia[], filter));
    }, 500);
    debounced();
    return debounced.cancel;
  }, [filter]);

  useEffect(() => {
    if (!createInvoiceRes) return;

    if (errorCreatingInvoice) {
      return;
    }

    toast.success("Fatura gerada com sucesso, redirecionando...", { autoClose: 600 });
    setTimeout(() => {
      navigate(`/invoices/${createInvoiceRes.id}`);
    }, 1200);
  }, [createInvoiceRes]);

  if (errorCreatingInvoice) toast.error(errorCreatingInvoice.toast_message);
  if (errorFetchingPericias) toast.error("Erro ao buscar perícias...");

  return (
    <FlexContainer>
      <Typography variant="h4">Gerar Fatura</Typography>
      <Typography variant="subtitle1">Gera fatura para clientes. Perícias incluídas em outra fatura não aparecem aqui.</Typography>
      <Grid container mt={2}>
        <PaperCard title="Selecione o cliente e o período">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <CostumerAutocompleteSearchBar props={{ costumer, updateCostumer: setCostumer }} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <DateRange props={{ startDate, endDate, onChange: handleChangeDate }} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <LoadingButton
                fullWidth
                variant="contained"
                sx={{ mt: 1 }}
                loading={isLoadingFetchingPericias}
                onClick={handleSearchPericias}
              >
                BUSCAR
              </LoadingButton>
            </Grid>
          </Grid>
        </PaperCard>
        {pericias && pericias.length > 0 && (
          <>
            <Typography variant="h5" mt={2}>
              {`${capitalizeFirstLetter(costumer.name)} - ${pericias.length} perícia(s) encontrada(s)`}
            </Typography>
            <InvoiceItemsList
              props={{
                selectedPericias: selectedPericias,
                pericias: filteredPericias,
                filter,
                handleFilterChange,
                handleToggleSelectPericia,
                handleSelectAllPericias,
                handlePericiaUpdated: handlePericiaUpdated
              }}
            />
            <Grid item xs={12} sm={12} mt={2}>
              <PaperCard title="Comissão ao vendedor">
                <TextField
                  size="small"
                  margin="dense"
                  id="term"
                  label="Em porcentagem (%)"
                  variant="standard"
                  name="agentComission"
                  type="text"
                  fullWidth
                  value={agentComission}
                  onChange={(e) => {
                    setAgentComission(extractPercentage(e.target.value).toString());
                  }}
                />
              </PaperCard>
            </Grid>
            <div style={{ width: "100%", textAlign: "right", marginTop: "2rem" }}>
              <Typography variant="body1">
                {selectedPericias.length} perícia(s) selecionada(s) - Total: {toCHF(selectedPericiasTotalPrice)}
              </Typography>
            </div>
            <div style={{ width: "100%", textAlign: "right" }}>
              <Typography variant="body1">
                Comissão ao vendedor: {agentComission ? toCHF((selectedPericiasTotalPrice * parseFloat(agentComission)) / 100) : ""}
              </Typography>
            </div>
            <Grid item xs={12} sm={12} mt={2} sx={{ textAlign: "right" }}>
              <LoadingButton fullWidth variant="contained" sx={{ mt: 1 }} loading={isLoadingCreateInvoice} onClick={handleCreateInvoice}>
                CRIAR FATURA
              </LoadingButton>
            </Grid>
          </>
        )}
      </Grid>
      <ToastContainer />
    </FlexContainer>
  );
};
