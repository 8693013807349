import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Tab, Tabs, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { CarPart } from "../model/pericia";
import { isNotNumber } from "../../../utils/helpers/numbers";
import { CAR_PARTS } from "../../../shared/constants/car-parts.constants";
const { PARAFANGO_AD, PARAFANGO_AS } = CAR_PARTS;
const notesInLine = [PARAFANGO_AD.value, PARAFANGO_AS.value];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

interface CarPartComponentProps {
  top: number;
  left: number;
  carPart: CarPart;
  updateCarPart: (carPart: CarPart) => void;
}

const CarPartComponent: React.FC<CarPartComponentProps> = ({ top, left, carPart, updateCarPart }) => {
  const [value, setValue] = useState(carPart.customHours > 0 ? 1 : 0);
  const [open, setOpen] = useState(false);
  const [carPartMask, setCarPartMask] = useState<CarPart>(carPart);
  let {
    isAluminum,
    shouldPaint,
    shouldReplace,
    shouldGlue,
    smallSmash,
    smash,
    name,
    useMaxNoteDetails,
    isAddtionalRepair,
    customHours,
    customHoursDescription
  } = carPartMask;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;

    if (isNotNumber(value)) {
      return;
    }

    if (name === "customHours" && Number(value) > 0) {
      setCarPartMask({ ...carPartMask, [name]: Number(value) * 10 });
      return;
    }

    setCarPartMask({ ...carPartMask, [name]: Number(value) });
  };

  const handleTextValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;

    setCarPartMask({ ...carPartMask, [name]: value });
  };

  const handleClickChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setCarPartMask({ ...carPartMask, [name]: checked });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== "backdropClick") {
      updateCarPart(carPartMask);
      setOpen(false);
    }
  };

  const getFormattedNotes = () => {
    const firstNotesLenght = carPart.note.smashes.length;
    const secondNotesLenght = carPart.note.details.length;

    if (firstNotesLenght + secondNotesLenght === 0) {
      return (
        <Typography variant="h5" component="span" fontSize={18}>
          0
        </Typography>
      );
    }

    if (firstNotesLenght === 0) {
      return (
        <Typography variant="h5" component="div" fontSize={18}>
          {carPart.note.details}
        </Typography>
      );
    }

    if (notesInLine.includes(name)) {
      return (
        <Typography variant="h5" component="div" fontSize={18}>
          {carPart.note.smashes} {carPart.note.details}
        </Typography>
      );
    }

    return (
      <>
        <Typography variant="h5" component="div" fontSize={18}>
          {carPart.note.smashes} <br></br> {carPart.note.details}
        </Typography>
      </>
    );
  };

  useEffect(() => {
    setCarPartMask(carPart);
  }, [carPart]);

  return (
    <div style={{ position: "absolute", top: `${top}px`, left: `${left}px` }}>
      <Button onClick={handleClickOpen} style={{ zIndex: "10", transform: "rotate(0deg)" }}>
        {getFormattedNotes()}
      </Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose} sx={{ marginTop: "-23%" }}>
        <DialogTitle>{name}</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              minWidth: "350px"
            }}
          >
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Amassados" {...a11yProps(0)} />
              <Tab label="Horas" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Grid container spacing={1}>
              {[
                { name: "smallSmash", label: "25mm", value: smallSmash },
                { name: "smash", label: ">25mm", value: smash }
              ].map((item) => {
                return (
                  <Grid item xs={6} sm={6} key={item.name + "grid-item-small"}>
                    <TextField
                      fullWidth
                      size="small"
                      margin="dense"
                      id="name"
                      label={item.label}
                      variant="standard"
                      value={item.value}
                      onChange={handleValueChange}
                      name={item.name}
                      error={customHours > 0}
                      disabled={customHours > 0}
                      helperText={customHours > 0 && "Utilizando horas"}
                    />
                  </Grid>
                );
              })}
              <Grid item xs={12} sm={12}>
                {[
                  { name: "shouldPaint", label: "Pintura", func: shouldPaint },
                  { name: "isAluminum", label: "AL", func: isAluminum },
                  { name: "shouldReplace", label: "Trocar", func: shouldReplace },
                  { name: "shouldGlue", label: "Cola", func: shouldGlue },
                  { name: "useMaxNoteDetails", label: "MASSIMO", func: useMaxNoteDetails },
                  { name: "isAddtionalRepair", label: "Adicional", func: isAddtionalRepair }
                ].map((item) => {
                  return (
                    <FormControlLabel
                      key={item.name + "form-control-label-small"}
                      control={<Checkbox checked={item.func} onChange={handleClickChange} name={item.name} disabled={customHours > 0} />}
                      label={item.label}
                      sx={{ marginBottom: "-10%" }}
                    />
                  );
                })}
              </Grid>
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  id="customHours"
                  label="Horas"
                  variant="standard"
                  value={customHours / 10}
                  onChange={handleValueChange}
                  name="customHours"
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  id="customHoursDescription"
                  label="Descrição"
                  variant="standard"
                  value={customHoursDescription}
                  onChange={handleTextValueChange}
                  name="customHoursDescription"
                  helperText="Somente 4 caracteres"
                />
              </Grid>
            </Grid>
          </CustomTabPanel>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CarPartComponent;
