import supabase from "../../../shared/database/supabase";

export const getFinishedRepairRecordsByPericiaId = async (id_pericia: string) => {
  try {
    const { data, error } = await supabase
      .from("pericia_repair_records")
      .select(`id, finished_at, payed, payed_value, user: id_user(id, name, last_name, displayName: display_name)`)
      .eq("id_pericia", id_pericia)
      .eq("done", true);

    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (error) {
    throw error;
  }
};

interface UpdateRepairRecordPayedAmountArgs {
  id: string;
  amount: number;
  id_payment: string;
}

export const updateRepairRecordPayedAmount = async (args: UpdateRepairRecordPayedAmountArgs) => {
  const { id, amount } = args;

  try {
    const { data, error } = await supabase
      .from("pericia_repair_records")
      .update({ payed_value: amount, id_technician_payment: args.id_payment })
      .eq("id", id);

    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (error) {
    throw error;
  }
};

export const updateRepairRecordStatus = async (id_pericia: string) => {
  try {
    const { data, error } = await supabase.from("pericia_repair_records").update({ done: true }).eq("id_pericia", id_pericia);

    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (error) {
    throw error;
  }
};

export const updateRepairRecordPayed = async (id_pericia: string) => {
  try {
    const { data, error } = await supabase
      .from("pericia_repair_records")
      .update({ payed: true })
      .eq("id_pericia", id_pericia)
      .eq("done", true);

    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (error) {
    throw error;
  }
};

interface UpdateRepairRecordPayedArgs {
  id_pericia: string;
  payed: boolean;
}
export const updateRepairRecordsPayedStatus = async ({ id_pericia, payed }: UpdateRepairRecordPayedArgs) => {
  try {
    const { data, error } = await supabase
      .from("pericia_repair_records")
      .update({ payed, payed_at: new Date() })
      .eq("id_pericia", id_pericia)
      .eq("done", true);

    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (error) {
    throw error;
  }
};
