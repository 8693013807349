import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import CostumerAutocompleteSearchBar from "../../../../entities/costumer/ui/costumer-autocomplete-search-bar.component";
import DateRangePicker from "../../../../shared/ui/date/date-range-picker.component";
import { LoadingButton } from "@mui/lab";
import { Costumer } from "../../../../entities/costumer";
import { PaperCard } from "../../../../shared/ui/paper-card/paper-card.component";

interface CostumerListPericiasHeaderProps {
  props: {
    costumer: Costumer;
    updateCostumer: (costumer: Costumer) => void;
    start: Date;
    end: Date;
    handleDateChange: (start: Date, end: Date) => void;
    handleSetListCostumerPericiasProps: () => void;
    handleFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isLoading: boolean;
  };
}

export const CostumerListPericiasHeader = (props: CostumerListPericiasHeaderProps) => {
  const { costumer, updateCostumer, start, end, isLoading, handleDateChange, handleSetListCostumerPericiasProps, handleFilterChange } =
    props.props;

  const filters = [
    { name: "notDone", label: "Em andamento" },
    { name: "done", label: "Finalizado" },
    { name: "isPriceCalculated", label: "Controlado" },
    { name: "billed", label: "Faturado" }
  ];

  return (
    <PaperCard title="Buscar pericias">
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12}>
          <CostumerAutocompleteSearchBar props={{ costumer, updateCostumer }} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DateRangePicker props={{ start, end, onChange: handleDateChange }} />
        </Grid>
        {filters.map((filter) => {
          return (
            <Grid item xs={6} sm={3} mt={2} key={filter.name}>
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox name={filter.name} onChange={handleFilterChange} size="small" />}
                  label={filter.label}
                />
              </FormGroup>
            </Grid>
          );
        })}
        <Grid item xs={12} mt={3}>
          <LoadingButton type="submit" fullWidth variant="contained" loading={isLoading} onClick={handleSetListCostumerPericiasProps}>
            Buscar
          </LoadingButton>
        </Grid>
      </Grid>
    </PaperCard>
  );
};
