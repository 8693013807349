import { LoadingButton } from "@mui/lab";
import { InputAdornment, ListItem, ListItemButton, ListItemText, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FlexContainer from "../../../../shared/ui/container/flex-container.component";
import { getMechanicPericiasByChassisOrPlate, MechanicPericia } from "../../../../storage/supabase/pericia/pericia.storage";
import { periciaService } from "../../../../services/pericia/pericia.service";
import { ToastContainer, toast } from "react-toastify";
import CreateRepairRecordDialogComponent from "../../../../components/repair-records/create-repair-record-dialog/create-repair-record-dialog";
import { useQuery } from "react-query";
import SearchIcon from "@mui/icons-material/Search";

const MechanistPericiaList = () => {
  const [pericias, setPericias] = useState<MechanicPericia[]>([]);
  const [currentPericiaID, setCurrentPericiaID] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState("");

  const navigate = useNavigate();

  const { data } = useQuery("repairRecordsByUserID", () =>
    periciaService.findRepairRecordsByUserID().then((res) => {
      if (res.error) {
        console.log(res.error);
        throw new Error("Erro ao buscar clientes");
      }
      return res.data;
    })
  );

  const handleStartRepairRecord = async (periciaID: string) => {
    const res = await periciaService.findRepairRecord(periciaID);
    if (res.error) {
      toast.error("Algo deu errado. Tente novamente.");
      console.log(res.error);
      return;
    }

    if (!res.data) {
      return handleClickOpen(periciaID);
    }

    return navigate(`/mechanics/pericias/${periciaID}`);
  };

  const handleFetchPericias = async () => {
    setLoading(true);
    const res = await getMechanicPericiasByChassisOrPlate(term);
    if (res.error) {
      toast.error("Algo deu errado. Tente novamente.");
      console.log(res.error);
      return;
    }

    if (res.data.length === 0) {
      toast.info("Nenhuma pericia encontrada");
    }

    setPericias(res.data);
    setLoading(false);
  };

  const handleClickOpen = (periciaID: string) => {
    setCurrentPericiaID(periciaID);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickConfirm = async () => {
    if (!currentPericiaID) {
      return;
    }

    setLoading(true);
    const res = await periciaService.createRepairRecord(currentPericiaID);
    if (res.error) {
      toast.error("Algo deu errado. Tente novamente.");
      console.log(res.error);
      setLoading(false);
      return;
    }

    setOpen(false);
    setLoading(false);
    return navigate(`/mechanics/pericias/${currentPericiaID}`);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      navigate(`/mechanics/pericias/${data[0].id_pericia}?operation=ongoing`);
    }
  }, [data]);

  return (
    <FlexContainer>
      <Typography variant="h4" component="div">
        Perícias
      </Typography>
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          margin: "24px 0",
          width: "100%",
          borderRadius: "8px"
        }}
      >
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Chassi ou Placa"
          type="text"
          fullWidth
          variant="standard"
          value={term}
          disabled={loading}
          onChange={(e) => setTerm(e.target.value.toUpperCase())}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
        <LoadingButton
          fullWidth
          variant="contained"
          onClick={handleFetchPericias}
          loading={loading}
          disabled={loading}
          sx={{ marginTop: "16px", marginBottom: "12px" }}
        >
          Buscar
        </LoadingButton>
      </Paper>

      {pericias.map((pericia) => {
        const { car, id, costumer, date, totalHours } = pericia;
        const { brand, model, plate, chassis_number } = car;
        const { name } = costumer;
        const labelId = `checkbox-list-label-${id}`;

        return (
          <ListItem key={id} divider>
            <ListItemButton
              onClick={() => handleStartRepairRecord(id)}
              role={undefined}
              dense
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignText: "center",
                textAlign: "center"
              }}
            >
              <ListItemText id={labelId} primary={`${name}`} sx={{ flex: "1" }} />
              <ListItemText id={labelId} primary={`${model}`} sx={{ flex: "1" }} />
              <ListItemText id={labelId} primary={`${plate || chassis_number}`} sx={{ flex: "1" }} />
              <ListItemText id={labelId} primary={`${new Date(date).toLocaleDateString("pt-BR")}`} sx={{ flex: "1" }} />
            </ListItemButton>
          </ListItem>
        );
      })}
      <CreateRepairRecordDialogComponent
        props={{
          open,
          loading,
          handleClose,
          handleClickConfirm
        }}
      />
      <ToastContainer />
    </FlexContainer>
  );
};

export default MechanistPericiaList;
