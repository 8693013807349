import supabase from "../../../shared/database/supabase";

export interface CreatePericiaNoteRequest {
  id_pericia: string;
  note: string;
}

export interface CreatePericiaNoteResponse {
  id: string;
}

export const createPericiaNote = async (req: CreatePericiaNoteRequest): Promise<CreatePericiaNoteResponse | Error> => {
  const { id_pericia, note } = req;
  try {
    const { data, error } = await supabase.functions.invoke("create-pericia-note", {
      body: JSON.stringify({ id_pericia, note })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    if (!data.id) throw new Error("id not found");

    return data.id;
  } catch (err) {
    console.log("error creating pericia:", err);
    throw err;
  }
};
