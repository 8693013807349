import supabase from "../../../shared/database/supabase";
import { PericiaToUpdateDTO } from "../../../shared/dto/pericia/update-pericia";

export const updatePericiaMechanic = async (pericia: PericiaToUpdateDTO): Promise<string | Error> => {
  try {
    const { data, error } = await supabase.functions.invoke("update-pericia-mechanic", {
      body: JSON.stringify({ pericia })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    if (!data.id) throw new Error("id not found");

    return data.id;
  } catch (err) {
    console.log("error updating pericia:", err);
    throw err;
  }
};
