import { Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ChangeEvent, useEffect, useState } from "react";
import { currencyMask, formatIntegerToCurrency, getNumberFromCurrencyMask } from "../../utils/helpers/numbers";
import { PaperCard } from "../../shared/ui/paper-card/paper-card.component";

interface TechnicianAmountToPayFormProps {
  value: number;
  isLoading: boolean;
  handleCreatePayment: (amount: number) => void;
}

const TechnicianAmountToPayForm: React.FC<TechnicianAmountToPayFormProps> = ({ value, isLoading, handleCreatePayment }) => {
  const [amountToPayMask, setAmountToPayMask] = useState(currencyMask("0"));

  const handleAmountToPayChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAmountToPayMask(currencyMask(e.target.value));
  };

  const handleSaveAmountToPay = () => {
    handleCreatePayment(getNumberFromCurrencyMask(amountToPayMask));
  };

  useEffect(() => {
    if (!value) return;

    setAmountToPayMask(currencyMask(formatIntegerToCurrency(value)));
  }, [value]);

  return (
    <PaperCard title="Valor que será pago aos técnicos">
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            value={amountToPayMask}
            onChange={handleAmountToPayChange}
            label="Valor para os técnicos"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <LoadingButton variant="contained" loading={isLoading} onClick={handleSaveAmountToPay}>
            Salvar
          </LoadingButton>
        </Grid>
      </Grid>
    </PaperCard>
  );
};

export default TechnicianAmountToPayForm;
