import { Car } from "../../models/car/car";
import { CarPart as CarPartRefact } from "../../entities/pericia";
import { CarPart, Pericia, PericiaPriceCalculated } from "../../models/pericia/pericia";
import {
  CarRepositoryToInsert,
  PericiaPriceCalculatedRepository,
  PericiaRepositoryCarPartMap
} from "../../models/pericia/pericia.repository";

export const carToRepo = (car: Car): CarRepositoryToInsert => ({
  brand: car.brand,
  model: car.model,
  color: car.color,
  plate: car.plate,
  chassis_number: car.chassisNumber,
  insurance_name: car.insuranceName
});

export const periciaPriceCalculatedToRepo = (pericia: PericiaPriceCalculated): PericiaPriceCalculatedRepository => ({
  id: pericia.id,
  price_calculated: pericia.isPriceCalculated
});

export const mapCarParts = (carParts: CarPart[]) => {
  return carParts
    .map((carPart) => {
      const {
        name,
        isAluminum,
        shouldPaint,
        shouldReplace,
        shouldGlue,
        smallSmash,
        smallSmashWorkingHours,
        smash,
        smashWorkingHours,
        price,
        useMaxNoteDetails,
        isAddtionalRepair,
        customHours,
        customHoursDescription
      } = carPart;

      return {
        name,
        isAluminum,
        shouldPaint,
        shouldReplace,
        shouldGlue,
        smallSmash,
        smallSmashWorkingHours,
        smash,
        smashWorkingHours,
        price,
        note: "",
        useMaxNoteDetails,
        isAddtionalRepair,
        customHours,
        customHoursDescription
      };
    })
    .reduce((acc, carPart) => {
      const { name, ...rest } = carPart;
      acc[name] = rest;
      return acc;
    }, {} as PericiaRepositoryCarPartMap);
};

export const mapCarPartsToLowerCase = (carParts: CarPartRefact[]) => {
  return carParts
    .map((carPart) => {
      const {
        name,
        isAluminum,
        shouldPaint,
        shouldReplace,
        shouldGlue,
        smallSmash,
        smallSmashWorkingHours,
        smash,
        smashWorkingHours,
        price,
        useMaxNoteDetails,
        isAddtionalRepair
      } = carPart;

      return {
        name: name.toLowerCase(),
        isAluminum,
        shouldPaint,
        shouldReplace,
        shouldGlue,
        smallSmash,
        smallSmashWorkingHours,
        smash,
        smashWorkingHours,
        price,
        note: "",
        useMaxNoteDetails,
        isAddtionalRepair
      };
    })
    .reduce((acc, carPart) => {
      const { name, ...rest } = carPart;
      acc[name] = rest;
      return acc;
    }, {} as PericiaRepositoryCarPartMap);
};
