import { useCallback } from "react";
import { Pericia, getPericiaCustomPrice } from "../../pericia/model/pericia";

function totalPrice(pericias: Pericia[]) {
  return pericias.reduce((acc, pericia) => {
    return acc + getPericiaCustomPrice(pericia);
  }, 0);
}

export function useInvoice() {
  const getInvoiceTotalPrice = useCallback((pericias: Pericia[]) => {
    return totalPrice(pericias);
  }, []);

  return { getInvoiceTotalPrice };
}
