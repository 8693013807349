import { Grid, Typography } from "@mui/material";
import { PericiaInvoiceItem } from "../../../../entities/pericia";
import { LoadingButton } from "@mui/lab";
import { currencyMask, formatIntegerToCurrency } from "../../../../utils/helpers/numbers";
import { capitalizeFirstLetter } from "../../../../shared/helpers/string";
import { PericiasListByDateComponent } from "./pericias-list-by-date-component";
import { PericiasListByDateDisplayFieldsToggler } from "./pericias-list-by-date-display-fields";

interface ListPericiasByCostumerListProps {
  props: {
    pericias: PericiaInvoiceItem[];
    selectedFieldsToDisplay: {
      displayPlate: boolean;
      displayChassis: boolean;
      displayDate: boolean;
      displayPrice: boolean;
      displayUserName: boolean;
      displayTotalHours: boolean;
    };
    totalPrice: number;
    totalCostumers: number;
    start: Date;
    end: Date;
    fetchedAll: boolean;
    listLoading: boolean;
    handleFetchNextPage: () => void;
    handleSelectedFieldsToDisplayChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCreatePDF: () => void;
  };
}

export const PericiasListByDate: React.FC<ListPericiasByCostumerListProps> = ({ props }) => {
  const {
    pericias,
    selectedFieldsToDisplay,
    totalPrice,
    totalCostumers,
    handleFetchNextPage,
    fetchedAll,
    listLoading,
    handleSelectedFieldsToDisplayChange,
    handleCreatePDF
  } = props;

  const totalPericias = pericias.length;

  if (totalPericias === 0) return <></>;

  return (
    <>
      <PericiasListByDateDisplayFieldsToggler
        props={{
          selectedFieldsToDisplay,
          toggleDisplay: handleSelectedFieldsToDisplayChange
        }}
      />
      <Typography variant="subtitle1" component="p">
        Utilize o celular com a tela deitada para visualizar a lista*
      </Typography>
      <PericiasListByDateComponent
        props={{
          pericias,
          selectedFieldsToDisplay,
          totalPrice,
          totalCostumers,
          fetchedAll,
          listLoading,
          handleFetchNextPage
        }}
      />
      <LoadingButton fullWidth variant="contained" onClick={handleCreatePDF} sx={{ mb: 1, mt: 5 }}>
        GERAR PDF
      </LoadingButton>
    </>
  );
};
