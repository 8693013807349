import {
  BottomNavigation,
  BottomNavigationAction,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import FlexContainer from "../../shared/ui/container/flex-container.component";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createNewInvoice } from "../../entities/invoice/model/invoice";
import { UpdateInvoiceHeader } from "../../features/invoice/update-invoice/ui/update-invoice-header";
import { useNavigate, useParams } from "react-router-dom";
import { useGetInvoiceById } from "../../features/invoice/get-invoice-by-id/api/useGetInvoiceById";
import updateInvoiceListComponent from "../../features/invoice/update-invoice/ui/update-invoice-list.component";
import { LoadingButton } from "@mui/lab";
import DeleteButton from "../../shared/ui/buttons/delete-button.component";
import { ChangeEvent, useEffect, useState } from "react";
import { currencyMask, formatCurrency, getNumberFromCurrencyMask } from "../../utils/helpers/numbers";
import { useCreateInvoicePDF } from "../../features/invoice/update-invoice/api/useCreateInvoicePDF";
import { ToastContainer, toast } from "react-toastify";
import { useUpdateInvoiceDate } from "../../features/invoice/update-invoice-date/api/useUpdateInvoiceDate";
import { useUpdateInvoiceIvaPercentage } from "../../features/invoice/update-iva-percentage/api";
import { useUpdateInvoiceText } from "../../features/invoice/update-invoice-text/api";
import { updateInvoiceCustomCount } from "../../storage/supabase/invoice/invoice.storage";
import CustomButton from "../../shared/ui/buttons/custom-button.component";
import { useUpdateInvoice } from "../../features/invoice/update-invoice-payed/api";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { PaperCard } from "../../shared/ui/paper-card/paper-card.component";
import updateInvoicePaymentListComponent from "../../features/invoice/update-invoice/ui/update-invoice-payment-list.component";
import {
  useCreateInvoicePayment,
  UseCreateInvoicePaymentRequest
} from "../../features/invoice-payment/create-invoice-payment/api/useCreateInvoicePayment";
import { createInvoicePaymentReq } from "../../entities/invoice-payment/api/create-invoice-payment";
import { useListInvoicePayments } from "../../features/invoice/list-invoice-payments/api/useListInvoicePayments";
import { error } from "console";

export interface InvoicePayment {
  id: string;
  id_invoice: string;
  payed_by: string;
  amount: number;
  discount_percentage: number;
  created_at: Date;
  updated_at: Date;
}

const MOCK_DATA: InvoicePayment[] = [
  {
    id: "1",
    id_invoice: "1",
    payed_by: "João",
    amount: 100,
    discount_percentage: 0,
    created_at: new Date("2021-10-09"),
    updated_at: new Date()
  },
  {
    id: "2",
    id_invoice: "1",
    payed_by: "Maria",
    amount: 150,
    discount_percentage: 0,
    created_at: new Date(),
    updated_at: new Date()
  }
];

const INITIAL_INVOICE = createNewInvoice();

interface InvoiceNewDate {
  id: string;
  date: Date;
  shouldUpdate: boolean;
}

const INITIAL_INVOICE_NEW_DATE: InvoiceNewDate = {
  id: "",
  date: new Date(),
  shouldUpdate: false
};

const INITIAL_INVOICE_PAYMENT: UseCreateInvoicePaymentRequest = {
  id_invoice: "",
  amount: 0,
  discount_percentage: 0,
  payed_at: new Date(),
  enabled: false
};

export const UpdateInvoicePage = () => {
  const { invoiceID } = useParams();
  const navigate = useNavigate();
  const [invoiceNewDate, setInvoiceNewDate] = useState<InvoiceNewDate>(INITIAL_INVOICE_NEW_DATE);
  const {
    data: invoice,
    error: errorFetchingInvoice,
    isLoading: isLoadingFetchingInvoice,
    refetch: refecthInvoice
  } = useGetInvoiceById(invoiceID || "");
  const [displayTotalHours, setDisplayTotalHours] = useState<boolean>(false);
  const [invoiceCustomCount, setInvoiceCustomCount] = useState<number>(0);
  const [ivaPercentage, setIvaPercentage] = useState<number>(0);
  const [invoiceText, setInvoiceText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [value, setValue] = useState(0);
  const [updateInvoicePros, setUpdateInvoicePros] = useState({ id: "", enabled: false });
  const [amountMask, setAmountMask] = useState(currencyMask("0"));
  const [paymentDate, setPaymentDate] = useState(new Date());
  const { data: updateInvoiceDateRes, error: errorUpdatingInvoiceDate } = useUpdateInvoiceDate(invoiceNewDate);
  const [useDiscount, setUseDiscount] = useState<boolean>(false);
  const [invoicePayment, setInvoicePayment] = useState<UseCreateInvoicePaymentRequest>(INITIAL_INVOICE_PAYMENT);
  const {
    data: invoicePaymentRes,
    isLoading: createPaymentRecordIsLoading,
    error: errorCreatingInvoicePayment
  } = useCreateInvoicePayment(createInvoicePaymentReq, invoicePayment);
  const {
    data: invoicePayments,
    error: errorListingInvoicePayments,
    refetch: refetchInvoicePayments
  } = useListInvoicePayments({ id_invoice: invoiceID || "" });
  const { data: ivaPercentageUpdateRes } = useUpdateInvoiceIvaPercentage({ id: invoiceID || "", new_iva_percentage: ivaPercentage });
  const { data: updateInvoiceTextRes } = useUpdateInvoiceText({ id: invoiceID || "", new_invoice_text: invoiceText });
  const { data: updateInvoiceRes, isFetching: isPaying } = useUpdateInvoice({ ...updateInvoicePros });

  const handleUseDiscountSelect = () => {
    setUseDiscount(!useDiscount);
  };

  const handleCreateInvoicePayment = async () => {
    if (!invoiceID) return;

    const amount = getNumberFromCurrencyMask(amountMask);
    setInvoicePayment({
      id_invoice: invoiceID,
      amount,
      discount_percentage: useDiscount ? 2 : 0,
      payed_at: paymentDate,
      enabled: true
    });
  };

  const handlePaymentDateUpdate = (date: Date) => {
    setPaymentDate(date);
  };

  const handleFilterChange = (newValue: number) => {
    if (newValue === 0) {
      setValue(0);
    } else {
      setValue(1);
    }
  };

  const handleInvoiceCustomCountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInvoiceCustomCount(Number(value));
  };

  const handleUpdateDate = (date: Date) => {
    if (!invoiceID) return;

    setInvoiceNewDate({ shouldUpdate: true, id: invoiceID, date });
  };

  const handleInvoiceCustomCountOnBlur = async () => {
    if (!invoiceID) return;

    if (invoiceCustomCount > 0) {
      const res = await updateInvoiceCustomCount(invoiceID, invoiceCustomCount);
      if (res.error) {
        console.error(res.error);
        toast.error("Erro ao atualizar número da fatura.");
        return;
      }
      refecthInvoice();
      toast.success("Número da fatura atualizado com sucesso.");
    }
  };

  const handleToggleDisplayTotalHours = () => {
    setDisplayTotalHours(!displayTotalHours);
  };

  const handleInvoiceTextUpdate = (newInvoiceText: string) => {
    if (!invoiceID) return;

    setInvoiceText(newInvoiceText);
  };

  const handleIvaPercentageUpdate = (newIvaPercentage: number) => {
    if (!invoiceID) return;

    setIvaPercentage(newIvaPercentage);
  };

  const handleSavePDF = () => {
    if (!invoice) return;

    const invoicePdfDTO = {
      costumerName: invoice.costumer.name,
      costumerAddress: invoice.costumer.fullAddress,
      costumerLanguage: invoice.costumer.language,
      createdAt: invoice.createdAt.toLocaleDateString("pt-BR"),
      totalPrice: formatCurrency(invoice.totalPrice),
      totalIVA: formatCurrency(calculateTotalPriceWithIVA(invoice.totalPrice, invoice.ivaPercentage)),
      IVA: invoice.ivaPercentage,
      items: [...invoice.items],
      displayTotalHours,
      invoiceNumber: invoice.invoiceCustomCount > 0 ? invoice.invoiceCustomCount : invoice.invoiceCount,
      invoiceText: invoice.invoiceText || "Riparazione Alternativa Grandine"
    };

    useCreateInvoicePDF.execute(invoicePdfDTO);
  };

  const handleUpdateInvoice = async () => {
    if (!invoiceID) return;

    setUpdateInvoicePros({ id: invoiceID, enabled: true });
  };

  const handleDeleteInvoice = async () => {
    if (!invoiceID) return;

    setDisabled(true);
    setLoading(true);
    // const { error } = await invoiceService.deleteInvoice(invoiceID);
    // if (error) {
    //   console.error(error);
    //   toast.error("Erro ao deletar fatura.");
    //   setLoading(false);
    //   setDisabled(false);
    //   return;
    // }

    // setLoading(false);
    // toast.success("Fatura deletada com sucesso. Redirecionando...", { autoClose: 600 });
    // setTimeout(() => {
    //   navigate("/invoices/list");
    // }, 1200);
  };

  useEffect(() => {
    if (invoice) {
      setInvoiceCustomCount(invoice.invoiceCustomCount);
      setInvoiceNewDate({ shouldUpdate: false, id: invoice.id, date: invoice.createdAt });
    }
  }, [invoice]);

  useEffect(() => {
    if (!ivaPercentageUpdateRes) return;
    if (!invoiceID) return;

    refecthInvoice();
    setTimeout(() => {
      toast.success("Porcentagem IVA atualizada com sucesso.");
    }, 1000);
  }, [ivaPercentageUpdateRes]);

  useEffect(() => {
    if (!invoiceID) return;
    if (!updateInvoiceTextRes) return;

    refecthInvoice();
    setTimeout(() => {
      toast.success("Texto de rodapé com sucesso.");
    }, 1000);
  }, [updateInvoiceTextRes]);

  useEffect(() => {
    if (!updateInvoiceRes) return;

    refecthInvoice();
    setTimeout(() => {
      toast.success("Fatura paga com sucesso.");
    }, 1000);
  }, [updateInvoiceRes]);

  useEffect(() => {
    if (!updateInvoiceDateRes) return;

    toast.success("Data da fatura atualizada com sucesso.", { autoClose: 800 });
    refecthInvoice();
  }, [updateInvoiceDateRes]);

  useEffect(() => {
    if (!invoicePaymentRes) return;
    if (errorCreatingInvoicePayment) return;

    refetchInvoicePayments();
    setAmountMask(currencyMask("0"));
    setPaymentDate(new Date());
    setUseDiscount(false);
    toast.success("Recibo emitido com sucesso.");
  }, [invoicePaymentRes]);

  useEffect(() => {
    if (errorListingInvoicePayments) {
      toast.error("Erro ao buscar fatura.");
    }
  }, [errorListingInvoicePayments]);

  if (errorUpdatingInvoiceDate) {
    toast.error("Erro ao atualizar data da fatura.");
  }

  if (errorCreatingInvoicePayment) {
    setInvoicePayment(INITIAL_INVOICE_PAYMENT);
    toast.error(`${errorCreatingInvoicePayment.message}`);
  }

  return (
    <FlexContainer>
      <Typography variant="h4">Fatura</Typography>
      <UpdateInvoiceHeader
        disabled={!!invoicePayments && invoicePayments.invoicePayments.length > 0}
        invoice={invoice || INITIAL_INVOICE}
        invoiceNewDate={invoiceNewDate || new Date()}
        totalPrice={invoice?.totalPrice || 0}
        ivaPercentage={invoice?.ivaPercentage || 0}
        invoiceText={invoice?.invoiceText || "Riparazione Alternativa Grandine"}
        totalIVA={calculateIVA(invoice?.totalPrice || 0, invoice?.ivaPercentage || 0)}
        totalPriceWithIVA={calculateTotalPriceWithIVA(invoice?.totalPrice || 0, invoice?.ivaPercentage || 0)}
        invoiceCustomCount={invoiceCustomCount}
        handleUpdateDate={handleUpdateDate}
        handleInvoiceTextUpdate={handleInvoiceTextUpdate}
        handleInvoiceCustomCountChange={handleInvoiceCustomCountChange}
        handleInvoiceCustomCountOnBlur={handleInvoiceCustomCountOnBlur}
        handleToggleDisplayTotalHours={handleToggleDisplayTotalHours}
        handleIvaPercentageUpdate={handleIvaPercentageUpdate}
      />
      {invoice && (
        <>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => handleFilterChange(newValue)}
            sx={{ marginBottom: "2rem" }}
          >
            <BottomNavigationAction label="Perícias" icon={<ContentPasteSearchIcon />} />
            <BottomNavigationAction label="Recibos" icon={<ContentPasteSearchIcon />} />
          </BottomNavigation>
          {value === 0 &&
            updateInvoiceListComponent({
              items: invoice.items,
              displayTotalHours
            })}

          {value === 1 && (
            <>
              <PaperCard title="Emitir Recibo">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="medium"
                      margin="dense"
                      id="name"
                      label="Valor"
                      variant="standard"
                      value={amountMask}
                      name="amount"
                      onChange={(e) => setAmountMask(currencyMask(e.target.value))}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={6} mt={1}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={0}>
                        <DatePicker
                          label="Data do recibo"
                          openTo="day"
                          inputFormat="DD/MM/YYYY"
                          views={["year", "month", "day"]}
                          value={paymentDate}
                          onChange={(newValue) => {
                            if (newValue) handlePaymentDateUpdate(new Date(newValue));
                          }}
                          renderInput={(params) => <TextField {...params} variant="standard" />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={3} mb={0} mt={2}>
                    <FormGroup sx={{ width: "100%" }}>
                      <FormControlLabel control={<Checkbox onChange={handleUseDiscountSelect} />} label="Desconto de 2%" />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoadingButton
                      fullWidth
                      variant="contained"
                      onClick={handleCreateInvoicePayment}
                      loading={createPaymentRecordIsLoading}
                    >
                      Emitir Recibo
                    </LoadingButton>
                  </Grid>
                </Grid>
              </PaperCard>
              {invoicePayments &&
                updateInvoicePaymentListComponent({
                  items: invoicePayments.invoicePayments,
                  total: invoicePayments.total,
                  unpaid: invoicePayments.unpaid
                })}
            </>
          )}
          <LoadingButton fullWidth variant="contained" sx={{ mt: 10, mb: 1 }} onClick={handleSavePDF} loading={loading} disabled={disabled}>
            GERAR PDF
          </LoadingButton>
          {/* <CustomButton
            props={{
              customFunction: handleUpdateInvoice,
              message: "Tem certeza que deseja marcar esta fatura como recebida? Esta ação não pode ser desfeita.",
              buttonText: "FATURA RECEBIDA",
              confirmDialogText: "recebida",
              loading: isPaying,
              disabled: invoice?.payed
            }}
          /> */}
          <DeleteButton
            props={{
              deleteFunction: handleDeleteInvoice,
              message: "Tem certeza que deseja excluir esta fatura? Esta ação não pode ser desfeita.",
              buttonText: "DELETAR FATURA",
              loading,
              disabled
            }}
          />
        </>
      )}
      <ToastContainer />
    </FlexContainer>
  );
};

const calculateIVA = (totalPrice: number, iva: number) => {
  return (totalPrice * iva) / 100;
};

const calculateTotalPriceWithIVA = (totalPrice: number, iva: number) => {
  return totalPrice + calculateIVA(totalPrice, iva);
};
