import supabase from "../../../shared/database/supabase";
import { CustomError } from "../../../shared/error/error";
import { Invoice } from "../model/invoice";

export interface ListInvoicesRequest {
  term: string;
  start_date: string;
  end_date: string;
  page: number;
  limit: number;
}

export interface ListInvoicesResponse {
  totalPrice: number;
  totalFound: number;
  invoices: Invoice[];
}

export const listInvoicesRequest = async (req: ListInvoicesRequest): Promise<ListInvoicesResponse> => {
  try {
    const { data, error } = await supabase.functions.invoke("list-invoices", {
      body: JSON.stringify({ ...req })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    return {
      totalPrice: data.totalPrice,
      totalFound: data.totalFound,
      invoices: data.invoices.map((item: Invoice) => {
        return {
          ...item,
          createdAt: new Date(item.createdAt),
          updatedAt: new Date(item.updatedAt),
          startDate: new Date(item.startDate),
          endDate: new Date(item.endDate)
        };
      })
    } as ListInvoicesResponse;
  } catch (err) {
    const apiError = err as CustomError;
    console.error(`API Error: ${apiError.message}`);

    if (apiError && apiError.code && apiError.message && apiError.toast_message && apiError.http_status_code) {
      throw new CustomError(apiError);
    }

    throw new CustomError({});
  }
};
