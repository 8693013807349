import { UseQueryResult, useQuery } from "react-query";
import { getProfitMetricsRequest } from "../../../../entities/metrics/api/get-profit-metrics";
import { ProfitMetricDTO } from "../../../../entities/metrics/model/profit.model";

export interface UseGetProfitMetricsProps {
  startDate: Date;
  endDate: Date;
  enabled: boolean;
}

export const queryKeys = {
  getProfitMetrics: (props: UseGetProfitMetricsProps) => ["getPericiasMetrics", props]
};

export function useGetProfitMetrics(props: UseGetProfitMetricsProps): UseQueryResult<ProfitMetricDTO[]> {
  const args = {
    ...props,
    start_date: props.startDate.toISOString().split("T")[0],
    end_date: props.endDate.toISOString().split("T")[0]
  };

  return useQuery(queryKeys.getProfitMetrics(args), () => getProfitMetricsRequest(args), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: props.enabled && Boolean(props.startDate) && Boolean(props.endDate)
  });
}
