import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TodayIcon from "@mui/icons-material/Today";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";

import FlexContainer from "../../../../shared/ui/container/flex-container.component";
import { BottomNavigation, BottomNavigationAction, Box, Grid, Paper, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { PericiaCostumer } from "../../../../models/costumer/costumer";
import CostumerAutocomplete from "../../../../components/pericia/costumer-autocomplete/costumer-autocomplete.component";
import { ToastContainer, toast } from "react-toastify";
import RepairRecordsByMonthListComponent from "../../../../components/repair-records/repair-records-list/repair-records-list.component";
import { pdfService } from "../../../../services/pdf/pdf.service";
import { periciaService } from "../../../../services/pericia/pericia.service";
import { useListRepairRecordsByUserId } from "../../../../features/repair-record/list-repair-records-by-user-id/api/useListRepairRecordsByUserId";
import supabase from "../../../../shared/database/supabase";
import { PaperCard } from "../../../../shared/ui/paper-card/paper-card.component";
import { useNavigate } from "react-router-dom";

interface RepairRecord {
  id: string;
  payed: boolean;
  payed_value: number;
  created_at: string;
  finished_at: string;
  pericia: {
    id: string;
    date: string;
    costumer: {
      name: string;
    };
    car: {
      brand: string;
      model: string;
      chassis_number: string;
      plate: string;
      insurance_name: string;
      color: string;
    };
  };
}

const RepairRecordsListComponent = () => {
  const [costumer, setCostumer] = useState<PericiaCostumer>({
    id: "",
    name: "",
    language: "ita"
  });
  const [repairRecords, setRepairRecords] = useState<RepairRecord[]>([]);
  const [filteredRepairRecords, setFilteredRepairRecords] = useState<RepairRecord[]>([]);
  const [date, setDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const { data: repairs } = useListRepairRecordsByUserId(userId);
  const navigate = useNavigate();
  const [navItem, setNavItem] = useState(0);

  const handleNavigation = (event: React.SyntheticEvent, item: number) => {
    setNavItem(item);
    switch (item) {
      case 0:
        break;
      case 1:
        navigate("/mechanics/pericias/repair-records/date");
        break;
      case 2:
        navigate("/pericias/metrics/by-costumers");
        break;
      case 3:
        navigate("/pericias/metrics/profit");
        break;
      default:
        navigate("/");
    }
  };

  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
  };

  const handleListRepairRecords = async () => {
    setIsLoading(true);
    const res = await periciaService.getRepairRecordsByMonth(date);
    if (res.error) {
      setIsLoading(false);
      console.log(res.error);
      return;
    }

    if (res.data.length === 0) {
      console.log("Nenhuma fatura encontrada!", res.data.length);
      toast.info("Nenhuma fatura encontrada!");
    }

    setRepairRecords(res.data);
    setFilteredRepairRecords(res.data);
    setIsLoading(false);
  };

  const generatePDF = async () => {
    const res = await pdfService.generateRepairRecordPdf({
      repairRecords: filteredRepairRecords,
      date
    });

    if (res.error) {
      console.log(res.error);
      return;
    }
  };

  useEffect(() => {
    if (costumer.name === "") {
      setFilteredRepairRecords(repairRecords);
      return;
    }

    setFilteredRepairRecords(repairRecords.filter((invoice) => invoice.pericia.costumer.name === costumer.name));
  }, [costumer, repairRecords]);

  useEffect(() => {
    setUserId(supabase.auth.user()?.id || "");
  }, []);

  return (
    <div>
      <FlexContainer>
        <Box mb={1} />
        <BottomNavigation showLabels value={navItem} onChange={handleNavigation} sx={{ marginBottom: "2rem" }}>
          <BottomNavigationAction label="Selecione um mês" icon={<TodayIcon />} />
          <BottomNavigationAction label="Selecione um período" icon={<CalendarMonthIcon />} />
        </BottomNavigation>
        <Typography variant="h4">Lista de reparos realizados</Typography>
        <Grid container spacing={2} mt={2}>
          <PaperCard title="Selecione o mês">
            <Box mb={3} />
            <Grid item xs={12} sm={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <DatePicker
                    label="Mes"
                    openTo="month"
                    views={["month", "year"]}
                    maxDate={new Date()}
                    value={date}
                    onChange={(newValue: any) => {
                      if (newValue) handleDateChange(new Date(newValue));
                    }}
                    renderInput={(params: any) => <TextField {...params} variant="standard" />}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>

            <Box mb={3} />
            <Grid item xs={12} sm={12}>
              <LoadingButton fullWidth variant="contained" sx={{ mb: 1, mt: 1 }} loading={isLoading} onClick={handleListRepairRecords}>
                BUSCAR REPAROS
              </LoadingButton>
            </Grid>
          </PaperCard>
          {repairRecords.length > 0 && (
            <>
              <PaperCard title="Filtrar por cliente">
                <Grid item xs={12} md={12}>
                  <CostumerAutocomplete props={{ costumer, updateCostumer: setCostumer }} />
                </Grid>

                <RepairRecordsByMonthListComponent props={{ repairRecords: filteredRepairRecords }} />
              </PaperCard>

              <LoadingButton fullWidth variant="contained" sx={{ mb: 1, mt: 5 }} loading={isLoading} onClick={generatePDF}>
                GERAR PDF
              </LoadingButton>
            </>
          )}
        </Grid>
        <ToastContainer />
      </FlexContainer>
    </div>
  );
};

export default RepairRecordsListComponent;
