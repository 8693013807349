import { Paper, Typography } from "@mui/material";

interface PaperCardProps {
  title: string;
  children: React.ReactNode;
}

export const PaperCard: React.FC<PaperCardProps> = ({ title, children }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 5,
        width: "100%",
        borderRadius: "8px",
        margin: "1rem 0"
      }}
    >
      <Typography variant="h5" component="h1">
        {title}
      </Typography>
      {children}
    </Paper>
  );
};
