import supabase from "../../../shared/database/supabase";
import { RepairRecord } from "../model";

interface ListRepairRecordsByDateRange {
  id_user: string;
  start: Date;
  end: Date;
}

export const listRepairRecordsByDateRange = async (req: ListRepairRecordsByDateRange): Promise<RepairRecord[] | Error> => {
  const { id_user, start, end } = req;

  try {
    const { data, error } = await supabase.functions.invoke("get-repair-records-by-date-range", {
      body: JSON.stringify({ id_user, start, end })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    if (!data || data.length === 0) return [];

    return data.map((item: RepairRecord) => {
      return {
        ...item,
        finishedAt: item.finishedAt && new Date(item.finishedAt),
        createdAt: new Date(item.createdAt),
        pericia: { ...item.pericia, date: new Date(item.pericia.date) }
      };
    });
  } catch (err) {
    console.log("error fetching repair records by date range:", err);
    throw err;
  }
};
