import supabase from "../../../shared/database/supabase";
import { PericiaInvoiceItem } from "..";

export interface ListPericiasByDateRangeRequest {
  start: string;
  end: string;
  page: number;
  filters: {
    done: boolean;
    not_done: boolean;
    price_calculated: boolean;
    billed: boolean;
  };
}

export const listPericiasByDateRange = async (req: ListPericiasByDateRangeRequest): Promise<PericiaInvoiceItem[]> => {
  try {
    const { data, error } = await supabase.functions.invoke("list-pericias-by-date-range", {
      body: JSON.stringify(req)
    });

    if (error) throw error;
    if (!data || data.length === 0) {
      return [];
    }

    return data.map((pericia: PericiaInvoiceItem) => ({
      ...pericia,
      createdAt: new Date(pericia.createdAt),
      updatedAt: new Date(pericia.updatedAt),
      date: new Date(pericia.date)
    }));
  } catch (err) {
    console.log(err);
    throw err;
  }
};
