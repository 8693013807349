import { Grid, ListItem, ListItemButton, ListItemText, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FlexContainer from "../../../shared/ui/container/flex-container.component";
import { formatCurrency } from "../../../utils/helpers/numbers";

interface RepairRecordsListComponentProps {
  props: Props;
}

interface Props {
  repairRecords: RepairRecord[];
}

interface RepairRecord {
  id: string;
  payed: boolean;
  payed_value: number;
  created_at: string;
  finished_at: string;
  pericia: {
    id: string;
    date: string;
    costumer: {
      name: string;
    };
    car: {
      brand: string;
      model: string;
      chassis_number: string;
      plate: string;
      insurance_name: string;
      color: string;
    };
  };
}

const RepairRecordsByMonthListComponent: React.FC<RepairRecordsListComponentProps> = ({ props }) => {
  const navigate = useNavigate();
  const { repairRecords } = props;
  const totalPayed = repairRecords.reduce((acc, record) => {
    if (record.payed) {
      return acc + record.payed_value;
    }
    return acc;
  }, 0);
  const totalToPay = repairRecords.reduce((acc, record) => {
    if (!record.payed) {
      return acc + record.payed_value;
    }
    return acc;
  }, 0);

  return (
    <FlexContainer>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={12}>
          <ListItem
            divider
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-end",
              textAlign: "center"
            }}
          >
            {["CLIENTE", "CARRO", "PLACA", "CHASSI", "DATA", "VALOR"].map((item, idx) => (
              <ListItemText id={`record-list-header-${idx}`} primary={item} sx={{ flex: "1", fontWeight: "bold" }} />
            ))}
          </ListItem>
        </Grid>
        <Grid item xs={12} sm={12} mb={6}>
          {repairRecords.map((record) => (
            <ListItem key={record.id} divider>
              <ListItemButton
                onClick={() => navigate(`/mechanics/pericias/${record.pericia.id}`)}
                role={undefined}
                dense
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textAlign: "center"
                }}
              >
                <ListItemText id={record.id} primary={`${record.pericia.costumer.name}`} sx={{ flex: "1" }} />
                <ListItemText id={record.id} primary={`${record.pericia.car.model}`} sx={{ flex: "1", textAlign: "center" }} />
                <ListItemText id={record.id} primary={`${record.pericia.car.plate}`} sx={{ flex: "1" }} />
                <ListItemText id={record.id} primary={`${record.pericia.car.chassis_number}`} sx={{ flex: "1" }} />
                <ListItemText id={record.id} primary={new Date(record.finished_at).toLocaleDateString("pt-BR")} sx={{ flex: "1" }} />
                <ListItemText id={record.id} primary={formatCurrency(record.payed_value)} sx={{ flex: "1" }} />
              </ListItemButton>
            </ListItem>
          ))}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            disabled
            margin="dense"
            id="totayToReceive"
            label="Total feito no mês"
            type="text"
            fullWidth
            variant="outlined"
            value={formatCurrency(totalToPay)}
          />
        </Grid>
      </Grid>
    </FlexContainer>
  );
};

export default RepairRecordsByMonthListComponent;
