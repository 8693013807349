import { useParams } from "react-router-dom";
import { useGetUserById } from "../../features/user/get-user-by-id/api/useGetUserById";
import FlexContainer from "../../shared/ui/container/flex-container.component";
import { PaperCard } from "../../shared/ui/paper-card/paper-card.component";
import { Grid, Skeleton, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { GetUserByIdResponse } from "../../entities/user";
import { useUpdateUserById } from "../../features/user/update-user-by-id/api/useUpdateUserById";
import { ToastContainer, toast } from "react-toastify";
import UserRolesSelect from "../../entities/user/ui/user-roles-select.component";

const INITIAL_USER: GetUserByIdResponse = {
  id: "",
  name: "",
  lastName: "",
  displayName: "",
  phone: "",
  nationality: "",
  role: ""
};

export const UpdateUserByIdPage = () => {
  const { userId } = useParams();
  const { data: fetchedUser, error: errorFetchingUser, refetch: refecthUser } = useGetUserById(userId || "");
  const [user, setUser] = useState<GetUserByIdResponse>({} as GetUserByIdResponse);
  const [userToUpdate, setUserToUpdate] = useState<GetUserByIdResponse>(INITIAL_USER);
  const { data: userUpdated, error: errorUpdatingUser, isLoading: isLoadingUpdateUser } = useUpdateUserById(userToUpdate);
  const { name, lastName, displayName, nationality, phone, role } = user;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setUser((prev: GetUserByIdResponse) => ({ ...prev, [name]: value }));
  };

  const handleUpdateUser = () => {
    setUserToUpdate(user);
  };

  const handleRoleChange = (role: string) => {
    setUser((prev: GetUserByIdResponse) => ({ ...prev, role }));
  };

  useEffect(() => {
    if (!fetchedUser) return;

    setUser(fetchedUser);
  }, [fetchedUser]);

  useEffect(() => {
    if (!userUpdated) return;

    toast.success("Usuário atualizado com sucesso.");

    refecthUser();
  }, [userUpdated]);

  if (errorFetchingUser) toast.error("Erro ao buscar usuário.");

  if (errorUpdatingUser) toast.error("Erro ao atualizar usuário.");

  if (!fetchedUser) {
    return (
      <FlexContainer>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={12}>
            <Skeleton variant="rounded" width="100%" height={50} />
          </Grid>
          <Grid item xs={12} sm={12} mt={5}>
            <Skeleton variant="rounded" width="100%" height={35} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Skeleton variant="rounded" width="100%" height={35} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Skeleton variant="rounded" width="80%" height={35} />
          </Grid>
        </Grid>
      </FlexContainer>
    );
  }

  return (
    <FlexContainer>
      <Typography variant="h4" component="h1">
        Edição de Usuário
      </Typography>
      {fetchedUser && (
        <PaperCard title="Dados cadastrados">
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12} sm={6}>
              <TextField name="name" label="Nome" variant="standard" fullWidth value={name || ""} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField name="lastName" label="Sobrenome" variant="standard" fullWidth value={lastName || ""} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="displayName"
                label="Apelido no aplicativo"
                variant="standard"
                fullWidth
                value={displayName || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField name="phone" label="Telefone" variant="standard" fullWidth value={phone || ""} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="nationality"
                label="Nacionalidade"
                variant="standard"
                fullWidth
                value={nationality || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <UserRolesSelect currentRole={role || ""} setCurrentRole={handleRoleChange} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <LoadingButton fullWidth variant="contained" sx={{ mb: 0, mt: 2 }} onClick={handleUpdateUser} loading={isLoadingUpdateUser}>
                Atualizar
              </LoadingButton>
            </Grid>
          </Grid>
        </PaperCard>
      )}
      <ToastContainer />
    </FlexContainer>
  );
};
