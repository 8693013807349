import { Route, Routes } from "react-router-dom";
import AddUser from "./add-users/add-users.component";
import ListUsers from "./list-users/list-users.component";
import ListUserRepairRecords from "./list-user-repair-records/list-user-repair-records.component";
import { GetUserBalanceByIdPage } from "../../pages/user/get-user-balance-by-id.page";
import { UpdateUserByIdPage } from "../../pages/user/update-user-by-id.page";
import { InviteUserByEmailPage } from "../../pages/user/invite-user-by-email.page";

const UsersRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/add" element={<AddUser />} /> */}
      <Route path="/add" element={<InviteUserByEmailPage />} />
      <Route path="/list" element={<ListUsers />} />
      <Route path="/:userId/balance" element={<GetUserBalanceByIdPage />} />
      <Route path="/:userId/update" element={<UpdateUserByIdPage />} />
      <Route path="/repair-records/:userID" element={<ListUserRepairRecords />} />
    </Routes>
  );
};

export default UsersRoutes;
