import { UseQueryResult, useQuery } from "react-query";
import {
  GetPericiasMetricsByCostumersRequest,
  getPericiasMetricsByCostumersRequest
} from "../../../../entities/pericia/api/get-pericias-metrics-by-costumers";
import { PericiaMetricByCostumersDTO } from "../../../../entities/pericia/model/dto";
import { Costumer } from "../../../../entities/costumer";

export interface UseGetPericiasMetricsByCostumersProps {
  costumer: Costumer;
  costumer2: Costumer;
  startDate: Date;
  endDate: Date;
  enabled: boolean;
}

export const queryKeys = {
  getPericiasMetrics: (props: GetPericiasMetricsByCostumersRequest) => ["getPericiasMetricsByCostumers", props]
};

export function useGetPericiasMetricsByCostumers(
  props: UseGetPericiasMetricsByCostumersProps
): UseQueryResult<PericiaMetricByCostumersDTO[]> {
  const args = {
    id_costumer: props.costumer.id,
    id_costumer_2: props.costumer2.id,
    start_date: props.startDate.toISOString().split("T")[0],
    end_date: props.endDate.toISOString().split("T")[0]
  };

  return useQuery(queryKeys.getPericiasMetrics(args), () => getPericiasMetricsByCostumersRequest(args), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled:
      props.enabled && Boolean(props.startDate) && Boolean(props.endDate) && props.costumer.id.length > 3 && props.costumer2.id.length > 3
  });
}
