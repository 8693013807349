import { PericiaByCostumerID } from "../../../models/pericia/pericia";
import { Pericia } from "./pericia";

//TODO refact this to use only pericia-helpers.ts
// export const filterPericias = (pericias: PericiaByCostumerID[], filter: string): PericiaByCostumerID[] => {
//   const filteredPericiasMap = new Map<string, PericiaByCostumerID>();

//   pericias.forEach((pericia) => {
//     const plate = pericia.car.plate.toLowerCase();
//     const chassisNumber = pericia.car.chassisNumber.toLowerCase();
//     const model = pericia.car.model.toLowerCase();
//     const brand = pericia.car.brand.toLowerCase();

//     if (
//       (plate.includes(filter.toLowerCase()) ||
//         chassisNumber.includes(filter.toLowerCase()) ||
//         model.includes(filter.toLowerCase()) ||
//         brand.includes(filter.toLowerCase())) &&
//       !filteredPericiasMap.has(pericia.id)
//     ) {
//       filteredPericiasMap.set(pericia.id, pericia);
//     }
//   });

//   const response: PericiaByCostumerID[] = [];
//   filteredPericiasMap.forEach((pericia) => {
//     response.push(pericia);
//   });

//   return response;
// };

export const filterPericiaIds = (pericias: Pericia[], filter: string): Pericia[] => {
  if (!pericias) return [];

  const filteredPericias: Pericia[] = [];
  const lowerCaseFilter = filter.toLowerCase();
  const seenIds = new Set<string>();

  for (const pericia of pericias) {
    const { id, car } = pericia;
    const { plate, chassisNumber, model, brand } = car;
    const lowerCasePlate = plate.toLowerCase();
    const lowerCaseChassisNumber = chassisNumber.toLowerCase();
    const lowerCaseModel = model.toLowerCase();
    const lowerCaseBrand = brand.toLowerCase();

    if (
      lowerCasePlate.includes(lowerCaseFilter) ||
      lowerCaseChassisNumber.includes(lowerCaseFilter) ||
      lowerCaseModel.includes(lowerCaseFilter) ||
      lowerCaseBrand.includes(lowerCaseFilter)
    ) {
      if (!seenIds.has(id)) {
        seenIds.add(id);
        filteredPericias.push(pericia);
      }
    }
  }

  return filteredPericias;
};

export const filterPericias = (pericias: PericiaByCostumerID[], filter: string): PericiaByCostumerID[] => {
  const filteredPericias: PericiaByCostumerID[] = [];
  const lowerCaseFilter = filter.toLowerCase();
  const seenIds = new Set<string>();

  for (const pericia of pericias) {
    const { id, car } = pericia;
    const { plate, chassisNumber, model, brand } = car;
    const lowerCasePlate = plate.toLowerCase();
    const lowerCaseChassisNumber = chassisNumber.toLowerCase();
    const lowerCaseModel = model.toLowerCase();
    const lowerCaseBrand = brand.toLowerCase();

    if (
      lowerCasePlate.includes(lowerCaseFilter) ||
      lowerCaseChassisNumber.includes(lowerCaseFilter) ||
      lowerCaseModel.includes(lowerCaseFilter) ||
      lowerCaseBrand.includes(lowerCaseFilter)
    ) {
      if (!seenIds.has(id)) {
        seenIds.add(id);
        filteredPericias.push(pericia);
      }
    }
  }

  return filteredPericias;
};
