import supabase from "../../../shared/database/supabase";

export type UpdatePericiaCashPayedAmountResquest = {
  id: string;
  cash_payed_amount: number;
};

export const updatePericiaCashPayedAmount = async (pericia: UpdatePericiaCashPayedAmountResquest): Promise<boolean | Error> => {
  try {
    const { data, error } = await supabase.functions.invoke("pay-pericia-by-cash", {
      body: JSON.stringify({ ...pericia })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    return data;
  } catch (err) {
    console.log("error updating pericia payed by cash:", err);
    throw err;
  }
};
