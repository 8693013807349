import { UseQueryResult, useQuery } from "react-query";
import { Pericia, listPericias } from "../../../../entities/pericia";
import { CustomError } from "../../../../shared/error/error";

interface ListPericiasArgs {
  term: string;
  done: boolean;
  billed: boolean;
  priceCalculated: boolean;
  notDone: boolean;
  page: number;
}

export const queryKeys = {
  listPericias: (args: ListPericiasArgs) => ["list-pericias", { ...args }]
};

export function useListPericias(filters: ListPericiasArgs): UseQueryResult<Pericia[], CustomError> {
  return useQuery(
    queryKeys.listPericias(filters),
    () =>
      listPericias({
        ...filters,
        not_done: filters.notDone
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: true
    }
  );
}
