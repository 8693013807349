import { UseQueryResult, useQuery } from "react-query";
import { PericiaToUpdateDTO } from "../../../../shared/dto/pericia/update-pericia";
import { Pericia } from "../../../../entities/pericia";
import { updatePericia } from "../../../../entities/pericia/api/update-pericia";

export const queryKeys = {
  updatePericia: (pericia: PericiaToUpdateDTO) => ["updatePericia", pericia]
};

export function useUpdatePericia(pericia: Pericia): UseQueryResult<string, Error> {
  const periciaToUpdate: PericiaToUpdateDTO = {
    id: pericia.id,
    car: pericia.car,
    costumer: pericia.costumer,
    pricePerHour: pericia.pricePerHour,
    date: pericia.date,
    carParts: pericia.carParts,
    insuranceHours: pericia.insuranceHours,
    totalHours: pericia.totalHours,
    totalPrice: pericia.totalPrice,
    costumerPrice: pericia.costumerPrice,
    unmountPrice: pericia.unmountPrice,
    addtionalCost: pericia.addtionalCost,
    addtionalCostDescription: pericia.addtionalCostDescription,
    shouldUnmount: pericia.shouldUnmount,
    finished: pericia.finished,
    done: pericia.done,
    billed: pericia.billed,
    isPriceCalculated: pericia.isPriceCalculated,
    isEditable: pericia.isEditable,
    priceVersion: pericia.priceVersion
  };

  return useQuery(queryKeys.updatePericia(periciaToUpdate), () => updatePericia(periciaToUpdate), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: pericia.id !== ""
  });
}
