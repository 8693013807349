import { Grid, Paper, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { currencyMask, formatIntegerToCurrency, getNumberFromCurrencyMask } from "../../../utils/helpers/numbers";
import { PaperCard } from "../../../shared/ui/paper-card/paper-card.component";

interface PericiaCostumerPriceProps {
  props: Props;
}

interface Props {
  costumerPrice: number;
  updatePericiaCostumerPrice: (costumerPrice: number) => void;
}

const PericiaCostumerPrice: React.FC<PericiaCostumerPriceProps> = ({ props }) => {
  const { costumerPrice, updatePericiaCostumerPrice } = props;
  const [costumerPriceMask, setCostumerPriceMask] = useState("");

  const handleCostumerPriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCostumerPriceMask(currencyMask(e.target.value));
  };

  const handleSaveCostumerPrice = async () => {
    const price = getNumberFromCurrencyMask(costumerPriceMask);
    if (price === costumerPrice) return;
    updatePericiaCostumerPrice(price);
  };

  useEffect(() => {
    setCostumerPriceMask(currencyMask(formatIntegerToCurrency(costumerPrice)));
  }, [costumerPrice]);

  return (
    <PaperCard title="Preço do cliente">
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            name="PericiaCostumerPrice"
            label="Preço cliente/CHF"
            id="pricePerHour"
            variant="standard"
            value={costumerPriceMask}
            onChange={handleCostumerPriceChange}
            onBlur={handleSaveCostumerPrice}
          />
        </Grid>
      </Grid>
    </PaperCard>
  );
};

export default PericiaCostumerPrice;
