import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Pericia } from "../../../../entities/pericia";
import { UpdatePericiaComponent } from "./update-pericia.component";
import { Box } from "@mui/material";

interface UpdatePericiaDialogProps {
  pericia: Pericia;
  open: boolean;
  handleToggle: () => void;
  onPericiaUpdated: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UpdatePericiaDialog: React.FC<UpdatePericiaDialogProps> = (props: UpdatePericiaDialogProps) => {
  const { pericia, open, handleToggle, onPericiaUpdated } = props;

  const handleDialogClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <>
      {open && (
        <Dialog fullScreen open={open} TransitionComponent={Transition} onClick={handleDialogClick}>
          <AppBar sx={{ position: "sticky" }}>
            <Toolbar>
              <Box sx={{ flexGrow: 1 }} />
              <Button autoFocus color="inherit" onClick={handleToggle}>
                Fechar
              </Button>
            </Toolbar>
          </AppBar>
          <UpdatePericiaComponent pericia={pericia} onPericiaUpdated={onPericiaUpdated} />
        </Dialog>
      )}
    </>
  );
};

export default UpdatePericiaDialog;
