import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Drawer, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useListNotifications } from "../api/useListNotifications";
import { useCreateUserNotification } from "../../../user-notification/api/useCreateUserNotification";
import { useNavigate } from "react-router-dom";
import supabase from "../../../../shared/database/supabase";

type Anchor = "top" | "left" | "bottom" | "right";

const NotificationBadgeIcon = () => {
  const [readNotificationIds, setReadNotificationIds] = useState<string[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [exitTime, setExitTime] = useState(500);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });
  const { data: listNotificationsRes, error: errorListNotifications, refetch: refetchNotifications } = useListNotifications();
  const { data: createUserNotificationRes, error: errorCreateUserNotificaton } = useCreateUserNotification(readNotificationIds);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {listNotificationsRes &&
          listNotificationsRes.notifications.map((notification, index) => (
            <ListItem key={notification.id} disablePadding>
              <ListItemButton
                onClick={() => {
                  setExitTime(0);
                  if (!notification.read) {
                    setReadNotificationIds([...readNotificationIds, notification.id]);
                  }

                  if (notification.periciaId) {
                    setIsDrawerOpen(false);
                    navigate(`/pericias/${notification.periciaId}`);
                  }
                }}
              >
                <ListItemIcon>{notification.read ? <MarkEmailReadIcon color="action" /> : <EmailIcon color="info" />}</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body2" style={{ color: notification.read ? "#666" : "#000" }}>
                      {notification.message}
                    </Typography>
                  }
                  color={notification.read ? "lightgrey" : "red"}
                />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Box>
  );

  if (errorListNotifications) {
    console.log("errorListNotifications", errorListNotifications);
  }
  if (errorCreateUserNotificaton) {
    console.log("errorCreateUserNotificaton", errorCreateUserNotificaton);
  }

  useEffect(() => {
    supabase
      .from("*")
      .on("*", (payload) => {
        if (payload.eventType === "INSERT" && payload.table === "notifications") {
          refetchNotifications();
        }
      })
      .subscribe();
  }, []);

  useEffect(() => {
    if (!isDrawerOpen) {
      if (readNotificationIds.length === 0) return;

      refetchNotifications();
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    if (createUserNotificationRes) {
      setReadNotificationIds([]);
      refetchNotifications();
    }
  }, [createUserNotificationRes]);

  return (
    <>
      <IconButton onClick={handleDrawerOpen} color="inherit">
        <Badge badgeContent={listNotificationsRes ? listNotificationsRes.unreadCount : 0} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Drawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} transitionDuration={{ exit: exitTime, enter: 500 }}>
        {list("right")}
      </Drawer>
    </>
  );
};

export default NotificationBadgeIcon;
