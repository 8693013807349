import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { PaperCard } from "../../../../shared/ui/paper-card/paper-card.component";
import DateRange from "../../../../components/date/date-range/date-range.component";

interface ListInvoicesSearchComponentProps {
  props: {
    termMask: string;
    startDate: Date;
    endDate: Date;
    displayDateRange: boolean;
    handleTermMaskChange: (term: string) => void;
    toggleDisplayDateRange: () => void;
    handleDatesChange: (startDate: Date, endDate: Date) => void;
  };
}

export const ListInvoicesSearchComponent: React.FC<ListInvoicesSearchComponentProps> = ({ props }) => {
  const { termMask, displayDateRange, startDate, endDate, handleTermMaskChange, toggleDisplayDateRange, handleDatesChange } = props;

  return (
    <PaperCard title="Buscar">
      <TextField
        size="small"
        margin="dense"
        id="term"
        label="Termo"
        variant="standard"
        name="term"
        fullWidth
        value={termMask}
        onChange={(e) => {
          handleTermMaskChange(e.target.value);
        }}
      />
      <FormControlLabel
        control={<Checkbox checked={displayDateRange} onChange={toggleDisplayDateRange} name="shouldReplace" />}
        label="Busca por data"
        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
      />
      {displayDateRange && <DateRange props={{ startDate, endDate, onChange: handleDatesChange }} />}
    </PaperCard>
  );
};
