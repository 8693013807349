import supabase from "../../../shared/database/supabase";

export interface AddSalesmanArgs {
  userId: string;
  costumerId: string;
}

export const addSalesman = async (args: AddSalesmanArgs): Promise<Boolean | Error> => {
  try {
    const { data, error } = await supabase.functions.invoke("add-salesman", {
      body: JSON.stringify({ ...args })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    return true;
  } catch (err) {
    console.log("error fetching costumers:", err);
    throw err;
  }
};
