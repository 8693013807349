import { InvoiceItem } from "../../entities/invoice/api/get-invoice-by-id";
import { InvoicePericia } from "../../models/invoice/invoice.model";

export const itemsPerPage = <T>(items: T[], pageLimit: number) => {
  var results = [];

  while (items.length) {
    results.push(items.splice(0, pageLimit));
  }

  return results;
};

export const splitArrayIntoPages = <T>(items: T[], firstPageSize: number, pageSize: number) => {
  const results = [];

  if (firstPageSize >= items.length) {
    return [items];
  }

  results.push(items.splice(0, firstPageSize));

  while (items.length) {
    results.push(items.splice(0, pageSize));
  }

  return results;
};

export const createPages = (items: InvoicePericia[]): InvoicePericia[][] => {
  const result: InvoicePericia[][] = [];

  if (items.length <= 10) {
    result.push(items);
    return result;
  }

  let firstPageLimit = 18;

  if (items.length >= 13 && items.length <= 18) {
    firstPageLimit = items.length - 1;
  }

  result.push(items.splice(0, firstPageLimit));

  while (items.length) {
    result.push(items.splice(0, 28));
  }

  return result;
};

export const createPagesFromInvoiceItems = (items: InvoiceItem[]): InvoiceItem[][] => {
  const result: InvoiceItem[][] = [];

  if (items.length <= 10) {
    result.push(items);
    return result;
  }

  let firstPageLimit = 18;

  if (items.length >= 13 && items.length <= 18) {
    firstPageLimit = items.length - 1;
  }

  result.push(items.splice(0, firstPageLimit));

  while (items.length) {
    result.push(items.splice(0, 28));
  }

  return result;
};
