import { Typography } from "@mui/material";

interface TitleProps {
  value: string;
  component?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

const Title: React.FC<TitleProps> = ({
  value,
  component = "h1",
  variant = "h5",
}) => {
  return (
    <Typography component={component} variant={variant}>
      {value}
    </Typography>
  );
};

export default Title;
