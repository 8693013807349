import { Grid, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";

interface DateRangeProps {
  props: Props;
}

interface Props {
  start: Date;
  end: Date;
  onChange: (start: Date, end: Date) => void;
}

const DateRangePicker: React.FC<DateRangeProps> = ({ props }) => {
  const { start, end, onChange } = props;

  const handleDateChange = (start: Date, end: Date) => {
    onChange(start, end);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DatePicker
              label="Inicio"
              openTo="day"
              inputFormat="DD/MM/YYYY"
              shouldDisableDate={(date) => date > end}
              views={["year", "month", "day"]}
              value={start}
              onChange={(newValue: any) => {
                if (newValue) handleDateChange(new Date(newValue), end);
              }}
              renderInput={(params: any) => <TextField {...params} variant="standard" />}
            />
          </Stack>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DatePicker
              label="Limite"
              openTo="day"
              inputFormat="DD/MM/YYYY"
              disableFuture
              views={["year", "month", "day"]}
              value={end}
              onChange={(newValue: any) => {
                if (newValue) handleDateChange(start, new Date(newValue));
              }}
              renderInput={(params: any) => <TextField {...params} variant="standard" />}
            />
          </Stack>
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

export default DateRangePicker;
