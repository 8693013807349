import { UseQueryResult, useQuery } from "react-query";
import { Pericia, getPericiaById } from "../../../../entities/pericia";

export const queryKeys = {
  pericia: (periciaID: string) => ["pericia", periciaID]
};

export function useGetPericiaById(periciaID: string): UseQueryResult<Pericia, Error> {
  return useQuery(queryKeys.pericia(periciaID), () => getPericiaById(periciaID), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: periciaID !== ""
  });
}
