import supabase from "../../../shared/database/supabase";

export interface GetUserByIdResponse {
  id: string;
  name: string;
  phone: string;
  lastName: string;
  displayName: string;
  nationality: string;
  role: string;
}

export const getUserById = async (id: string): Promise<GetUserByIdResponse | Error> => {
  try {
    const { data, error } = await supabase.functions.invoke("get-user-by-id", {
      body: JSON.stringify({ id })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    return data as GetUserByIdResponse;
  } catch (err) {
    console.log("error fetching users:", err);
    throw err;
  }
};
