import { UseQueryResult, useQuery } from "react-query";
import { CustomError } from "../../../../shared/error/error";
import { createPericiaCommentReq } from "../../../../entities/pericia-comment/api/create-pericia-comment";

export interface UseCreatePericiaCommentProps {
  comment: string;
  id_pericia: string;
}

export const queryKeys = {
  createPericiaComment: (props: UseCreatePericiaCommentProps) => ["createPericiaComment", props]
};

export function useCreatePericiaComment(props: UseCreatePericiaCommentProps): UseQueryResult<boolean, CustomError> {
  return useQuery(queryKeys.createPericiaComment(props), () => createPericiaCommentReq(props), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: props.id_pericia.length > 3 && props.comment.length > 3
  });
}
