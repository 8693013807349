import { UseQueryResult, useQuery } from "react-query";
import { listPericiaNotes } from "../../../entities/pericia-notes/api/list-pericia-notes";
import { PericiaNotes } from "../../../entities/pericia-notes/model/pericia-notes";

export interface ListPericiaNotesProps {
  id_pericia: string;
}

export const queryKeys = {
  listPericiaNotes: (props: ListPericiaNotesProps) => ["ListPericiaNotes", props]
};

export function useListPericiaNotes(props: ListPericiaNotesProps): UseQueryResult<PericiaNotes[], Error> {
  const { id_pericia } = props;
  return useQuery(queryKeys.listPericiaNotes(props), () => listPericiaNotes({ id_pericia }), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: id_pericia !== "" && id_pericia.length > 3
  });
}
