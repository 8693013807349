import { UseQueryResult, useQuery } from "react-query";
import { CustomError } from "../../../../shared/error/error";
import { CreateInvoicePaymentRequest, CreateInvoicePaymentResponse } from "../../../../entities/invoice-payment/api/create-invoice-payment";

export type CreateInvoicePaymentReq = (req: CreateInvoicePaymentRequest) => Promise<CreateInvoicePaymentResponse>;

export interface UseCreateInvoicePaymentRequest {
  id_invoice: string;
  amount: number;
  discount_percentage: number;
  payed_at: Date;
  enabled: boolean;
}

export const queryKeys = {
  createInvoicePayment: (req: UseCreateInvoicePaymentRequest) => ["createInvoicePayment", req]
};

export function useCreateInvoicePayment(
  createInvoicePayment: CreateInvoicePaymentReq,
  req: UseCreateInvoicePaymentRequest
): UseQueryResult<CreateInvoicePaymentResponse, CustomError> {
  return useQuery(
    queryKeys.createInvoicePayment(req),
    () =>
      createInvoicePayment({
        id_invoice: req.id_invoice,
        amount: req.amount,
        discount_percentage: req.discount_percentage,
        payed_at: req.payed_at
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: req.enabled
    }
  );
}
