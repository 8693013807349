import { Skeleton, Stack } from "@mui/material";

interface ListSkeletonProps {
  count?: number;
  effect?: "pulse" | "wave" | false | undefined;
  variant?: "rectangular" | "rounded" | "text" | "circular" | undefined;
}

const ListSkeleton: React.FC<ListSkeletonProps> = ({
  count = 7,
  effect = "wave",
  variant = "rounded",
}) => {
  return (
    <Stack spacing={1.5} width={"100%"}>
      {Array.from(Array(count).keys()).map((item, index) => (
        <Skeleton
          key={index}
          variant={variant}
          animation={effect}
          width={"100%"}
          height={50}
        />
      ))}
    </Stack>
  );
};

export default ListSkeleton;
