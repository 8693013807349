import { Grid, Typography } from "@mui/material";
import { PendingPericiasDisplayFieldsToggler } from "../../list-pending-pericias/ui/pending-pericias-display-fields-toggler.component";
import { PendingPericiasList } from "../../list-pending-pericias/ui/pending-pericias-list.component";
import { PericiaInvoiceItem } from "../../../../entities/pericia";
import { LoadingButton } from "@mui/lab";
import { currencyMask, formatIntegerToCurrency } from "../../../../utils/helpers/numbers";
import { capitalizeFirstLetter } from "../../../../shared/helpers/string";

interface ListPericiasByCostumerListProps {
  props: {
    pericias: PericiaInvoiceItem[];
    selectedFieldsToDisplay: {
      displayPlate: boolean;
      displayChassis: boolean;
      displayDate: boolean;
      displayPrice: boolean;
      displayUserName: boolean;
      displayTotalHours: boolean;
    };
    totalPrice: number;
    totalCustomPrice: number;
    start: Date;
    end: Date;
    handleSelectedFieldsToDisplayChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCreatePDF: () => void;
  };
}

export const ListPericiasByCostumerList: React.FC<ListPericiasByCostumerListProps> = ({ props }) => {
  const {
    pericias,
    selectedFieldsToDisplay,
    totalPrice,
    totalCustomPrice,
    start,
    end,
    handleSelectedFieldsToDisplayChange,
    handleCreatePDF
  } = props;

  const formattedTotalPrice = currencyMask(formatIntegerToCurrency(totalPrice));
  const totalPericias = pericias.length;

  if (totalPericias === 0) return <></>;

  return (
    <>
      <Grid item xs={12} sm={12} mt={5} mb={3} sx={{ textAlign: "left" }}>
        <Typography variant="h5" component="div">
          {`${capitalizeFirstLetter(pericias[0].costumer.name)} - ${start.toLocaleDateString("pt-BR")} à ${end.toLocaleDateString(
            "pt-BR"
          )}`}
        </Typography>
        <Typography variant="body1" component="div">
          {`Foram encontradas ${totalPericias} pericias totalizando ${formattedTotalPrice}`}
        </Typography>
      </Grid>
      <PendingPericiasDisplayFieldsToggler
        props={{
          selectedFieldsToDisplay,
          toggleDisplay: handleSelectedFieldsToDisplayChange
        }}
      />
      <Typography variant="subtitle1" component="p">
        Utilize o celular com a tela deitada para visualizar a lista*
      </Typography>
      <PendingPericiasList
        props={{
          pericias,
          selectedFieldsToDisplay,
          totalPrice: totalCustomPrice
        }}
      />
      <LoadingButton fullWidth variant="contained" onClick={handleCreatePDF} sx={{ mb: 1, mt: 5 }}>
        GERAR PDF
      </LoadingButton>
    </>
  );
};
