import supabase from "../../../shared/database/supabase";
import { ProfitMetricDTO } from "../model/profit.model";

interface GetProfitMetricsRequest {
  start_date: string;
  end_date: string;
}

export const getProfitMetricsRequest = async (args: GetProfitMetricsRequest): Promise<ProfitMetricDTO[]> => {
  try {
    const { data, error } = await supabase.functions.invoke("get-gp-np-metrics", {
      body: JSON.stringify({ ...args })
    });
    if (error) throw error;

    if (data.error) throw data.error;
    console.log("data", data);

    return data.data;
  } catch (err) {
    console.log("error fetching profit metrics:", err);
    throw err;
  }
};
