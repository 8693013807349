import supabase from "../../../shared/database/supabase";
import { PericiaPhoto } from "../model/pericia-photo";

export interface ListPericiaPhotosRequest {
  id_pericia: string;
}

export interface ListPericiaPhotosResponse {
  data: PericiaPhoto[] | null;
  error: Error | null;
}

export const listPericiaPhotos = async ({ id_pericia }: ListPericiaPhotosRequest): Promise<ListPericiaPhotosResponse> => {
  try {
    const { data, error } = await supabase.functions.invoke("list-pericia-photos", {
      body: JSON.stringify({ id_pericia })
    });

    if (error) {
      console.log("error listing pericia photos:", error);
      return { data: null, error };
    }

    return {
      data: data.data.map((item: any) => {
        return {
          ...item,
          createdAt: new Date(item.createdAt),
          updatedAt: new Date(item.updatedAt)
        };
      }),
      error: null
    };
  } catch (err) {
    console.log(err);
    throw err;
  }
};
