import { UseQueryResult, useQuery } from "react-query";
import { addSalesman } from "../../../../entities/costumer/api/add-salesman";

export interface UseADdSalesmanProps {
  userId: string;
  costumerId: string;
  enabled: boolean;
}

export const queryKeys = {
  addsalesman: (req: UseADdSalesmanProps) => ["addsalesman", req]
};

export function useAddSalesman(req: UseADdSalesmanProps): UseQueryResult<Boolean, Error> {
  return useQuery(queryKeys.addsalesman(req), () => addSalesman(req), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: req.enabled
  });
}
