import { BottomNavigation, BottomNavigationAction, Box, Container } from "@mui/material";
import { PaperCard } from "../../shared/ui/paper-card/paper-card.component";
import DateRangePicker from "../../shared/ui/date/date-range-picker.component";
import { UsePericiasMetrics } from "../../features/pericia/get-pericias-metrics/api/usePericiasMetrics";
import { LoadingButton } from "@mui/lab";
import { CreatedFinishedBarchart } from "../../features/pericia/get-pericias-metrics/ui/created-finished-barchart";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import { CreatedBarchart } from "../../features/pericia/get-pericias-metrics/ui/created-barchart";
import { FinishedBarchart } from "../../features/pericia/get-pericias-metrics/ui/finished-barchart";
import { BilledBarchart } from "../../features/pericia/get-pericias-metrics/ui/billed-barchart";
import { useNavigate } from "react-router-dom";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";

export const GetPericiasMetricsPage = () => {
  const {
    metrics,
    searchProps,
    createdInfo,
    finishedInfo,
    billedInfo,
    timeFrame,
    handleSetSearchProps,
    metricsByDay,
    metricsByWeek,
    metricsByMonth,
    metricsStartDate,
    metricsEndDate,
    isLoading,
    error
  } = UsePericiasMetrics();
  const { startDate, endDate } = searchProps;
  const navigate = useNavigate();
  const [navItem, setNavItem] = useState(0);

  const handleNavigation = (event: React.SyntheticEvent, item: number) => {
    setNavItem(item);
    switch (item) {
      case 0:
        break;
      case 1:
        navigate("/pericias/metrics/by-costumer");
        break;
      case 2:
        navigate("/pericias/metrics/by-costumers");
        break;
      case 3:
        navigate("/pericias/metrics/profit");
        break;
      default:
        navigate("/");
    }
  };

  const handleDateChange = (start: Date, end: Date) => {
    handleSetSearchProps({ startDate: start, endDate: end });
  };

  const handleGenerateMetrics = () => {
    handleSetSearchProps({ enabled: true });
  };

  useEffect(() => {
    if (!error) return;
    toast.error("Erro ao gerar métricas...");
  }, [error]);

  return (
    <Container>
      <Box mb={5} />
      <BottomNavigation showLabels value={navItem} onChange={handleNavigation} sx={{ marginBottom: "2rem" }}>
        <BottomNavigationAction label="Métricas Nuovauto" icon={<EqualizerIcon />} />
        <BottomNavigationAction label="Métricas cliente" icon={<SignalCellularAltIcon />} />
        <BottomNavigationAction label="Métricas entre clientes" icon={<StackedBarChartIcon />} />
        <BottomNavigationAction label="Métricas faturamento" icon={<TrendingUpIcon />} />
      </BottomNavigation>
      <h1>Métricas Nuovauto</h1>
      <PaperCard title="Selecione o período">
        <Box mb={3} />
        <DateRangePicker props={{ start: startDate, end: endDate, onChange: handleDateChange }} />
        <Box mb={3} />
        <LoadingButton fullWidth variant="contained" loading={isLoading} onClick={handleGenerateMetrics}>
          Gerar métricas
        </LoadingButton>
      </PaperCard>
      <Box mb={5} />
      <LoadingButton variant="outlined" loading={isLoading} onClick={metricsByDay} style={{ marginRight: "2rem" }}>
        Métricas por dia
      </LoadingButton>
      <LoadingButton variant="outlined" loading={isLoading} onClick={metricsByWeek} style={{ marginRight: "2rem" }}>
        Métricas por semana
      </LoadingButton>
      <LoadingButton variant="outlined" loading={isLoading} onClick={metricsByMonth}>
        Métricas por mes
      </LoadingButton>
      <Box mb={5} />
      {metrics.length > 0 && (
        <>
          <CreatedFinishedBarchart
            metrics={metrics}
            createdInfo={createdInfo}
            finishedInfo={finishedInfo}
            timeFrame={timeFrame}
            startDate={metricsStartDate}
            endDate={metricsEndDate}
          />
          <CreatedBarchart
            metrics={metrics}
            createdInfo={createdInfo}
            timeFrame={timeFrame}
            startDate={metricsStartDate}
            endDate={metricsEndDate}
          />
          <FinishedBarchart
            metrics={metrics}
            finishedInfo={finishedInfo}
            timeFrame={timeFrame}
            startDate={metricsStartDate}
            endDate={metricsEndDate}
          />
          <BilledBarchart
            metrics={metrics}
            billedInfo={billedInfo}
            timeFrame={timeFrame}
            startDate={metricsStartDate}
            endDate={metricsEndDate}
          />
        </>
      )}
      <ToastContainer />
    </Container>
  );
};
