import supabase from "../../../shared/database/supabase";
import { Invoice } from "../model/invoice";

interface UpdateInvoiceCreationDateRequest {
  id: string;
  new_created_at_date: string;
}

export const updateInvoiceCreationDate = async (req: UpdateInvoiceCreationDateRequest): Promise<Invoice> => {
  try {
    const { data, error } = await supabase.functions.invoke("update-invoice-date", {
      body: JSON.stringify({ ...req })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
