import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { currencyMask, formatCurrency, formatIntegerToCurrency, getNumberFromCurrencyMask } from "../../../utils/helpers/numbers";
import { PaperCard } from "../../../shared/ui/paper-card/paper-card.component";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { FinishedRepairRecord } from "../../../features/repair-record/model/finished-repair-record";
import {
  UseUpdateFinishedRepairRecordProps,
  useUpdateFinishedRepairRecord
} from "../../../features/repair-record/update-finished-repair-record/api/useUpdateFinishedRepairRecord";
import { toast } from "react-toastify";

interface FinishedRepairRecordsListProps {
  props: Props;
}

interface Props {
  amountToPay: number;
  repairRecords: FinishedRepairRecord[];
  hideActionsAndValues: boolean;
  deleteRepairRecord: (userId: string) => void;
  onEditRepairRecord: () => void;
}

interface CardMessage {
  shouldDisplayMessage: boolean;
  amountToDisplay: string;
  message: string;
}

const INITIAL_CARD_MESSGE: CardMessage = {
  shouldDisplayMessage: false,
  amountToDisplay: "",
  message: ""
};

const INITIAL_REPAIR_RECORD_TO_UPDATE: UseUpdateFinishedRepairRecordProps = {
  id: "",
  finished_at: "",
  payed: false,
  payed_at: "",
  payed_value: 0,
  user: {
    id: "",
    displayName: "",
    last_name: "",
    name: ""
  },
  enabled: false
};

const FinishedRepairRecordsList = ({ props }: FinishedRepairRecordsListProps) => {
  const { repairRecords, amountToPay, hideActionsAndValues, deleteRepairRecord, onEditRepairRecord } = props;
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedRepairRecordToDelete, setSelectedRepairRecordToDelete] = useState<FinishedRepairRecord | null>(null);
  const [selectedRepairRecordToEdit, setSelectedRepairRecordToEdit] = useState<FinishedRepairRecord | null>(null);
  const [newAmountToPayMask, setNewAmountToPayMask] = useState<string>("");
  const [cardMessage, setCardMessage] = useState<CardMessage>(INITIAL_CARD_MESSGE);
  const [repairRecordToUpdate, setRepairRecordToUpdate] = useState<UseUpdateFinishedRepairRecordProps>(INITIAL_REPAIR_RECORD_TO_UPDATE);
  const { data: updateFinishedRepairRecordRes, error: errorUpdatingFinishedRepairRecord } =
    useUpdateFinishedRepairRecord(repairRecordToUpdate);

  const deleteRef = useRef<HTMLInputElement | null>(null);
  const editRef = useRef<HTMLInputElement | null>(null);

  const handleClickConfirmToUpdate = () => {
    if (!selectedRepairRecordToEdit) return;

    setRepairRecordToUpdate({ ...selectedRepairRecordToEdit, payed_value: getNumberFromCurrencyMask(newAmountToPayMask), enabled: true });
    setOpenEditDialog(false);
    setNewAmountToPayMask(currencyMask("0"));
  };

  const handleAmountToPayChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewAmountToPayMask(currencyMask(e.target.value));
  };

  const handleClickOpenToEdit = (repairRecord: FinishedRepairRecord) => {
    setSelectedRepairRecordToEdit(repairRecord);
    setOpenEditDialog(true);
  };

  const handleClickCloseToEdit = () => {
    setSelectedRepairRecordToEdit(null);
    setOpenEditDialog(false);
    setNewAmountToPayMask(currencyMask("0"));
  };

  const handleClickOpenToDelete = (repairRecord: FinishedRepairRecord) => {
    setSelectedRepairRecordToDelete(repairRecord);
    setOpenDeleteDialog(true);
  };

  const handleClickCloseToDelete = () => {
    setSelectedRepairRecordToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleClickConfirmToDelete = () => {
    if (!selectedRepairRecordToDelete) return;

    if (deleteRef?.current?.value.toLocaleLowerCase() !== "remover") {
      return alert("Digite remover para confirmar");
    }

    deleteRepairRecord(selectedRepairRecordToDelete.user.id);
    setOpenDeleteDialog(false);
  };

  useEffect(() => {
    if (!amountToPay) return;

    const repairRecordsAmountToPay = repairRecords.reduce((acc, cur) => {
      return acc + cur.payed_value;
    }, 0);

    if (repairRecordsAmountToPay === amountToPay) {
      setCardMessage((prevState) => {
        return { ...prevState, shouldDisplayMessage: false };
      });

      return;
    }

    const amountToDisplay = amountToPay - repairRecordsAmountToPay;
    const messagePayingLess = `O valor a ser pago é ${formatCurrency(amountToPay)} - Este pagamento está em ${formatCurrency(
      repairRecordsAmountToPay
    )} - Está pagando ABAIXO do valor em `;
    const messagePayingAbove = `O valor a ser pago é ${formatCurrency(amountToPay)} - Este pagamento está em ${formatCurrency(
      repairRecordsAmountToPay
    )} Está pagando ACIMA do valor em `;

    setCardMessage((prevState) => {
      return {
        ...prevState,
        shouldDisplayMessage: true,
        amountToDisplay: formatCurrency(amountToDisplay),
        message: amountToDisplay > 0 ? messagePayingLess : messagePayingAbove
      };
    });
  }, [repairRecords, amountToPay]);

  useEffect(() => {
    if (!updateFinishedRepairRecordRes) return;

    setRepairRecordToUpdate((prevRec) => {
      return { ...prevRec, payed_value: 0, enabled: false };
    });

    toast.success("Reparo atualizado com sucesso. Recalculando...", { autoClose: 800 });
    onEditRepairRecord();
  }, [updateFinishedRepairRecordRes]);

  useEffect(() => {
    if (!errorUpdatingFinishedRepairRecord) return;
    toast.error("Erro ao atualizar reparo. Tente novamente.", { autoClose: 800 });
  }, [errorUpdatingFinishedRepairRecord]);

  return (
    <>
      <PaperCard title="Histórico">
        <List>
          {repairRecords.map((repairRecord) => {
            const finishedAt = new Date(repairRecord.finished_at).toLocaleDateString("pt-BR");
            return (
              <ListItem
                key={repairRecord.id}
                role={undefined}
                dense
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textAlign: "center"
                }}
              >
                <ListItemText id={repairRecord.id} primary={repairRecord.user.displayName} sx={{ flex: "1" }} />
                <ListItemText id={repairRecord.id} primary={finishedAt} sx={{ flex: "1", textAlign: "center" }} />
                {!hideActionsAndValues && (
                  <>
                    <ListItemText
                      id={repairRecord.id}
                      primary={currencyMask(formatIntegerToCurrency(repairRecord.payed_value))}
                      sx={{ flex: "1", textAlign: "center" }}
                    />
                    <IconButton color="primary" onClick={() => handleClickOpenToEdit(repairRecord)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleClickOpenToDelete(repairRecord)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </>
                )}
              </ListItem>
            );
          })}
          {repairRecords.length > 0 && cardMessage.shouldDisplayMessage && (
            <ListItemText
              id="warning-card-message"
              primary={
                <Typography variant="body2" color={`red`}>
                  *{cardMessage.message} {cardMessage.amountToDisplay}
                </Typography>
              }
              sx={{ flex: "1", marginTop: "2rem" }}
            />
          )}
        </List>
      </PaperCard>
      <Dialog open={openDeleteDialog} onClose={handleClickCloseToDelete}>
        <DialogTitle>Remover reparo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja remover este reparo? Digite <strong>remover</strong> no campo abaixo para confirmar.
          </DialogContentText>
          <TextField autoFocus margin="dense" id="name" label="remover" type="text" fullWidth variant="standard" inputRef={deleteRef} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCloseToDelete}>Cancelar</Button>
          <Button onClick={handleClickConfirmToDelete} color="error">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEditDialog} onClose={handleClickCloseToEdit}>
        <DialogTitle>Editar valor do reparo</DialogTitle>
        <DialogContent>
          <DialogContentText>Qual o novo valor?</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Valor"
            type="text"
            fullWidth
            variant="standard"
            inputRef={editRef}
            onChange={handleAmountToPayChange}
            value={newAmountToPayMask}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCloseToEdit} color="error">
            Cancelar
          </Button>
          <Button onClick={handleClickConfirmToUpdate} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FinishedRepairRecordsList;
