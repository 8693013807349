import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { User } from "../model";
import { useListUsers } from "../api";
import { useState } from "react";

interface UserAutocompleteProps {
  props: Props;
}

interface Props {
  user: User;
  updateUser: (user: User) => void;
}

const UserAutocompleteSearchBar: React.FC<UserAutocompleteProps> = ({ props }) => {
  const { updateUser, user } = props;
  const { data: users, error, refetch } = useListUsers();
  const [refetchCount, setRefetchCount] = useState(0);

  const handleUpdateUser = (updatedUser: Partial<User>) => {
    updateUser({ ...user, ...updatedUser });
  };

  if (!users) return <TextField label="Cliente" required fullWidth id="costumerName" autoFocus variant="standard" disabled />;

  if (error && refetchCount < 3) {
    refetch();
    setRefetchCount(refetchCount + 1);
  }

  return (
    <Autocomplete
      disablePortal
      id="user-autocomplete"
      options={users.map((user) => ({
        label: user.displayName,
        id: user.id
      }))}
      onChange={(e, value) => {
        if (!value) return;
        handleUpdateUser({ id: value.id, name: value.label });
      }}
      value={findUser(users, user)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField {...params} label="Usuário" required fullWidth id="userName" variant="standard" />}
    />
  );
};

const findUser = (users: User[], user: User) => {
  if (!user) return null;

  const res = users.find((item) => item.id === user.id);
  if (!res) return null;

  return { label: res.displayName, id: res.id };
};

export default UserAutocompleteSearchBar;
