import { UseQueryResult, useQuery } from "react-query";
import { UpdateInvoiceIvaPercentageResponse, updateInvoiceIvaPercentageReq } from "../../../../entities/invoice/api/update-iva-percentage";

interface UseUpdateInvoiceIvaPercentageProps {
  id: string;
  new_iva_percentage: number;
}

export const queryKeys = {
  updateInvoiceIvaPercentage: (props: UseUpdateInvoiceIvaPercentageProps) => ["updateInvoiceIvaPercentage", props]
};

export function useUpdateInvoiceIvaPercentage(
  props: UseUpdateInvoiceIvaPercentageProps
): UseQueryResult<UpdateInvoiceIvaPercentageResponse, Error> {
  return useQuery(queryKeys.updateInvoiceIvaPercentage(props), () => updateInvoiceIvaPercentageReq(props), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: props.id !== "" && props.new_iva_percentage > 0 && props.new_iva_percentage <= 100
  });
}
