import { useQuery } from "react-query";
import { getUsers } from "../../../storage/supabase/user/user.storage";
import FlexContainer from "../../../shared/ui/container/flex-container.component";
import Title from "../../../components/typography/title.component";
import UsersList from "../../../components/user/list-users/users-list.component";
import { Box, Typography } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import ListSkeleton from "../../../components/skeleton/list-skeleton.component";

const ListUsers = () => {
  const {
    data: users,
    isLoading,
    error
  } = useQuery("users", () =>
    getUsers().then((res) => {
      if (res.error) {
        console.log(res.error);
        throw new Error("Erro ao buscar clientes");
      }
      return res.data;
    })
  );

  if (isLoading) {
    return (
      <FlexContainer>
        <Title value="Lista de usuários" />
        <Box sx={{ width: "100%", mb: 10 }} />
        <ListSkeleton />
      </FlexContainer>
    );
  }

  if (error) {
    toast.error("Algo saiu errado...");
    return (
      <FlexContainer>
        <Title value="Lista de usuários" />
        <Box sx={{ width: "100%", mb: 10 }} />
        <ListSkeleton effect={false} count={1} />
        <ToastContainer />
      </FlexContainer>
    );
  }

  if (!users) {
    toast.info("Nenhum usuário encontrado...");
    return (
      <FlexContainer>
        <Title value="Lista de usuários" />
        <Box sx={{ width: "100%", mb: 10 }} />
        <ListSkeleton effect={false} count={1} />
        <ToastContainer />
      </FlexContainer>
    );
  }

  return (
    <FlexContainer>
      <Typography variant="h4" gutterBottom>
        Lista de usuários
      </Typography>
      <Box sx={{ width: "100%", mb: 3 }} />
      <UsersList users={users} />
    </FlexContainer>
  );
};

export default ListUsers;
