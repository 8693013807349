import { PericiaCostumer } from "../../models/costumer/costumer";

export const findCostumerById = (costumers: PericiaCostumer[], id: string) => {
  if (!id) return costumers[0];

  const res = costumers.find((item) => item.id === id);
  if (!res) return costumers[0];

  return res;
};
