import supabase from "../../../shared/database/supabase";
import { PericiaNotes } from "../model/pericia-notes";

export interface ListPericiaNotesRequest {
  id_pericia: string;
}

export const listPericiaNotes = async (req: ListPericiaNotesRequest): Promise<PericiaNotes[]> => {
  try {
    const { data, error } = await supabase.functions.invoke("list-pericia-notes", {
      body: JSON.stringify(req)
    });

    if (error) throw error;
    if (!data || data.notes.length === 0) {
      return [];
    }

    return data.notes.map((periciaNote: PericiaNotes) => ({
      ...periciaNote,
      createdAt: new Date(periciaNote.createdAt),
      updatedAt: new Date(periciaNote.updatedAt)
    }));
  } catch (err) {
    console.log(err);
    throw err;
  }
};
