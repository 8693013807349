const textItaTranslation = require("../../shared/locale/ita.json");
const textDeTranslation = require("../../shared/locale/de.json");

export interface Locale {
  pericia_pdf: {
    header: {
      client_name: string;
      date: string;
      brand: string;
      model: string;
      plate: string;
      color: string;
      insurance_name: string;
      chassis: string;
      finished: string;
      unmount: string;
    };
    car_parts: Record<string, unknown>;
    cost_description: {
      unmount: string;
      additional_repair: string;
      repair_cost: string;
      total: string;
    };
    legends: {
      additional_repair: string;
      glue: string;
      paint: string;
      aluminium: string;
      change: string;
    };
  };
  invoice_pdf: {
    email: string;
    introduction_message: string;
    salute_message: string;
    greetings_message: string;
    business_slogan: string;
    header: {
      invoice_number: string;
      invoice_date: string;
    };
    table: {
      description: string;
      plate: string;
      chassis: string;
      repair: string;
      hours: string;
    };
    costs: {
      subtotal: string;
      tax: string;
      total: string;
    };
  };
}

export const getTranslation = (key: string): Locale => {
  switch (key) {
    case "ita":
      return textItaTranslation;
    case "de":
      return textDeTranslation;
    default:
      console.log("language not found: ", key);
      return textItaTranslation;
  }
};
