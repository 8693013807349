import supabase from "../../../shared/database/supabase";
import { User } from "../model";

export const listUsers = async (): Promise<User[] | Error> => {
  try {
    const { data, error } = await supabase.functions.invoke("list-users");
    if (error) throw error;

    if (data.error) throw data.error;

    return [...data.users];
  } catch (err) {
    console.log("error fetching users:", err);
    throw err;
  }
};
