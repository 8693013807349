import { UseQueryResult, useQuery } from "react-query";
import { Pericia, createPericia } from "../../../../entities/pericia";
import { PericiaToSaveDTO } from "../../../../shared/dto/pericia";

export const queryKeys = {
  createPericia: (pericia: PericiaToSaveDTO) => ["createPericia", pericia]
};

export function useCreatePericia(pericia: Pericia): UseQueryResult<string, Error> {
  const periciaToSave: PericiaToSaveDTO = {
    costumer: {
      id: pericia.costumer.id
    },
    car: {
      brand: pericia.car.brand,
      model: pericia.car.model,
      plate: pericia.car.plate,
      chassisNumber: pericia.car.chassisNumber,
      color: pericia.car.color,
      insuranceName: pericia.car.insuranceName
    },
    pricePerHour: pericia.pricePerHour,
    date: pericia.date,
    carParts: pericia.carParts,
    insuranceHours: pericia.insuranceHours,
    totalHours: pericia.totalHours,
    totalPrice: pericia.totalPrice,
    costumerPrice: pericia.costumerPrice,
    unmountPrice: pericia.unmountPrice,
    addtionalCost: pericia.addtionalCost,
    addtionalCostDescription: pericia.addtionalCostDescription,
    shouldUnmount: pericia.shouldUnmount,
    finished: pericia.finished,
    done: pericia.done,
    billed: pericia.billed,
    isPriceCalculated: pericia.isPriceCalculated,
    isEditable: pericia.isEditable,
    priceVersion: pericia.priceVersion
  };

  return useQuery(queryKeys.createPericia(periciaToSave), () => createPericia(periciaToSave), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: pericia.costumer.id !== "" && isValidCar(pericia.car)
  });
}

function isValidCar(car: Pericia["car"]) {
  return (
    car.brand !== "" &&
    car.model !== "" &&
    car.plate !== "" &&
    car.chassisNumber !== "" &&
    car.color !== "" &&
    car.insuranceName !== ""
  );
}
