import { v4 as uuid } from "uuid";

export interface PericiaPhoto {
  id: string;
  id_pericia: string;
  id_user: string;
  url: string;
  description: string;
  file: File | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  deleted: boolean;
}

export interface PericiaPhotoDTO {
  id_pericia: string;
  id_user: string;
  description: string;
  file: File | null;
}

export const newPericiaPhoto = ({ id_pericia, id_user, description, file }: PericiaPhotoDTO): PericiaPhoto => {
  const id = uuid();
  return {
    id,
    id_pericia,
    id_user,
    url: createPhotoUrl(id_pericia, id),
    description,
    file: file || null,
    created_at: new Date(),
    updated_at: new Date(),
    deleted_at: null,
    deleted: false
  };
};

export const createPhotoUrl = (id_pericia: string, id: string): string => {
  return `${id_pericia}-${id.split("-")[3]}`;
};
