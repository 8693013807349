import supabase from "../../../shared/database/supabase";
import { TechnicianPayment } from "../model";

interface GetTechnicianPaymentRecordRequest {
  id_pericia: string;
}

export const getTechnicianPaymentRecord = async (req: GetTechnicianPaymentRecordRequest): Promise<TechnicianPayment | Error> => {
  try {
    const { data, error } = await supabase.functions.invoke("get-technician-payment-record", {
      body: JSON.stringify({ id_pericia: req.id_pericia })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    if (!data) return {} as TechnicianPayment;

    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
