export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "CHF"
  }).format(value);
};

export const currencyMask = (input: string) => {
  const value = input.replace(/\D/g, "");
  const number = Number(value) / 100;
  const currency = number.toLocaleString("pt-BR", {
    style: "currency",
    currency: "CHF"
  });
  return currency;
};

export const extractPercentage = (input: string) => {
  const value = input.replace(/\D/g, "");
  const result = Number(value) / 100;
  return result > 100 ? "100.00" : result.toFixed(2);
};

export const getNumberFromCurrencyMask = (input: string) => {
  const value = input.replace(/\D/g, "");
  const number = Number(value) / 100;
  return number;
};

export const formatIntegerToCurrency = (value: number): string => {
  if (Number.isInteger(value)) {
    return (value * 100).toString();
  }

  return value.toFixed(2);
};

export const toCHF = (value: number) => {
  return currencyMask(formatIntegerToCurrency(value));
};

export const isNotNumber = (value: string) => {
  return isNaN(Number(value));
};

export const calculateIVA = (value: number) => {
  return Number((value * 1.077).toFixed(2));
};

export const calculateIVAFromPercentage = (value: number, IVAPercentage: number) => {
  return Number((value * (1 + IVAPercentage / 100)).toFixed(2));
};
