import { UseQueryResult, useQuery } from "react-query";
import { CreatePericiaNoteResponse, createPericiaNote } from "../../../entities/pericia-notes/api/create-pericia-note";

export interface CreatePericiaNoteProps {
  id_pericia: string;
  note: string;
}

export const queryKeys = {
  createPericiaNote: (props: CreatePericiaNoteProps) => ["createPericiaNote", props]
};

export function useCreatePericiaNote(props: CreatePericiaNoteProps): UseQueryResult<CreatePericiaNoteResponse, Error> {
  const { id_pericia, note } = props;
  return useQuery(
    queryKeys.createPericiaNote(props),
    () =>
      createPericiaNote({
        id_pericia,
        note
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: id_pericia !== "" && id_pericia.length > 3 && note !== "" && note.length > 3
    }
  );
}
