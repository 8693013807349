import { UseQueryResult, useQuery } from "react-query";
import { Car, CarExistsResponse, carExists } from "../../../entities/car";

export const queryKeys = {
  carExists: (props: Car) => ["carExists", props]
};

export function useCarExists(props: Car): UseQueryResult<CarExistsResponse, Error> {
  const car = props;

  return useQuery(queryKeys.carExists(props), () => carExists({ car }), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled:
      car.plate !== "" && car.brand !== "" && car.model !== "" && car.color !== "" && car.chassisNumber !== "" && car.insuranceName !== ""
  });
}
