import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Formik, FormikHelpers, FormikProps, Form, Field, FieldProps } from "formik";
import { object, string } from "yup";
import FlexContainer from "../../shared/ui/container/flex-container.component";
import { PaperCard } from "../../shared/ui/paper-card/paper-card.component";
import { ToastContainer, toast } from "react-toastify";
import { FormTextField } from "../../components/form/form-input/form-input.component";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useInviteUserByEmail } from "../../features/user/invite-user-by-email/api/useInviteUserByEmail";

let inviteUserSchema = object({
  email: string().required("Email é obrigatório").email("Email inválido"),
  role: string().required("Role é obrigatório")
});

interface FormValues {
  email: string;
  role: string;
}

const initialValues: FormValues = {
  email: "",
  role: "user"
};

export const InviteUserByEmailPage = () => {
  const [account, setAccount] = useState<FormValues>(initialValues);
  const { data, error, isLoading } = useInviteUserByEmail(account);

  if (error) toast.error("Erro ao criar usuário!");

  if (data) {
    toast.success("Email enviado com sucesso!");
    setAccount(initialValues);
  }

  return (
    <FlexContainer>
      <Typography variant="h4">Cadastrar usuário novo</Typography>
      <PaperCard title="Dados da conta">
        <Typography variant="h4" mt={2}></Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={inviteUserSchema}
          enableReinitialize
          onSubmit={(values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
            setAccount({ ...values });
          }}
        >
          {(formikProps: FormikProps<FormValues>) => (
            <Form noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Field name="email" label="Email" size="small" required fullWidth component={FormTextField} />
                </Grid>
                <Grid item xs={12} sm={12} mt={2}>
                  <Field
                    name="password"
                    required
                    fullWidth
                    size="small"
                    component={FormTextField}
                    render={(fieldProps: FieldProps) => (
                      <Box sx={{ maxWidth: 180 }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Nível de Acesso</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fieldProps.field.value}
                            label="role"
                            onChange={fieldProps.field.onChange}
                            defaultValue={"user"}
                          >
                            <MenuItem value={"admin"}>Admin</MenuItem>
                            <MenuItem value={"limited_admin"}>Gestão</MenuItem>
                            <MenuItem value={"salesman"}>Vendedor</MenuItem>
                            <MenuItem value={"user_privileged"}>Técnico Perito</MenuItem>
                            <MenuItem value={"user"}>Técnico</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item xs={12} mt={3}>
                  <LoadingButton type="submit" variant="contained" loading={isLoading} disabled={isLoading}>
                    Enviar convite
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </PaperCard>
      <ToastContainer />
    </FlexContainer>
  );
};
