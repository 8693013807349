import { UseQueryResult, useQuery } from "react-query";
import { PericiaInvoiceItem, listPericiasByDateRange } from "../../../../entities/pericia";

export interface ListPericiaByDateRangeProps {
  start: Date;
  end: Date;
  filters: ListPericiaByDateRangeFilters;
  enabled: boolean;
  page: number;
}

export interface ListPericiaByDateRangeFilters {
  done: boolean;
  notDone: boolean;
  billed: boolean;
  isPriceCalculated: boolean;
}

export const queryKeys = {
  listPericiasByDateRange: (props: ListPericiaByDateRangeProps) => ["listPericiasByDateRange", props]
};

export function useListPericiasByDateRange(props: ListPericiaByDateRangeProps): UseQueryResult<PericiaInvoiceItem[], Error> {
  return useQuery(
    queryKeys.listPericiasByDateRange(props),
    () =>
      listPericiasByDateRange({
        start: props.start.toISOString(),
        end: props.end.toISOString(),
        page: props.page,
        filters: {
          done: props.filters.done,
          not_done: props.filters.notDone,
          price_calculated: props.filters.isPriceCalculated,
          billed: props.filters.billed
        }
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(props.start) && Boolean(props.end) && props.enabled
    }
  );
}
