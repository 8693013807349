import { Accordion, AccordionDetails, AccordionSummary, TextareaAutosize, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PericiaComment } from "../../../entities/pericia-comment/model/pericia-comment";
import { useState } from "react";

export interface PericiaCommentsListProps {
  comments: PericiaComment[];
  defaultExpanded?: boolean;
}

export const PericiaCommentsList = ({ comments, defaultExpanded = false }: PericiaCommentsListProps) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={{ marginTop: "1rem" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
        <Typography sx={{ width: "33%", flexShrink: 0 }}>Comentários dos técnicos</Typography>
        <Typography sx={{ color: "text.secondary" }}>{`${comments.length} comentário(s)`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextareaAutosize
          aria-label="minimum height"
          minRows={3}
          placeholder="Comentários de técnicos"
          style={{ width: "100%", marginTop: "1rem", padding: "1rem 1rem 0.5rem 1rem" }}
          value={formatPericiaComments(comments || [])}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const formatPericiaComments = (comments: PericiaComment[]) => {
  return comments
    .map((comment) => {
      return `- ${comment.user.displayName}: ${comment.comment} - ${comment.createdAt.toLocaleString("pt-BR")}.`;
    })
    .join("\n\n");
};
