import "react-toastify/dist/ReactToastify.css";
import { Typography } from "@mui/material";
import FlexContainer from "../../shared/ui/container/flex-container.component";
import { LoadingButton } from "@mui/lab";
import PericiaAddtionalCost from "../../entities/pericia/ui/pericia-addtional-cost.component";
import PericiaHeader from "../../entities/pericia/ui/pericia-header.component";
import PericiaImg from "../../entities/pericia/ui/pericia-img.component";
import PericiaTable from "../../entities/pericia/ui/periciaTable.component";
import { PericiaFinishedInput } from "../../entities/pericia/ui/pericia-finished-input-component";
import { PericiaInsuranceForm } from "../../entities/pericia/ui/pericia-insurance-form-component";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePericia } from "../../entities/pericia/model/usePericia";
import { useCreatePericia } from "../../features/pericia/create-pericia/api/useCreatePericia";
import { CreateNewPericia, Pericia } from "../../entities/pericia";
import { Car, CreateNewCar, isValidCar } from "../../entities/car";
import { useCarExists } from "../../features/car/car-exists/useCarExists";
import { USER_ROLES, UserWithRole } from "../../contexts/user/user.context";
import supabase from "../../shared/database/supabase";

export const PericiaCreatePage = () => {
  const user = supabase.auth.user() as UserWithRole;
  const navigate = useNavigate();
  const {
    pericia,
    updatePericiaCostumer,
    updatePericiaCar,
    updatePericiaPricePerHour,
    updatePericiaShouldUnmount,
    updatePericiaUnmountPrice,
    updatePericiaDate,
    updatePericiaAddtionalCost,
    updatePericiaAddtionalCostDescription,
    updatePericiaFinished,
    updateCarPart,
    updatePericiaInsuranceHours,
    findCarPart
  } = usePericia();
  const {
    costumer,
    car,
    pricePerHour,
    shouldUnmount,
    unmountPrice,
    date,
    carParts,
    totalHours,
    totalPrice,
    addtionalCost,
    addtionalCostDescription,
    finished,
    insuranceHours
  } = pericia;
  const [periciaToCreate, setPericiaToCreate] = useState<Pericia>(CreateNewPericia());
  const { data: createdPericiaID, error: errorCreatingPericia, isLoading } = useCreatePericia(periciaToCreate);
  const [carBeingCreated, setCarBeingCreated] = useState<Car>(CreateNewCar());
  const { data: carExistsRes, error: errCarExsists, isLoading: isLoadingCarExists } = useCarExists({ ...carBeingCreated });

  const ALLOWED_ROLES = [USER_ROLES.ADMIN, USER_ROLES.SALESMAN];
  const IS_ALLOWED = ALLOWED_ROLES.includes(user.permission);

  const handleCreatePericia = async () => {
    if (costumer.id === "") {
      toast.error("Cliente inválido!");
      return;
    }

    if (!isValidCar(car)) {
      toast.error("Carro inválido!");
      return;
    }

    setPericiaToCreate({ ...pericia });
  };

  if (errorCreatingPericia) {
    toast.error("Erro ao criar pericia!");
  }

  useEffect(() => {
    if (!carExistsRes) return;

    if (carExistsRes.exists) {
      toast.info("Carro já cadastrado anteriormente!");
      return;
    }
  }, [carExistsRes]);

  useEffect(() => {
    if (!isValidCar(car)) return;

    setCarBeingCreated({ ...car });
  }, [car]);

  useEffect(() => {
    if (!createdPericiaID || createdPericiaID === "") return;

    toast.success("Pericia criada com sucesso, redirecionando...", { autoClose: 600 });
    setTimeout(() => {
      navigate(`/pericias/${createdPericiaID}?operation=creation`);
    }, 1200);
  }, [createdPericiaID]);

  return (
    <FlexContainer>
      <Typography variant="h4" component="h1">
        Cadastro de Pericia
      </Typography>
      <PericiaHeader
        props={{
          costumer,
          car,
          shouldUnmount,
          pricePerHour,
          unmountPrice,
          date,
          updateCostumer: updatePericiaCostumer,
          updateCar: updatePericiaCar,
          updatePricePerHour: updatePericiaPricePerHour,
          updateShouldUnmount: updatePericiaShouldUnmount,
          updateUnmountPrice: updatePericiaUnmountPrice,
          updateDate: updatePericiaDate
        }}
      />
      <Typography component="h1" variant="h5">
        Pericia
      </Typography>
      <PericiaImg
        props={{
          findCarPart,
          updateCarPart
        }}
      />
      <PericiaFinishedInput props={{ finished, updateFinished: updatePericiaFinished }} />
      <PericiaAddtionalCost
        props={{
          addtionalCost,
          addtionalCostDescription,
          updateAddtionalCost: updatePericiaAddtionalCost,
          updateAddtionalCostDescription: updatePericiaAddtionalCostDescription
        }}
      />
      {IS_ALLOWED && (
        <>
          <Typography component="h1" variant="h5" sx={{ mt: 5, mb: 3 }}>
            Tabela
          </Typography>
          <PericiaTable
            props={{
              carParts,
              totalHours,
              totalPrice,
              pricePerHour,
              unmountPrice,
              addtionalCost
            }}
          />
        </>
      )}
      <PericiaInsuranceForm
        props={{
          insuranceHours,
          updateInsuranceHours: updatePericiaInsuranceHours,
          insurancePrice: insuranceHours * pricePerHour
        }}
      />
      <LoadingButton
        fullWidth
        variant="contained"
        onClick={handleCreatePericia}
        loading={isLoading}
        disabled={Boolean(createdPericiaID)}
        sx={{ mt: 3, mb: 2 }}
      >
        Salvar Pericia
      </LoadingButton>
      <ToastContainer />
    </FlexContainer>
  );
};
