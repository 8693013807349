import supabase from "../../../shared/database/supabase";
import { PericiaPhoto } from "../model/pericia-photo";

export interface UploadPericiaPhotoRequest {
  photo: PericiaPhoto;
}

export interface UploadPericiaPhotoResponse {
  data: PericiaPhoto | null;
  error: Error | null;
}

export const uploadPericiaPhotos = async ({ photo }: UploadPericiaPhotoRequest): Promise<UploadPericiaPhotoResponse> => {
  const user = supabase.auth.user();
  if (!user) return { data: null, error: new Error("unauthorized") };
  if (!photo.file) return { data: null, error: new Error("file not found") };

  try {
    const { data, error } = await supabase
      .from("pericia_photos")
      .insert({
        id: photo.id,
        id_pericia: photo.id_pericia,
        id_user: user?.id,
        url: photo.url,
        description: photo.description,
        created_at: photo.created_at,
        updated_at: photo.updated_at,
        deleted_at: photo.deleted_at,
        deleted: photo.deleted
      })
      .select("*");

    if (error) {
      console.log("error creating pericia photo:", error);
      return { data: null, error: new Error("error creating pericia photo") };
    }

    const { error: photoError } = await supabase.storage.from("pericias").upload(`photos/${photo.url}`, photo.file);

    if (photoError) {
      console.error("error uploading photo:", photoError);
      return { data: null, error: photoError };
    }

    if (!data) {
      console.error("error creating pericia:", error);
      return { data: null, error: new Error("error creating pericia photo") };
    }

    return { data: data[0], error: null };
  } catch (err) {
    console.log("error creating pericia:", err);
    throw err;
  }
};
