import { UseQueryResult, useQuery } from "react-query";
import { GetUserByIdResponse, getUserById } from "../../../../entities/user";

export const queryKeys = {
  getUserById: (id: string) => ["getUserById", id]
};

export function useGetUserById(id: string): UseQueryResult<GetUserByIdResponse, Error> {
  return useQuery(queryKeys.getUserById(id), () => getUserById(id), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: id !== ""
  });
}
