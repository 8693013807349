import { UseQueryResult, useQuery } from "react-query";
import { getInvoiceByPericiaId } from "../../../../entities/invoice/api/get-invoice-by-pericia-id";
import { Invoice } from "../../../../entities/invoice/model/invoice";

export const queryKeys = {
  getInvoiceByPericiaId: (pericia_id: string) => ["getInvoiceByPericiaId", pericia_id]
};

export function useGetInvoiceByPericiaId(pericia_id: string): UseQueryResult<Invoice, Error> {
  return useQuery(queryKeys.getInvoiceByPericiaId(pericia_id), () => getInvoiceByPericiaId(pericia_id), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: pericia_id !== ""
  });
}
