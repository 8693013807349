import supabase from "../../../shared/database/supabase";

interface DeleteRepairRecordRequest {
  userID: string;
  periciaID: string;
}

export const deleteRepairRecord = async (req: DeleteRepairRecordRequest): Promise<boolean | Error> => {
  const { userID: id_user, periciaID: id_pericia } = req;

  try {
    const { data, error } = await supabase.functions.invoke("delete-repair-record", {
      body: JSON.stringify({ id_user, id_pericia })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    return true;
  } catch (err) {
    console.log("error fetching car exists endpoint:", err);
    throw err;
  }
};
