import { BottomNavigation, BottomNavigationAction, List, Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { PericiaInvoiceItem } from "../../entities/pericia";
import FlexContainer from "../../shared/ui/container/flex-container.component";
import { usePericiasByDateRange } from "../../features/pericia/list-pericias-by-date-range/api/usePericiasByDateRange";
import { PericiasByDateHeader } from "../../features/pericia/list-pericias-by-date-range/ui/pericias-by-date-header.component";
import { PericiasListByDate } from "../../features/pericia/list-pericias-by-date-range/ui/pericias-list-by-date";
import { ChangeEvent, useState } from "react";
import { useListPericiasByDateRangePDFCreator } from "../../features/pericia/list-pericias-by-date-range/api/useListPericiasByDateRangePDFCreator";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { useNavigate } from "react-router-dom";

interface SelectedFieldsToDisplay {
  displayPlate: boolean;
  displayChassis: boolean;
  displayDate: boolean;
  displayPrice: boolean;
  displayUserName: boolean;
  displayTotalHours: boolean;
}

const INITIAL_SELECTED_FIELDS_TO_DISPLAY: SelectedFieldsToDisplay = {
  displayPlate: true,
  displayChassis: true,
  displayDate: true,
  displayPrice: true,
  displayUserName: true,
  displayTotalHours: true
};

const ListPericiasByDateRangePage = () => {
  const [selectedFieldsToDisplay, setSelectedFieldsToDisplay] = useState<SelectedFieldsToDisplay>(INITIAL_SELECTED_FIELDS_TO_DISPLAY);
  const {
    pericias,
    searchProps,
    INITIAL_FILTER,
    listError,
    listIsLoading,
    fetchedAll,
    totalPrice,
    totalCostumers,
    activeFilters,
    handleFetchNextPage,
    handleSearchPericias,
    handleSetSearchProps
  } = usePericiasByDateRange();
  const { createPDF } = useListPericiasByDateRangePDFCreator();

  const navigate = useNavigate();

  const handleSelectedFieldsToDisplayChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedFieldsToDisplay({
      ...selectedFieldsToDisplay,
      [e.target.name]: e.target.checked
    });
  };

  const handleDateChange = (start: Date, end: Date) => {
    handleSetSearchProps({ start, end });
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    handleSetSearchProps({ filters: { ...INITIAL_FILTER, [name]: checked } });
  };

  const handleCreatePDF = () => {
    if (!pericias) {
      toast.error("Nenhuma pericia encontrada");
      return;
    }

    createPDF({
      pericias: pericias as PericiaInvoiceItem[],
      filter: activeFilters.length > 0 ? activeFilters[0] : "",
      start: searchProps.start,
      end: searchProps.end,
      displayFields: selectedFieldsToDisplay,
      totalPrice: totalPrice
    });
  };

  if (listError) {
    toast.error("Erro ao buscar perícias");
  }

  return (
    <FlexContainer>
      <BottomNavigation showLabels value={1} onChange={() => navigate("/costumers/list/pericias/pending")} sx={{ marginBottom: "2rem" }}>
        <BottomNavigationAction label="Perícias por clientes" icon={<ContentPasteSearchIcon />} />
        <BottomNavigationAction label="Todas as perícias" icon={<ContentPasteSearchIcon />} />
      </BottomNavigation>
      <Typography component="h1" variant="h4">
        Perícias por período
      </Typography>
      <Typography component="h1" variant="subtitle1">
        Permite buscar perícias por período
      </Typography>

      <PericiasByDateHeader
        props={{
          handleSearchPericias,
          start: searchProps.start,
          end: searchProps.end,
          filters: searchProps.filters,
          isLoading: listIsLoading,
          handleDateChange,
          handleFilterChange
        }}
      />
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper"
        }}
      >
        {pericias && pericias.length > 0 && (
          <>
            <PericiasListByDate
              props={{
                pericias,
                selectedFieldsToDisplay,
                totalPrice,
                totalCostumers,
                start: searchProps.start,
                end: searchProps.end,
                fetchedAll,
                handleFetchNextPage,
                listLoading: listIsLoading,
                handleSelectedFieldsToDisplayChange,
                handleCreatePDF
              }}
            />
          </>
        )}
      </List>
      <ToastContainer />
    </FlexContainer>
  );
};

export default ListPericiasByDateRangePage;
