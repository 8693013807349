import { UseQueryResult, useQuery } from "react-query";
import { ListPericiasNotBilled } from "../../../../entities/pericia/api/list-pericias-not-billed";
import { Pericia } from "../../../../entities/pericia";

export interface ListPericiasNotBilledProps {
  start: Date;
  end: Date;
  costumerId: string;
}

export const queryKeys = {
  ListPericiasNotBilled: (props: ListPericiasNotBilledProps) => ["ListPericiasNotBilled", props]
};

export function useListPericiasNotBilled(props: ListPericiasNotBilledProps): UseQueryResult<Pericia[], Error> {
  return useQuery(
    queryKeys.ListPericiasNotBilled(props),
    () =>
      ListPericiasNotBilled({
        start: props.start.toISOString(),
        end: props.end.toISOString(),
        costumer_id: props.costumerId
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: props.costumerId !== "" && props.costumerId.length > 3 && Boolean(props.start) && Boolean(props.end)
    }
  );
}
