import supabase from "../../../shared/database/supabase";
import { CustomError } from "../../../shared/error/error";

export interface CreatePericiaCommentRequest {
  comment: string;
  id_pericia: string;
}

export const createPericiaCommentReq = async (req: CreatePericiaCommentRequest): Promise<boolean> => {
  try {
    const { data, error } = await supabase.functions.invoke("create-pericia-comment", {
      body: JSON.stringify({ ...req })
    });

    if (error) throw error;
    if (data.error) throw data.error;

    return true;
  } catch (err) {
    const apiError = err as CustomError;
    console.error(`API Error: ${apiError.message}`);

    if (apiError && apiError.code && apiError.message && apiError.toast_message && apiError.http_status_code) {
      throw new CustomError(apiError);
    }

    throw new CustomError({});
  }
};
