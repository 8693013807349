import { Container } from "@mui/material";

interface FlexContainerProps {
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  flexDirection?: "row" | "column";
  children: React.ReactNode;
}

const FlexContainer: React.FC<FlexContainerProps> = ({ children, maxWidth = "md", flexDirection = "column" }) => {
  return (
    <Container
      component={"main"}
      maxWidth={maxWidth}
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: { flexDirection }
      }}
    >
      {children}
    </Container>
  );
};

export default FlexContainer;
