import { UseQueryResult, useQuery } from "react-query";
import { uploadPericiaPhotos } from "../../../entities/pericia-photo/api/upload-pericia-photo";
import { newPericiaPhoto } from "../../../entities/pericia-photo/model/pericia-photo";

export interface UploadtPericiaPhotosProps {
  id_pericia: string;
  item: photoDTO;
}

export interface photoDTO {
  file: File | null;
  description: string;
}

export const queryKeys = {
  uploadtPericiaPhotos: (props: UploadtPericiaPhotosProps) => ["uploadtPericiaPhotos", props]
};

export function useUploadtPericiaPhotos({ id_pericia, item }: UploadtPericiaPhotosProps): UseQueryResult<null, Error> {
  const photo = newPericiaPhoto({ id_pericia, description: item.description, id_user: "", file: item.file });

  return useQuery(queryKeys.uploadtPericiaPhotos({ id_pericia, item }), () => uploadPericiaPhotos({ photo }), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: id_pericia !== "" && id_pericia.length > 3 && photo.file !== null
  });
}
