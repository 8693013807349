import { UseQueryResult, useQuery } from "react-query";
import { Costumer, listCostumers } from "../../../../entities/costumer";

export const queryKeys = {
  listCostumers: () => "listCostumers"
};

export function useListCostumers(): UseQueryResult<Costumer[], Error> {
  return useQuery(queryKeys.listCostumers(), () => listCostumers(), {
    refetchOnWindowFocus: false,
    retry: 3
  });
}
