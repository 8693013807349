import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Stack, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useState } from "react";
import { currencyMask, formatIntegerToCurrency, getNumberFromCurrencyMask } from "../../../utils/helpers/numbers";
import CostumerAutocompleteSearchBar from "../../costumer/ui/costumer-autocomplete-search-bar.component";
import { Car, isValidCar } from "../../car/model/car";
import { Costumer } from "../../costumer/model/costumer";
import { PaperCard } from "../../../shared/ui/paper-card/paper-card.component";

interface PericiaHeaderProps {
  props: Props;
}

interface Props {
  car: Car;
  costumer: Costumer;
  pricePerHour: number;
  shouldUnmount: boolean;
  unmountPrice: number;
  date: Date;
  createdBy?: string;
  updateDate: (date: Date) => void;
  updateCostumer: (costumer: Costumer) => void;
  updateCar: (car: Car) => void;
  updatePricePerHour: (pricePerHour: number) => void;
  updateShouldUnmount: (shouldUnmount: boolean) => void;
  updateUnmountPrice: (unmountPrice: number) => void;
}

const PericiaHeader: React.FC<PericiaHeaderProps> = ({ props }) => {
  const {
    costumer,
    car,
    pricePerHour,
    unmountPrice,
    shouldUnmount,
    date,
    updateCostumer,
    updateCar,
    updatePricePerHour,
    updateShouldUnmount,
    updateUnmountPrice,
    updateDate,
    createdBy
  } = props;
  const [carMask, setCarMask] = useState<Car>(car);
  const [unmountPriceMask, setUnmountPriceMask] = useState("");
  const [carFormError, setCarFormError] = useState(true);
  const { plate, brand, model, chassisNumber, insuranceName, color } = carMask;

  const handleCarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "plate") {
      return setCarMask({ ...carMask, [name]: value.toUpperCase() });
    }

    return setCarMask({ ...carMask, [name]: value });
  };

  const handleCheckCarFormisValid = () => {
    if (
      !isValidCar({
        ...car,
        brand,
        plate,
        chassisNumber,
        model,
        color,
        insuranceName
      })
    ) {
      return setCarFormError(true);
    }

    handleOnCarFinishEditing();
    return setCarFormError(false);
  };

  const handleOnCarFinishEditing = () => {
    updateCar(carMask);
  };

  const handleShouldUnmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    return updateShouldUnmount(!shouldUnmount);
  };

  const handleUnmountPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    return setUnmountPriceMask(currencyMask(value));
  };

  const handleUnmountPriceChangeOnBlur = () => {
    return updateUnmountPrice(getNumberFromCurrencyMask(unmountPriceMask));
  };

  const handlePricePerHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    return updatePricePerHour(Number(value));
  };

  useEffect(() => {
    setUnmountPriceMask(currencyMask(formatIntegerToCurrency(unmountPrice)));
  }, [unmountPrice]);

  useEffect(() => {
    setCarMask(car);
  }, [car]);

  const carFields = [
    {
      id: "brand",
      label: "Marca",
      name: "brand",
      value: brand || "",
      error: carFormError && !brand,
      helperText: carFormError && !brand && "Informe a marca"
    },
    {
      id: "model",
      label: "Modelo",
      name: "model",
      value: model || "",
      error: carFormError && !model,
      helperText: carFormError && !model && "Informe o modelo"
    },
    {
      id: "placa",
      label: "Placa",
      name: "plate",
      value: plate || "",
      error: carFormError && !plate,
      helperText: carFormError && !plate && "Informe a placa"
    },
    {
      id: "chassisNumber",
      label: "Chassi",
      name: "chassisNumber",
      value: chassisNumber || "",
      error: carFormError && !chassisNumber,
      helperText: carFormError && !chassisNumber && "Informe o chassi"
    },
    {
      id: "insuranceName",
      label: "Seguro",
      name: "insuranceName",
      value: insuranceName || "",
      error: carFormError && !insuranceName,
      helperText: carFormError && !insuranceName && "Informe o seguro"
    },
    {
      id: "color",
      label: "Cor",
      name: "color",
      value: color || "",
      error: carFormError && !color,
      helperText: carFormError && !color && "Informe a cor"
    }
  ];

  return (
    <Box component="form" noValidate sx={{ mt: 3, mb: 5 }}>
      <PaperCard title=" Informações da perícia">
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={12}>
            <CostumerAutocompleteSearchBar props={{ costumer, updateCostumer }} />
          </Grid>
          {carFields.map((field) => (
            <Grid item xs={12} sm={4} key={field.id}>
              <TextField
                autoComplete="off"
                required
                fullWidth
                id={field.id}
                label={field.label}
                name={field.name}
                variant="standard"
                onChange={handleCarChange}
                value={field.value}
                error={field.error}
                helperText={field.helperText}
                onBlur={handleCheckCarFormisValid}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={6}>
            <TextField
              type={"number"}
              required
              fullWidth
              name="pricePerHour"
              label="CHF/Ora"
              id="pricePerHour"
              variant="standard"
              value={pricePerHour.toString()}
              onChange={handlePricePerHourChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DatePicker
                  label="Data"
                  openTo="month"
                  inputFormat="DD/MM/YYYY"
                  views={["year", "month", "day"]}
                  value={date}
                  onChange={(newValue) => {
                    if (newValue) updateDate(new Date(newValue));
                  }}
                  renderInput={(params) => <TextField {...params} variant="standard" />}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={handleShouldUnmountChange} value={shouldUnmount} checked={shouldUnmount} />}
                label="Desmontar"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6} sm={6} display={shouldUnmount ? "block" : "none"}>
            <TextField
              required
              fullWidth
              name="unmount"
              label="Preço desmontagem"
              id="unmount"
              variant="standard"
              value={unmountPriceMask}
              onChange={handleUnmountPriceChange}
              onBlur={handleUnmountPriceChangeOnBlur}
            />
          </Grid>
          {createdBy && (
            <Grid item xs={12} sm={12}>
              <TextField name="createdBy" label="Perito" id="createdBy" variant="outlined" value={createdBy} disabled />
            </Grid>
          )}
        </Grid>
      </PaperCard>
    </Box>
  );
};

export default PericiaHeader;
