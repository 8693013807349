import supabase from "../../../shared/database/supabase";
import { Invoice } from "../model/invoice";

export const getInvoiceByPericiaId = async (pericia_id: string): Promise<Invoice> => {
  try {
    const { data, error } = await supabase.functions.invoke("get-invoice-by-pericia-id", {
      body: JSON.stringify({ pericia_id })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
