import { UseQueryResult, useQuery } from "react-query";
import { GetInvoiceByIdResponse, getInvoiceById } from "../../../../entities/invoice/api/get-invoice-by-id";

export const queryKeys = {
  getInvoiceById: (id: string) => ["getInvoiceById", id]
};

export function useGetInvoiceById(id: string): UseQueryResult<GetInvoiceByIdResponse, Error> {
  return useQuery(queryKeys.getInvoiceById(id), () => getInvoiceById(id), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: id !== ""
  });
}
