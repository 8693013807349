import { PericiaBilled, PericiaPriceCalculated } from "../../../models/pericia/pericia";
import { PericiaByCostumerIDRepository, PericiaRepository, PericiaRepositoryCarPart } from "../../../models/pericia/pericia.repository";
import supabase from "../../../shared/database/supabase";
import { periciaFromRepo, periciasByCostumerIDFromRepo } from "../../../utils/mappers/mappers-from-repository";
import { periciaPriceCalculatedToRepo } from "../../../utils/mappers/mappers-to-repository";
import { getPericiaByIdSelect, getPericiasWithinRangeSelect } from "../queries/pericia/pericia.query";

export const deletePericiaByCarId = async (carId: string) => {
  try {
    const { data, error } = await supabase.from("cars").delete().eq("id", carId);
    if (error) {
      return { data: null, error };
    }

    console.log(data);

    return { data, error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getPericiaById = async (id: string) => {
  try {
    const { data, error } = await supabase.from<PericiaRepository>("pericias").select(getPericiaByIdSelect).eq("id", id);
    if (error) {
      return {
        data: null,
        error
      };
    }

    if (data.length === 0) {
      return { data: null, error: new Error("Pericia nao encontrada") };
    }

    return { data: periciaFromRepo(data[0]), error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updatePericiaPriceCalculated = async (pericia: PericiaPriceCalculated) => {
  try {
    const { data, error } = await supabase
      .from<PericiaBilled>("pericias")
      .update(periciaPriceCalculatedToRepo(pericia))
      .eq("id", pericia.id)
      .is("editable", true);
    if (error) {
      return { data: null, error };
    }

    return { data: data[0], error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getPericiasWithinRange = async (start: string, end: string, costumerID: string) => {
  try {
    const { data, error } = await supabase
      .from<PericiaByCostumerIDRepository>("pericias")
      .select(getPericiasWithinRangeSelect)
      .eq("id_costumer", costumerID)
      .gte("date", start)
      .lte("date", end)
      .order("date", { ascending: false });
    if (error) {
      return { data: null, error };
    }

    return { data: periciasByCostumerIDFromRepo(data), error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

//TODO include filter for not done pericias
export const getMechanicPericiasByChassisOrPlate = async (q: string) => {
  try {
    const { data, error } = await supabase
      .from<MechanicPericia>("pericias_view")
      .select("id, car: cars(brand, model, chassis_number, plate), costumer: costumers(name), date, totalHours: total_hours")
      .is("done", false)
      .is("billed", false)
      .or(`car_plate.eq.${q},car_chassis_number.eq.${q}`)
      .limit(1)
      .order("date", { ascending: false });
    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export interface MechanicPericia {
  id: string;
  car: {
    brand: string;
    model: string;
    chassis_number: string;
    plate: string;
  };
  costumer: {
    name: string;
  };
  date: string;
  totalHours: number;
  price_calculated: boolean;
  done: boolean;
  billed: boolean;
}

export interface MechanicPericiaByID {
  id: string;
  pricePerHour: number;
  costumer: {
    name: string;
  };
  date: string;
  car: {
    id: string;
    brand: string;
    model: string;
    plate: string;
    chassisNumber: string;
    insuranceName: string;
    color: string;
  };
  priceVersion: number;
  isAddtionalRepair: boolean;
  unmount: boolean;
  cofano: PericiaRepositoryCarPart;
  tetto: PericiaRepositoryCarPart;
  sportelloS: PericiaRepositoryCarPart;
  sportelloI: PericiaRepositoryCarPart;
  parafangoAs: PericiaRepositoryCarPart;
  portaAs: PericiaRepositoryCarPart;
  portaPs: PericiaRepositoryCarPart;
  parafangoPs: PericiaRepositoryCarPart;
  piantoneS: PericiaRepositoryCarPart;
  parafangoAd: PericiaRepositoryCarPart;
  portaAd: PericiaRepositoryCarPart;
  portaPd: PericiaRepositoryCarPart;
  parafangoPd: PericiaRepositoryCarPart;
  piantoneD: PericiaRepositoryCarPart;
}

export interface UpdateShouldPaintPericia {
  id: string;
  tetto?: PericiaRepositoryCarPart;
  cofano?: PericiaRepositoryCarPart;
  parafango_ad?: PericiaRepositoryCarPart;
  porta_ad?: PericiaRepositoryCarPart;
  porta_pd?: PericiaRepositoryCarPart;
  parafango_pd?: PericiaRepositoryCarPart;
  piantone_d?: PericiaRepositoryCarPart;
  piantone_s?: PericiaRepositoryCarPart;
  sportello_s?: PericiaRepositoryCarPart;
  sportello_i?: PericiaRepositoryCarPart;
  parafango_as?: PericiaRepositoryCarPart;
  porta_as?: PericiaRepositoryCarPart;
  porta_ps?: PericiaRepositoryCarPart;
  parafango_ps?: PericiaRepositoryCarPart;
}

interface CreatePericiaComment {
  id_user: string;
  id_pericia: string;
  comment: string;
}

export const createPericiaComment = async (comment: CreatePericiaComment) => {
  try {
    const { data, error } = await supabase.from("pericia_comments").insert(comment);
    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getPericiaComments = async (id: string) => {
  try {
    const { data, error } = await supabase
      .from("pericia_comments")
      .select(`comment, created_at, user: id_user (id, name, last_name, display_name)`)
      .eq("id_pericia", id);
    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

interface CreateRepairRecord {
  id_pericia: string;
  id_user: string;
}

export const createRepairRecord = async (pericia: CreateRepairRecord) => {
  try {
    const { data, error } = await supabase.from("pericia_repair_records").insert(pericia);
    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const finishRepairRecord = async (id_pericia: string) => {
  try {
    const now = new Date().toISOString();
    const { data, error } = await supabase
      .from("pericia_repair_records")
      .update({ done: true, finished_at: now, updated_at: now })
      .eq("id_pericia", id_pericia);
    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

interface FindRepairRecord {
  id_user: string;
  id_pericia: string;
}

interface RepairRecord {
  id: string;
  id_pericia: string;
  id_user: string;
  done: boolean;
}

export const findRepairRecord = async (args: FindRepairRecord) => {
  try {
    const { data, error } = await supabase
      .from<RepairRecord>("pericia_repair_records")
      .select("*")
      .eq("id_user", args.id_user)
      .eq("id_pericia", args.id_pericia);
    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getRepairRecordsByDate = async (id_user: string, startDate: Date, endDate: Date) => {
  const firstDayOfMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 1); // get the first day of the month
  const initialTime = new Date(firstDayOfMonth); // create a copy of the date object
  initialTime.setHours(0, 0, 0, 0); // set the time to the initial time of the day

  try {
    const { data, error } = await supabase
      .from("pericia_repair_records")
      .select(
        `id, created_at, finished_at, payed, payed_value, pericia: id_pericia (id, date, price_per_working_hour, costumer: id_costumer (name), car: id_car (brand, model, plate, chassis_number, insurance_name, color))`
      )
      .eq("done", true)
      .eq("id_user", id_user)
      .gte("created_at", initialTime.toISOString())
      .lt("created_at", endDate.toISOString())
      .order("finished_at", { ascending: false });
    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getRepairRecordsByMonth = async (date: Date, id_user: string) => {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1); // get the first day of the month
  const initialTime = new Date(firstDayOfMonth); // create a copy of the date object
  initialTime.setHours(0, 0, 0, 0); // set the time to the initial time of the day

  const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  nextMonth.setHours(23, 59, 59, 999);

  try {
    const { data, error } = await supabase
      .from("pericia_repair_records")
      .select(
        `id, created_at, finished_at, payed, payed_value, pericia: id_pericia (id, date, price_per_working_hour, costumer: id_costumer (name), car: id_car (brand, model, plate, chassis_number, insurance_name, color))`
      )
      .eq("done", true)
      .eq("id_user", id_user)
      .gte("created_at", initialTime.toISOString())
      .lt("created_at", nextMonth.toISOString())
      .order("finished_at", { ascending: false });
    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const findRepairRecordsByPericiaID = async (id_pericia: string) => {
  try {
    const { data, error } = await supabase.from("pericia_repair_records").select("*").eq("id_pericia", id_pericia);
    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const findRepairRecordsByUserID = async (id_user: string) => {
  try {
    const { data, error } = await supabase.from("pericia_repair_records").select("*").eq("id_user", id_user).eq("done", false);
    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

interface InsuranceData {
  id: string;
  insurance_hours: number;
}
export const saveInsuranceData = async ({ id, insurance_hours }: InsuranceData) => {
  try {
    const { data, error } = await supabase
      .from("pericias")
      .update({
        insurance_hours
      })
      .eq("id", id);
    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updatePericiaDone = async (id: string) => {
  try {
    const { data, error } = await supabase
      .from("pericias")
      .update({
        done: true
      })
      .eq("id", id);
    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};
