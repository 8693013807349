import { UseQueryResult, useQuery } from "react-query";
import { PericiaMetricV2DTO } from "../../../../entities/pericia/model/dto";
import {
  GetPericiasMetricsByCostumerRequest,
  getPericiasMetricsByCostumerRequest
} from "../../../../entities/pericia/api/get-pericias-metrics-by-costumer";
import { Costumer } from "../../../../entities/costumer";

export interface UseGetPericiasMetricsProps {
  costumer: Costumer;
  startDate: Date;
  endDate: Date;
  enabled: boolean;
}

export const queryKeys = {
  getPericiasMetrics: (props: GetPericiasMetricsByCostumerRequest) => ["getPericiasMetrics", props]
};

export function useGetPericiasMetricsByCostumer(props: UseGetPericiasMetricsProps): UseQueryResult<PericiaMetricV2DTO[]> {
  const args = {
    id_costumer: props.costumer.id,
    start_date: props.startDate.toISOString().split("T")[0],
    end_date: props.endDate.toISOString().split("T")[0]
  };

  return useQuery(queryKeys.getPericiasMetrics(args), () => getPericiasMetricsByCostumerRequest(args), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: props.enabled && Boolean(props.startDate) && Boolean(props.endDate) && Boolean(props.costumer.id)
  });
}
