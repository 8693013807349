import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import TimeToLeaveSharpIcon from "@mui/icons-material/TimeToLeaveSharp";
import LocationOnSharpIcon from "@mui/icons-material/LocationOnSharp";

import { Costumer } from "../model";
import { useNavigate } from "react-router-dom";

interface Props {
  costumers: Costumer[];
}

export const CostumersList: React.FC<Props> = ({ costumers }) => {
  const navigate = useNavigate();

  return (
    <>
      {costumers.map(({ id, name, address }) => {
        const labelId = `checkbox-list-label-${id}`;

        return (
          <ListItem key={id} divider>
            <ListItemButton
              role={undefined}
              dense
              onClick={() => navigate(`/costumers/${id}`)}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <ListItemText
                id={labelId}
                primary={`${name}`}
                sx={{ flex: "1", textAlign: "left" }}
              />
            </ListItemButton>
            <IconButton>
              <a href={address} target="_blank" rel="noreferrer">
                <LocationOnSharpIcon />
              </a>
            </IconButton>
            <IconButton>
              <TimeToLeaveSharpIcon />
            </IconButton>
          </ListItem>
        );
      })}
    </>
  );
};
