export const PERICIAS_CREATED_ITA = `create`;
export const PERICIAS_FINISHED_ITA = `finalizzate`;
export const PERICIAS_BILLED_ITA = `fatturate`;
export const PERICIAS_NOT_BILLED_ITA = `non fatturate`;
export const PERICIAS_NOT_DONE_ITA = `non finalizzate`;

export const TOTAL_CREATED_ITA = `totale create`;
export const TOTAL_FINISHED_ITA = `totale finalizzate`;
export const TOTAL_BILLED_ITA = `totale fatturate`;

export const AVERAGE_CREATED_ITA = `media create`;
export const AVERAGE_FINISHED_ITA = `media finalizzate`;
export const AVERAGE_BILLED_ITA = `media fatturate `;

export const DAY_ITA = `giorno`;
export const WEEK_ITA = `settimana`;
export const MONTH_ITA = `mese`;
export const YEAR_ITA = `anno`;

export const TIME_ITA = `tempo`;

export const PERICIA_ITA = `perizia`;

export const CHART_CREATED_FINISHED_ITA = `Perizie Create vs Finalizzate`;
export const CHART_CREATED_ITA = `Perizie Create`;
export const CHART_FINISHED_ITA = `Perizie Finalizzate`;
export const CHART_BILLED_ITA = `Perizie Fatturate`;
export const CHART_NOT_BILLED_ITA = `Perizie Non Fatturate`;
export const CHART_NOT_DONE_ITA = `Perizie Non Finalizzate`;
