import { UseQueryResult, useQuery } from "react-query";
import { createNotificationReq } from "../../../../entities/notification/api/create-notification";

export interface CreateNotificationProps {
  message: string;
  id_pericia?: string;
}

export const queryKeys = {
  createNotification: (props: CreateNotificationProps) => ["createNotification", props]
};

export function useCreateNotification(props: CreateNotificationProps): UseQueryResult<boolean, Error> {
  return useQuery(queryKeys.createNotification(props), () => createNotificationReq(props), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: props.message.length > 0
  });
}
