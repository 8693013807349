import supabase from "../shared/database/supabase";

interface TechnicianPayment {
  id: string;
  amount: number;
  id_pericia: string;
  id_user: string;
  payed: boolean;
  payed_at: Date;
  created_at: Date;
  updated_at: Date;
}

export const createTechnicianPayment = async (args: any) => {
  try {
    const { data: existingRow, error: errorExistingRow } = await supabase
      .from("technician_payments")
      .select("*")
      .eq("id_pericia", args.id_pericia);

    if (errorExistingRow) {
      return { data: null, error: errorExistingRow };
    }

    //update value if payment already exists
    if (existingRow.length > 0) {
      const { data, error } = await supabase
        .from<TechnicianPayment>("technician_payments")
        .update(args, { returning: "representation" })
        .eq("id", existingRow[0].id);

      if (error) {
        return { data: null, error };
      }

      return { data, error: null };
    }

    const { data, error } = await supabase
      .from<TechnicianPayment>("technician_payments")
      .upsert(args, { returning: "representation" })
      .eq("id_pericia", args.id_pericia);

    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (error) {
    throw error;
  }
};

interface getTechnicianPaymentsByPericiaIdResponse {
  id: string;
  amount: number;
  id_pericia: string;
  payed: boolean;
}

export const getTechnicianPaymentsByPericiaId = async (id_pericia: string) => {
  try {
    const { data, error } = await supabase
      .from<getTechnicianPaymentsByPericiaIdResponse>("technician_payments")
      .select("*")
      .eq("id_pericia", id_pericia);

    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (error) {
    throw error;
  }
};

interface UpdateTechnicianPaymentPayedStatusArgs {
  id_pericia: string;
  payed: boolean;
}
export const updateTechnicianPaymentPayedStatus = async ({ id_pericia, payed }: UpdateTechnicianPaymentPayedStatusArgs) => {
  try {
    const { data, error } = await supabase
      .from("technician_payments")
      .update({ payed, payed_at: new Date() }, { count: "exact" })
      .eq("id_pericia", id_pericia);

    if (error) {
      return { data: null, error };
    }

    if (data.length === 0) {
      return { data: null, error: "Pagamento não encontrado!" };
    }

    return { data, error: null };
  } catch (error) {
    throw error;
  }
};
