import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { COSTUMER_LANGUAGES } from "../model";
import { ReactNode } from "react";

interface CostumerLanguagesSelectProps {
  language: string;
  handleLanguageChange: (
    event: SelectChangeEvent<string>,
    child: ReactNode
  ) => void;
}

export const CostumerLanguagesSelect: React.FC<CostumerLanguagesSelectProps> = (
  props
) => {
  const { language, handleLanguageChange } = props;

  return (
    <FormControl fullWidth>
      <InputLabel id="costumer-language-select-label">Idioma</InputLabel>
      <Select
        labelId="costumer-language-select-label"
        id="costumer-language-select"
        value={mapLanguageToLabel(language).value}
        label="Idioma"
        onChange={handleLanguageChange}
      >
        {COSTUMER_LANGUAGES.map((lang) => (
          <MenuItem value={lang.value} key={`menu-item-${lang.value}`}>
            {lang.icon} - {lang.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

function mapLanguageToLabel(language: string) {
  const lang = COSTUMER_LANGUAGES.find((lang) => lang.value === language);
  return lang ? lang : COSTUMER_LANGUAGES[0];
}
