export interface PericiaMetricDTO {
  date: string;
  created: number;
  finished: number;
  billed: number;
}

export interface PericiaMetricV2DTO {
  date: string;
  created: number;
  finished: number;
  billed: number;
  notBilled: number;
  notDone: number;
}

export interface PericiaMetricByCostumersDTO {
  date: string;
  created: number;
  finished: number;
  billed: number;
  notBilled: number;
  notDone: number;
  created2: number;
  finished2: number;
  billed2: number;
  notBilled2: number;
  notDone2: number;
}

export const mergePericiaMetricsByCostumers = (
  costumer1: PericiaMetricV2DTO[],
  costumer2: PericiaMetricV2DTO[]
): PericiaMetricByCostumersDTO[] => {
  const mergedMap: { [key: string]: PericiaMetricByCostumersDTO } = {};

  costumer1.forEach((metric) => {
    mergedMap[metric.date] = {
      date: metric.date,
      created: metric.created,
      finished: metric.finished,
      billed: metric.billed,
      notBilled: metric.notBilled,
      notDone: metric.notDone,
      created2: 0,
      finished2: 0,
      billed2: 0,
      notBilled2: 0,
      notDone2: 0
    };
  });

  costumer2.forEach((metric) => {
    if (mergedMap[metric.date]) {
      mergedMap[metric.date].created2 = metric.created;
      mergedMap[metric.date].finished2 = metric.finished;
      mergedMap[metric.date].billed2 = metric.billed;
      mergedMap[metric.date].notBilled2 = metric.notBilled;
      mergedMap[metric.date].notDone2 = metric.notDone;
    } else {
      mergedMap[metric.date] = {
        date: metric.date,
        created: 0,
        finished: 0,
        billed: 0,
        notBilled: 0,
        notDone: 0,
        created2: metric.created,
        finished2: metric.finished,
        billed2: metric.billed,
        notBilled2: metric.notBilled,
        notDone2: metric.notDone
      };
    }
  });

  return Object.values(mergedMap);
};
