import { UseQueryResult, useQuery } from "react-query";
import { Pericia } from "../../../../entities/pericia";
import { CreateInvoiceRequest, CreateInvoiceResponse } from "../../../../entities/invoice/api/create-invoice";
import { CustomError } from "../../../../shared/error/error";

export type CreateInvoiceReq = (req: CreateInvoiceRequest) => Promise<CreateInvoiceResponse>;

export interface UseCreateInvoiceRequest {
  costumerId: string;
  totalPericias: number;
  totalPrice: number;
  startDate: Date;
  endDate: Date;
  agentComission: number;
  pericias: Pericia[];
}

export const queryKeys = {
  createInvoice: (req: UseCreateInvoiceRequest) => ["createInvoice", req]
};

export function useCreateInvoice(
  createInvoice: CreateInvoiceReq,
  req: UseCreateInvoiceRequest
): UseQueryResult<CreateInvoiceResponse, CustomError> {
  return useQuery(
    queryKeys.createInvoice(req),
    () =>
      createInvoice({
        agent_comission: req.agentComission,
        costumer_id: req.costumerId,
        total_pericias: req.totalPericias,
        total_price: req.totalPrice,
        start_date: req.startDate.toISOString(),
        end_date: req.endDate.toISOString(),
        pericias: req.pericias.map((pericia) => ({
          id: pericia.id,
          car_id: pericia.car.id
        }))
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled:
        req.costumerId !== "" &&
        req.totalPericias > 0 &&
        req.totalPericias !== 0 &&
        req.totalPrice > 0 &&
        req.pericias.length > 0 &&
        req.pericias.length === req.totalPericias
    }
  );
}
