import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { ChangeEvent } from "react";
import { PaperCard } from "../../../../shared/ui/paper-card/paper-card.component";

interface PendingPericiasDisplayFieldsTogglerProps {
  props: {
    selectedFieldsToDisplay: SelectedFieldsToDisplay;
    toggleDisplay(e: ChangeEvent<HTMLInputElement>): void;
  };
}

interface SelectedFieldsToDisplay {
  displayPlate: boolean;
  displayDate: boolean;
  displayPrice: boolean;
  displayUserName: boolean;
  displayTotalHours: boolean;
}

export const PendingPericiasDisplayFieldsToggler: React.FC<PendingPericiasDisplayFieldsTogglerProps> = ({ props }) => {
  const { selectedFieldsToDisplay, toggleDisplay } = props;
  const fields = [
    { name: "displayPlate", label: "Placa", checked: selectedFieldsToDisplay.displayPlate },
    { name: "displayDate", label: "Data", checked: selectedFieldsToDisplay.displayDate },
    { name: "displayUserName", label: "Técnico", checked: selectedFieldsToDisplay.displayUserName },
    { name: "displayPrice", label: "Preço", checked: selectedFieldsToDisplay.displayPrice },
    { name: "displayTotalHours", label: "Horas", checked: selectedFieldsToDisplay.displayTotalHours }
  ];

  return (
    <PaperCard title="Campos visíveis">
      <FormGroup
        row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: "1rem"
        }}
      >
        {fields.map((field) => {
          return (
            <Grid item xs={3} sm={3} key={field.name}>
              <FormControlLabel
                control={<Checkbox name={field.name} size="small" onChange={toggleDisplay} checked={field.checked} />}
                label={field.label}
              />
            </Grid>
          );
        })}
      </FormGroup>
    </PaperCard>
  );
};
