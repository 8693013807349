import supabase from "../../../shared/database/supabase";
import { Pericia } from "../model";

export interface ListPericiasNotBilledRequest {
  start: string;
  end: string;
  costumer_id: string;
}

export const ListPericiasNotBilled = async ({ start, end, costumer_id }: ListPericiasNotBilledRequest): Promise<Pericia[]> => {
  try {
    const { data, error } = await supabase.functions.invoke("list-pericias-not-billed", {
      body: JSON.stringify({ start, end, costumer_id })
    });

    if (error) throw error;
    if (!data || data.length === 0) {
      return [];
    }

    return data.map((pericia: Pericia) => ({
      ...pericia,
      createdAt: new Date(pericia.createdAt),
      updatedAt: new Date(pericia.updatedAt),
      date: new Date(pericia.date)
    }));
  } catch (err) {
    console.log(err);
    throw err;
  }
};
