import { updateRepairRecordsAmount } from "../../entities/repair-record";
import supabase from "../../shared/database/supabase";
import {
  createPericiaComment,
  createRepairRecord,
  findRepairRecord,
  findRepairRecordsByUserID,
  finishRepairRecord,
  getRepairRecordsByDate,
  getRepairRecordsByMonth,
  updatePericiaDone
} from "../../storage/supabase/pericia/pericia.storage";
import { getPericiasSelect } from "../../storage/supabase/queries/pericia/pericia.query";
import { updateRepairRecordPayed } from "../../storage/supabase/repair-records/repair-records.storage";
import { periciaWithCarAndCostumerFromRepo } from "../../utils/mappers/mappers-from-repository";
import { setIsPriceCalculated } from "./price-calculated/price-calculated.service";

interface ListPericiaArgs {
  term: string;
  done: boolean;
  billed: boolean;
  priceCalculated: boolean;
  page: number;
}

interface QueryBuilderArgs {
  byTerm: boolean;
  byStatus: boolean;
  byStatusAndTerm: boolean;
  filterByTerm: string;
  filterByStatus: {
    billed: string;
    priceCalculated: string;
    done: string;
  };
  start: number;
  end: number;
}

export const periciaService = {
  listPericias: async (args: ListPericiaArgs) => {
    const { start, end } = getPageLimits(args.page);
    const { term: filterByTerm, billed, priceCalculated, done } = args;

    const qBuilder = {
      byTerm: !!filterByTerm && !done && !billed && !priceCalculated,
      byStatus: !filterByTerm && (done || billed || priceCalculated),
      byStatusAndTerm: !!filterByTerm && (done || billed || priceCalculated),
      filterByTerm,
      filterByStatus: getFilterByStatus(billed, priceCalculated, done),
      start,
      end
    };

    try {
      const { data, error } = await getPericiasQueryBuilder(qBuilder);

      if (error) {
        return { data: null, error };
      }

      return {
        data: data.map((d) => {
          return periciaWithCarAndCostumerFromRepo(d);
        }),
        error: null
      };
    } catch (err) {
      console.log(err);
      throw err;
    }
  },

  updateShouldPaintPericia: async (id: string, carPartsUpdated: string[]) => {
    const id_user = supabase.auth.user()?.id || "";
    if (!id_user) {
      return { data: null, error: "Usuario nao autenticado" };
    }

    const carPartsNames = carPartsUpdated.join(", ");
    const comment = `atualizou a pintura das partes : ${carPartsNames}`;

    const res2 = await periciaService.createPericiaComment(id, comment);
    if (res2.error) {
      console.log(res2.error);
      periciaService.createPericiaComment(id, comment);
    }

    return { data: null, error: null };
  },

  createPericiaComment: async (id_pericia: string, comment: string) => {
    const id_user = supabase.auth.user()?.id || "";
    if (!id_user) {
      return { data: null, error: "Usuario nao autenticado" };
    }

    const res = await createPericiaComment({
      id_user,
      id_pericia,
      comment
    });
    if (res.error) {
      console.log(res.error);
      return { data: null, error: "Erro ao criar comentario" };
    }

    return { data: null, error: null };
  },

  createRepairRecord: async (id_pericia: string) => {
    const id_user = supabase.auth.user()?.id || "";
    if (!id_user) {
      return { data: null, error: "Usuario nao autenticado" };
    }

    try {
      const repairReacord = await periciaService.findRepairRecord(id_pericia);
      if (repairReacord.error) {
        return { data: null, error: "Erro ao criar registro de reparo" };
      }

      if (repairReacord.data) {
        return { data: null, error: "Registro de reparo ja existe!" };
      }

      const res = await createRepairRecord({
        id_pericia,
        id_user
      });
      if (res.error) {
        console.log(res.error);
        return { data: null, error: "Erro ao criar registro de reparo" };
      }

      const res2 = await periciaService.createPericiaComment(id_pericia, "iniciou o reparo");
      if (res2.error) {
        console.log(res2.error);
      }

      const res3 = await updateRepairRecordPayed(id_pericia);

      return { data: null, error: null };
    } catch (err) {
      console.log(err);
      return { data: null, error: err };
    }
  },

  findRepairRecord: async (id_pericia: string) => {
    const id_user = supabase.auth.user()?.id || "";
    if (!id_user) {
      return { data: null, error: "Usuario nao autenticado" };
    }

    const res = await findRepairRecord({
      id_pericia,
      id_user
    });
    if (res.error) {
      console.log(res.error);
      return { data: null, error: "Erro ao buscar registro de reparo" };
    }

    if (!res.data) {
      return { data: null, error: null };
    }

    if (res.data.length === 0) {
      return { data: null, error: null };
    }

    return { data: res.data, error: null };
  },

  finishRepairRecord: async (id_pericia: string) => {
    const id_user = supabase.auth.user()?.id || "";
    if (!id_user) {
      return { data: null, error: "Usuario nao autenticado" };
    }

    try {
      const res = await finishRepairRecord(id_pericia);
      if (res.error) {
        return { data: null, error: "Erro ao finalizar registro de reparo" };
      }

      if (!res.data) {
        return { data: null, error: "Registro de reparo nao encontrado" };
      }

      if (res.data.length === 0) {
        return { data: null, error: "Registro de reparo nao encontrado" };
      }

      const res2 = await periciaService.createPericiaComment(id_pericia, "finalizou o reparo");
      if (res2.error) {
        console.log(res2.error);
      }

      const res3 = await updatePericiaDone(id_pericia);
      if (res3.error) {
        console.log(res3.error);
      }

      const res4 = await updateRepairRecordsAmount(id_pericia);
      return { data: null, error: null };
    } catch (err) {
      return { data: null, error: "Erro ao finalizar registro de reparo" };
    }
  },

  getRepairRecordsByMonth: async (date: Date) => {
    const id_user = supabase.auth.user()?.id || "";
    if (!id_user) {
      return { data: [], error: "Usuario nao autenticado" };
    }

    const resp = await getRepairRecordsByMonth(date, id_user);
    if (resp.error) {
      return { data: [], error: "Erro ao buscar registros de reparo" };
    }

    if (resp.data.length === 0) {
      return { data: [], error: null };
    }

    if (!resp.data) {
      return { data: [], error: null };
    }

    return { data: resp.data, error: null };
  },

  getRepairRecordsByDate: async ({ start, end }: { start: Date; end: Date }) => {
    const id_user = supabase.auth.user()?.id || "";
    if (!id_user) {
      return { data: [], error: "Usuario nao autenticado" };
    }

    const resp = await getRepairRecordsByDate(id_user, start, end);
    if (resp.error) {
      return { data: [], error: "Erro ao buscar registros de reparo" };
    }

    if (resp.data.length === 0) {
      return { data: [], error: null };
    }

    if (!resp.data) {
      return { data: [], error: null };
    }

    return { data: resp.data, error: null };
  },

  findRepairRecordsByUserID: async () => {
    const id_user = supabase.auth.user()?.id || "";
    if (!id_user) {
      return { data: [], error: "Usuario nao autenticado" };
    }

    const resp = await findRepairRecordsByUserID(id_user);
    if (resp.error) {
      return { data: [], error: "Erro ao buscar registros de reparo" };
    }

    if (resp.data.length === 0) {
      return { data: [], error: null };
    }

    if (!resp.data) {
      return { data: [], error: null };
    }

    return { data: resp.data, error: null };
  },

  SetIsPriceCalculated: setIsPriceCalculated
};

const getPageLimits = (page: number) => {
  const start = (page - 1) * 10;
  const end = page * 10 - 1;
  return { start, end };
};

const getFilterByStatus = (billed: boolean, priceCalculated: boolean, done: boolean) => {
  const filterByStatus = {
    billed: "(true, false)",
    priceCalculated: "(true, false)",
    done: "(true, false)"
  };
  if (billed) {
    filterByStatus.billed = "(true)";
  }
  if (priceCalculated) {
    filterByStatus.priceCalculated = "(true)";
  }
  if (done) {
    filterByStatus.done = "(true)";
  }
  return filterByStatus;
};

const getPericiasQueryBuilder = async (query: QueryBuilderArgs) => {
  const { filterByTerm, filterByStatus, byStatus, byStatusAndTerm, byTerm, start, end } = query;

  if (byTerm) {
    return supabase
      .from("pericias_view")
      .select(getPericiasSelect)
      .or(
        `costumer_name.ilike.*${filterByTerm}*,car_brand.ilike.*${filterByTerm}*,car_model.ilike.*${filterByTerm}*,car_plate.ilike.*${filterByTerm}*,car_chassis_number.ilike.*${filterByTerm}*`
      )
      .range(start, end)
      .order("date", { ascending: false });
  }

  if (byStatus) {
    return supabase
      .from("pericias_view")
      .select(getPericiasSelect)
      .filter("billed", "in", filterByStatus.billed)
      .filter("price_calculated", "in", filterByStatus.priceCalculated)
      .filter("done", "in", filterByStatus.done)
      .range(start, end)
      .order("date", { ascending: false });
  }

  if (byStatusAndTerm) {
    return supabase
      .from("pericias_view")
      .select(getPericiasSelect)
      .filter("billed", "in", filterByStatus.billed)
      .filter("price_calculated", "in", filterByStatus.priceCalculated)
      .filter("done", "in", filterByStatus.done)
      .or(
        `costumer_name.ilike.*${filterByTerm}*,car_brand.ilike.*${filterByTerm}*,car_model.ilike.*${filterByTerm}*,car_plate.ilike.*${filterByTerm}*,car_chassis_number.ilike.*${filterByTerm}*`
      )
      .range(start, end)
      .order("date", { ascending: false });
  }

  return supabase.from("pericias").select(getPericiasSelect).range(start, end).order("date", { ascending: false });
};
