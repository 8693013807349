import { UseQueryResult, useQuery } from "react-query";
import { FinishedRepairRecord } from "../../model/finished-repair-record";
import {
  UpdateFinishedRepairRecordRes,
  updateFinishedRepairRecordRequest
} from "../../../../entities/repair-record/api/update-finished-repair-record";

export interface UseUpdateFinishedRepairRecordProps extends FinishedRepairRecord {
  enabled: boolean;
}

export const queryKeys = {
  updateFinishedRepairRecord: (props: UseUpdateFinishedRepairRecordProps) => ["updateFinishedRepairRecord", props]
};

export function useUpdateFinishedRepairRecord(
  record: UseUpdateFinishedRepairRecordProps
): UseQueryResult<UpdateFinishedRepairRecordRes, Error> {
  const { id, finished_at, payed, payed_at, payed_value, enabled } = record;

  return useQuery(
    queryKeys.updateFinishedRepairRecord(record),
    () =>
      updateFinishedRepairRecordRequest({
        id,
        finished_at,
        payed,
        payed_at,
        payed_value
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: id !== "" && finished_at !== "" && enabled,
      onSuccess(data) {
        console.log("updateFinishedRepairRecord success", data);
        return { data: true };
      },
      onError(err) {
        console.log("updateFinishedRepairRecord error", err);
        return { error: err };
      },
      select: (data) => {
        console.log("updateFinishedRepairRecord select", data);
        return data;
      }
    }
  );
}
