import supabase from "../../../shared/database/supabase";
import { Car } from "../model";

export interface CarExistsRequest {
  car: Omit<Car, "id" | "createdAt" | "updatedAt">;
}

export interface CarExistsResponse {
  exists: boolean;
  id: string;
}

export const carExists = async (
  req: CarExistsRequest
): Promise<CarExistsResponse | Error> => {
  const { car } = req;

  try {
    const { data, error } = await supabase.functions.invoke("car-exists", {
      body: JSON.stringify({ car }),
    });
    if (error) throw error;

    if (data.error) throw data.error;

    return data;
  } catch (err) {
    console.log("error fetching car exists endpoint:", err);
    throw err;
  }
};
