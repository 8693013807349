export class CustomError extends Error {
  code: string;
  message: string;
  toast_message: string;
  http_status_code?: number;

  constructor(args?: CustomErrorConstructor) {
    super(args?.message || "Generic error");
    this.name = "CustomError";
    this.code = args?.code || "GENERIC_ERROR";
    this.message = args?.message || "Generic error";
    this.toast_message = args?.toast_message || "Algo deu errado...";
    this.http_status_code = args?.http_status_code;
  }
}

export interface CustomErrorConstructor {
  code?: string;
  message?: string;
  toast_message?: string;
  http_status_code?: number;
}
