import { List, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { Costumer } from "../../entities/costumer";
import {
  ListCostumerPericiasFilters,
  ListCostumerPericiasProps,
  useListPericiasByCostumerId
} from "../../features/pericia/list-pericias-by-costumer-id/api/useListPericiasByCostumerId";
import { useCostumerPericiasListPDFCreator } from "../../features/costumer/create-costumer-pericias-list-pdf/api/useCostumerPericiasListPDFCreator";
import { Pericia } from "../../entities/pericia";
import { CostumerPericiasList } from "../../features/pericia/list-pericias-by-costumer-id/ui/costumer-pericias-list.component";
import { CostumerListPericiasHeader } from "../../features/pericia/list-pericias-by-costumer-id/ui/costumer-pericias-list-header.component";
import FlexContainer from "../../shared/ui/container/flex-container.component";

const INITIAL_FILTER: ListCostumerPericiasFilters = {
  billed: false,
  isPriceCalculated: false,
  done: false,
  notDone: false
};

const LIST_COSTUMER_PERICIAS_PROPS_INITIAL_STATE: ListCostumerPericiasProps = {
  costumerId: "",
  start: new Date(),
  end: new Date(),
  filters: INITIAL_FILTER
};

const ListCostumerPericiasPage = () => {
  const [filter, setFilter] = useState<ListCostumerPericiasFilters>(INITIAL_FILTER);
  const [start, setStartDate] = useState<Date>(new Date());
  const [end, setEndDate] = useState<Date>(new Date());
  const [costumer, setCostumer] = useState<Costumer>({} as Costumer);
  const [listCostumerPericiasProps, setListCostumerPericiasProps] = useState<ListCostumerPericiasProps>(
    LIST_COSTUMER_PERICIAS_PROPS_INITIAL_STATE
  );
  const { data: pericias, error: periciasError, isLoading: periciasIsLoading } = useListPericiasByCostumerId(listCostumerPericiasProps);
  const { createPDF } = useCostumerPericiasListPDFCreator();

  if (periciasError) {
    toast.error("Erro ao buscar pericias");
  }

  const handleSetListCostumerPericiasProps = () => {
    setListCostumerPericiasProps({
      costumerId: costumer.id,
      start: start,
      end: end,
      filters: filter
    });
  };

  const handleCreatePDF = () => {
    if (!pericias) {
      toast.error("Nenhuma pericia encontrada");
      return;
    }

    createPDF({
      costumerName: pericias[0].costumer.name as string,
      pericias: pericias as Pericia[],
      start,
      end
    });
  };

  const handleDateChange = (start: Date, end: Date) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.checked
    });
  };

  useEffect(() => {
    if (pericias?.length === 0) {
      toast.info("Nenhuma pericia encontrada");
    }
  }, [pericias]);

  return (
    <FlexContainer>
      <Typography component="h1" variant="h4">
        Perícias por cliente
      </Typography>
      <Typography component="h1" variant="subtitle1">
        Permite buscar perícias por cliente e período
      </Typography>
      <CostumerListPericiasHeader
        props={{
          costumer,
          updateCostumer: setCostumer,
          start,
          end,
          handleDateChange,
          handleSetListCostumerPericiasProps,
          handleFilterChange,
          isLoading: periciasIsLoading
        }}
      />
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper"
        }}
      >
        {pericias && pericias.length > 0 && (
          <>
            <CostumerPericiasList pericias={pericias as Pericia[]} />
            <LoadingButton variant="contained" sx={{ mb: 1, mt: 5 }} fullWidth onClick={handleCreatePDF}>
              GERAR PDF
            </LoadingButton>
          </>
        )}
      </List>
      <ToastContainer />
    </FlexContainer>
  );
};

export default ListCostumerPericiasPage;
