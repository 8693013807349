import jsPDF from "jspdf";
import { formatCurrency } from "../../../utils/helpers/numbers";
import { RepairRecord } from "../../../entities/repair-record";
import supabase from "../../../shared/database/supabase";

interface GenerateRepairRecordPdfDTO {
  repairRecords: RepairRecord[];
  start: Date;
  end: Date;
  user: {
    name: string;
    last_name: string;
  };
  totalPrice: number;
}

export const createTechnicianRepairRecordsPdfService = {
  async execute(args: GenerateRepairRecordPdfDTO) {
    const user = supabase.auth.user()?.user_metadata || "";
    if (!user) {
      return { error: "Usuário não autenticado" };
    }

    const userIdentification = user.name || user.id || "Usuário não identificado";

    const { repairRecords } = args;
    const { start } = args;
    const { end } = args;
    const { user: technician } = args;
    const today = new Date();
    const totalRecords = repairRecords.length;

    const pdf = new jsPDF("p", "mm", "a4");

    pdf.setFontSize(12);
    pdf.setFont("helvetica", "bold");

    const logoImg = require("../../../assets/nuovauto.png");
    pdf.addImage(logoImg, "PNG", 16, 12, 90, 24);

    pdf.text(`Técnico: ${technician.name} ${technician.last_name}`, 110, 18);
    pdf.text(`Período: ${start.toLocaleDateString("pt-BR")} - ${end.toLocaleDateString("pt-BR")} `, 110, 24);
    pdf.text(`Total de carros reparados: ${totalRecords}`, 110, 30);
    pdf.text(`LISTA DE CARROS REPARADOS`, 16, 56);
    pdf.text(`Emitido em: ${today.toLocaleString("pt-BR")}`, 16, 62);
    pdf.text(`Por: ${userIdentification}`, 16, 68);

    const itemsPerPage = repairRecordsPerPage(repairRecords, 28);

    pdf.text(`Cliente`, 25, 84);
    pdf.text(`Carro`, 70, 84);
    pdf.text(`Placa`, 115, 84);
    pdf.text(`Data`, 150, 84);
    pdf.text(`Valor`, 180, 84);

    pdf.setFont("helvetica", "normal");

    itemsPerPage.forEach((items, page) => {
      let finalY = 0;
      items.forEach((item, index) => {
        const yOffset = page === 0 ? 90 : 40;
        const row = index + 1;
        const y = row * 6 + yOffset;

        const { pericia, payedValue, createdAt } = item;
        const { name } = pericia.costumer;
        const { brand, model, plate } = pericia.car;

        pdf.text(`${name.slice(0, 20)}`, 16, y);
        pdf.text(`${brand} ${model}`, 70, y);
        pdf.text(plate, 115, y);
        pdf.text(`${new Date(createdAt).toLocaleDateString("pt-BR")}`, 150, y);
        pdf.text(`${formatCurrency(payedValue)}`, 180, y);

        finalY = y;
      });

      if (itemsPerPage.indexOf(items) !== itemsPerPage.length - 1) {
        pdf.addPage();
      } else {
        pdf.setFont("helvetica", "bold");
        pdf.text(`Total: ${formatCurrency(args.totalPrice)}`, 150, finalY + 10);

        pdf.setTextColor(128);
        pdf.setFont("helvetica", "normal");

        pdf.text(`NUOVAUTO`, 16, 270);
        pdf.text(`Via G. Canevascini 15`, 16, 274);
        pdf.text(`6600 Locarno`, 16, 278);

        pdf.text(`Tel.: 0767464412`, 75, 270);

        pdf.text(`Responsabile: Keil Roger`, 75, 278);

        pdf.text(`PostFinance`, 150, 270);
        pdf.text(`CH7309000000155473924`, 150, 274);
        pdf.text(`IVA: CHE-162.607.499`, 150, 278);

        pdf.setTextColor(0, 0, 255);
        pdf.textWithLink(`nuovauto@bluewin.ch`, 75, 274, {});
      }
    });

    pdf.save(`reparos-${userIdentification}-${today.toLocaleDateString("pt-BR")}.pdf`);

    return { error: null };
  }
};

const repairRecordsPerPage = (items: RepairRecord[], pageLimit: number) => {
  var results = [];

  while (items.length) {
    results.push(items.splice(0, pageLimit));
  }

  return results;
};
