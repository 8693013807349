import { BottomNavigation, BottomNavigationAction, Grid, ListItem, ListItemButton, ListItemText, TextField } from "@mui/material";
import FlexContainer from "../../../shared/ui/container/flex-container.component";
import { formatCurrency } from "../../../utils/helpers/numbers";
import { RepairRecord } from "../../../entities/repair-record";
import { useState } from "react";
import { Pericia } from "../../../entities/pericia";
import UpdatePericiaDialog from "../../../features/pericia/update-pericia/ui/update-pericia-dialog.component";
import { PaperCard } from "../../../shared/ui/paper-card/paper-card.component";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

interface RepairRecordsListComponentProps {
  props: Props;
}

interface Props {
  repairRecords: RepairRecord[];
  totalPrice: number;
  handlePericiaUpdated: () => void;
}

const RepairRecordsAdminList: React.FC<RepairRecordsListComponentProps> = ({ props }) => {
  const { repairRecords, totalPrice, handlePericiaUpdated } = props;
  const [pericia, setPericia] = useState<Pericia | null>(null);
  const [filterBy, setFilterBy] = useState<"price" | "date">("date");
  const [value, setValue] = useState(0);
  const [updatePericiaDialogOpen, setUpdatePericiaDialogOpen] = useState(false);

  const rowColor = (record: RepairRecord) => {
    const isNotPriced = record.pericia.cashPayedAmount === 0 && record.payedValue === 0;
    if (isNotPriced) return "#EF5350";

    return record.pericia.cashPayedAmount && record.pericia.cashPayedAmount > 0 ? "green" : "text.primary";
  };

  const handleFilterChange = (newValue: number) => {
    if (newValue === 0) {
      setFilterBy("date");
      setValue(0);
    } else {
      setFilterBy("price");
      setValue(1);
    }
  };

  const sortRecords = (r: RepairRecord[], filterBy: string) => {
    if (filterBy === "price") {
      return r.sort((a, b) => {
        const amountA = a.pericia.cashPayedAmount > 0 ? a.pericia.cashPayedAmount : a.payedValue;
        const amountB = b.pericia.cashPayedAmount > 0 ? b.pericia.cashPayedAmount : b.payedValue;
        return amountA - amountB;
      });
    }

    return r.sort((a, b) => {
      const aTime = a.finishedAt ? a.finishedAt.getTime() : Number.POSITIVE_INFINITY;
      const bTime = b.finishedAt ? b.finishedAt.getTime() : Number.POSITIVE_INFINITY;
      return aTime - bTime;
    });
  };

  const handlePericiaClick = (pericia: Pericia) => {
    setPericia(pericia);
    handleToggleDialog();
  };

  const handleToggleDialog = () => {
    setUpdatePericiaDialogOpen(!updatePericiaDialogOpen);
  };

  return (
    <FlexContainer>
      <PaperCard title="Reparos Finalizados">
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            handleFilterChange(newValue);
          }}
          sx={{ marginBottom: "2rem" }}
        >
          <BottomNavigationAction label="Ordenar por data" icon={<CalendarTodayIcon />} />
          <BottomNavigationAction label="Ordenar por preço " icon={<MonetizationOnIcon />} />
        </BottomNavigation>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={12}>
            <ListItem
              id="record-list-header"
              divider
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
                textAlign: "center"
              }}
            >
              {["CLIENTE", "CARRO", "PLACA", "CHASSI", "DATA", "VALOR"].map((item, idx) => (
                <ListItemText
                  key={`record-list-header-${idx}`}
                  id={`record-list-header-${idx}`}
                  primary={item}
                  sx={{ flex: "1", fontWeight: "bold" }}
                />
              ))}
            </ListItem>
          </Grid>
          <Grid item xs={12} sm={12} mb={6}>
            {sortRecords(repairRecords, filterBy).map((record) => (
              <ListItem key={record.id} divider>
                <ListItemButton
                  key={record.id}
                  onClick={handlePericiaClick.bind(this, record.pericia)}
                  role={undefined}
                  dense
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    textAlign: "center",
                    color: rowColor(record)
                  }}
                >
                  <ListItemText id={record.id} primary={`${record.pericia.costumer.name}`} sx={{ flex: "1" }} />
                  <ListItemText id={record.id} primary={`${record.pericia.car.model}`} sx={{ flex: "1", textAlign: "center" }} />
                  <ListItemText id={record.id} primary={`${record.pericia.car.plate}`} sx={{ flex: "1" }} />
                  <ListItemText id={record.id} primary={`${record.pericia.car.chassisNumber}`} sx={{ flex: "1" }} />
                  <ListItemText
                    id={record.id}
                    primary={record.finishedAt && record.finishedAt.toLocaleDateString("pt-BR")}
                    sx={{ flex: "1" }}
                  />
                  <ListItemText
                    id={record.id}
                    primary={formatCurrency(record.pericia.cashPayedAmount > 0 ? record.pericia.cashPayedAmount : record.payedValue)}
                    sx={{ flex: "1" }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
            {pericia && (
              <UpdatePericiaDialog
                pericia={pericia}
                onPericiaUpdated={handlePericiaUpdated}
                open={updatePericiaDialogOpen}
                handleToggle={handleToggleDialog}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} container justifyContent="flex-end">
            <TextField label="Total" variant="standard" disabled value={formatCurrency(totalPrice)} />
          </Grid>
        </Grid>
      </PaperCard>
    </FlexContainer>
  );
};

export default RepairRecordsAdminList;
