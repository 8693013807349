import { createTechnicianPayment } from "../../repositories/payment-repository";
import supabase from "../../shared/database/supabase";
import {
  getFinishedRepairRecordsByPericiaId,
  updateRepairRecordPayedAmount
} from "../../storage/supabase/repair-records/repair-records.storage";

interface CreateTechnicianPaymentArgs {
  id_pericia: string;
  amount: number;
}

export const createTechnicianPaymentService = {
  async execute(args: CreateTechnicianPaymentArgs) {
    const user = supabase.auth.user() || "";
    if (!user) {
      return { error: "Usuário não autenticado" };
    }

    const { id_pericia, amount } = args;

    const payment = {
      id_user: user.id,
      id_pericia,
      amount,
      created_at: new Date()
    };

    try {
      const { data: paymentCreated, error: errCreatingPayment } = await createTechnicianPayment(payment);
      if (errCreatingPayment) {
        console.log(errCreatingPayment);
        return { error: "Error criando pagamento" };
      }

      if (!paymentCreated) {
        return { error: "Error criando pagamento" };
      }

      const { data: repairRecords, error: errFetchingRepairRecords } = await getFinishedRepairRecordsByPericiaId(id_pericia);
      if (errFetchingRepairRecords) {
        console.log(errFetchingRepairRecords);
        return { error: "Error atualizando pagamento para os técnicos..." };
      }

      if (repairRecords.length === 0 || !repairRecords) {
        return {
          data: [],
          error: null
        };
      }

      const amountToPay = amount / repairRecords.length;
      const id_payment = paymentCreated[0].id;

      const promises = repairRecords.map(async (repairRecord: any) => {
        const args = {
          id: repairRecord.id,
          amount: amountToPay,
          id_payment
        };
        updateRepairRecordPayedAmount(args);
      });

      const res = await Promise.all(promises);
      return { data: res, error: null };
    } catch (err) {
      console.log(err);
      return { data: null, error: err };
    }
  }
};
