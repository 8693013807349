import supabase from "../../../shared/database/supabase";

export interface GetUserBalanceByIdResponse {
  balance: number;
  totalHistory: number;
  totalReceived: number;
  userDisplayName: string;
}

export const getUserBalanceById = async (userId: string): Promise<GetUserBalanceByIdResponse> => {
  try {
    const { data, error } = await supabase.functions.invoke("get-user-balance-by-id", { body: JSON.stringify({ user_id: userId }) });
    if (error) throw error;

    if (data.error) throw data.error;

    return { ...data };
  } catch (err) {
    console.log("error fetching user balance by id:", err);
    throw err;
  }
};
