import { useEffect, useState } from "react";
import { PericiaMetricByCostumersDTO } from "../../../../entities/pericia/model/dto";
import { UseGetPericiasMetricsByCostumersProps, useGetPericiasMetricsByCostumers } from "./useGetPericiasMetricsByCostumers";
import { DAY_ITA, MONTH_ITA, WEEK_ITA } from "../../../../shared/constants/metric-chart-texts";
import { CreateNewCostumer } from "../../../../entities/costumer";

export const UsePericiasByCostumersMetrics = () => {
  const [searchProps, setSearchProps] = useState<UseGetPericiasMetricsByCostumersProps>({
    costumer: CreateNewCostumer(),
    costumer2: CreateNewCostumer(),
    startDate: new Date(),
    endDate: new Date(),
    enabled: false
  });
  const { data, error, isLoading } = useGetPericiasMetricsByCostumers({ ...searchProps });
  const [metrics, setMetrics] = useState<PericiaMetricByCostumersDTO[]>([]);
  const [timeFrame, setTimeFrame] = useState<string>("");

  const metricsByDay = () => {
    if (!data) return;

    setMetrics(mergeByDay(data));
    setTimeFrame(`${DAY_ITA}-${data.length}`);
  };

  const metricsByWeek = () => {
    if (!data) return;

    const mergedMetrics = mergeByWeek(data);
    setTimeFrame(`${WEEK_ITA}-${mergedMetrics.length}`);
    setMetrics(mergedMetrics);
  };

  const metricsByMonth = () => {
    if (!data) return;
    const mergedMetrics = mergeByMonthAndYear(data);
    setTimeFrame(`${MONTH_ITA}-${mergedMetrics.length}`);
    setMetrics(mergedMetrics);
  };

  const handleSetSearchProps = (props: Partial<UseGetPericiasMetricsByCostumersProps>) => {
    setSearchProps((prevState) => {
      return { ...prevState, ...props, enabled: props.enabled ? true : false };
    });
  };

  useEffect(() => {
    if (!data) return;

    const mergedMetrics = mergeByMonthAndYear(data);
    setMetrics(mergedMetrics);
    setTimeFrame(`${MONTH_ITA}-${mergedMetrics.length}`);
    console.log("max created per day", Math.max(...data.map((item) => item.finished)));
  }, [data]);

  return {
    error,
    isLoading,
    metrics,
    searchProps,
    timeFrame,
    createdInfo: createdInfo(metrics),
    finishedInfo: finishedInfo(metrics),
    billedInfo: billedInfo(metrics),
    costumerName: searchProps.costumer.name,
    costumerName2: searchProps.costumer2.name,
    metricsStartDate: data ? new Date(data[0].date).toLocaleDateString("pt-BR") : new Date().toLocaleDateString("pt-BR"),
    metricsEndDate: data ? new Date(data[data.length - 1].date).toLocaleDateString("pt-BR") : new Date().toLocaleDateString("pt-BR"),
    metricsByDay,
    metricsByWeek,
    metricsByMonth,
    handleSetSearchProps
  };
};

const mergeByDay = (data: PericiaMetricByCostumersDTO[]): PericiaMetricByCostumersDTO[] => {
  return data.map((item) => ({
    date: new Date(item.date).toLocaleDateString("pt-BR"),
    created: item.created,
    finished: item.finished,
    billed: item.billed,
    notBilled: item.notBilled,
    notDone: item.notDone,
    created2: item.created2,
    finished2: item.finished2,
    billed2: item.billed2,
    notBilled2: item.notBilled2,
    notDone2: item.notDone2
  }));
};

const mergeByMonthAndYear = (data: PericiaMetricByCostumersDTO[]): PericiaMetricByCostumersDTO[] => {
  const mergedMetricsMap: { [yearMonth: string]: PericiaMetricByCostumersDTO } = {};

  data.forEach((metric) => {
    const [year, month] = metric.date.split("-");
    const yearMonth = `${year}-${month}`;

    if (mergedMetricsMap[yearMonth]) {
      mergedMetricsMap[yearMonth].created += metric.created;
      mergedMetricsMap[yearMonth].finished += metric.finished;
      mergedMetricsMap[yearMonth].billed += metric.billed;
      mergedMetricsMap[yearMonth].notBilled = metric.notBilled;
      mergedMetricsMap[yearMonth].notDone = metric.notDone;
      mergedMetricsMap[yearMonth].created2 += metric.created2;
      mergedMetricsMap[yearMonth].finished2 += metric.finished2;
      mergedMetricsMap[yearMonth].billed2 += metric.billed2;
      mergedMetricsMap[yearMonth].notBilled2 = metric.notBilled2;
      mergedMetricsMap[yearMonth].notDone2 = metric.notDone2;
    } else {
      mergedMetricsMap[yearMonth] = { ...metric, date: yearMonth };
    }
  });

  const mergedMetrics: PericiaMetricByCostumersDTO[] = Object.values(mergedMetricsMap);
  return mergedMetrics;
};

const getWeek = (date: Date): number => {
  const onejan = new Date(date.getFullYear(), 0, 1);
  const millisecsInDay = 86400000;
  return Math.ceil(((date.getTime() - onejan.getTime()) / millisecsInDay + onejan.getDay() + 1) / 7);
};

const mergeByWeek = (data: PericiaMetricByCostumersDTO[]): PericiaMetricByCostumersDTO[] => {
  return data.reduce((acc, curr) => {
    const date = new Date(curr.date);
    const week = getWeek(date);
    const year = date.getFullYear();
    const existing = acc.find((item) => item.date === `${year}-${week}`);
    if (existing) {
      existing.created += curr.created;
      existing.finished += curr.finished;
      existing.billed += curr.billed;
      existing.notBilled = curr.notBilled;
      existing.notDone = curr.notDone;
      existing.created2 += curr.created2;
      existing.finished2 += curr.finished2;
      existing.billed2 += curr.billed2;
      existing.notBilled2 = curr.notBilled2;
      existing.notDone2 = curr.notDone2;
    } else {
      acc.push({
        date: `${year}-${week}`,
        created: curr.created,
        finished: curr.finished,
        billed: curr.billed,
        notBilled: curr.notBilled,
        notDone: curr.notDone,
        created2: curr.created2,
        finished2: curr.finished2,
        billed2: curr.billed2,
        notBilled2: curr.notBilled2,
        notDone2: curr.notDone2
      });
    }
    return acc;
  }, [] as PericiaMetricByCostumersDTO[]);
};

const createdInfo = (metrics: PericiaMetricByCostumersDTO[]) => {
  const totalCreated = metrics.reduce((acc, curr) => acc + curr.created, 0);
  return {
    totalCreated,
    average: (totalCreated / metrics.length).toFixed(2)
  };
};

const finishedInfo = (metrics: PericiaMetricByCostumersDTO[]) => {
  const totalFinished = metrics.reduce((acc, curr) => acc + curr.finished, 0);
  return {
    totalFinished,
    average: (totalFinished / metrics.length).toFixed(2)
  };
};

const billedInfo = (metrics: PericiaMetricByCostumersDTO[]) => {
  const totalBilled = metrics.reduce((acc, curr) => acc + curr.billed, 0);
  return {
    totalBilled,
    average: (totalBilled / metrics.length).toFixed(2)
  };
};
