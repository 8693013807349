import { User } from "@supabase/supabase-js";
import { createContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../../shared/database/supabase";

export interface UserWithRole extends User {
  isAdmin: boolean;
  name: string;
  lastName: string;
  displayName: string;
  permission: string;
}

export const USER_ROLES = {
  ADMIN: "admin",
  LIMITED_ADMIN: "limited_admin",
  SALESMAN: "salesman",
  PERICIA_CREATOR: "pericia_creator",
  USER_PRIVILEGED: "user_privileged",
  USER: "user"
};

interface Props {
  children: React.ReactNode;
}

enum UserActionTypes {
  SET_USER = "SET_USER"
}

interface UserReducerAction {
  type: UserActionTypes;
  payload: UserWithRole | null;
}

interface UserState {
  user: UserWithRole | null;
}

const userReducer = (state: UserState, action: UserReducerAction) => {
  const { type, payload } = action;

  switch (type) {
    case UserActionTypes.SET_USER:
      return { ...state, user: payload };
    default:
      return state;
  }
};

interface UserContextProps {
  user: UserWithRole | null;
  setUser: (user: UserWithRole | null) => void;
}

export const UserContext = createContext<UserContextProps>({
  user: null,
  setUser: () => {}
});

export const UserProvider: React.FC<Props> = ({ children }) => {
  const [{ user }, dispatch] = useReducer(userReducer, { user: null });
  const navigate = useNavigate();

  const setUser = (user: UserWithRole | null) => {
    dispatch({ type: UserActionTypes.SET_USER, payload: user });
  };

  const value = { user, setUser };

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === "PASSWORD_RECOVERY") {
        return navigate("/auth/update-password");
      }

      if (event === "SIGNED_OUT") {
        setUser(null);
        return navigate("/auth/sign-in");
      }

      if (event === "SIGNED_IN") {
        const session = supabase.auth.session();
        const user = session?.user as UserWithRole;
        if (!user?.user_metadata?.name) {
          return navigate("/auth/sign-up");
        }
        user.isAdmin = session?.user?.user_metadata.role === USER_ROLES.ADMIN;
        user.permission = session?.user?.user_metadata.role || USER_ROLES.USER;
        user.name = session?.user?.user_metadata.name;
        user.lastName = session?.user?.user_metadata.lastName;
        setUser(session?.user as UserWithRole);
      }
    });
  }, []);

  useEffect(() => {
    const session = supabase.auth.session();
    if (!session) {
      return;
    }
    const user = session.user as UserWithRole;
    user.isAdmin = session.user?.user_metadata.role === "admin";
    user.name = session?.user?.user_metadata.name;
    user.lastName = session?.user?.user_metadata.lastName;
    user.permission = session?.user?.user_metadata.role || USER_ROLES.USER;
    setUser(session?.user as UserWithRole);
  }, []);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
