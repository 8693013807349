import { UseQueryResult, useQuery } from "react-query";
import { PericiaInvoiceItem, listPericiasByCostumerId } from "../../../../entities/pericia";

export interface ListCostumerPericiasProps {
  start: Date;
  end: Date;
  costumerId: string;
  filters: ListCostumerPericiasFilters;
}

export interface ListCostumerPericiasFilters {
  done: boolean;
  notDone: boolean;
  billed: boolean;
  isPriceCalculated: boolean;
}

export const queryKeys = {
  listPericiasByCostumerId: (props: ListCostumerPericiasProps) => ["listPericiasByCostumerId", props]
};

export function useListPericiasByCostumerId(props: ListCostumerPericiasProps): UseQueryResult<PericiaInvoiceItem[], Error> {
  return useQuery(
    queryKeys.listPericiasByCostumerId(props),
    () =>
      listPericiasByCostumerId({
        start: props.start.toISOString(),
        end: props.end.toISOString(),
        costumer_id: props.costumerId,
        filters: {
          done: props.filters.done,
          not_done: props.filters.notDone,
          price_calculated: props.filters.isPriceCalculated,
          billed: props.filters.billed
        }
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: props.costumerId !== "" && Boolean(props.start) && Boolean(props.end)
    }
  );
}
