import { UseQueryResult, useQuery } from "react-query";
import { TechnicianPayment } from "../../../entities/technician-payment";
import { getTechnicianPaymentRecord } from "../../../entities/technician-payment/api/get-technician-payment-record";

interface GetTechnicianPaymentRecordProps {
  id_pericia: string;
}

export const queryKeys = {
  GetTechnicianPaymentRecord: (props: GetTechnicianPaymentRecordProps) => ["GetTechnicianPaymentRecord", props]
};

export function useGetTechnicianPaymentRecord(props: GetTechnicianPaymentRecordProps): UseQueryResult<TechnicianPayment, Error> {
  return useQuery(queryKeys.GetTechnicianPaymentRecord(props), () => getTechnicianPaymentRecord({ id_pericia: props.id_pericia }), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: props.id_pericia !== "" && props.id_pericia.length > 3
  });
}
