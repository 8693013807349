import supabase from "../../../shared/database/supabase";

export interface CreatePaymentRecordsRequest {
  id_user: string;
  amount: number;
  description: string;
}

export interface CreatePaymentRecordsResponse {
  data: any[];
}

export const createPaymentRecord = async (
  props: CreatePaymentRecordsRequest
) => {
  if (!supabase.auth.user()) throw new Error("User is not logged in");

  try {
    const { data, error } = await supabase.from("payment_records").insert([
      {
        id_user: props.id_user,
        issued_by: supabase.auth.user()?.id,
        amount: props.amount,
        description: props.description,
      },
    ]);

    if (error) {
      throw new Error(error.message);
    }

    return { data: data };
  } catch (err) {
    console.log(err);
    throw err;
  }
};
