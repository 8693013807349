import { useCallback, useState } from "react";
import {
  Pericia,
  CreateNewPericia,
  setPericiaCostumer,
  setPericiaCar,
  setPericiaPricePerHour,
  setPericiaShouldUnmount,
  setPericiaUnmountPrice,
  setpericiaDate,
  PericiaCarPartsNames,
  CarPart,
  updatePericiaCarPart,
  setPericiaAddtionalCost,
  setPericiaAddtionalCostDescription,
  setPericiaInsuranceHours,
  setupPericiaProperties,
  setPericiaCostumerPrice,
  setPericiaIsBilled,
  setPericiaIsDone,
  setPericiaIsPriceCalculated
} from "./pericia";
import { Costumer } from "../../costumer/model/costumer";
import { Car } from "../../car/model/car";

export function usePericia(initialPericia?: Pericia): {
  pericia: Pericia;
  updatePericia: (pericia: Pericia) => void;
  updatePericiaIsPriceCalculated: (isPriceCalculated: boolean) => void;
  updatePericiaIsDone: (isDone: boolean) => void;
  updatePericiaIsBilled: (isBilled: boolean) => void;
  updatePericiaCostumer: (costumer: Costumer) => void;
  updatePericiaCostumerPrice: (price: number) => void;
  updatePericiaCar: (car: Car) => void;
  updatePericiaPricePerHour: (pricePerHour: number) => void;
  updatePericiaShouldUnmount: (shouldUnmount: boolean) => void;
  updatePericiaUnmountPrice: (price: number) => void;
  updatePericiaAddtionalCost: (cost: number) => void;
  updatePericiaAddtionalCostDescription: (description: string) => void;
  updatePericiaDate: (date: Date) => void;
  updateCarPart: (carPart: CarPart) => void;
  updatePericiaFinished: (finished: boolean) => void;
  updatePericiaInsuranceHours: (hours: number) => void;
  findCarPart: (name: FindCarPartArgs) => CarPart;
} {
  const [pericia, setPericia] = useState<Pericia>(initialPericia ? periciaConstructor(initialPericia) : CreateNewPericia());

  function updatePericia(pericia: Pericia) {
    setPericia(periciaConstructor(pericia));
  }

  function periciaConstructor(pericia: Pericia) {
    return setupPericiaProperties(pericia);
  }

  function updatePericiaIsBilled(isBilled: boolean) {
    setPericia(setPericiaIsBilled(pericia, isBilled));
  }

  function updatePericiaInsuranceHours(hours: number) {
    setPericia(setPericiaInsuranceHours({ ...pericia }, hours));
  }

  function updatePericiaIsPriceCalculated(isPriceCalculated: boolean) {
    setPericia(setPericiaIsPriceCalculated(pericia, isPriceCalculated));
  }

  function updatePericiaCostumer(costumer: Costumer) {
    setPericia(setPericiaCostumer({ ...pericia }, costumer));
  }

  function updatePericiaCostumerPrice(price: number) {
    setPericia(setPericiaCostumerPrice(pericia, price));
  }

  function updatePericiaCar(updatedCar: Car) {
    setPericia(
      setPericiaCar(
        { ...pericia },
        {
          ...updatedCar,
          plate: updatedCar.plate.toUpperCase().trim()
        }
      )
    );
  }

  function updatePericiaFinished(finished: boolean) {
    setPericia({ ...pericia, finished });
  }

  function updatePericiaPricePerHour(pricePerHour: number) {
    setPericia(setPericiaPricePerHour({ ...pericia }, pricePerHour));
  }

  function updatePericiaShouldUnmount(shouldUnmount: boolean) {
    setPericia(setPericiaShouldUnmount({ ...pericia }, shouldUnmount));
  }

  function updatePericiaUnmountPrice(price: number) {
    setPericia(setPericiaUnmountPrice({ ...pericia }, price));
  }

  function updatePericiaDate(date: Date) {
    setPericia(setpericiaDate({ ...pericia }, date));
  }

  const findCarPart = useCallback(
    (name: FindCarPartArgs) => {
      return pericia.carParts.find((carPart) => carPart.name === name) as CarPart;
    },
    [pericia.carParts]
  );

  function updateCarPart(carPart: CarPart) {
    setPericia(updatePericiaCarPart({ ...pericia }, carPart));
  }

  function updatePericiaAddtionalCost(cost: number) {
    setPericia(setPericiaAddtionalCost({ ...pericia }, cost));
  }

  function updatePericiaAddtionalCostDescription(description: string) {
    setPericia(setPericiaAddtionalCostDescription({ ...pericia }, description));
  }

  function updatePericiaIsDone(isDone: boolean) {
    setPericia(setPericiaIsDone(pericia, isDone));
  }

  return {
    pericia,
    updatePericia,
    updatePericiaIsDone,
    updatePericiaIsBilled,
    updatePericiaCostumer,
    updatePericiaCostumerPrice,
    updatePericiaCar,
    updatePericiaPricePerHour,
    updatePericiaShouldUnmount,
    updatePericiaUnmountPrice,
    updatePericiaAddtionalCost,
    updatePericiaAddtionalCostDescription,
    updatePericiaDate,
    updateCarPart,
    updatePericiaFinished,
    updatePericiaInsuranceHours,
    updatePericiaIsPriceCalculated,
    findCarPart
  };
}

const CarPartNamePossibleValues = PericiaCarPartsNames();
export type FindCarPartArgs = (typeof CarPartNamePossibleValues)[number];
