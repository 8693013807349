import { UseQueryResult, useQuery } from "react-query";
import {
  GetUserBalanceResponse,
  getUserBalance,
} from "../../../../entities/user";

export const queryKeys = {
  getUserBalance: () => ["getUserBalance"],
};

export function useGetUserBalance(): UseQueryResult<
  GetUserBalanceResponse,
  Error
> {
  return useQuery(queryKeys.getUserBalance(), () => getUserBalance(), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
