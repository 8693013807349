import supabase from "../../../shared/database/supabase";
import { Costumer } from "../model";

export const listCostumers = async (): Promise<Costumer[] | Error> => {
  try {
    const { data, error } = await supabase.functions.invoke("list-costumers");
    if (error) throw error;

    if (data.error) throw data.error;
    return [...data.costumers];
  } catch (err) {
    console.log("error fetching costumers:", err);
    throw err;
  }
};
