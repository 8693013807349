import supabase from "../../../shared/database/supabase";

export interface InviteUserByEmailRequest {
  email: string;
  role: string;
}

export const inviteUserByEmail = async (req: InviteUserByEmailRequest): Promise<boolean | Error> => {
  const { email, role } = req;
  try {
    const { data, error } = await supabase.functions.invoke("invite-user-by-email", {
      body: JSON.stringify({ email, role })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    return true;
  } catch (err) {
    console.log("error inviting user:", err);
    throw err;
  }
};
