import { UseQueryResult, useQuery } from "react-query";
import { listInvoicePaymentsRequest, ListInvoicePaymentsResponse } from "../../../../entities/invoice-payment/api/list-invoice-payments";

export interface UseListInvoicePaymentsProps {
  id_invoice: string;
}

export const queryKeys = {
  listInvoicePayments: (props: UseListInvoicePaymentsProps) => ["listInvoicePayments", props]
};

export function useListInvoicePayments(props: UseListInvoicePaymentsProps): UseQueryResult<ListInvoicePaymentsResponse, Error> {
  const { id_invoice } = props;

  return useQuery(
    queryKeys.listInvoicePayments(props),
    () =>
      listInvoicePaymentsRequest({
        id_invoice
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: true
    }
  );
}
