import { UseQueryResult, useQuery } from "react-query";
import {
  ListPaymentRecordsByUserIdResponse,
  listPaymentRecordsByUserId,
} from "../../../entities/payment-record";

export const queryKeys = {
  listPaymentRecordsByUserId: (userId: string) => [
    "listPaymentRecordsByUserId",
    userId,
  ],
};

export function useListPaymentRecordsByUserId(
  userId: string
): UseQueryResult<ListPaymentRecordsByUserIdResponse, Error> {
  return useQuery(
    queryKeys.listPaymentRecordsByUserId(userId),
    () => listPaymentRecordsByUserId(userId),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: userId !== "",
    }
  );
}
