/**
 * Displays the header information for a Pericia entity. This component is read-only,
 * meaning it is only used to display data and does not allow user interaction to change it.
 */
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Stack, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useState } from "react";
import { currencyMask, formatIntegerToCurrency } from "../../../utils/helpers/numbers";
import CostumerAutocompleteSearchBar from "../../costumer/ui/costumer-autocomplete-search-bar.component";
import { Car } from "../../car/model/car";
import { Costumer } from "../../costumer/model/costumer";
import { PaperCard } from "../../../shared/ui/paper-card/paper-card.component";

interface PericiaHeaderProps {
  props: Props;
}

interface Props {
  car: Car;
  costumer: Costumer;
  pricePerHour: number;
  shouldUnmount: boolean;
  unmountPrice: number;
  date: Date;
}

const PericiaHeaderDisabled: React.FC<PericiaHeaderProps> = ({ props }) => {
  const { costumer, car, pricePerHour, unmountPrice, shouldUnmount, date } = props;
  const [carMask, setCarMask] = useState<Car>(car);
  const [unmountPriceMask, setUnmountPriceMask] = useState("");
  const [carFormError, setCarFormError] = useState(true);
  const { plate, brand, model, chassisNumber, insuranceName, color } = carMask;

  const handleCarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "plate") {
      return setCarMask({ ...carMask, [name]: value.toUpperCase() });
    }

    return setCarMask({ ...carMask, [name]: value });
  };

  const updateCostumer = (costumer: Costumer) => {
    console.log("nada");
  };

  useEffect(() => {
    setUnmountPriceMask(currencyMask(formatIntegerToCurrency(unmountPrice)));
  }, [unmountPrice]);

  useEffect(() => {
    setCarMask(car);
  }, [car]);

  const carFields = [
    {
      id: "costumerName",
      label: "Cliente",
      name: "costumerName",
      value: costumer.name || "",
      gridSize: 12
    },
    {
      id: "brand",
      label: "Marca",
      name: "brand",
      value: brand || "",
      gridSize: 4
    },
    {
      id: "model",
      label: "Modelo",
      name: "model",
      value: model || "",
      gridSize: 4
    },
    {
      id: "placa",
      label: "Placa",
      name: "plate",
      value: plate || "",
      gridSize: 4
    },
    {
      id: "chassisNumber",
      label: "Chassi",
      name: "chassisNumber",
      value: chassisNumber || "",
      gridSize: 4
    },
    {
      id: "insuranceName",
      label: "Seguro",
      name: "insuranceName",
      value: insuranceName || "",
      gridSize: 4
    },
    {
      id: "color",
      label: "Cor",
      name: "color",
      value: color || "",
      gridSize: 4
    },
    {
      id: "pricePerHour",
      label: "CHF/Ora",
      name: "pricePerHour",
      value: pricePerHour.toString() || "",
      gridSize: 6
    }
  ];

  return (
    <Box component="form" noValidate sx={{ mt: 3, mb: 5 }}>
      <PaperCard title=" Informações da perícia">
        <Grid container spacing={2} mt={1}>
          {carFields.map((field) => (
            <Grid item xs={12} sm={field.gridSize} key={field.id}>
              <TextField
                autoComplete="off"
                required
                fullWidth
                id={field.id}
                label={field.label}
                name={field.name}
                variant="standard"
                onChange={handleCarChange}
                value={field.value}
                disabled
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DatePicker
                  label="Data"
                  openTo="month"
                  inputFormat="DD/MM/YYYY"
                  views={["year", "month", "day"]}
                  value={date}
                  onChange={(newValue) => {}}
                  renderInput={(params) => <TextField {...params} variant="standard" />}
                  disabled={true}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormGroup>
              <FormControlLabel control={<Checkbox value={shouldUnmount} disabled />} label="Desmontar" />
            </FormGroup>
          </Grid>
          <Grid item xs={6} sm={6} display={shouldUnmount ? "block" : "none"}>
            <TextField
              required
              fullWidth
              name="unmount"
              label="Preço desmontagem"
              id="unmount"
              variant="standard"
              value={unmountPriceMask}
              disabled
            />
          </Grid>
        </Grid>
      </PaperCard>
    </Box>
  );
};

export default PericiaHeaderDisabled;
