import jsPDF from "jspdf";
import { invoicePDFMethods } from "./invoice/invoice-pdf.service";
import { repairRecordsPDFMethods } from "./repair-record/repair-record-pdf.service";

export const pdfService = {
  generateInvoicePdf: invoicePDFMethods.generateInvoicePdf,
  generateRepairRecordPdf: repairRecordsPDFMethods.generateRepairRecordPdf,
};

export const addLogo = (pdf: jsPDF) => {
  const logoImg = require("../../assets/nuovauto.png");
  pdf.addImage(logoImg, "PNG", 16, 12, 60, 30);
};
