export interface User {
  id: string;
  displayName: string;
  lastName: string;
  name: string;
  permissions: string[];
}

export function makeNewUser(override?: Partial<User>) {
  return {
    id: "",
    displayName: "",
    lastName: "",
    name: "",
    permissions: [],
    ...override
  };
}
