import { Navigate, Outlet } from "react-router-dom";
import { UserWithRole } from "../../../contexts/user/user.context";
import supabase from "../../../shared/database/supabase";

interface ProptectedRouteProps {
  redirectPath?: string;
  children?: React.ReactNode;
}

const ProtectedRoute: React.FC<ProptectedRouteProps> = ({ children, redirectPath = "/auth/sign-in" }) => {
  const user = supabase.auth.user() as UserWithRole;
  if (!user) {
    return <Navigate to={redirectPath} />;
  }

  user.name = user.user_metadata?.name;

  if (!user.name) {
    return <Navigate to="/auth/sign-up?operation=finish" />;
  }

  return children ? <>children</> : <Outlet />;
};

export default ProtectedRoute;
