import { Checkbox, FormControlLabel, FormGroup, Grid, Stack, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Invoice } from "../../../../entities/invoice/model/invoice";
import { PaperCard } from "../../../../shared/ui/paper-card/paper-card.component";
import { formatCurrency } from "../../../../utils/helpers/numbers";
import { ChangeEvent, useEffect, useState } from "react";

interface UpdateInvoiceHeaderProps {
  invoice: Invoice;
  invoiceNewDate: any;
  handleUpdateDate: (date: Date) => void;
  totalPrice: number;
  totalIVA: number;
  totalPriceWithIVA: number;
  invoiceCustomCount: number;
  ivaPercentage: number;
  invoiceText: string;
  disabled: boolean;
  handleInvoiceCustomCountChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleInvoiceCustomCountOnBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleToggleDisplayTotalHours: () => void;
  handleIvaPercentageUpdate: (newIvaPercentage: number) => void;
  handleInvoiceTextUpdate: (newInvoiceText: string) => void;
}

export const UpdateInvoiceHeader: React.FC<UpdateInvoiceHeaderProps> = ({
  disabled,
  invoice,
  invoiceNewDate,
  totalPrice,
  totalIVA,
  totalPriceWithIVA,
  invoiceCustomCount,
  ivaPercentage,
  invoiceText,
  handleUpdateDate,
  handleInvoiceCustomCountChange,
  handleInvoiceCustomCountOnBlur,
  handleToggleDisplayTotalHours,
  handleIvaPercentageUpdate,
  handleInvoiceTextUpdate
}) => {
  const { costumer } = invoice;
  const [ivaPercentageMask, setIvaPercentageMask] = useState<string>(ivaPercentage.toString());
  const [invoiceTextMask, setInvoiceTextMask] = useState<string>(invoiceText);

  const handleIvaPercentageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIvaPercentageMask(e.target.value);
  };

  const handleIvaPercentageOnBlur = () => {
    handleIvaPercentageUpdate(Number(ivaPercentageMask));
  };

  const handleInvoiceTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInvoiceTextMask(e.target.value);
  };

  const handleInvoiceTextOnBlur = () => {
    handleInvoiceTextUpdate(invoiceTextMask);
  };

  useEffect(() => {
    setIvaPercentageMask(ivaPercentage.toString());
  }, [ivaPercentage]);

  useEffect(() => {
    setInvoiceTextMask(invoiceText);
  }, [invoiceText]);

  return (
    <PaperCard title="Dados da fatura">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField label="Cliente" fullWidth id="costumerNameInvoice" variant="standard" disabled value={costumer.name} />
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <DatePicker
                label="Data de criação"
                openTo="day"
                inputFormat="DD/MM/YYYY"
                views={["year", "month", "day"]}
                value={invoiceNewDate.date}
                onChange={(newValue) => {
                  if (newValue) handleUpdateDate(new Date(newValue));
                }}
                renderInput={(params) => <TextField {...params} variant="standard" />}
                disabled={disabled}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Data da primeira pericia"
            fullWidth
            id="startDateInvoice"
            variant="standard"
            disabled
            value={invoice ? invoice?.startDate.toLocaleDateString("pt-BR") : ""}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Data da ultima pericia"
            fullWidth
            id="endDateInvoice"
            variant="standard"
            disabled
            value={invoice ? invoice?.endDate.toLocaleDateString("pt-BR") : ""}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Número de pericias"
            fullWidth
            id="totalPericiasInvoice"
            variant="standard"
            disabled
            value={invoice.totalPericias || ""}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField label="Valor total" fullWidth id="totalPriceInvoice" variant="standard" disabled value={formatCurrency(totalPrice)} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Porcentagem IVA"
            fullWidth
            id="IVAInvoice"
            variant="standard"
            value={ivaPercentageMask}
            onChange={handleIvaPercentageChange}
            onBlur={handleIvaPercentageOnBlur}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField label="Valor IVA" fullWidth id="IVAInvoice" variant="standard" disabled value={formatCurrency(totalIVA)} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Valor total com IVA"
            fullWidth
            id="totalPriceWithIVAInvoice"
            variant="standard"
            disabled
            value={formatCurrency(totalPriceWithIVA)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Número da fatura"
            fullWidth
            id="invoiceCustomCount"
            variant="standard"
            value={invoiceCustomCount}
            onChange={handleInvoiceCustomCountChange}
            onBlur={handleInvoiceCustomCountOnBlur}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Mensagem no rodapé da fatura"
            fullWidth
            id="InvoiceText"
            variant="standard"
            value={invoiceTextMask}
            onChange={handleInvoiceTextChange}
            onBlur={handleInvoiceTextOnBlur}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={12} mb={0} mt={2}>
          <FormGroup sx={{ width: "100%" }}>
            <FormControlLabel control={<Checkbox onChange={handleToggleDisplayTotalHours} />} label="Mostrar horas totais" />
          </FormGroup>
        </Grid>
      </Grid>
    </PaperCard>
  );
};
