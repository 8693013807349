import { UseQueryResult, useQuery } from "react-query";
import { updatePericiaCashPayedAmount } from "../../../../entities/pericia/api/update-pericia-payed-by-cash";

export type UseUpdatePericiaCashPayedAmountProps = {
  id: string;
  cashPayedAmount: number;
  enabled: boolean;
};

export const queryKeys = {
  updatePericiaCashPayedAmount: (pericia: UseUpdatePericiaCashPayedAmountProps) => ["updatePericiaCashPayedAmount", [pericia]]
};

export function useUpdatePericiaCashPayedAmount(pericia: UseUpdatePericiaCashPayedAmountProps): UseQueryResult<string, Error> {
  const { id, cashPayedAmount, enabled } = pericia;
  return useQuery(
    queryKeys.updatePericiaCashPayedAmount(pericia),
    () => updatePericiaCashPayedAmount({ id, cash_payed_amount: cashPayedAmount }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled
    }
  );
}
