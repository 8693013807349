import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface BasicAccordionProps {
  title: string;
  children: React.ReactNode;
  expanded: boolean | undefined;
}

const BasicAccordion: React.FC<BasicAccordionProps> = (props: BasicAccordionProps) => {
  const { title, children, expanded = false } = props;

  return (
    <div>
      <Accordion expanded={expanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BasicAccordion;
