import { Grid, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { PaperCard } from "../../../../shared/ui/paper-card/paper-card.component";
import { getPericiaCustomPriceCurrencyFormat } from "../../../../entities/pericia";
import { InvoiceItem } from "../../../../entities/invoice/api/get-invoice-by-id";

interface UpdateInvoiceListProps {
  items: InvoiceItem[];
  displayTotalHours: boolean;
}

const updateInvoiceListComponent = (props: UpdateInvoiceListProps) => {
  const { items, displayTotalHours } = props;

  return (
    <PaperCard title="Pericias">
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={12}>
          <ListItem
            divider
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-end",
              textAlign: "left"
            }}
          >
            <ListItemText id="invoice-list-header" primary={"MARCA"} sx={{ flex: "1", fontWeight: "bold" }} />
            <ListItemText id="invoice-list-header" primary={"MODELO"} sx={{ flex: "1", fontWeight: "bold" }} />
            <ListItemText id="invoice-list-header" primary={"PLACA"} sx={{ flex: "1", fontWeight: "bold" }} />
            <ListItemText id="invoice-list-header" primary={"CHASSI"} sx={{ flex: "1" }} />
            <ListItemText hidden={!displayTotalHours} id="invoice-list-header" primary={"HORAS"} sx={{ flex: "1" }} />
            <ListItemText id="invoice-list-header" primary={"VALOR"} sx={{ flex: "1" }} />
          </ListItem>
        </Grid>
        <Grid item xs={12} md={12} id="invoice-by-id-list">
          {items.map(({ pericia, car }, index) => {
            const labelId = `checkbox-list-label-${index}`;
            const { brand, model, plate } = car;
            const chassisNumber = car.chassisNumber.length > 2 ? car.chassisNumber : "N/A";

            return (
              <ListItem key={car.plate} divider>
                <ListItemButton
                  role={undefined}
                  dense
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-end"
                  }}
                >
                  <ListItemText id={labelId} primary={`${brand}`} sx={{ flex: "1" }} />
                  <ListItemText id={labelId} primary={`${model}`} sx={{ flex: "1" }} />
                  <ListItemText id={labelId} primary={`${plate}`} sx={{ flex: "1" }} />
                  <ListItemText id={labelId} primary={`${chassisNumber}`} sx={{ flex: "1" }} />
                  <ListItemText
                    hidden={!displayTotalHours}
                    id={labelId}
                    primary={pericia.insuranceHours ? pericia.insuranceHours.toFixed(2) : (pericia.totalHours / 10).toFixed(2)}
                    sx={{
                      flex: "1"
                    }}
                  />
                  <ListItemText
                    id={labelId}
                    // primary={pericia.getCurrencyCustomInsurancePrice()}
                    primary={getPericiaCustomPriceCurrencyFormat(pericia)}
                    sx={{
                      flex: "1"
                    }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </Grid>
      </Grid>
    </PaperCard>
  );
};

export default updateInvoiceListComponent;
