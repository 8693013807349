import { useEffect, useState } from "react";
import { ListPericiaByDateRangeFilters, ListPericiaByDateRangeProps, useListPericiasByDateRange } from "./useListPericiasByDateRange";
import { PericiaInvoiceItem, getPericiasCustomPrice } from "../../../../entities/pericia";

const INITIAL_FILTER: ListPericiaByDateRangeFilters = {
  billed: false,
  isPriceCalculated: false,
  done: false,
  notDone: false
};

const LIST_COSTUMER_PERICIAS_PROPS_INITIAL_STATE: ListPericiaByDateRangeProps = {
  start: new Date(),
  end: new Date(),
  filters: INITIAL_FILTER,
  page: 1,
  enabled: false
};

export function usePericiasByDateRange() {
  const [pericias, setPericias] = useState<PericiaInvoiceItem[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalCostumers, setTotalCostumers] = useState<number>(0);
  const [searchProps, setSearchProps] = useState<ListPericiaByDateRangeProps>(LIST_COSTUMER_PERICIAS_PROPS_INITIAL_STATE);
  const [fetchedAll, setFetchedAll] = useState<boolean>(false);
  const { data: fetchedList, error: listError, isLoading: listIsLoading } = useListPericiasByDateRange(searchProps);

  const handleSetSearchProps = (props: Partial<ListPericiaByDateRangeProps>) => {
    setSearchProps((prevState) => {
      return { ...prevState, ...props, enabled: props.enabled ? true : false, page: 1 };
    });
  };

  const handlePushToPericias = (items: PericiaInvoiceItem[]) => {
    setPericias((prevState) => [...prevState, ...items]);
  };

  const handleFlushPericias = () => {
    setPericias([]);
  };

  const handleSearchPericias = () => {
    handleFlushPericias();
    handleSetSearchProps({ enabled: true });
  };

  const handleFetchNextPage = () => {
    setSearchProps((prevState) => {
      return { ...prevState, page: prevState.page + 1, enabled: true };
    });
  };

  const activeFilters = Object.keys(searchProps.filters).filter((key) => searchProps.filters[key as keyof ListPericiaByDateRangeFilters]);

  useEffect(() => {
    if (!fetchedList) return;

    handlePushToPericias(fetchedList);
    setFetchedAll(fetchedList.length < 32);
  }, [fetchedList]);

  useEffect(() => {
    if (!pericias) return;

    const price = getPericiasCustomPrice(pericias);

    const costumersSet = new Set(pericias.map((pericia) => pericia.costumer.id));

    setTotalPrice(price);
    setTotalCostumers(costumersSet.size);
  }, [pericias]);

  return {
    pericias,
    searchProps,
    listError,
    listIsLoading,
    fetchedAll,
    INITIAL_FILTER,
    totalPrice,
    totalCostumers,
    activeFilters,
    handleSetSearchProps,
    handleSearchPericias,
    handleFetchNextPage
  };
}
