import { Grid, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { PaperCard } from "../../../../shared/ui/paper-card/paper-card.component";
import { currencyMask, formatIntegerToCurrency } from "../../../../utils/helpers/numbers";
import { InvoicePayment } from "../../../../entities/invoice-payment/model/invoice-payment";

interface UpdateInvoicePaymentListProps {
  items: InvoicePayment[];
  total: number;
  unpaid: number;
}

const updateInvoicePaymentListComponent = (props: UpdateInvoicePaymentListProps) => {
  const { items, total, unpaid } = props;

  return (
    <PaperCard title="Pagamentos">
      <p>Total da fatura: {currencyMask(formatIntegerToCurrency(total))}</p>
      <p>Em aberto: {currencyMask(formatIntegerToCurrency(unpaid))}</p>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={12}>
          <ListItem
            divider
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-end",
              textAlign: "left"
            }}
          >
            <ListItemText id="invoice-list-header" primary={"USUÁRIO"} sx={{ flex: "1", fontWeight: "bold" }} />
            <ListItemText id="invoice-list-header" primary={"VALOR"} sx={{ flex: "1", fontWeight: "bold" }} />
            <ListItemText id="invoice-list-header" primary={"SEM DESCONTO"} sx={{ flex: "1", fontWeight: "bold" }} />
            <ListItemText id="invoice-list-header" primary={"DATA"} sx={{ flex: "1" }} />
          </ListItem>
        </Grid>
        <Grid item xs={12} md={12} id="invoice-by-id-list">
          {items.map(({ id, payedBy, amount, discountPercentage, payedAt }, index) => {
            const labelId = `checkbox-list-label-${index}`;

            return (
              <ListItem key={id} divider>
                <ListItemButton
                  role={undefined}
                  dense
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-end"
                  }}
                >
                  <ListItemText id={labelId} primary={`${payedBy.displayName}`} sx={{ flex: "1" }} />
                  <ListItemText id={labelId} primary={`${currencyMask(formatIntegerToCurrency(amount))}`} sx={{ flex: "1" }} />
                  <ListItemText
                    id={labelId}
                    primary={`${currencyMask(formatIntegerToCurrency(amount / (1 - discountPercentage / 100)))}`}
                    sx={{ flex: "1" }}
                  />
                  <ListItemText
                    id={labelId}
                    primary={new Date(payedAt).toLocaleDateString("pt-BR")}
                    sx={{
                      flex: "1"
                    }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </Grid>
      </Grid>
    </PaperCard>
  );
};

export default updateInvoicePaymentListComponent;
