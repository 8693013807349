import supabase from "../../../shared/database/supabase";
import { Car } from "../../car";
import { Costumer } from "../../costumer";
import { Pericia } from "../../pericia";

export interface GetInvoiceByIdResponse {
  id: string;
  invoiceCount: number;
  invoiceCustomCount: number;
  invoiceText: string;
  ivaPercentage: number;
  totalPericias: number;
  totalPrice: number;
  payed: boolean;
  payedValue: number;
  payedAt: Date;
  startDate: Date;
  endDate: Date;
  createdAt: Date;
  deleted: boolean;
  updatedAt: Date;
  costumer: Costumer;
  items: InvoiceItem[];
}

export interface InvoiceItem {
  pericia: Pericia;
  car: Car;
}

export const getInvoiceById = async (id: string): Promise<GetInvoiceByIdResponse> => {
  try {
    const { data, error } = await supabase.functions.invoke("get-invoice-by-id", {
      body: JSON.stringify({ id })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    return {
      ...data,
      createdAt: new Date(data.createdAt),
      endDate: new Date(data.endDate),
      payedAt: data.payedAt ? new Date(data.payedAt) : null,
      startDate: new Date(data.startDate)
    };
  } catch (err) {
    console.log(err);
    throw err;
  }
};
