import { useEffect, useState } from "react";
import FlexContainer from "../../../shared/ui/container/flex-container.component";
import DateRange from "../../../components/date/date-range/date-range.component";
import Title from "../../../components/typography/title.component";
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import RepairRecordsAdminList from "../../../components/repair-records/repair-records-admin-list/repair-records-admin-list.component";
import { createTechnicianRepairRecordsPdfService } from "../../../services/pdf/repair-record/create-technician-repair-records-pdf.service";
import { useListRepairRecordsByDateRange } from "../../../features/repair-record/list-repair-records-by-date-rage/api/useListRepairRecordsByDateRange";

interface User {
  name: string;
  last_name: string;
}

interface ListUserRepairRecordsProps {
  id_user: string;
  start: Date;
  end: Date;
}

const ListUserRepairRecords = () => {
  const { userID } = useParams();
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [search, setSearch] = useState<ListUserRepairRecordsProps>({
    id_user: "",
    start: new Date(),
    end: new Date()
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const [user, setUser] = useState<User>({ name: "", last_name: "" });
  const {
    data: repairRecords,
    error,
    isLoading: loading,
    refetch
  } = useListRepairRecordsByDateRange({
    id_user: userID || "",
    start: search.start,
    end: search.end
  });

  const handleDateChange = (startDate: Date, endDate: Date) => {
    setStart(startDate);
    setEnd(endDate);
  };

  const handleCreatePDF = async () => {
    if (!repairRecords || repairRecords.length === 0) {
      toast.info("Nenhum reparo encontrado...");
      return;
    }

    const { error } = await createTechnicianRepairRecordsPdfService.execute({
      repairRecords,
      start,
      end,
      user,
      totalPrice
    });

    if (error) {
      toast.error("Algo saiu errado...");
      return;
    }

    toast.success("PDF gerado com sucesso!");
  };

  const handleListRepairRecords = () => {
    setSearch({ ...search, id_user: userID || "", start, end });
  };

  const handlePericiaUpdated = () => {
    refetch();
  };

  useEffect(() => {
    if (!repairRecords) return;

    setTotalPrice(
      repairRecords.reduce((item, acc) => {
        return item + acc.payedValue;
      }, 0)
    );
  }, [repairRecords]);

  if (error) {
    toast.error("Algo saiu errado...");
  }

  return (
    <FlexContainer>
      <Title value={`Buscar Reparos`} />
      <Box sx={{ width: "100%", mb: 3 }} />
      <DateRange props={{ startDate: start, endDate: end, onChange: handleDateChange }} />
      <LoadingButton variant="contained" fullWidth loading={loading} sx={{ mt: 2, mb: 5 }} onClick={handleListRepairRecords}>
        Buscar
      </LoadingButton>
      {repairRecords && repairRecords.length > 0 && (
        <>
          <RepairRecordsAdminList
            props={{
              repairRecords,
              totalPrice: totalPrice,
              handlePericiaUpdated: handlePericiaUpdated
            }}
          />
          <LoadingButton variant="contained" fullWidth loading={loading} sx={{ mt: 5 }} onClick={handleCreatePDF}>
            Gerar PDF
          </LoadingButton>
        </>
      )}
      <ToastContainer />
    </FlexContainer>
  );
};

export default ListUserRepairRecords;
