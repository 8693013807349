import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import DateRangePicker from "../../../../shared/ui/date/date-range-picker.component";
import { LoadingButton } from "@mui/lab";
import { PaperCard } from "../../../../shared/ui/paper-card/paper-card.component";

interface CostumerListPericiasHeaderProps {
  props: {
    start: Date;
    end: Date;
    filters: {
      notDone: boolean;
      done: boolean;
      isPriceCalculated: boolean;
      billed: boolean;
    };
    handleSearchPericias: () => void;
    handleDateChange: (start: Date, end: Date) => void;
    handleFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isLoading: boolean;
  };
}

export const PericiasByDateHeader = ({ props }: CostumerListPericiasHeaderProps) => {
  const { start, end, isLoading, filters, handleDateChange, handleSearchPericias, handleFilterChange } = props;

  return (
    <PaperCard title="Buscar pericias">
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12}>
          <DateRangePicker props={{ start, end, onChange: handleDateChange }} />
        </Grid>
        <Grid item xs={6} sm={3} mt={2} key={"emandamento"}>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox name="notDone" onChange={handleFilterChange} size="small" checked={filters.notDone} />}
              label={"Em andamento"}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3} mt={2} key={"finalizado"}>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox name="done" onChange={handleFilterChange} size="small" checked={filters.done} />}
              label="Finalizado"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3} mt={2} key={"controlado"}>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox name="isPriceCalculated" onChange={handleFilterChange} size="small" checked={filters.isPriceCalculated} />}
              label="Controlado"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3} mt={2} key={"faturado"}>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox name="billed" onChange={handleFilterChange} size="small" checked={filters.billed} />}
              label="Faturado"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} mt={3}>
          <LoadingButton type="submit" fullWidth variant="contained" loading={isLoading} onClick={handleSearchPericias}>
            Buscar
          </LoadingButton>
        </Grid>
      </Grid>
    </PaperCard>
  );
};
