import { jsPDF } from "jspdf";
import Canvas from "./canvas.component";
import { CAR_PARTS, CAR_PARTS_CANVAS_COORDINATES } from "../../shared/constants/car-parts.constants";
import { Button } from "@mui/material";
import { removeWhiteSpaces } from "../../utils/helpers/strings";
import { drawBorder } from "../../utils/helpers/pdf";
import { formatCurrency } from "../../utils/helpers/numbers";
import { PericiaCostumer } from "../../models/costumer/costumer";
import { Car } from "../../models/car/car";
import { Locale, getTranslation } from "../../shared/locale/locale";
import { CarPart } from "../../entities/pericia";

interface PDFGeneratorProps {
  disabled: boolean;
  withCostumerPrice: boolean;
  withTableHours?: boolean;
  props: Props;
}

interface Props {
  costumer: PericiaCostumer;
  car: Car;
  carParts: CarPart[];
  date: Date;
  finished: boolean;
  shouldUnmount: boolean;
  unmountPrice: number;
  costumerPrice: number;
  addtionalCost: number;
  totalPrice: number;
  insurancePrice: number;
  addtionalCostDescription: string;
  totalHours: number;
}

const carroImg = require("../../assets/pericia.jpg");

const { PARAFANGO_AD, PARAFANGO_AS } = CAR_PARTS;
const notesInLine = [PARAFANGO_AD.value, PARAFANGO_AS.value];

const PDFGenerator: React.FC<PDFGeneratorProps> = ({ disabled, withCostumerPrice, withTableHours = false, props }) => {
  const {
    carParts,
    costumer,
    car,
    date,
    finished,
    shouldUnmount,
    unmountPrice,
    costumerPrice,
    addtionalCost,
    totalPrice,
    insurancePrice,
    addtionalCostDescription,
    totalHours
  } = props;
  const text = getTranslation(costumer.language || "ita");

  const { name: CostumerName } = costumer;
  const { plate, chassisNumber } = car;
  let canvasID = "";

  if (withCostumerPrice) {
    canvasID = "pdf-canvas-with-price";
  } else {
    if (withTableHours) {
      canvasID = "pdf-canvas-with-hours";
    } else {
      canvasID = "pdf-canvas";
    }
  }

  const canvas = document.getElementById(canvasID) as HTMLCanvasElement;

  const draw = (context: any) => {
    const img = new Image();
    img.src = carroImg;
    img.onload = () => {
      setBackgroundColor(context, text, canvas.width, canvas.height);

      const pdfInfo = makePDFInfoObject(costumer, car, finished, date, shouldUnmount, withTableHours, totalHours);

      context.drawImage(img, 20, 80, 1150, 1100);
      drawIdentification(context, text, pdfInfo);
      drawPDFLegend(context, text);
      drawCarParts(context, text, carParts);
      drawBorder(context, canvas.width, canvas.height);
      if (withCostumerPrice) {
        let price = totalPrice;

        if (insurancePrice > 0) {
          price = insurancePrice;
        }

        if (Number(costumerPrice) > 0) {
          price = Number(costumerPrice);
        }

        drawCostumerPrice(context, text, price, unmountPrice, addtionalCost);

        if (addtionalCostDescription.length > 0) {
          context.fillText(`Ammaccatura: `, 10, 300);
          context.fillText(`${addtionalCostDescription}`, 10, 330);
        }
      }
    };
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    doc.addImage(canvas, "JPEG", 204, 93, 290, 200, "", "NONE", 90);
    const fileNameSuffix = withCostumerPrice ? "-prezzo" : "";
    let plateValue = plate.length < 2 ? "assente" : plate;

    if (plate.toLocaleLowerCase() === "assente") {
      plateValue = chassisNumber;
    }

    const fileName = `${removeWhiteSpaces(CostumerName)}-${plateValue}-${date.toLocaleDateString("pt-br")}${fileNameSuffix}.pdf`;

    doc.save(fileName);
  };

  let btnText = "";

  if (withCostumerPrice) {
    btnText = "Gerar PDF com preço";
  } else {
    if (withTableHours) {
      btnText = "Gerar PDF com horas";
    } else {
      btnText = "Gerar PDF";
    }
  }

  return (
    <>
      <Canvas id={canvasID} draw={draw} height={1200} width={1200} />
      <Button fullWidth variant="contained" sx={{ mb: 1 }} onClick={handleGeneratePDF} disabled={disabled}>
        {btnText}
      </Button>
    </>
  );
};

function setBackgroundColor(context: any, text: Locale, width: number, height: number) {
  context.fillStyle = "white";
  context.fillRect(0, 0, width, height);
}

function drawCarParts(context: any, text: Locale, carParts: CarPart[]) {
  context.font = "26px Arial";
  context.fillStyle = "blue";

  carParts.forEach((part) => {
    const { x, y, relocate } = CAR_PARTS_CANVAS_COORDINATES[part.name as keyof typeof CAR_PARTS_CANVAS_COORDINATES];

    if (part.note.smashes.length === 0 && part.note.details.length === 0) {
      drawZeroText(context, x, y);
      return;
    }

    if (notesInLine.includes(part.name)) {
      drawTextInLine(context, part, x, y);
      return;
    }

    if (relocate) {
      drawArrow(context, part, x, y);
      drawRelocatedText(context, text, part, x, y);
    } else {
      drawText(context, part, x, y);
    }
  });
}

function drawZeroText(context: any, x: number, y: number) {
  context.fillText("0", x - 10, y);
}

function drawTextInLine(context: any, part: CarPart, x: number, y: number) {
  context.fillText(`${part.note.smashes} ${part.note.details.trim()}`, x, y);
}

function drawText(context: any, carPart: CarPart, x: number, y: number) {
  if (carPart.note.smashes.length > 0) {
    context.fillText(carPart.note.smashes, x, y);
    context.fillText(carPart.note.details.trim(), x + 5, y + 30);
  } else {
    context.fillText(carPart.note.details.trim(), x, y);
  }
}

function drawRelocatedText(context: any, text: Locale, carPart: CarPart, x: number, y: number) {
  if (carPart.note.smashes.length > 0) {
    context.fillText(carPart.note.smashes, x - 15, y + 240);
    context.fillText(carPart.note.details.trim(), x - 10, y + 270);
  } else {
    context.fillText(carPart.note.details.trim(), x - 15, y + 240);
  }
}

function drawIdentification(context: any, text: Locale, pdfInfoObject: PDFInfoObject) {
  const { costumer, car, finished, date, unmount, withTotalHours, totalHours } = pdfInfoObject;
  const { brand, model, plate, chassisNumber, insuranceName, color } = car;
  const { name } = costumer;

  context.font = "26px Arial";
  context.fillStyle = "black";
  context.fillText(`${text.pericia_pdf.header.chassis}: ${chassisNumber ? chassisNumber : ""}`, 830, 100);
  context.fillText(`${text.pericia_pdf.header.client_name}: ${name}`, 10, 40);
  context.fillText(`${text.pericia_pdf.header.brand}: ${brand}`, 500, 40);
  context.fillText(`${text.pericia_pdf.header.model}: ${model}`, 500, 70);
  context.fillText(`${text.pericia_pdf.header.plate}: ${plate}`, 500, 100);
  context.fillText(`${text.pericia_pdf.header.color}: ${color ? color : ""}`, 830, 40);
  context.fillText(`${text.pericia_pdf.header.insurance_name}: ${insuranceName ? insuranceName : ""}`, 830, 70);
  context.fillText(`${text.pericia_pdf.header.date}: ${date.toLocaleDateString("pt-br")}`, 10, 70);
  if (finished) {
    context.fillText(`${text.pericia_pdf.header.finished}`, 10, 100);
    context.fillStyle = "black";
  }

  if (unmount) {
    context.fillText(`${text.pericia_pdf.header.unmount}`, 10, 130);
    context.fillStyle = "blak";
  }

  if (withTotalHours) {
    context.fillText(`Ore: ${totalHours}`, 10, 160);
  }
}

function drawCostumerPrice(context: any, text: Locale, costumerPrice: number, unmountPrice: number, addtionalCost: number) {
  context.font = "24px Arial";
  context.fillStyle = "black";

  let costs = [];
  let totalPrice = 0;
  let initialY = 880;

  if (unmountPrice > 0)
    costs.push({
      name: `${text.pericia_pdf.cost_description.unmount}`,
      price: unmountPrice
    });

  if (addtionalCost > 0)
    costs.push({
      name: `${text.pericia_pdf.cost_description.additional_repair}`,
      price: addtionalCost
    });

  if (costumerPrice > 0)
    costs.push({
      name: `${text.pericia_pdf.cost_description.repair_cost}`,
      price: costumerPrice
    });

  costs.forEach((cost) => {
    totalPrice += cost.price;
    context.fillText(`${cost.name}: ${formatCurrency(cost.price)} `, 10, initialY);
    initialY += 30;
  });

  context.fillText(`${text.pericia_pdf.cost_description.total}: ${formatCurrency(totalPrice)}`, 10, initialY);
}

function drawPDFLegend(context: any, text: Locale) {
  context.font = "20px Arial";
  context.fillText(`X: ${text.pericia_pdf.legends.additional_repair}`, 1010, 1070);
  context.font = "22px Arial";
  context.fillText(`C: ${text.pericia_pdf.legends.glue}`, 1010, 1100);
  context.fillText(`V: ${text.pericia_pdf.legends.paint}`, 1010, 1130);
  context.fillText(`AL: ${text.pericia_pdf.legends.aluminium}`, 1010, 1160);
  context.fillText(`SOST: ${text.pericia_pdf.legends.change}`, 1010, 1190);
}

function drawArrow(context: any, part: CarPart, x: number, y: number) {
  const { note } = part;
  if (note.smashes.length > 0 || note.details.length > 0) {
    context.strokeStyle = "blue";
    context.beginPath();
    canvas_arrow(context, x, y, x, y + 200);
    context.stroke();
  }
}

function canvas_arrow(context: any, fromx: number, fromy: number, tox: number, toy: number) {
  var headlen = 10; // length of head in pixels
  var dx = tox - fromx;
  var dy = toy - fromy;
  var angle = Math.atan2(dy, dx);
  context.moveTo(fromx, fromy);
  context.lineTo(tox, toy);
  context.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
  context.moveTo(tox, toy);
  context.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
}

interface PDFInfoObject {
  costumer: PericiaCostumer;
  car: Car;
  finished: boolean;
  date: Date;
  unmount: boolean;
  withTotalHours: boolean;
  totalHours: number;
}

function makePDFInfoObject(
  costumer: PericiaCostumer,
  car: Car,
  finished: boolean,
  date: Date,
  unmount: boolean,
  withTotalHours: boolean,
  totalHours: number
) {
  return { costumer, car, finished, date, unmount, withTotalHours, totalHours };
}

export default PDFGenerator;
