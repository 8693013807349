import { UseQueryResult, useQuery } from "react-query";
import { createRepairRecordByUserId } from "../../../../entities/repair-record/api/create-repair-record-by-user-id";

interface UseCreateRepairRecordsByUserIdProps {
  periciaId: string;
  userId: string;
  done: boolean;
}

export const queryKeys = {
  createRepairRecordByUserId: (props: UseCreateRepairRecordsByUserIdProps) => ["createRepairRecordByUserId", props]
};

export function useCreateRepairRecordByUserId(props: UseCreateRepairRecordsByUserIdProps): UseQueryResult<boolean, Error> {
  return useQuery(queryKeys.createRepairRecordByUserId(props), () => createRepairRecordByUserId(props), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: props.periciaId !== "" && props.userId !== ""
  });
}
