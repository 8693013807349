import { Accordion, AccordionDetails, AccordionSummary, ImageList, ImageListItem, ImageListItemBar, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { PericiaPhoto } from "../../../entities/pericia-photo/model/pericia-photo";

interface PericiaPhotosListProps {
  defaultExpanded?: boolean;
  photos: PericiaPhoto[];
}

export const PericiaPhotosList: React.FC<PericiaPhotosListProps> = ({ photos, defaultExpanded = false }: PericiaPhotosListProps) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={{ marginTop: "1rem" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
        <Typography sx={{ width: "33%", flexShrink: 0 }}>Lista de fotos</Typography>
        <Typography sx={{ color: "text.secondary" }}>{`${photos.length} foto(s)`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ImageList>
          {photos.map((item) => (
            <a
              key={item.url}
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ImageListItem>
                <img
                  srcSet={`${item.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.url}?w=248&fit=crop&auto=format`}
                  alt={item.description}
                  loading="lazy"
                />
                <ImageListItemBar title={item.description} position="below" />
              </ImageListItem>
            </a>
          ))}
        </ImageList>
      </AccordionDetails>
    </Accordion>
  );
};
