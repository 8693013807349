import supabase from "../../../shared/database/supabase";
import { CustomError } from "../../../shared/error/error";

export interface UpdateInvoiceIvaPercentageRequest {
  id: string;
  new_iva_percentage: number;
}

export interface UpdateInvoiceIvaPercentageResponse {
  id: string;
}

export const updateInvoiceIvaPercentageReq = async (
  req: UpdateInvoiceIvaPercentageRequest
): Promise<UpdateInvoiceIvaPercentageResponse | Error> => {
  try {
    const { data, error } = await supabase.functions.invoke("update-invoice-iva-percentage", {
      body: JSON.stringify({ ...req })
    });

    if (error) throw error;
    if (data.error) throw data.error;

    return data;
  } catch (err) {
    const apiError = err as CustomError;
    console.error(`API Error: ${apiError.message}`);

    if (apiError && apiError.code && apiError.message && apiError.toast_message && apiError.http_status_code) {
      throw new CustomError(apiError);
    }

    throw new CustomError({});
  }
};
