import { Checkbox, FormControlLabel, FormGroup, ListItem, ListItemButton, ListItemText, TextField } from "@mui/material";
import { Pericia, getPericiaCustomPrice } from "../../../entities/pericia";
import { PaperCard } from "../../../shared/ui/paper-card/paper-card.component";
import { currencyMask, formatIntegerToCurrency } from "../../../utils/helpers/numbers";
import UpdatePericiaDialog from "../../pericia/update-pericia/ui/update-pericia-dialog.component";
import { useState } from "react";

interface CreateInvoiceListProps {
  props: Props;
}

interface Props {
  pericias: Pericia[];
  selectedPericias: Pericia[];
  filter: string;
  handleFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleToggleSelectPericia: (pericia: Pericia) => void;
  handleSelectAllPericias: () => void;
  handlePericiaUpdated: () => void;
}

const InvoiceItemsList: React.FC<CreateInvoiceListProps> = ({ props }) => {
  const {
    pericias,
    selectedPericias,
    filter,
    handleToggleSelectPericia,
    handleFilterChange,
    handleSelectAllPericias,
    handlePericiaUpdated
  } = props;
  const [updatePericiaDialogOpen, setUpdatePericiaDialogOpen] = useState(false);
  const [pericia, setPericia] = useState<Pericia | null>(null);

  const handleToggleDialog = () => {
    setUpdatePericiaDialogOpen(!updatePericiaDialogOpen);
  };

  return (
    <PaperCard title="Lista de Reparos">
      <TextField
        fullWidth
        size="medium"
        margin="dense"
        id="filter"
        label="filtro"
        variant="standard"
        value={filter}
        onChange={handleFilterChange}
        name="smallSmash"
      />
      <ListItem
        divider
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
          textAlign: "left",
          marginTop: "4rem",
          marginBottom: "1.5rem"
        }}
      >
        <FormGroup>
          <FormControlLabel
            control={<Checkbox onChange={handleSelectAllPericias} checked={pericias.length === selectedPericias.length} />}
            label=""
          />
        </FormGroup>
        {["MARCA", "MODELO", "PLACA", "CHASSI", "VALOR"].map((item, idx) => (
          <ListItemText id={`invoice-list-header-${idx}`} primary={item} sx={{ flex: "1", fontWeight: "bold" }} key={item} />
        ))}
      </ListItem>
      {pericias.map((item) => {
        const { car, id } = item;
        const labelId = `checkbox-list-label-${id}`;
        const chassisNumber = car.chassisNumber ? car.chassisNumber : "N/A";

        return (
          <ListItem
            key={id}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-end",
              textAlign: "left"
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox onChange={() => handleToggleSelectPericia(item)} checked={selectedPericias.some((item) => item.id === id)} />
                }
                label=""
              />
            </FormGroup>
            <ListItemButton
              onClick={() => {
                setPericia(item);
                handleToggleDialog();
              }}
              role={undefined}
              dense
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <ListItemText id={labelId} primary={`${car.brand}`} sx={{ flex: "1" }} />
              <ListItemText id={labelId} primary={`${car.model}`} sx={{ flex: "1" }} />
              <ListItemText id={labelId} primary={`${car.plate}`} sx={{ flex: "1" }} />
              <ListItemText id={labelId} primary={`${chassisNumber}`} sx={{ flex: "1" }} />
              <ListItemText
                id={labelId}
                primary={currencyMask(formatIntegerToCurrency(getPericiaCustomPrice(item)))}
                sx={{
                  flex: "1"
                }}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
      {pericia && (
        <UpdatePericiaDialog
          pericia={pericia}
          onPericiaUpdated={handlePericiaUpdated}
          open={updatePericiaDialogOpen}
          handleToggle={handleToggleDialog}
        />
      )}
    </PaperCard>
  );
};

export default InvoiceItemsList;
