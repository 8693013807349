import { Car } from "../../car/model/car";
import { Costumer } from "../../costumer/model/costumer";
import { CarPart, Pericia, setupCarPartProperties } from "./pericia";

export interface PericiaDTO {
  id: string;
  pricePerHour: number;
  date: Date;
  costumer: Costumer;
  car: Car;
  cofano: CarPartDTO;
  tetto: CarPartDTO;
  parafango_ad: CarPartDTO;
  parafango_as: CarPartDTO;
  parafango_pd: CarPartDTO;
  parafango_ps: CarPartDTO;
  piantone_d: CarPartDTO;
  piantone_s: CarPartDTO;
  porta_ad: CarPartDTO;
  porta_as: CarPartDTO;
  porta_pd: CarPartDTO;
  porta_ps: CarPartDTO;
  sportello_i: CarPartDTO;
  sportello_s: CarPartDTO;
  insuranceHours: number;
  totalHours: number;
  totalPrice: number;
  costumerPrice: number;
  unmountPrice: number;
  addtionalCost: number;
  addtionalCostDescription: string;
  shouldUnmount: boolean;
  finished: boolean;
  done: boolean;
  billed: boolean;
  isPriceCalculated: boolean;
  isEditable: boolean;
  priceVersion: number;
  cashPayedAmount: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface CarPartDTO {
  isAluminum: boolean;
  shouldPaint: boolean;
  shouldReplace: boolean;
  shouldGlue: boolean;
  smallSmash: number;
  smallSmashWorkingHours: number;
  smash: number;
  smashWorkingHours: number;
  price: number;
  note: string;
  useMaxNoteDetails: boolean;
  isAddtionalRepair: boolean;
  customHours: number;
  customHoursDescription: string;
}

export function mapPericiaFromRepository(pericia: PericiaDTO): Pericia {
  const {
    id,
    costumer,
    car,
    pricePerHour,
    date,
    insuranceHours,
    totalHours,
    totalPrice,
    costumerPrice,
    unmountPrice,
    addtionalCost,
    addtionalCostDescription,
    shouldUnmount,
    finished,
    done,
    billed,
    isPriceCalculated,
    isEditable,
    priceVersion,
    cashPayedAmount,
    createdAt,
    updatedAt,

    ...rest
  } = pericia;
  const carParts: CarPart[] = Object.entries(rest).map(([key, value]) => {
    return setupCarPartProperties(
      {
        ...value,
        name: key,
        workingHours: 0,
        note: { smashes: "", details: "" }
      },
      pricePerHour,
      priceVersion
    );
  });

  return {
    id,
    costumer,
    car,
    pricePerHour,
    carParts,
    insuranceHours,
    totalHours,
    totalPrice,
    costumerPrice,
    addtionalCost,
    addtionalCostDescription,
    unmountPrice,
    shouldUnmount: shouldUnmount || false,
    finished,
    done,
    billed,
    isPriceCalculated,
    isEditable,
    priceVersion,
    cashPayedAmount,
    date: new Date(date),
    createdAt: new Date(createdAt),
    updatedAt: new Date(updatedAt)
  };
}

export function periciaDTOFactory(override?: Partial<PericiaDTO>): PericiaDTO {
  return {
    id: "1",
    costumer: {
      id: "1",
      name: "name",
      phone: "phone",
      phone2: "phone2",
      email: "email",
      address: "address",
      fullAddress: "fullAddress",
      priceVersion: 2,
      language: "language",
      createdAt: new Date(),
      updatedAt: new Date()
    },
    car: {
      id: "1",
      brand: "brand",
      model: "model",
      color: "color",
      chassisNumber: "chassisNumber",
      insuranceName: "insuranceName",
      plate: "plate",
      createdAt: new Date(),
      updatedAt: new Date()
    },
    pricePerHour: 100,
    date: new Date(),
    insuranceHours: 0,
    totalHours: 0,
    totalPrice: 0,
    costumerPrice: 0,
    unmountPrice: 0,
    addtionalCost: 0,
    addtionalCostDescription: "addtionalCostDescription",
    shouldUnmount: false,
    finished: false,
    done: false,
    billed: false,
    isPriceCalculated: false,
    isEditable: false,
    priceVersion: 1,
    cashPayedAmount: 0,
    tetto: carPartDTOFactory(),
    cofano: carPartDTOFactory(),
    parafango_ad: carPartDTOFactory(),
    parafango_as: carPartDTOFactory(),
    parafango_pd: carPartDTOFactory(),
    parafango_ps: carPartDTOFactory(),
    piantone_d: carPartDTOFactory(),
    piantone_s: carPartDTOFactory(),
    porta_ad: carPartDTOFactory(),
    porta_as: carPartDTOFactory(),
    porta_pd: carPartDTOFactory(),
    porta_ps: carPartDTOFactory(),
    sportello_i: carPartDTOFactory(),
    sportello_s: carPartDTOFactory(),
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override
  };
}

export function carPartDTOFactory(override?: Partial<CarPartDTO>): CarPartDTO {
  return {
    isAluminum: false,
    shouldPaint: false,
    shouldReplace: false,
    shouldGlue: false,
    smallSmash: 0,
    smallSmashWorkingHours: 0,
    smash: 0,
    smashWorkingHours: 0,
    price: 0,
    note: "",
    useMaxNoteDetails: false,
    isAddtionalRepair: false,
    customHours: 0,
    customHoursDescription: "",
    ...override
  };
}
