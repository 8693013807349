import { UseQueryResult, useQuery } from "react-query";
import { Invoice } from "../../../../entities/invoice/model/invoice";
import { updateInvoiceCreationDate } from "../../../../entities/invoice/api/update-invoice-date";

interface UpdateInvoiceCreationDateProps {
  id: string;
  date: Date;
  shouldUpdate: boolean;
}

export const queryKeys = {
  updateInvoiceCreationDate: (props: UpdateInvoiceCreationDateProps) => ["updateInvoiceCreationDate", props]
};

export function useUpdateInvoiceDate(props: UpdateInvoiceCreationDateProps): UseQueryResult<Invoice, Error> {
  return useQuery(
    queryKeys.updateInvoiceCreationDate(props),
    () => updateInvoiceCreationDate({ ...props, new_created_at_date: props.date.toISOString() }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: props.id !== "" && props.shouldUpdate
    }
  );
}
