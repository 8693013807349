import { Paper, Typography } from "@mui/material";
import { PaperCard } from "../../../shared/ui/paper-card/paper-card.component";

interface PericiaInvoiceProps {
  props: Props;
}

interface Props {
  invoiceNumber: number;
}

export const PericiaInvoce: React.FC<PericiaInvoiceProps> = ({ props }) => {
  const { invoiceNumber } = props;
  return (
    <PaperCard title="Informação sobre fatura">
      <Typography component="h1" variant="body1" mt={2}>
        {invoiceNumber > 0 ? `Fatura ${invoiceNumber}` : "Sem fatura"}
      </Typography>
    </PaperCard>
  );
};
