import { UseQueryResult, useQuery } from "react-query";
import { createUserNotificationReq } from "../../../entities/user-notification/api/create-user-notification";

export const queryKeys = {
  createUserNotification: (ids: string[]) => ["createUserNotification", ids]
};

export function useCreateUserNotification(ids: string[]): UseQueryResult<boolean, Error> {
  const req = ids.map((id) => id);

  return useQuery(queryKeys.createUserNotification(req), () => createUserNotificationReq({ notification_ids: req }), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: ids.length > 0
  });
}
