import { Costumer, CostumerRepositoryUpdate, CostumerUpdate } from "../../../models/costumer/costumer";
import { CostumerRepositoryInsert } from "../../../models/costumer/costumer.repository";
import { CostumerPriceRepository, CostumerRepository } from "../../../models/pericia/pericia.repository";
import { Costumer as CostumerV2 } from "../../../domain/costumer";
import supabase from "../../../shared/database/supabase";

export const getCostumers = async () => {
  const { data, error } = await supabase.from<CostumerRepository>("costumers").select("*");
  if (error) {
    return { data: null, error };
  }

  return { data, error: null };
};

export const getCostumersV2 = async () => {
  const { data, error } = await supabase
    .from<CostumerV2>("costumers")
    .select("id, name, address, phone, phone2, email, language, createdAt: created_at, updatedAt: updated_at, fullAddress: full_address");
  if (error) {
    return { data: null, error };
  }

  return { data, error: null };
};

export const createCostumer = async (costumer: Costumer) => {
  const { fullAddress, ...rest } = costumer;
  try {
    const { data, error } = await supabase.from<CostumerRepositoryInsert>("costumers").insert({
      ...rest,
      full_address: fullAddress
    });
    if (error) {
      return { data: null, error };
    }

    return { data: data[0], error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveCostumerPrice = async (costumerPrice: CostumerPriceRepository) => {
  const { id, costumer_price } = costumerPrice;
  try {
    const { data, error } = await supabase.from("pericias").update({ costumer_price }).eq("id", id);
    if (error) {
      return { data: null, error };
    }

    return { data: data[0].id, error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCostumerById = async (id: string) => {
  try {
    const { data, error } = await supabase.from<CostumerRepository>("costumers").select().eq("id", id);
    if (error) {
      return { data: null, error };
    }

    return { data: data[0], error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const upsertCostumer = async (costumer: CostumerUpdate) => {
  const { fullAddress, ...rest } = costumer;
  try {
    const { data, error } = await supabase
      .from<CostumerRepositoryUpdate>("costumers")
      .update({
        ...rest,
        full_address: fullAddress
      })
      .eq("id", costumer.id);
    if (error) {
      return { data: null, error };
    }

    return { data: data[0], error: null };
  } catch (err) {
    console.log(err);
    throw err;
  }
};
