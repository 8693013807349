import { Navigate, Route, Routes } from "react-router-dom";
import supabase from "../../shared/database/supabase";
import { USER_ROLES, UserWithRole } from "../../contexts/user/user.context";
import { CreateInvoicePage } from "../../pages/invoice/create-invoice.page";
import { UpdateInvoicePage } from "../../pages/invoice/update-invoice.page";
import { ListInvoicesPage } from "../../pages/invoice/list-invoices.page";

const InvoiceRoutes = () => {
  const user = supabase.auth.user() as UserWithRole;
  user.isAdmin = user.user_metadata?.role === "admin";
  user.name = user.user_metadata?.name;
  user.permission = user.user_metadata?.role || USER_ROLES.USER;

  if (user.permission === USER_ROLES.USER) {
    return <Navigate to={"/"} />;
  }

  return (
    <Routes>
      <Route path="/create" element={<CreateInvoicePage />} />
      <Route path="/list" element={<ListInvoicesPage />} />
      <Route path=":invoiceID" element={<UpdateInvoicePage />} />
    </Routes>
  );
};

export default InvoiceRoutes;
