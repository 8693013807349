import { UseQueryResult, useQuery } from "react-query";
import { PericiaMetricDTO } from "../../../../entities/pericia/model/dto";
import { getPericiasMetricsRequest } from "../../../../entities/pericia/api/get-pericias-metrics";

export interface UseGetPericiasMetricsProps {
  startDate: Date;
  endDate: Date;
  enabled: boolean;
}

export const queryKeys = {
  getPericiasMetrics: (props: UseGetPericiasMetricsProps) => ["getPericiasMetrics", props]
};

export function useGetPericiasMetrics(props: UseGetPericiasMetricsProps): UseQueryResult<PericiaMetricDTO[]> {
  const args = {
    ...props,
    start_date: props.startDate.toISOString().split("T")[0],
    end_date: props.endDate.toISOString().split("T")[0]
  };

  return useQuery(queryKeys.getPericiasMetrics(args), () => getPericiasMetricsRequest(args), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: props.enabled && Boolean(props.startDate) && Boolean(props.endDate)
  });
}
