import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import CostumerAutocompleteSearchBar from "../../../../entities/costumer/ui/costumer-autocomplete-search-bar.component";
import { LoadingButton } from "@mui/lab";
import { Costumer } from "../../../../entities/costumer";

interface RepairRecordsSearchProps {
  props: Props;
}

interface Props {
  isLoading: boolean;
  costumer: Costumer;
  updateCostumer: (costumer: Costumer) => void;
  date: Date;
  updateDate: (date: Date) => void;
  handleListRepairRecords: () => void;
  useFilter: boolean;
  handleToggleUseFilter: () => void;
}

export const RepairRecordsSearchComponent: React.FC<
  RepairRecordsSearchProps
> = ({ props }) => {
  const {
    costumer,
    updateCostumer,
    date,
    updateDate,
    handleListRepairRecords,
    useFilter,
    handleToggleUseFilter,
    isLoading,
  } = props;

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 5,
        width: "100%",
        borderRadius: "8px",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <DatePicker
                label="Mes"
                openTo="month"
                views={["month", "year"]}
                maxDate={new Date()}
                value={date}
                onChange={(newValue: any) => {
                  if (newValue) updateDate(new Date(newValue));
                }}
                renderInput={(params: any) => (
                  <TextField {...params} variant="standard" />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={12}>
          <CostumerAutocompleteSearchBar props={{ costumer, updateCostumer }} />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleToggleUseFilter}
                  checked={useFilter}
                />
              }
              label="Filtro Por Cliente"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={12}>
          <LoadingButton
            fullWidth
            variant="contained"
            sx={{ mb: 1, mt: 1 }}
            loading={isLoading}
            onClick={handleListRepairRecords}
          >
            BUSCAR REPAROS
          </LoadingButton>
        </Grid>
      </Grid>
    </Paper>
  );
};
