import { UseQueryResult, useQuery } from "react-query";
import { listRepairRecordsByDateRange } from "../../../../entities/repair-record/api/list-repair-record-by-date-range";
import { RepairRecord } from "../../../../entities/repair-record";

interface UseListRepairRecordsByDateRangeProps {
  id_user: string;
  start: Date;
  end: Date;
}

export const queryKeys = {
  listRepairRecordsByDateRangeProps: (props: UseListRepairRecordsByDateRangeProps) => ["listRepairRecordsByDateRange", props]
};

export function useListRepairRecordsByDateRange(props: UseListRepairRecordsByDateRangeProps): UseQueryResult<RepairRecord[], Error> {
  return useQuery(queryKeys.listRepairRecordsByDateRangeProps(props), () => listRepairRecordsByDateRange(props), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: props.id_user !== "" && props.start < props.end
  });
}
