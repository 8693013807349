import { Checkbox, FormControlLabel, FormGroup, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Pericia, getPericiaCustomPriceCurrencyFormat } from "../../../../entities/pericia";
import { useNavigate } from "react-router-dom";

interface CostumerPericiasListProps {
  pericias: Pericia[];
}

export const CostumerPericiasList: React.FC<CostumerPericiasListProps> = (props: CostumerPericiasListProps) => {
  const { pericias } = props;
  const navigate = useNavigate();

  if (!pericias) {
    return <></>;
  }

  return (
    <>
      {pericias.map((item) => {
        const { car, id, date, billed } = item;
        const labelId = `checkbox-list-label-${id}`;

        return (
          <ListItem key={id} divider>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={billed} disabled />} label="Faturado" />
            </FormGroup>
            <ListItemButton
              onClick={() => navigate(`/pericias/${id}`)}
              role={undefined}
              dense
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                textAlign: "left"
              }}
            >
              <ListItemText id={labelId} primary={`${car.plate}`} sx={{ flex: "1" }} />
              <ListItemText id={labelId} primary={`${car.model}`} sx={{ flex: "1" }} />
              <ListItemText
                id={labelId}
                primary={new Date(date).toLocaleDateString("pt-BR")}
                sx={{
                  flex: "1"
                }}
              />
              <ListItemText
                id={labelId}
                primary={getPericiaCustomPriceCurrencyFormat(item)}
                sx={{
                  flex: "1"
                }}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </>
  );
};
