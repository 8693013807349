import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface CreateRepairRecordDialogProps {
  props: Props;
}

interface Props {
  open: boolean;
  loading: boolean;
  handleClose: () => void;
  handleClickConfirm: () => void;
}

const CreateRepairRecordDialogComponent: React.FC<
  CreateRepairRecordDialogProps
> = ({ props }) => {
  const { open, loading, handleClose, handleClickConfirm } = props;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Iniciar Reparo</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Tem certeza que você quer iniciar este reparo?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} onClick={handleClose}>
          Cancelar
        </LoadingButton>
        <LoadingButton
          loading={loading}
          onClick={handleClickConfirm}
          color="error"
        >
          Confirmar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateRepairRecordDialogComponent;
