import supabase from "../../../shared/database/supabase";
import { PericiaMetricByCostumersDTO, mergePericiaMetricsByCostumers } from "../model/dto";

export interface GetPericiasMetricsByCostumersRequest {
  id_costumer: string;
  id_costumer_2: string;
  start_date: string;
  end_date: string;
}

export const getPericiasMetricsByCostumersRequest = async (
  args: GetPericiasMetricsByCostumersRequest
): Promise<PericiaMetricByCostumersDTO[]> => {
  try {
    const { id_costumer, id_costumer_2, start_date, end_date } = args;
    const { data, error } = await supabase.functions.invoke("get-pericias-metrics-by-costumer", {
      body: JSON.stringify({ start_date, end_date, id_costumer: id_costumer })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    const { data: data2, error: error2 } = await supabase.functions.invoke("get-pericias-metrics-by-costumer", {
      body: JSON.stringify({ start_date, end_date, id_costumer: id_costumer_2 })
    });
    if (error2) throw error2;

    if (data2.error) throw data2.error;

    return mergePericiaMetricsByCostumers(data.metrics, data2.metrics).sort((a, b) => a.date.localeCompare(b.date));
  } catch (err) {
    console.log("error fetching pericias metrics by costumers:", err);
    throw err;
  }
};
