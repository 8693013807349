import { Grid, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RepairRecord } from "../../../../entities/repair-record";
import FlexContainer from "../../../../shared/ui/container/flex-container.component";
import Title from "../../../../components/typography/title.component";
import { formatCurrency } from "../../../../utils/helpers/numbers";

interface RepairRecordsListProps {
  props: Props;
}

interface Props {
  repairRecords: RepairRecord[];
}

const RepairRecordsList: React.FC<RepairRecordsListProps> = ({ props }) => {
  const navigate = useNavigate();
  const { repairRecords } = props;

  if (repairRecords.length === 0) {
    return <></>;
  }

  return (
    <FlexContainer>
      <Title value="Reparos" />
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={12}>
          <ListItem
            divider
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "left"
            }}
          >
            {["CLIENTE", "CARRO", "PLACA", "CHASSI", "DATA", "VALOR"].map((item, idx) => (
              <ListItemText
                id={`record-list-header-${idx}`}
                key={`record-list-header-${idx}`}
                primary={item}
                sx={{ flex: "1", fontWeight: "bold" }}
              />
            ))}
          </ListItem>
        </Grid>
        <Grid item xs={12} sm={12} mb={6}>
          {repairRecords.map((record) => (
            <ListItem key={record.id} divider>
              <ListItemButton
                onClick={() => navigate(`/mechanics/pericias/${record.pericia.id}`)}
                role={undefined}
                dense
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textAlign: "left"
                }}
              >
                <ListItemText
                  id={`${record.id}-name`}
                  key={`${record.id}-name`}
                  primary={`${record.pericia.costumer.name}`}
                  sx={{ flex: "1" }}
                />
                <ListItemText
                  id={`${record.id}-model`}
                  key={`${record.id}-model`}
                  primary={`${record.pericia.car.model}`}
                  sx={{ flex: "1" }}
                />
                <ListItemText
                  id={`${record.id}-plate`}
                  key={`${record.id}-plate`}
                  primary={`${record.pericia.car.plate}`}
                  sx={{ flex: "1" }}
                />
                <ListItemText
                  id={`${record.id}-chassis`}
                  key={`${record.id}-chassis`}
                  primary={`${record.pericia.car.chassisNumber}`}
                  sx={{ flex: "1" }}
                />
                <ListItemText
                  id={record.id}
                  primary={new Date(record.finishedAt || record.createdAt).toLocaleDateString("pt-BR")}
                  secondary={record.finishedAt ? "Finalização" : "Criação"}
                  sx={{
                    flex: "1",
                    color: !record.finishedAt ? "red" : "black"
                  }}
                />
                <ListItemText
                  id={`${record.id}-payedValue`}
                  key={`${record.id}-payedValue`}
                  primary={formatCurrency(record.payedValue)}
                  sx={{ flex: "1" }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </Grid>
      </Grid>
    </FlexContainer>
  );
};

export default RepairRecordsList;
