import { Box, Grid, Typography } from "@mui/material";
import { Bar, BarChart, CartesianGrid, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { LoadingButton } from "@mui/lab";
import {
  AVERAGE_CREATED_ITA,
  CHART_CREATED_ITA,
  PERICIAS_CREATED_ITA,
  TIME_ITA,
  TOTAL_CREATED_ITA
} from "../../../../shared/constants/metric-chart-texts";
import { ProfitMetricDTO } from "../../../../entities/metrics/model/profit.model";
import { formatIntegerToCurrency } from "../../../../utils/helpers/numbers";

interface Props {
  metrics: ProfitMetricDTO[];
  profitInfo: ProfitInfo;
  timeFrame: string;
  startDate: string;
  endDate: string;
}

interface ProfitInfo {
  totalProfit: string;
  average: string;
}

const NUOVAUTO_LOGO = require("../../../../assets/nuovauto.png");

export const NetProfitBarchart = ({ metrics, timeFrame, startDate, endDate, profitInfo }: Props) => {
  const time = timeFrame.split("-")[1];
  const unit = timeFrame.split("-")[0];

  const chartRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => chartRef.current
  });

  return (
    <>
      <Grid container spacing={2} ref={chartRef} mt={5}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box component="img" src={NUOVAUTO_LOGO} ml={5} mb={2} sx={{ width: "320px" }} />
          <Box>
            <Typography variant="h4" ml={5}>
              Lucro Liquido
            </Typography>
          </Box>
        </Box>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ResponsiveContainer width="100%" height={550}>
            <BarChart
              width={500}
              height={300}
              data={metrics}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="day" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey={(value) => +formatIntegerToCurrency(value.profit)}
                name="Lucro Liquido/CHF"
                fill="#82ca9d"
                activeBar={<Rectangle fill="gold" stroke="purple" />}
              />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
        <Box ml={5} mt={5}>
          <Typography variant="body1">Lucro total líquido: {profitInfo.totalProfit}</Typography>
          <Typography variant="body1">Lucro líquido médio: {`${profitInfo.average}/${unit}`}</Typography>
        </Box>
        <Box ml={5} mb={5} mt={5}>
          <Typography variant="body1">
            {TIME_ITA}: {`${time} ${unit}`}
          </Typography>
        </Box>
      </Grid>
      <LoadingButton variant="outlined" onClick={handlePrint}>
        print
      </LoadingButton>
    </>
  );
};
