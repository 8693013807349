import { Grid, TextField } from "@mui/material";
import { PaperCard } from "../../../shared/ui/paper-card/paper-card.component";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useCreatePericiaNote } from "../api/useCreatePericiaNote";
import { toast } from "react-toastify";

interface CreatePericiaNoteFormProps {
  id_pericia: string;
  onPericiaNoteCreated: () => void;
}

interface PericiaNote {
  id_pericia: string;
  note: string;
}

const INITIAL_PERICIA_NOTE: PericiaNote = {
  id_pericia: "",
  note: ""
};

export const CreatePericiaNoteForm: React.FC<CreatePericiaNoteFormProps> = ({ id_pericia, onPericiaNoteCreated }) => {
  const [note, setNote] = useState("");
  const [periciaNote, setPericiaNote] = useState<PericiaNote>(INITIAL_PERICIA_NOTE);
  const { data: createPericiaNoteRes, error: errorCreatingPericiaNote, isLoading } = useCreatePericiaNote({ ...periciaNote });

  const handleCreatePericiaNote = () => {
    setPericiaNote({ id_pericia, note });
  };

  useEffect(() => {
    if (!createPericiaNoteRes) return;

    setPericiaNote(INITIAL_PERICIA_NOTE);
    setNote("");
    onPericiaNoteCreated();
  }, [createPericiaNoteRes]);

  useEffect(() => {
    if (!errorCreatingPericiaNote) return;

    toast.error("Erro ao criar observação sobre a perícia", { autoClose: 2000 });
  }, [errorCreatingPericiaNote]);

  return (
    <PaperCard title="Criar observação sobre a perícia">
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={12}>
          <TextField
            autoComplete="off"
            fullWidth
            id="createPericiaNoteForm"
            label="Digite aqui a observação sobre a perícia"
            name="addtional_cost"
            variant="standard"
            onChange={(e) => setNote(e.target.value)}
            value={note}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <LoadingButton variant="contained" loading={isLoading} onClick={handleCreatePericiaNote}>
            Salvar
          </LoadingButton>
        </Grid>
      </Grid>
    </PaperCard>
  );
};
