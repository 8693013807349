import { UseQueryResult, useQuery } from "react-query";
import { ListPericiaPhotosResponse, listPericiaPhotos } from "../../../entities/pericia-photo/api/list-pericia-photos";

export interface UseListPericiaPhotosProps {
  id_pericia: string;
}

export const queryKeys = {
  listPericiaPhotos: (props: UseListPericiaPhotosProps) => ["listPericiaPhotos", props]
};

export function useListPericiaPhotos({ id_pericia }: UseListPericiaPhotosProps): UseQueryResult<ListPericiaPhotosResponse> {
  return useQuery(queryKeys.listPericiaPhotos({ id_pericia }), () => listPericiaPhotos({ id_pericia }), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: id_pericia !== "" && id_pericia.length > 3
  });
}
