import jsPDF from "jspdf";
import { useCallback } from "react";
import { PericiaInvoiceItem, getPericiaCustomPriceCurrencyFormat } from "../../../../entities/pericia";
import { currencyMask, formatIntegerToCurrency } from "../../../../utils/helpers/numbers";
import { itemsPerPage } from "../../../../shared/helpers/array";

interface ListPericiasByDatePDFProps {
  pericias: PericiaInvoiceItem[];
  filter: string;
  start: Date;
  end: Date;
  totalPrice: number;
  displayFields: SelectedFieldsToDisplay;
}

interface SelectedFieldsToDisplay {
  displayPlate: boolean;
  displayChassis: boolean;
  displayDate: boolean;
  displayPrice: boolean;
  displayUserName: boolean;
  displayTotalHours: boolean;
}

const create = async (props: ListPericiasByDatePDFProps) => {
  const { pericias, filter, start, end, totalPrice, displayFields } = props;

  let selectedPericiasLabel = "";

  switch (filter) {
    case "done":
      selectedPericiasLabel = "Macchine finite";
      break;
    case "notDone":
      selectedPericiasLabel = "Macchine in aperto";
      break;
    case "billed":
      selectedPericiasLabel = "Macchine fatturate";
      break;
    case "isPriceCalculated":
      selectedPericiasLabel = "Macchine controlate";
      break;
    default:
      selectedPericiasLabel = "Macchine";
  }

  const logoImg = require("../../../../assets/nuovauto.png");

  //Header information
  const pdf = new jsPDF("p", "mm", "a4");
  pdf.addImage(logoImg, "PNG", 16, 12, 90, 24);

  pdf.setFontSize(12);
  pdf.setFont("helvetica", "bold");

  pdf.text(`${selectedPericiasLabel}`, 134, 18);
  pdf.text(`Periodo: ${start.toLocaleDateString("pt-BR")} ${end.toLocaleDateString("pt-BR")}`, 134, 24);
  pdf.text(`Totale di Perizie: ${pericias.length}`, 134, 30);

  if (displayFields.displayPrice) {
    pdf.text(`Totale: ${currencyMask(formatIntegerToCurrency(totalPrice))}`, 134, 36);
  }

  pdf.text(`Generato in: ${new Date().toLocaleDateString("pt-BR")}`, 16, 62);

  let headerInitialX = 16;

  pdf.text(`Carrozeria`, headerInitialX, 80);

  headerInitialX = headerInitialX > 16 ? headerInitialX + 35 : headerInitialX + 40;

  pdf.text(`Macchina`, headerInitialX, 80);

  if (displayFields.displayPlate) {
    headerInitialX = headerInitialX > 16 ? headerInitialX + 35 : headerInitialX + 40;
    pdf.text(`Targa (Telaio)`, headerInitialX, 80);
  }

  if (displayFields.displayDate) {
    headerInitialX = headerInitialX > 16 ? headerInitialX + 45 : headerInitialX + 50;
    pdf.text(`Data`, headerInitialX, 80);
  }

  if (displayFields.displayUserName) {
    headerInitialX = headerInitialX > 16 ? headerInitialX + 35 : headerInitialX + 50;
    pdf.text(`Tecnico`, headerInitialX, 80);
  }

  if (displayFields.displayPrice) {
    headerInitialX = headerInitialX > 16 ? headerInitialX + 35 : headerInitialX + 50;
    pdf.text(`Prezzo`, headerInitialX, 80);
  }

  if (displayFields.displayTotalHours) {
    headerInitialX = headerInitialX > 16 ? headerInitialX + 35 : headerInitialX + 50;
    pdf.text(`Ore`, headerInitialX, 80);
  }

  //pericias information
  pdf.setFont("helvetica", "normal");

  const pages = itemsPerPage(pericias, 28);

  pdf.setFontSize(11);
  pages.forEach((items, index) => {
    let finalY = 0;
    items.forEach((item, itemIndex) => {
      const yOffset = index === 0 ? 90 : 40;
      const row = itemIndex + 1;
      const y = row * 6 + yOffset;
      let x = 16;

      const { car } = item;
      const { brand, model, plate, chassisNumber } = car;
      const chassis = chassisNumber.length > 2 ? chassisNumber : "-";
      const carIdentifier = `${brand} ${model}`;

      pdf.text(`${item.costumer.name.slice(0, 20)}`, x, y);

      x = x > 16 ? x + 35 : x + 40;

      pdf.text(`${carIdentifier.slice(0, 15)}`, x, y);

      if (displayFields.displayPlate) {
        x = x > 16 ? x + 35 : x + 40;
        pdf.text(`${plate} (${chassis})`, x, y);
      }

      if (displayFields.displayDate) {
        x = x > 16 ? x + 45 : x + 50;
        pdf.text(`${item.date.toLocaleDateString("pt-BR")}`, x, y);
      }

      if (displayFields.displayUserName) {
        x = x > 16 ? x + 35 : x + 50;
        pdf.text(`${item.repairRecords[0] ? item.repairRecords[0].user.display_name : "-"}`, x, y);
      }

      if (displayFields.displayPrice) {
        x = x > 16 ? x + 35 : x + 50;
        pdf.text(`${getPericiaCustomPriceCurrencyFormat(item)}`, x, y);
      }

      if (displayFields.displayTotalHours) {
        x = x > 16 ? x + 35 : x + 50;
        pdf.text(`${item.totalHours}`, x, y);
      }

      finalY = y;
    });

    if (pages.indexOf(items) !== pages.length - 1) {
      pdf.addPage();
    } else {
      if (displayFields.displayPrice) {
        pdf.text(`Totale: ${currencyMask(formatIntegerToCurrency(totalPrice))}`, 150, finalY + 10);
      }
    }
  });

  pdf.save(`${selectedPericiasLabel}_pericias_${new Date().toLocaleDateString("pt-BR")}.pdf`);
};

export function useListPericiasByDateRangePDFCreator() {
  const createPDF = useCallback((props: ListPericiasByDatePDFProps) => {
    create(props);
  }, []);

  return { createPDF };
}
