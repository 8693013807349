import { UseQueryResult, useQuery } from "react-query";
import { UpdateInvoiceTextResponse, updateInvoiceText } from "../../../../entities/invoice/api/update-invoice-text";

interface UseUpdateInvoiceTextProps {
  id: string;
  new_invoice_text: string;
}

export const queryKeys = {
  updateInvoiceText: (props: UseUpdateInvoiceTextProps) => ["updateInvoiceText", props]
};

export function useUpdateInvoiceText(props: UseUpdateInvoiceTextProps): UseQueryResult<UpdateInvoiceTextResponse, Error> {
  return useQuery(queryKeys.updateInvoiceText(props), () => updateInvoiceText(props), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: props.id !== "" && props.new_invoice_text.length > 0
  });
}
