import supabase from "../../../shared/database/supabase";

export const getInvoiceNextCustomCount = async (): Promise<number> => {
  try {
    const { data, error } = await supabase.functions.invoke("get-invoice-custom-count");
    if (error) throw error;

    if (data.error) throw data.error;

    return data.next_value;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
