import { Box, TextField } from "@mui/material";
import { PaperCard } from "../../../../shared/ui/paper-card/paper-card.component";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { currencyMask, formatIntegerToCurrency, getNumberFromCurrencyMask } from "../../../../utils/helpers/numbers";
import { useUpdatePericiaCashPayedAmount, UseUpdatePericiaCashPayedAmountProps } from "../api/useUpdatePericiaCashPayedAmount";

export type CashPayedAmountComponentProps = {
  id: string;
  cashPayedAmount: number;
  disabled: boolean;
  onCashPayedAmountSaved: () => void;
  repairRecountsCount: number;
};

export const CashPayedAmountComponent = ({
  id,
  cashPayedAmount,
  disabled,
  repairRecountsCount,
  onCashPayedAmountSaved
}: CashPayedAmountComponentProps) => {
  const [_props, set_props] = useState<UseUpdatePericiaCashPayedAmountProps>({
    id,
    cashPayedAmount,
    enabled: false
  });
  const { data, error, isLoading } = useUpdatePericiaCashPayedAmount({ ..._props });

  const handle_CashPayedAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    set_props({ ..._props, cashPayedAmount: getNumberFromCurrencyMask(e.target.value) });
  };

  const handleSaveCashPayedAmount = () => {
    set_props({ ..._props, enabled: true, cashPayedAmount: _props.cashPayedAmount / repairRecountsCount });
  };

  useEffect(() => {
    set_props((p) => ({ ...p, cashPayedAmount, id }));
  }, [cashPayedAmount, id]);

  useEffect(() => {
    if (data) onCashPayedAmountSaved();
  }, [data]);

  return (
    <PaperCard title="Valor pago em dinheiro">
      <Box sx={{ mb: 2 }} />
      <TextField
        autoComplete="off"
        fullWidth
        id="cashPayedAmount"
        name="cashPayedAmount"
        variant="standard"
        sx={{ mb: 2 }}
        onChange={handle_CashPayedAmountChange}
        value={currencyMask(formatIntegerToCurrency(_props.cashPayedAmount * repairRecountsCount))}
        disabled={disabled}
      />

      <LoadingButton
        fullWidth
        variant="contained"
        sx={{ mt: 2 }}
        loading={isLoading}
        disabled={disabled}
        onClick={handleSaveCashPayedAmount}
      >
        {disabled ? "Pagamento já registrado" : "Salvar"}
      </LoadingButton>
    </PaperCard>
  );
};
