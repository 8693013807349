import { UseQueryResult, useQuery } from "react-query";
import { User } from "../model";
import { listUsers } from "./list-users";

export const queryKeys = {
  listUsers: () => "listUsers",
};

export function useListUsers(): UseQueryResult<User[], Error> {
  return useQuery(queryKeys.listUsers(), () => listUsers(), {
    retry: false,
    refetchOnWindowFocus: false,
  });
}
