import { Route, Routes } from "react-router-dom";
import AddCostumer from "./add-costumer/add-costumer.component";
import EditCostumer from "./edit-costumer/edit-costumer.component";
import { PendingPericiasPage } from "../../pages/pericia/list-pericias-by-costumer";
import ListCostumersPage from "../../pages/costumer/list-costumers.page";
import ListCostumerPericiasPage from "../../pages/pericia/list-pericias-by-costumer-id.page";

const CostumersRoutes = () => {
  return (
    <Routes>
      <Route path="/add" element={<AddCostumer />} />
      <Route path="/list" element={<ListCostumersPage />} />
      {/* <Route path="/list/pericias2" element={<ListPericias />} /> */}
      <Route path="/list/pericias" element={<ListCostumerPericiasPage />} />
      <Route path="/list/pericias/pending" element={<PendingPericiasPage />} />
      <Route path=":costumerID" element={<EditCostumer />} />
    </Routes>
  );
};

export default CostumersRoutes;
