import List from "@mui/material/List";
import { Box, Container, Grid, TextField, Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { ChangeEvent, useEffect, useState } from "react";
import { debounce } from "lodash";
import { Costumer } from "../../entities/costumer";
import { useListCostumers } from "../../features/costumer/list-costumers/api/useListCostumers";
import { ListSkeleton } from "../../shared/ui/skeleton/list-skeleton.component";
import { CostumersList } from "../../entities/costumer/ui/costumers-list.component";

const ListCostumersPage = () => {
  const [costumersFiltered, setCostumersFiltered] = useState([] as Costumer[]);
  const { data: costumers, isLoading, error } = useListCostumers();

  const handleFilter = debounce((e: ChangeEvent<HTMLInputElement>) => {
    if (!costumers) return;

    const { value } = e.target;
    const filteredCostumers = filterCostumers(costumers, value);
    setCostumersFiltered(filteredCostumers);
  }, 200);

  useEffect(() => {
    setCostumersFiltered(costumers || []);
  }, [costumers]);

  if (error) toast.error("Erro ao carregar clientes");

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "72vh",
        }}
      >
        <Typography component="h1" variant="h5" mb={5}>
          Clientes Cadastrados
        </Typography>
        <Grid container spacing={2} mb={5}>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              id="filter"
              label="Filtro"
              name="filter"
              variant="standard"
              onChange={handleFilter}
            />
          </Grid>
        </Grid>
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
          }}
        >
          {isLoading && <ListSkeleton quantity={9} />}
          {!isLoading && costumersFiltered.length > 0 && (
            <CostumersList costumers={costumersFiltered} />
          )}
        </List>
      </Box>
      <ToastContainer />
    </Container>
  );
};

const filterCostumers = (costumers: Costumer[], term: string) => {
  return costumers.filter((costumer) => {
    return costumer.name.toLowerCase().includes(term.toLowerCase());
  });
};

export default ListCostumersPage;
