import supabase from "../../../shared/database/supabase";
import { CustomError } from "../../../shared/error/error";
import { PericiaComment } from "../model/pericia-comment";

export interface ListPericiaCommentsRequest {
  id_pericia: string;
}

export interface ListPericiaCommentsResponse {
  comments: PericiaComment[];
}

export const listPericiaCommentsReq = async ({ id_pericia }: ListPericiaCommentsRequest): Promise<ListPericiaCommentsResponse> => {
  try {
    const { data, error } = await supabase.functions.invoke("list-pericia-comments", {
      body: JSON.stringify({ id_pericia })
    });

    if (error) throw error;
    if (data.error) throw data.error;

    return {
      comments: data.comments.map((comment: any) => {
        return {
          id: comment.id,
          comment: comment.comment,
          periciaId: comment.id_pericia,
          user: {
            id: comment.user.id,
            name: comment.user.name,
            displayName: comment.user.displayName
          },
          deleted: comment.deleted,
          createdAt: new Date(comment.createdAt),
          updatedAt: new Date(comment.updatedAt),
          deletedAt: comment.deletedAt ? new Date(comment.deletedAt) : null
        };
      }) as PericiaComment[]
    };
  } catch (err) {
    const apiError = err as CustomError;
    console.error(`API Error: ${apiError.message}`);

    if (apiError && apiError.code && apiError.message && apiError.toast_message && apiError.http_status_code) {
      throw new CustomError(apiError);
    }

    throw new CustomError({});
  }
};
