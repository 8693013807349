import supabase from "../../../shared/database/supabase";

interface CreateRepairRecordByUserIdRequest {
  userId: string;
  periciaId: string;
  done: boolean;
}

export const createRepairRecordByUserId = async (req: CreateRepairRecordByUserIdRequest): Promise<Boolean | Error> => {
  try {
    const { data, error } = await supabase.functions.invoke("create-repair-record", {
      body: JSON.stringify({ id_pericia: req.periciaId, id_user: req.userId, done: req.done })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    return true;
  } catch (err) {
    console.log("error creating repair record:", err);
    throw err;
  }
};
