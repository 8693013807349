import { BottomNavigation, BottomNavigationAction, Box, Container } from "@mui/material";
import { PaperCard } from "../../shared/ui/paper-card/paper-card.component";
import DateRangePicker from "../../shared/ui/date/date-range-picker.component";
import { LoadingButton } from "@mui/lab";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import { UsePericiasByCostumersMetrics } from "../../features/pericia/get-pericias-metrics-by-costumers/api/usePericiasMetricsByCostumers";
import { CreatedCostumersBarchart } from "../../features/pericia/get-pericias-metrics-by-costumers/ui/created-costumers-barchart";
import { FinishedCostumersBarchart } from "../../features/pericia/get-pericias-metrics-by-costumers/ui/finished-costumers-barchart";
import { BilledCostumersBarchart } from "../../features/pericia/get-pericias-metrics-by-costumers/ui/billed-costumers-barchart";
import { NotBilledCostumersBarchart } from "../../features/pericia/get-pericias-metrics-by-costumers/ui/not-billed-costumers-barchart";
import { NotDoneCostumersBarchart } from "../../features/pericia/get-pericias-metrics-by-costumers/ui/not-done-costumers-barchart";
import { useNavigate } from "react-router-dom";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import CostumerAutocompleteSearchBar from "../../entities/costumer/ui/costumer-autocomplete-search-bar.component";
import { Costumer, CreateNewCostumer } from "../../entities/costumer";

export const GetPericiasMetricsByCostumersPage = () => {
  const [costumer, setCostumer] = useState<Costumer>(CreateNewCostumer());
  const [costumer2, setCostumer2] = useState<Costumer>(CreateNewCostumer());
  const {
    metrics,
    searchProps,
    createdInfo,
    finishedInfo,
    billedInfo,
    timeFrame,
    costumerName,
    costumerName2,
    handleSetSearchProps,
    metricsByDay,
    metricsByWeek,
    metricsByMonth,
    metricsStartDate,
    metricsEndDate,
    isLoading,
    error
  } = UsePericiasByCostumersMetrics();
  const { startDate, endDate } = searchProps;

  const navigate = useNavigate();
  const [navItem, setNavItem] = useState(2);

  const handleNavigation = (event: React.SyntheticEvent, item: number) => {
    setNavItem(item);
    switch (item) {
      case 0:
        navigate("/pericias/metrics");
        break;
      case 1:
        navigate("/pericias/metrics/by-costumer");
        break;
      case 2:
        break;
      case 3:
        navigate("/pericias/metrics/profit");
        break;
      default:
        navigate("/");
    }
  };

  const handleDateChange = (start: Date, end: Date) => {
    handleSetSearchProps({ startDate: start, endDate: end, costumer, costumer2 });
  };

  const handleGenerateMetrics = () => {
    handleSetSearchProps({ enabled: true, costumer, costumer2 });
  };

  useEffect(() => {
    if (!error) return;
    toast.error("Erro ao gerar métricas...");
  }, [error]);

  return (
    <Container>
      <Box mb={5} />
      <BottomNavigation showLabels value={navItem} onChange={handleNavigation} sx={{ marginBottom: "2rem" }}>
        <BottomNavigationAction label="Métricas Nuovauto" icon={<EqualizerIcon />} />
        <BottomNavigationAction label="Métricas cliente" icon={<SignalCellularAltIcon />} />
        <BottomNavigationAction label="Métricas entre clientes" icon={<StackedBarChartIcon />} />
        <BottomNavigationAction label="Métricas faturamento" icon={<TrendingUpIcon />} />
      </BottomNavigation>
      <h1>Métricas entre clientes</h1>
      <PaperCard title="Selecione os clientes e o período">
        <CostumerAutocompleteSearchBar props={{ costumer, updateCostumer: setCostumer }} />
        <Box mb={3} />
        <CostumerAutocompleteSearchBar props={{ costumer: costumer2, updateCostumer: setCostumer2 }} />
        <Box mb={3} />
        <DateRangePicker props={{ start: startDate, end: endDate, onChange: handleDateChange }} />
        <Box mb={3} />
        <LoadingButton fullWidth variant="contained" loading={isLoading} onClick={handleGenerateMetrics}>
          Gerar métricas
        </LoadingButton>
      </PaperCard>
      <Box mb={5} />
      <LoadingButton variant="outlined" loading={isLoading} onClick={metricsByDay} style={{ marginRight: "2rem" }}>
        Métricas por dia
      </LoadingButton>
      <LoadingButton variant="outlined" loading={isLoading} onClick={metricsByWeek} style={{ marginRight: "2rem" }}>
        Métricas por semana
      </LoadingButton>
      <LoadingButton variant="outlined" loading={isLoading} onClick={metricsByMonth}>
        Métricas por mes
      </LoadingButton>
      <Box mb={5} />
      {metrics.length > 0 && (
        <>
          <CreatedCostumersBarchart
            costumerName={costumerName}
            costumerName2={costumerName2}
            metrics={metrics}
            createdInfo={createdInfo}
            timeFrame={timeFrame}
            startDate={metricsStartDate}
            endDate={metricsEndDate}
          />
          <FinishedCostumersBarchart
            costumerName={costumerName}
            costumerName2={costumerName2}
            metrics={metrics}
            finishedInfo={finishedInfo}
            timeFrame={timeFrame}
            startDate={metricsStartDate}
            endDate={metricsEndDate}
          />
          <BilledCostumersBarchart
            costumerName={costumerName}
            costumerName2={costumerName2}
            metrics={metrics}
            billedInfo={billedInfo}
            timeFrame={timeFrame}
            startDate={metricsStartDate}
            endDate={metricsEndDate}
          />
          {/* <NotBilledCostumersBarchart
            metrics={metrics}
            billedInfo={billedInfo}
            timeFrame={timeFrame}
            startDate={metricsStartDate}
            endDate={metricsEndDate}
          />
          <NotDoneCostumersBarchart
            costumerName={costumerName}
            costumerName2={costumerName2}
            metrics={metrics}
            billedInfo={billedInfo}
            timeFrame={timeFrame}
            startDate={metricsStartDate}
            endDate={metricsEndDate}
          /> */}
        </>
      )}
      <ToastContainer />
    </Container>
  );
};
