import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { Costumer } from "../model/costumer";
import { useListCostumers } from "../../../features/costumer/list-costumers";

interface CostumerAutocompleteProps {
  props: Props;
}

interface Props {
  costumer: Costumer;
  updateCostumer: (costumer: Costumer) => void;
}

const CostumerAutocompleteSearchBar: React.FC<CostumerAutocompleteProps> = ({ props }) => {
  const { updateCostumer, costumer } = props;
  const { data: costumers, error } = useListCostumers();

  const handleUpdateCostumer = (updatedCostumer: Partial<Costumer>) => {
    const newCostumer = costumers?.find((item) => item.id === updatedCostumer.id);
    if (!newCostumer) return;

    updateCostumer({ ...newCostumer });
  };

  if (!costumers) return <TextField label="Cliente" required fullWidth id="costumerName" autoFocus variant="standard" disabled />;

  if (error) return <p>error</p>;

  return (
    <Autocomplete
      disablePortal
      id="costumer-autocomplete"
      options={costumers.map((costumer) => ({
        label: costumer.name,
        id: costumer.id
      }))}
      onChange={(e, value) => {
        if (!value) return;
        handleUpdateCostumer({ id: value.id, name: value.label });
      }}
      value={findCostumer(costumers, costumer)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField {...params} label="Cliente" required fullWidth id="costumerName" autoFocus variant="standard" />}
    />
  );
};

const findCostumer = (costumers: Costumer[], costumer: Costumer) => {
  if (!costumer) return null;

  const res = costumers.find((item) => item.id === costumer.id);
  if (!res) return null;

  return { label: res.name, id: res.id };
};

export default CostumerAutocompleteSearchBar;
