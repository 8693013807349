import { UseQueryResult, useQuery } from "react-query";
import {
  RepairRecord,
  getRepairRecordsByUserId,
} from "../../../../entities/repair-record";
import { mapPericiaFromRepository } from "../../../../entities/pericia";

export const queryKeys = {
  getRepairRecordsByUserId: (userId: string) => [
    "getRepairRecordsByUserId",
    userId,
  ],
};

export function useListRepairRecordsByUserId(
  userId: string
): UseQueryResult<RepairRecord[], Error> {
  return useQuery(
    queryKeys.getRepairRecordsByUserId(userId),
    () => getRepairRecordsByUserId(userId),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: userId !== "",
      select: (data) => {
        return data.map((repairRecord) => {
          const {
            createdAt,
            deletedAt,
            finishedAt,
            technicianPayment,
            payedAt,
            payedValue,
            updatedAt,
            pericia,
            ...rest
          } = repairRecord;
          return {
            ...rest,
            createdAt: new Date(createdAt),
            deletedAt: deletedAt ? new Date(deletedAt as string) : undefined,
            finishedAt: finishedAt ? new Date(finishedAt as string) : undefined,
            payedAt: payedAt ? new Date(payedAt as string) : undefined,
            payedValue: payedValue,
            updatedAt: new Date(updatedAt as string),
            pericia: mapPericiaFromRepository(pericia),
            technicianPayment: technicianPayment
              ? {
                  pericia: mapPericiaFromRepository(technicianPayment.pericia),
                  id: technicianPayment.id,
                  user: technicianPayment.user,
                  amount: technicianPayment.amount,
                  createdAt: new Date(technicianPayment.createdAt),
                  deleted: technicianPayment.deleted,
                  deletedAt: technicianPayment.deletedAt
                    ? new Date(technicianPayment.deletedAt)
                    : undefined,
                  payed: technicianPayment.payed,
                  payedAt: technicianPayment.payedAt
                    ? new Date(technicianPayment.payedAt)
                    : undefined,
                  updatedAt: new Date(technicianPayment.updatedAt),
                }
              : undefined,
          };
        });
      },
    }
  );
}
