import supabase from "../../../shared/database/supabase";
import { Pericia } from "../model";

export const getPericiaById = async (periciaId: string): Promise<Pericia | Error> => {
  try {
    const { data, error } = await supabase.functions.invoke("get-pericia-by-id", {
      body: JSON.stringify({ id: periciaId })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    return { ...data, date: new Date(data.date) };
  } catch (err) {
    console.log("error fetching pericia:", err);
    throw err;
  }
};
