import supabase from "../../../shared/database/supabase";

export const getUsers = async () => {
  try {
    const { data, error } = await supabase.from("users").select("*");
    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  } catch (error) {
    throw error;
  }
};
