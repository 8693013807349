import { UseQueryResult, useQuery } from "react-query";
import { deleteRepairRecord } from "../../../../entities/repair-record";

interface UseDeleteRepairRecordProps {
  periciaID: string;
  userID: string;
}

export const queryKeys = {
  deleteRepairRecord: (props: UseDeleteRepairRecordProps) => ["deleteRepairRecord", props]
};

export function useDeleteRepairRecord(props: UseDeleteRepairRecordProps): UseQueryResult<boolean, Error> {
  return useQuery(queryKeys.deleteRepairRecord(props), () => deleteRepairRecord(props), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: props.periciaID !== "" && props.userID !== ""
  });
}
