import { UseQueryResult, useQuery } from "react-query";
import { GetUserBalanceResponse } from "../../../../entities/user";
import { getSalesmanBalance } from "../../../../entities/user/api/get-salesman-balance";

export const queryKeys = {
  getSalesmanBalance: () => ["getSalesmanBalance"]
};

export function useGetSalesmanBalance(): UseQueryResult<GetUserBalanceResponse, Error> {
  return useQuery(queryKeys.getSalesmanBalance(), () => getSalesmanBalance(), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: true
  });
}
