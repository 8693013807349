import supabase from "../../../shared/database/supabase";
import { Pericia } from "../model";

interface ListPericiasArgs {
  term: string;
  done: boolean;
  not_done: boolean;
  billed: boolean;
  priceCalculated: boolean;
  page: number;
}

export const listPericias = async (args: ListPericiasArgs): Promise<Pericia[] | Error> => {
  try {
    const { data, error } = await supabase.functions.invoke("v2-list-pericias", {
      body: JSON.stringify({ ...args })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    return [...data.pericias];
  } catch (err) {
    console.log("error fetching pericias:", err);
    throw err;
  }
};
