import supabase from "../../../shared/database/supabase";

export interface GetUserBalanceResponse {
  balance: number;
  totalHistory: number;
  totalReceived: number;
  userDisplayName: string;
}

export const getUserBalance = async (): Promise<GetUserBalanceResponse> => {
  try {
    const { data, error } = await supabase.functions.invoke("get-user-balance");
    if (error) throw error;

    return { ...data };
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};
