import { UseQueryResult, useQuery } from "react-query";
import {
  ListPaymentRecordsResponse,
  listPaymentRecords,
} from "../../../entities/payment-record";

export const queryKeys = {
  listPaymentRecords: () => "listPaymentRecords",
};

export function useListPaymentRecords(): UseQueryResult<
  ListPaymentRecordsResponse,
  Error
> {
  return useQuery(queryKeys.listPaymentRecords(), () => listPaymentRecords(), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
