import { UseQueryResult, useQuery } from "react-query";
import { ListPericiaCommentsResponse, listPericiaCommentsReq } from "../../../../entities/pericia-comment/api/list-pericia-comments";

export interface UsePericiaCommentsProps {
  periciaId: string;
}

export const queryKeys = {
  listPericiaComments: (props: UsePericiaCommentsProps) => ["listPericiaComments", props]
};

export function useListPericiaComments(props: UsePericiaCommentsProps): UseQueryResult<ListPericiaCommentsResponse, Error> {
  return useQuery(queryKeys.listPericiaComments(props), () => listPericiaCommentsReq({ id_pericia: props.periciaId }), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: props.periciaId.length > 3
  });
}
