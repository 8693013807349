import supabase from "../../../shared/database/supabase";
import { CustomError } from "../../../shared/error/error";
import { InvoicePayment } from "../model/invoice-payment";

export interface ListInvoicePaymentsRequest {
  id_invoice: string;
}

export interface ListInvoicePaymentsResponse {
  invoicePayments: InvoicePayment[];
  total: number;
  unpaid: number;
  paymentsCount: number;
}

export const listInvoicePaymentsRequest = async (req: ListInvoicePaymentsRequest): Promise<ListInvoicePaymentsResponse> => {
  try {
    const { data, error } = await supabase.functions.invoke("list-invoice-payments", {
      body: JSON.stringify({ ...req })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    return data as ListInvoicePaymentsResponse;
  } catch (err) {
    const apiError = err as CustomError;
    console.error(`API Error: ${apiError.message}`);

    if (apiError && apiError.code && apiError.message && apiError.toast_message && apiError.http_status_code) {
      throw new CustomError(apiError);
    }

    throw new CustomError({});
  }
};
