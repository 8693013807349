import supabase from "../../../shared/database/supabase";
import { PericiaMetricDTO } from "../model/dto";

interface GetPericiasMetricsRequest {
  start_date: string;
  end_date: string;
}

export const getPericiasMetricsRequest = async (args: GetPericiasMetricsRequest): Promise<PericiaMetricDTO[]> => {
  try {
    const { data, error } = await supabase.functions.invoke("get-pericias-metrics", {
      body: JSON.stringify({ ...args })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    return [...data.metrics];
  } catch (err) {
    console.log("error fetching pericias:", err);
    throw err;
  }
};
