import { UseQueryResult, useQuery } from "react-query";
import {
  CreatePaymentRecordsResponse,
  createPaymentRecord,
} from "../../../entities/payment-record/api/create-payment-record";

export interface CreatePaymentProps {
  userId: string;
  amount: number;
  description: string;
}

export const queryKeys = {
  createPaymentRecord: (props: CreatePaymentProps) => [
    "createPaymentRecord",
    props,
  ],
};

export function useCreatePaymentRecord(
  props: CreatePaymentProps
): UseQueryResult<CreatePaymentRecordsResponse, Error> {
  return useQuery(
    queryKeys.createPaymentRecord(props),
    () =>
      createPaymentRecord({
        id_user: props.userId,
        amount: props.amount,
        description: props.description,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: props.userId !== "" && Boolean(props.amount) && props.amount > 0,
    }
  );
}
