import { Grid, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { currencyMask, formatIntegerToCurrency, getNumberFromCurrencyMask } from "../../../utils/helpers/numbers";
import { PaperCard } from "../../../shared/ui/paper-card/paper-card.component";

interface AddtionalCostProps {
  props: Props;
}

interface Props {
  addtionalCost: number;
  addtionalCostDescription: string;
  updateAddtionalCost: (addtionalCost: number) => void;
  updateAddtionalCostDescription: (addtionalCostDescription: string) => void;
}

const PericiaAddtionalCost: React.FC<AddtionalCostProps> = ({ props }) => {
  const { addtionalCost, addtionalCostDescription, updateAddtionalCost, updateAddtionalCostDescription } = props;
  const [addtionalCostMask, setAddtionalCostMask] = useState<string>("");
  const [addtionalCostDescriptionMask, setAddtionalCostDescriptionMask] = useState<string>(addtionalCostDescription);

  const handleAddtionalCostChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAddtionalCostMask(currencyMask(e.target.value));
  };

  const handleAddtionalCostOnBlur = async () => {
    updateAddtionalCost(getNumberFromCurrencyMask(addtionalCostMask));
  };

  const handleAddtionalCostDescriptionOnBlur = () => {
    updateAddtionalCostDescription(addtionalCostDescriptionMask);
  };

  const handleAddtionalCostDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAddtionalCostDescriptionMask(value);
  };

  useEffect(() => {
    setAddtionalCostMask(currencyMask(formatIntegerToCurrency(addtionalCost)));
    setAddtionalCostDescriptionMask(addtionalCostDescription);
  }, [addtionalCost, addtionalCostDescription]);

  return (
    <PaperCard title="Custo Adicional">
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            autoComplete="off"
            fullWidth
            id="addtionalCost"
            label="Custo Adicional/CHF"
            name="addtional_cost"
            variant="standard"
            onChange={handleAddtionalCostChange}
            onBlur={handleAddtionalCostOnBlur}
            value={addtionalCostMask}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField
            autoComplete="off"
            fullWidth
            id="addtionalCostDescription"
            label="Motivo do Custo Adicional"
            name="addtional_cost_description"
            variant="standard"
            onChange={handleAddtionalCostDescriptionChange}
            onBlur={handleAddtionalCostDescriptionOnBlur}
            value={addtionalCostDescriptionMask}
          />
        </Grid>
      </Grid>
    </PaperCard>
  );
};

export default PericiaAddtionalCost;
