import { ChangeEvent, useEffect, useState } from "react";
import FlexContainer from "../../shared/ui/container/flex-container.component";
import { Costumer, CreateNewCostumer } from "../../entities/costumer/model/costumer";
import { BottomNavigation, BottomNavigationAction, Grid, Typography } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { getPericiasCustomPrice } from "../../entities/pericia";
import { usePendingPericiasPDFCreator } from "../../features/pericia/create-pending-pericias-pdf/api/usePendingPericiasPDFCreator";
import {
  ListCostumerPericiasFilters,
  ListCostumerPericiasProps,
  useListPericiasByCostumerId
} from "../../features/pericia/list-pericias-by-costumer-id/api/useListPericiasByCostumerId";
import { CostumerListPericiasHeader } from "../../features/pericia/list-pericias-by-costumer-id/ui/costumer-pericias-list-header.component";
import { ListPericiasByCostumerList } from "../../features/pericia/list-pericias-by-costumer-id/ui/list-pericias-by-costumer-list.component";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { useNavigate } from "react-router-dom";

const INITIAL_FILTER: ListCostumerPericiasFilters = {
  billed: false,
  isPriceCalculated: false,
  done: false,
  notDone: false
};

const INITIAL_ARGS: ListCostumerPericiasProps = {
  costumerId: "",
  start: new Date(),
  end: new Date(),
  filters: INITIAL_FILTER
};

interface SelectedFieldsToDisplay {
  displayPlate: boolean;
  displayChassis: boolean;
  displayDate: boolean;
  displayPrice: boolean;
  displayUserName: boolean;
  displayTotalHours: boolean;
}

const INITIAL_SELECTED_FIELDS_TO_DISPLAY: SelectedFieldsToDisplay = {
  displayPlate: true,
  displayChassis: true,
  displayDate: true,
  displayPrice: true,
  displayUserName: true,
  displayTotalHours: true
};

export const PendingPericiasPage = () => {
  const [costumer, setCostumer] = useState<Costumer>(CreateNewCostumer());
  const [start, setStartDate] = useState(new Date());
  const [end, setEndDate] = useState(new Date());
  const [filter, setFilter] = useState<ListCostumerPericiasFilters>(INITIAL_FILTER);
  const [selectedFieldsToDisplay, setSelectedFieldsToDisplay] = useState<SelectedFieldsToDisplay>(INITIAL_SELECTED_FIELDS_TO_DISPLAY);
  const [totalCustomPrice, setTotalCustomPrice] = useState(0);
  const [args, setArgs] = useState<ListCostumerPericiasProps>(INITIAL_ARGS);
  const { data: pericias, error, isLoading: isLoadingListPericias } = useListPericiasByCostumerId(args);
  const { createPDF } = usePendingPericiasPDFCreator();

  const navigate = useNavigate();

  const handleDateChange = (start: Date, end: Date) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.checked
    });
  };

  const handleSelectedFieldsToDisplayChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedFieldsToDisplay({
      ...selectedFieldsToDisplay,
      [e.target.name]: e.target.checked
    });
  };

  const handleFetchPericias = () => {
    setArgs({
      costumerId: costumer.id,
      start: start,
      end: end,
      filters: filter
    });
  };

  const handleCreatePDF = async () => {
    if (!pericias) return;

    createPDF({
      costumer,
      pericias,
      start,
      end,
      displayFields: selectedFieldsToDisplay,
      totalPrice: totalCustomPrice
    });
  };

  if (error) {
    console.log(error);
    toast.error("Erro ao buscar pericias");
  }

  useEffect(() => {
    if (pericias) {
      setTotalCustomPrice(getPericiasCustomPrice(pericias));
    }
  }, [pericias, filter]);

  useEffect(() => {
    if (!pericias) return;

    if (pericias.length === 0) {
      toast.info("Nenhuma pericia encontrada");
      return;
    }

    if (pericias && pericias.length === 0) toast.info("Nenhuma pericia encontrada");
  }, [pericias]);

  return (
    <FlexContainer>
      <BottomNavigation showLabels value={0} onChange={() => navigate("/pericias/list/by-date")} sx={{ marginBottom: "2rem" }}>
        <BottomNavigationAction label="Perícias por clientes" icon={<ContentPasteSearchIcon />} />
        <BottomNavigationAction label="Todas as perícias" icon={<ContentPasteSearchIcon />} />
      </BottomNavigation>
      <Typography variant="h4">Relatório de Pericias por Cliente</Typography>
      <Typography variant="subtitle1">Lista as perícias de um cliente dentro do período selecionado</Typography>
      <Grid container spacing={0} mt={2}>
        <CostumerListPericiasHeader
          props={{
            costumer,
            updateCostumer: setCostumer,
            start,
            end,
            handleDateChange,
            handleSetListCostumerPericiasProps: handleFetchPericias,
            handleFilterChange,
            isLoading: isLoadingListPericias
          }}
        />

        {pericias && pericias.length > 0 && (
          <ListPericiasByCostumerList
            props={{
              pericias,
              selectedFieldsToDisplay,
              totalPrice: totalCustomPrice,
              totalCustomPrice,
              start,
              end,
              handleSelectedFieldsToDisplayChange,
              handleCreatePDF
            }}
          />
        )}
      </Grid>
      <ToastContainer />
    </FlexContainer>
  );
};
