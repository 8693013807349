import { Route, Routes } from "react-router-dom";
import "./App.css";
import Auth from "./routes/auth/auth.component";
import SignIn from "./routes/auth/sign-in/sign-in.component";
import CostumersRoutes from "./routes/costumers/costumers.component";
import Navigation from "./routes/navigation/navigation.component";
import PericiasRoutes from "./routes/pericia/pericia.component";
import ProtectedRouteAdmin from "./routes/protected/protected-route-admin.component";
import UsersRoutes from "./routes/users/users.component";
import InvoiceRoutes from "./routes/invoice/invoice.route";
import { QueryClient, QueryClientProvider } from "react-query";
import ProtectedRoute from "./routes/protected/protected-route/protected-route.component";
import MechanicPericiasRoute from "./routes/pericia/mechanic/pericia.component";
import { GetUserBalancePage } from "./pages/user/get-user-balance.page";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route index element={<SignIn />} />
          <Route path="auth/*" element={<Auth />} />
          <Route element={<ProtectedRoute />}>
            <Route path="mechanics/pericias/*" element={<MechanicPericiasRoute />} />
            <Route path="users/me/balance" element={<GetUserBalancePage />} />
          </Route>
          <Route element={<ProtectedRouteAdmin />}>
            <Route path="costumers/*" element={<CostumersRoutes />} />
            <Route path="pericias/*" element={<PericiasRoutes />} />
            <Route path="users/*" element={<UsersRoutes />} />
            <Route path="invoices/*" element={<InvoiceRoutes />} />
          </Route>
        </Route>
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
