import jsPDF from "jspdf";
import supabase from "../../../shared/database/supabase";

interface GenerateRepairRecordPdfDTO {
  repairRecords: RepairRecord[];
  date: Date;
}

interface RepairRecord {
  id: string;
  payed: boolean;
  payed_value: number;
  created_at: string;
  pericia: {
    id: string;
    date: string;
    costumer: {
      name: string;
    };
    car: {
      brand: string;
      model: string;
      chassis_number: string;
      plate: string;
      insurance_name: string;
      color: string;
    };
  };
}

export const repairRecordsPDFMethods = {
  generateRepairRecordPdf: async (generateRepairRecordPdfDTO: GenerateRepairRecordPdfDTO) => {
    const user = supabase.auth.user()?.user_metadata || "";
    if (!user) {
      return { error: "Usuário não autenticado" };
    }

    const userIdentification = user.name || user.id || "Usuário não identificado";

    const { repairRecords } = generateRepairRecordPdfDTO;
    const { date } = generateRepairRecordPdfDTO;
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const today = new Date();
    const totalRecords = repairRecords.length;

    const pdf = new jsPDF("p", "mm", "a4");

    pdf.setFontSize(12);
    pdf.setFont("helvetica", "bold");

    const logoImg = require("../../../assets/nuovauto.png");
    pdf.addImage(logoImg, "PNG", 16, 12, 90, 24);

    pdf.text(`Técnico: ${userIdentification}`, 134, 18);
    pdf.text(`Total de carros reparados: ${totalRecords}`, 134, 24);
    pdf.text(`Período: ${month}-${year}`, 134, 30);
    pdf.text(`LISTA DE CARROS REPARADOS`, 16, 56);
    pdf.text(`Emitido em: ${today.toLocaleString("pt-BR")}`, 16, 62);

    const itemsPerPage = repairRecordsPerPage(repairRecords, 14);

    pdf.text(`Cliente`, 25, 84);
    pdf.text(`Carro`, 70, 84);
    pdf.text(`Placa`, 115, 84);
    pdf.text(`Data`, 150, 84);
    pdf.text(`Valor`, 180, 84);

    pdf.setFont("helvetica", "normal");

    itemsPerPage.forEach((items, page) => {
      let finalY = 0;
      items.forEach((item, index) => {
        const yOffset = page === 0 ? 90 : 40;
        const row = index + 1;
        const y = row * 6 + yOffset;

        const { pericia, payed_value, created_at } = item;
        const { name } = pericia.costumer;
        const { brand, model, plate } = pericia.car;

        pdf.text(`${name.slice(0, 20)}`, 16, y);
        pdf.text(`${brand} ${model}`, 70, y);
        pdf.text(plate, 115, y);
        pdf.text(`${new Date(created_at).toLocaleDateString("pt-BR")}`, 150, y);
        pdf.text(`${payed_value}`, 180, y);

        finalY = y;
      });

      if (itemsPerPage.indexOf(items) !== itemsPerPage.length - 1) {
        pdf.addPage();
      } else {
        pdf.setTextColor(128);
        pdf.setFont("helvetica", "normal");

        pdf.text(`NUOVAUTO`, 16, 270);
        pdf.text(`Via G. Canevascini 15`, 16, 274);
        pdf.text(`6600 Locarno`, 16, 278);

        pdf.text(`Tel.: 0767464412`, 75, 270);

        pdf.text(`Responsabile: Keil Roger`, 75, 278);

        pdf.text(`PostFinance`, 150, 270);
        pdf.text(`CH7309000000155473924`, 150, 274);
        pdf.text(`IVA: CHE-162.607.499`, 150, 278);

        pdf.setTextColor(0, 0, 255);
        pdf.textWithLink(`nuovauto@bluewin.ch`, 75, 274, {});
      }
    });

    pdf.save(`reparos-${userIdentification}-${today.toLocaleDateString("pt-BR")}.pdf`);

    return { error: null };
  }
};

const repairRecordsPerPage = (items: RepairRecord[], pageLimit: number) => {
  var results = [];

  while (items.length) {
    results.push(items.splice(0, pageLimit));
  }

  return results;
};
