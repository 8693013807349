import supabase from "../../../shared/database/supabase";

export interface ListPaymentRecordsByUserIdResponse {
  PaymentRecords: PaymentRecord[];
}

interface PaymentRecord {
  id: string;
  userId: string;
  issuedBy: {
    name: string;
    lastName: string;
    displayName: string;
  };
  amount: number;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  deleted: boolean;
}

export const listPaymentRecordsByUserId = async (
  user_id: string
): Promise<ListPaymentRecordsByUserIdResponse> => {
  try {
    const { data, error } = await supabase.functions.invoke(
      "list-payment-records-by-user-id",
      {
        body: JSON.stringify({ user_id }),
      }
    );
    if (error) throw error;

    if (data.error) throw data.error;

    return { PaymentRecords: data || [] };
  } catch (err) {
    console.log(err);
    throw err;
  }
};
