import supabase from "../../../shared/database/supabase";
import { PericiaToSaveDTO } from "../../../shared/dto/pericia";

export const createPericia = async (pericia: PericiaToSaveDTO): Promise<string | Error> => {
  try {
    const { data, error } = await supabase.functions.invoke("create-pericia", {
      body: JSON.stringify({ pericia })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    if (!data.id) throw new Error("id not found");

    return data.id;
  } catch (err) {
    console.log("error creating pericia:", err);
    throw err;
  }
};
