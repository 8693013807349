import { useEffect, useState } from "react";
import { UseGetProfitMetricsProps, useGetProfitMetrics } from "./usetGetProfitMetrics";
import { ProfitMetricDTO } from "../../../../entities/metrics/model/profit.model";
import { currencyMask, formatIntegerToCurrency } from "../../../../utils/helpers/numbers";

export const UseProfitMetrics = () => {
  const [searchProps, setSearchProps] = useState<UseGetProfitMetricsProps>({
    startDate: new Date(),
    endDate: new Date(),
    enabled: false
  });
  const { data, error, isLoading } = useGetProfitMetrics({ ...searchProps });
  const [metrics, setMetrics] = useState<ProfitMetricDTO[]>([]);
  const [timeFrame, setTimeFrame] = useState<string>("");

  const metricsByDay = () => {
    if (!data) return;

    setMetrics(mergeByDay(data));
    setTimeFrame(`dia(s)-${data.length}`);
  };

  const metricsByWeek = () => {
    if (!data) return;

    const mergedMetrics = mergeByWeek(data);
    setTimeFrame(`semana(s)-${mergedMetrics.length}`);
    setMetrics(mergedMetrics);
  };

  const metricsByMonth = () => {
    if (!data) return;
    const mergedMetrics = mergeByMonthAndYear(data);
    setTimeFrame(`mês(es)-${mergedMetrics.length}`);
    setMetrics(mergedMetrics);
  };

  const handleSetSearchProps = (props: Partial<UseGetProfitMetricsProps>) => {
    setSearchProps((prevState) => {
      return { ...prevState, ...props, enabled: props.enabled ? true : false };
    });
  };

  useEffect(() => {
    if (!data) return;
    const mergedMetrics = mergeByMonthAndYear(data);
    setMetrics(mergedMetrics);
    setTimeFrame(`mês(es)-${mergedMetrics.length}`);
  }, [data]);

  return {
    error,
    isLoading,
    metrics,
    searchProps,
    timeFrame,
    profitInfo: profitInfo(metrics),
    payedToTechniciansInfo: payedToTechniciansInfo(metrics),
    billedInfo: billedInfo(metrics),
    metricsStartDate: data ? new Date(data[0].day).toLocaleDateString("pt-BR") : new Date().toLocaleDateString("pt-BR"),
    metricsEndDate: data ? new Date(data[data.length - 1].day).toLocaleDateString("pt-BR") : new Date().toLocaleDateString("pt-BR"),
    metricsByDay,
    metricsByWeek,
    metricsByMonth,
    handleSetSearchProps
  };
};

const mergeByDay = (data: ProfitMetricDTO[]): ProfitMetricDTO[] => {
  return data.map((item) => ({
    day: new Date(item.day).toLocaleDateString("pt-BR"),
    billed: item.billed,
    payedToTechnicians: item.payedToTechnicians,
    profit: item.profit
  }));
};

const mergeByMonthAndYear = (data: ProfitMetricDTO[]): ProfitMetricDTO[] => {
  const mergedMetricsMap: { [yearMonth: string]: ProfitMetricDTO } = {};

  data.forEach((metric) => {
    const [year, month] = metric.day.split("-");
    const yearMonth = `${year}-${month}`;

    if (mergedMetricsMap[yearMonth]) {
      mergedMetricsMap[yearMonth].billed += metric.billed;
      mergedMetricsMap[yearMonth].payedToTechnicians += metric.payedToTechnicians;
      mergedMetricsMap[yearMonth].profit += metric.profit;
    } else {
      mergedMetricsMap[yearMonth] = { ...metric, day: yearMonth };
    }
  });

  const mergedMetrics: ProfitMetricDTO[] = Object.values(mergedMetricsMap);
  return mergedMetrics;
};

const getWeek = (date: Date): number => {
  const onejan = new Date(date.getFullYear(), 0, 1);
  const millisecsInDay = 86400000;
  return Math.ceil(((date.getTime() - onejan.getTime()) / millisecsInDay + onejan.getDay() + 1) / 7);
};

const mergeByWeek = (data: ProfitMetricDTO[]): ProfitMetricDTO[] => {
  return data.reduce((acc, curr) => {
    const date = new Date(curr.day);
    const week = getWeek(date);
    const year = date.getFullYear();
    const existing = acc.find((item) => item.day === `${year}-${week}`);
    if (existing) {
      existing.profit += curr.profit;
      existing.payedToTechnicians += curr.payedToTechnicians;
      existing.billed += curr.billed;
    } else {
      acc.push({ day: `${year}-${week}`, profit: curr.profit, payedToTechnicians: curr.payedToTechnicians, billed: curr.billed });
    }
    return acc;
  }, [] as ProfitMetricDTO[]);
};

const profitInfo = (metrics: ProfitMetricDTO[]) => {
  const totalProfit = metrics.reduce((acc, curr) => acc + curr.profit, 0);
  return {
    totalProfit: currencyMask(formatIntegerToCurrency(totalProfit)),
    average: currencyMask(formatIntegerToCurrency(totalProfit / metrics.length))
  };
};

const payedToTechniciansInfo = (metrics: ProfitMetricDTO[]) => {
  const totalPayedToTechnicians = metrics.reduce((acc, curr) => acc + curr.payedToTechnicians, 0);
  return {
    totalPayedToTechnicians: currencyMask(formatIntegerToCurrency(totalPayedToTechnicians)),
    average: currencyMask(formatIntegerToCurrency(totalPayedToTechnicians / metrics.length))
  };
};

const billedInfo = (metrics: ProfitMetricDTO[]) => {
  const totalBilled = metrics.reduce((acc, curr) => acc + curr.billed, 0);
  const totalPayedToTechnicians = metrics.reduce((acc, curr) => acc + curr.payedToTechnicians, 0);
  return {
    totalBilled: currencyMask(formatIntegerToCurrency(totalBilled)),
    average: currencyMask(formatIntegerToCurrency(totalBilled / metrics.length)),
    percentage: `${(((totalBilled - totalPayedToTechnicians) * 100) / totalBilled).toFixed(2)}%`,
    netProfit: currencyMask(formatIntegerToCurrency(totalBilled - totalPayedToTechnicians))
  };
};
