import { useEffect, useState } from "react";
import { useGetPericiaById } from "../../features/pericia/get-pericia-by-id/api/useGetPericiaById";
import { useNavigate, useParams } from "react-router-dom";
import FlexContainer from "../../shared/ui/container/flex-container.component";
import { TextareaAutosize, Typography } from "@mui/material";
import { CarPart, usePericia } from "../../entities/pericia";
import PericiaHeaderDisabled from "../../entities/pericia/ui/pericia-header-disabled-fields.component";
import PericiaImgDisabled from "../../entities/pericia/ui/pericia-img-disabled-fields.component";
import { LoadingButton } from "@mui/lab";
import { PaperCard } from "../../shared/ui/paper-card/paper-card.component";
import { useListPericiaComments } from "../../features/pericia-comment/list-pericia-comments/api/useListPericiaComments";
import { PericiaCommentsList } from "../../features/pericia-comment/ui/pericia-comments-list.component";
import { useUpdatePericiaMechanic } from "../../features/pericia/update-pericia-mechanic/api/useUpdatePericiaMechanic";
import { ToastContainer, toast } from "react-toastify";
import { CreateNotificationProps, useCreateNotification } from "../../features/notification/list-notifications/api/createNotification";
import {
  UseCreatePericiaCommentProps,
  useCreatePericiaComment
} from "../../features/pericia-comment/create-pericia-comment/api/useCreatePericiaComment";
import CreateRepairRecordDialogComponent from "../../components/repair-records/create-repair-record-dialog/create-repair-record-dialog";
import { periciaService } from "../../services/pericia/pericia.service";
import { UploadPericiaPhotoComponent } from "../../features/pericia-photo/ui/upload-pericia-photo.component";
import { PericiaPhotosList } from "../../features/pericia-photo/ui/pericia-photos-list.component";
import { useListPericiaPhotos } from "../../features/pericia-photo/api/useListPericiaPhotos";
import { usePericiaPhotoUploader } from "../../features/pericia-photo/upload-photo/api/usePericiaPhotoUploader";

export const MechanicUpdatePericiaPage = () => {
  const { id } = useParams();
  const [notificationMessage, setNotificationMessage] = useState<CreateNotificationProps>({
    id_pericia: "",
    message: ""
  });
  const [periciaId, setPericiaId] = useState<string>("");
  const [comment, setComment] = useState<UseCreatePericiaCommentProps>({
    comment: "",
    id_pericia: ""
  });
  const [technicianComment, setTechnicianComment] = useState<string>("");
  const [carPartUpdate, setCarPartUpdated] = useState<string>("");
  const [enabled, setEnabled] = useState<boolean>(false);
  const [isRepairRecordOpen, setIsRepairRecordOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { image, handleSetImage, uploadPhotoRes, isUploadingImage } = usePericiaPhotoUploader();
  const {
    data: periciaPhotos,
    error: errorListingPericiaPhotos,
    refetch: refetchPericiaPhotos
  } = useListPericiaPhotos({ id_pericia: periciaId });
  const { pericia, updatePericia, findCarPart, updateCarPart } = usePericia();
  const { data: periciaFetched, isLoading: isFetchingPericia, isError } = useGetPericiaById(periciaId);
  const { data: listCommentsRes, error: errorListingComments, refetch: refetchComments } = useListPericiaComments({ periciaId });
  const { data: updatePericiaRes, error: errorUpdatingPericia } = useUpdatePericiaMechanic(pericia, enabled);
  const { data: createNotificationRes, error: errorCreateNotification } = useCreateNotification({ ...notificationMessage });
  const {
    data: createPericiaCommentRes,
    error: errorCreatingPericiaComment,
    isLoading: isLoadingCreatingComment
  } = useCreatePericiaComment(comment);
  const { costumer, car, shouldUnmount, pricePerHour, unmountPrice, date, done } = pericia;
  const navigate = useNavigate();

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      handleSetImage({ id_pericia: "", item: { ...image.item, file: selectedImage } });
    }
  };

  const handleImageDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!id) return;
    handleSetImage({ id_pericia: "", item: { ...image.item, description: e.target.value } });
  };

  const handleUploadImage = () => {
    if (!id) return;

    if (!image.item.file) {
      toast.error("Selecione uma imagem!");
      return;
    }

    handleSetImage({ id_pericia: id, item: { ...image.item } });
  };

  const findRepairRecord = async (periciaID: string) => {
    const res = await periciaService.findRepairRecord(periciaID);
    if (res.error) {
      console.log(res.error);
      toast.error("Erro ao buscar pericia!");
      return;
    }

    if (!res.data) {
      return setIsRepairRecordOpen(true);
    }

    if (res.data && res.data[0].done) {
      toast.info("Pericia já finalizada!");
      return;
    }
  };

  const handleClickConfirm = async () => {
    if (!id) return;

    setIsLoading(true);
    const res = await periciaService.createRepairRecord(id);
    if (res.error) {
      toast.error("Algo deu errado. Tente novamente.");
      console.log(res.error);
      setIsLoading(false);
      navigate(`/mechanics/pericias/list`);
      return;
    }

    setIsRepairRecordOpen(false);
    setIsLoading(false);
    refetchComments();
  };

  const handleCallOnClose = () => {
    setIsRepairRecordOpen(false);
    navigate(`/mechanics/pericias/list`);
  };

  const handleCreateRepairRecord = async () => {
    setIsLoading(true);
    const res = await periciaService.finishRepairRecord(pericia.id);
    if (res.error) {
      setIsLoading(false);
      console.log(res.error);
      toast.error("Erro ao finalizar pericia!");
      return;
    }

    setIsLoading(false);
    toast.success("Pericia finalizada com sucesso. Redirecionando...", { autoClose: 600 });
    setTimeout(() => {
      navigate(`/`);
    }, 1200);
  };

  const handleCreateComment = () => {
    if (technicianComment.length === 0) return;
    setComment({ comment: technicianComment, id_pericia: pericia.id });
  };

  const handleUpdateCarPart = (carPart: CarPart) => {
    if (done) {
      toast.warn("Pericia finalizada! Não é possível atualizar a pintura.", { autoClose: 1000 });
      return;
    }

    let shouldUpdate = false;

    pericia.carParts.some((part) => {
      if (part.name === carPart.name) {
        shouldUpdate = part.shouldPaint !== carPart.shouldPaint;
        return true;
      }
      return false;
    });

    if (!shouldUpdate) return;

    updateCarPart(carPart);
    setCarPartUpdated(carPart.name);
    setEnabled(true);
  };

  useEffect(() => {
    if (!id) return;

    setPericiaId(id);
    findRepairRecord(id);

    const queryParams = new URLSearchParams(window.location.search);
    const term = queryParams.get("operation");
    if (term === "ongoing") {
      toast.warn("Você tem esse reparo em andamento!", { autoClose: 800 });
      return;
    }
  }, [id]);

  useEffect(() => {
    if (!periciaFetched) return;

    updatePericia(periciaFetched);
  }, [periciaFetched]);

  useEffect(() => {
    if (!updatePericiaRes) return;
    toast.success("Perícia atualizada com sucesso!", { autoClose: 800 });

    const message = "atualizou a pintura de uma pericia.";
    const comment = `atualizou a pintura da parte : ${carPartUpdate}`;

    setNotificationMessage({ message, id_pericia: pericia.id });
    setComment({ comment, id_pericia: pericia.id });
  }, [updatePericiaRes, pericia.id]);

  useEffect(() => {
    if (!createNotificationRes) return;
    setNotificationMessage({ id_pericia: "", message: "" });
  }, [createNotificationRes]);

  useEffect(() => {
    if (!createPericiaCommentRes) return;
    setCarPartUpdated("");
    setComment({ comment: "", id_pericia: "" });
    setTechnicianComment("");
    refetchComments();
    toast.success("Comentário adicionado com sucesso!", { autoClose: 800 });
  }, [createPericiaCommentRes]);

  useEffect(() => {
    if (!uploadPhotoRes) return;

    handleSetImage({ id_pericia: "", item: { file: null, description: "" } });
    toast.success("Foto adicionada com sucesso!", { autoClose: 800 });
    refetchPericiaPhotos();
  }, [uploadPhotoRes]);

  return (
    <FlexContainer>
      <Typography variant="h4" component="h1">
        Reparo em andamento
      </Typography>
      <PericiaHeaderDisabled
        props={{
          costumer,
          car,
          shouldUnmount,
          pricePerHour,
          unmountPrice,
          date
        }}
      />
      <Typography variant="h5" component="h5">
        Pericia
      </Typography>
      <PericiaImgDisabled
        props={{
          findCarPart,
          updateCarPart: handleUpdateCarPart
        }}
      />
      <PericiaCommentsList defaultExpanded={true} comments={listCommentsRes?.comments || []} />
      <PaperCard title="Adicionar comentário">
        <TextareaAutosize
          aria-label="minimum height"
          minRows={3}
          placeholder="Observações..."
          value={technicianComment}
          onChange={(e) => setTechnicianComment(e.target.value)}
          style={{ width: "100%", marginTop: "1rem", padding: "1rem 1rem 0.5rem 1rem" }}
        />
        <LoadingButton
          fullWidth
          variant="contained"
          sx={{ mt: 2 }}
          onClick={handleCreateComment}
          loading={isLoadingCreatingComment}
          disabled={technicianComment.length === 0 || done}
        >
          Salvar comentário
        </LoadingButton>
      </PaperCard>
      {periciaPhotos?.data && periciaPhotos.data.length > 0 && <PericiaPhotosList photos={periciaPhotos.data} />}
      <UploadPericiaPhotoComponent
        isUploadingImage={isUploadingImage}
        done={done}
        image={image}
        handleImageChange={handleImageChange}
        handleImageDescriptionChange={handleImageDescriptionChange}
        handleUploadImage={handleUploadImage}
      />
      <LoadingButton
        color="secondary"
        fullWidth
        variant="contained"
        sx={{ mt: 2 }}
        onClick={handleCreateRepairRecord}
        loading={isLoading}
        disabled={done}
      >
        Finalizar
      </LoadingButton>
      <CreateRepairRecordDialogComponent
        props={{
          open: isRepairRecordOpen,
          loading: isLoading,
          handleClose: () => handleCallOnClose(),
          handleClickConfirm
        }}
      />
      <ToastContainer />
    </FlexContainer>
  );
};
