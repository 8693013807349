import { useState } from "react";
import { UploadtPericiaPhotosProps, useUploadtPericiaPhotos } from "../../api/useUploadPericiaPhotos";

export interface UsePericiaPhotoUploaderResponse {
  image: UploadtPericiaPhotosProps;
  isUploadingImage: boolean;
  uploadPhotoRes: any;
  handleSetImage: (image: UploadtPericiaPhotosProps) => void;
}

export const usePericiaPhotoUploader = (): UsePericiaPhotoUploaderResponse => {
  const [image, setImage] = useState<UploadtPericiaPhotosProps>({
    id_pericia: "",
    item: {
      file: null,
      description: ""
    }
  });
  const { data: uploadPhotoRes, isLoading: isUploadingImage } = useUploadtPericiaPhotos({ ...image });

  const handleSetImage = ({ id_pericia, item }: UploadtPericiaPhotosProps) => {
    setImage({
      id_pericia,
      item: {
        file: item.file,
        description: item.description
      }
    });
  };

  return { image, handleSetImage, uploadPhotoRes, isUploadingImage };
};
