import { Navigate, Outlet } from "react-router-dom";
import { USER_ROLES, UserWithRole } from "../../contexts/user/user.context";
import supabase from "../../shared/database/supabase";

interface ProptectedRouteProps {
  redirectPath?: string;
  children?: React.ReactNode;
}

const ALLOWED_ROLES = [
  USER_ROLES.ADMIN,
  USER_ROLES.SALESMAN,
  USER_ROLES.USER_PRIVILEGED,
  USER_ROLES.LIMITED_ADMIN,
  USER_ROLES.PERICIA_CREATOR
];

const ProtectedRouteAdmin: React.FC<ProptectedRouteProps> = ({ children, redirectPath = "/auth/sign-in" }) => {
  const user = supabase.auth.user() as UserWithRole;
  if (!user) {
    return <Navigate to={redirectPath} />;
  }

  user.name = user.user_metadata?.name;
  user.permission = user.user_metadata?.role || "user";

  const isAllowed = ALLOWED_ROLES.includes(user.permission);
  console.log("user permision", user.permission);
  // if (user.permission === USER_ROLES.LIMITED_ADMIN) {
  //   return <Navigate to="/invoices/create" />;
  // }

  if (!user.name) {
    return <Navigate to="/auth/sign-up?operation=finish" />;
  }

  if (!isAllowed) {
    return <Navigate to="/mechanics/pericias/list" />;
  }

  return children ? <>children</> : <Outlet />;
};

export default ProtectedRouteAdmin;
