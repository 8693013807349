import supabase from "../../../shared/database/supabase";

export const updateRepairRecordsAmount = async (id_pericia: string): Promise<boolean | Error> => {
  try {
    const { data, error } = await supabase.functions.invoke("update-repair-records-amount", {
      body: JSON.stringify({ id_pericia })
    });
    if (error) throw error;

    if (data.error) throw data.error;

    return data;
  } catch (err) {
    console.log("error updating repair records amount", err);
    throw err;
  }
};
