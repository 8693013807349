import { LoadingButton } from "@mui/lab";
import { PaperCard } from "../../../shared/ui/paper-card/paper-card.component";
import { TextField } from "@mui/material";

interface UploadPericiaPhotoComponentProps {
  isUploadingImage: boolean;
  done: boolean;
  image: { item: { file: File | null; description: string } };
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleImageDescriptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleUploadImage: () => void;
}

export const UploadPericiaPhotoComponent = ({
  isUploadingImage,
  done,
  image,
  handleImageChange,
  handleImageDescriptionChange,
  handleUploadImage
}: UploadPericiaPhotoComponentProps) => {
  return (
    <PaperCard title="Adicionar foto">
      <label htmlFor="contained-button-file">
        <input accept="image/*" style={{ display: "none" }} id="contained-button-file" type="file" onChange={handleImageChange} />
        <LoadingButton fullWidth variant="outlined" sx={{ mt: 2 }} component="span" disabled={done || isUploadingImage}>
          {image.item.file ? image.item.file.name : "Selecionar imagem"}
        </LoadingButton>
      </label>
      <TextField
        autoComplete="off"
        fullWidth
        id="photoDescription"
        label="Descrição da foto"
        name="photoDescription"
        variant="standard"
        disabled={done || isUploadingImage}
        sx={{ mb: 2 }}
        onChange={handleImageDescriptionChange}
        value={image.item.description}
      />
      <LoadingButton fullWidth variant="contained" sx={{ mt: 2 }} onClick={handleUploadImage} loading={isUploadingImage} disabled={done}>
        Enviar
      </LoadingButton>
    </PaperCard>
  );
};
