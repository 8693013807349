import { Car } from "../../models/car/car";
import { CarRepository } from "../../models/car/car.repository";
import {
  CarPart,
  Pericia,
  PericiaByCostumerID,
  PericiaWithCarAndCostumer,
} from "../../models/pericia/pericia";
import {
  PericiaByCostumerIDRepository,
  PericiaRepository,
  PericiaWithCarAndCostumerRepository,
} from "../../models/pericia/pericia.repository";
import { MechanicPericiaByID } from "../../storage/supabase/pericia/pericia.storage";
import { carPartFactory } from "../factories/car-part.factory";
import {
  periciaByCostumerIDFactory,
  periciaFactory,
} from "../factories/pericia.factory";
import { capitalizeFirstLetter } from "../helpers/strings";

export const carFromRepo = (car: CarRepository): Car => {
  return {
    id: car.id,
    plate: car.plate,
    brand: car.brand,
    model: car.model,
    color: car.color,
    insuranceName: car.insurance_name || "",
    chassisNumber: car.chassis_number || "",
  };
};

export const carsFromRepo = (cars: CarRepository[]): Car[] => {
  return cars.map((car) => carFromRepo(car));
};

export const periciaWithCarAndCostumerFromRepo = (
  pericia: PericiaWithCarAndCostumerRepository
): PericiaWithCarAndCostumer => ({
  car: carFromRepo(pericia.cars),
  costumer: pericia.costumers,
  isPriceCalculated: pericia.price_calculated,
  billed: pericia.billed,
  date: pericia.date,
  done: pericia.done,
  finished: pericia.finished,
  id: pericia.id,
});

export const periciaFromRepo = (pericia: PericiaRepository): Pericia => {
  const {
    id,
    id_car,
    id_costumer,
    finished,
    done,
    price_per_working_hour,
    date,
    cars,
    costumers,
    unmount,
    price_calculated,
    unmount_price,
    insurance_hours,
    costumer_price,
    addtional_cost,
    addtional_cost_description,
    billed,
    total_hours,
    total_price,
    editable,
    priceVersion,
    ...rest
  } = pericia;

  const carParts = Object.entries(rest).map(([key, value]) => {
    return carPartFactory(
      {
        name: capitalizeFirstLetter(key),
        ...value,
        workingHours: 0,
        note: { smashes: "", details: "" },
      },
      price_per_working_hour,
      priceVersion
    );
  });

  return periciaFactory({
    id,
    done,
    finished,
    car: {
      insuranceName: cars.insurance_name || "",
      chassisNumber: cars.chassis_number || "",
      ...cars,
    },
    carParts,
    date: new Date(date),
    pricePerHour: price_per_working_hour,
    billed,
    isPriceCalculated: price_calculated,
    costumer: costumers,
    shouldUnmount: unmount,
    unmountPrice: unmount_price,
    insuranceHours: insurance_hours,
    costumerPrice: costumer_price,
    addtionalCost: addtional_cost,
    addtionalCostDescription: addtional_cost_description,
    totalHours: total_hours,
    totalPrice: total_price,
    insurancePrice: insurance_hours * price_per_working_hour,
    editable,
    priceVersion,
  });
};

export const mechanicPericiaFromRepo = (
  pericia: MechanicPericiaByID
): MechanicPericiaUpdate => {
  const {
    id,
    pricePerHour,
    date,
    costumer,
    car,
    priceVersion,
    isAddtionalRepair,
    unmount,
    ...rest
  } = pericia;

  const carParts = Object.entries(rest).map(([key, value]) => {
    return carPartFactory(
      {
        name: capitalizeFirstLetter(key),
        ...value,
        workingHours: 0,
        note: { smashes: "", details: "" },
        isAddtionalRepair,
      },
      pricePerHour,
      priceVersion
    );
  });

  return {
    id,
    date: new Date(date),
    pricePerHour,
    costumer,
    car,
    carParts,
    unmount,
  };
};

export interface MechanicPericiaUpdate {
  id: string;
  date: Date;
  pricePerHour: number;
  unmount: boolean;
  costumer: {
    name: string;
  };
  car: Car;
  carParts: CarPart[];
}

export const periciasByCostumerIDFromRepo = (
  pericias: PericiaByCostumerIDRepository[]
): PericiaByCostumerID[] => {
  return pericias.map((pericia) => {
    const {
      id_costumer,
      date,
      cars,
      unmount_price,
      costumer_price,
      addtional_cost,
      total_price,
      insurance_hours,
      insurance_price,
      price_per_working_hour,
      priceVersion,
      ...rest
    } = pericia;

    return periciaByCostumerIDFactory({
      costumerId: id_costumer,
      date: new Date(date),
      costumerPrice: costumer_price,
      unmountPrice: unmount_price,
      addtionalCost: addtional_cost,
      totalPrice: total_price,
      car: cars,
      insuranceHours: insurance_hours,
      insurancePrice: insurance_price,
      pricePerHour: price_per_working_hour,
      priceVersion,
      ...rest,
    });
  });
};
